import axios from "axios";

import { sha256 } from "js-sha256";

import { isIOS, isAndroid, isMobile } from "utils/common";
import { appendCss, buildCssUrl } from "utils/css";
import { getCurrentSettings } from "utils/settings";

import {
	SET_SESSION_ID,
	SET_SESSION,
	SET_PLAYER,
	SET_REAL_MODE,
	SET_SETTINGS_DATA,
	SET_TOKEN_EXPIRATION,
	SET_SESSION_LOADED,
	SET_SESSION_FAILED,
	UPDATE_SESSION_BEFORE,
	UPDATE_SESSION_AFTER,
	SET_REAL_MODE_BEFORE,
	SET_REAL_MODE_AFTER,
	SET_TRANSLATIONS_LOADED,
	SET_BALANCE,
	SET_LOGO_ID,
	SET_MAINTENANCE_MODE,
	SET_STREAM_CONFIGURATION,
	SET_BONUS,
	SET_BONUS_SEEN
} from "../../actionTypes";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { GAME_ACTIVITY_STATE, SCHEDULED_GAME_CATEGORY } from "constants/game.constants";

import { getUrlVars } from "utils/common";
import { showError } from "utils/message";
import SessionStorageUtils from "utils/sessionStorage";

import { initTranslations } from "translations/config";
import localStorage from "utils/localStorage";
import moment from "moment";
import { ERROR_MESSAGE, ERROR_RESOURCE, ERROR_STATUSES } from "constants/message.constants";

export const setPlayer = (player) => ({
	type: SET_PLAYER,
	payload: { player }
});

const setSession = (session) => ({
	type: SET_SESSION,
	payload: { session }
});

const setSessionId = (sessionId) => ({
	type: SET_SESSION_ID,
	payload: { sessionId }
});

const setSessionLoaded = (loaded) => ({
	type: SET_SESSION_LOADED,
	payload: { loaded }
});

const setSessionFailed = (failed) => ({
	type: SET_SESSION_FAILED,
	payload: { failed }
});

const updateSessionBefore = () => ({
	type: UPDATE_SESSION_BEFORE
});

const updateSessionAfter = () => ({
	type: UPDATE_SESSION_AFTER
});

const setRealMode = () => ({
	type: SET_REAL_MODE
});

export const setSettings = (payload) => ({
	type: SET_SETTINGS_DATA,
	payload
});

export const setBonus = (bonus, sessionId, options = { isMobile: false }) => {
	const isNewBonus = bonus?.isNewBonus;

	if (options.isMobile && localStorage.get(`vs__isNewBonus__${sessionId}`) !== false && isNewBonus) {
		localStorage.set(`vs__isNewBonus__${sessionId}`, true);
	}

	return {
		type: SET_BONUS,
		payload: { bonus }
	};
};

export const setBonusSeen = () => {
	return {
		type: SET_BONUS_SEEN
	};
};

export const setTranslationsLoaded = () => ({
	type: SET_TRANSLATIONS_LOADED
});

export const setTokenExpiration = (seconds) => ({
	type: SET_TOKEN_EXPIRATION,
	payload: { seconds }
});

const getUser = () => {
	let userName = "Aram";
	if (getUrlVars()["user"]) {
		userName = getUrlVars()["user"];
	}
	let id = userName + "_id";
	if (getUrlVars()["Id"]) {
		id = getUrlVars()["Id"];
	}
	let apiKey = "1060c7b828414c498066cea8e53435e7";
	if (getUrlVars()["apiKey"]) {
		apiKey = getUrlVars()["apiKey"];
	}
	let secret = "902374A1C2";
	if (getUrlVars()["secret"]) {
		secret = getUrlVars()["secret"];
	}
	let currency = "USD";
	if (getUrlVars()["currency"]) {
		currency = getUrlVars()["currency"];
	}

	let language = "EN";
	if (getUrlVars()["language"]) {
		language = getUrlVars()["language"];
	}

	let balance = 10000;
	if (getUrlVars()["balance"]) {
		balance = getUrlVars()["balance"];
	}

	let isDemo = true;
	if (getUrlVars()["isDemo"]) {
		isDemo = getUrlVars()["isDemo"];
	}

	const result = {
		user: {
			ProjectId: apiKey,
			LanguageId: language,
			IsDemo: isDemo,
			User:
				getUrlVars()["user"] !== "Guest"
					? {
							Id: id,
							UserName: userName,
							CurrencyId: currency,
							Balance: balance
						}
					: null,
			Urls: {
				ExitUrl: "return_url",
				DepositUrl: "deposit_url",
				LoginUrl: "Login_url"
			}
		}
	};

	result.sign = sha256.hmac(secret, JSON.stringify(result.user));
	return result;
};

export const createSession = (isStream, options = { isMobile: false }) => {
	return (dispatch) => {
		if (isStream) {
			return dispatch(createStreamAccess());
		}

		const sessionId = getUrlVars()["sessionId"];

		const d = getUser().user;
		const sign = getUser().sign;

		if (!sessionId) {
			return axios({
				url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CREATE_SESSION}`,
				method: Methods.POST,
				headers: { "X-REQUEST-SIGN": sign },
				data: d,
				params: {
					gameCategory: SCHEDULED_GAME_CATEGORY
				}
			})
				.then(({ status, data }) => {
					if (status < 400) {
						const sessionId = data?.session?.sessionId;
						if (sessionId) {
							dispatch(setSessionId(sessionId));
							dispatch(authenticate(sessionId, options));
						}
					}
				})
				.catch((err) => {
					initTranslations(null, null, () => {
						dispatch(setSessionFailed(true));
						showError({
							message: err?.response?.data?.message ?? "",
							status: err?.response?.data?.status ?? "",
							resource: err?.response?.data?.resource ?? ""
						});
					});
				});
		} else {
			const cssFilePath = getUrlVars()["cssFilePath"];
			if (cssFilePath) {
				let url = decodeURIComponent(cssFilePath);
				// if (!getUrlVars()["builder"]) {
				// 	url = `${import.meta.env.SYSTEM_CDN_URL}` + url;
				// }
				appendCss(url);
			}

			dispatch(setSessionId(sessionId));

			if (options.isMobile) {
				if (SessionStorageUtils.get("sessionId") && SessionStorageUtils.get("player")) {
					if (SessionStorageUtils.get("sessionId") === sessionId) {
						dispatch(setPlayer(SessionStorageUtils.get("player")));
						dispatch(getSessionDetails(options));
					} else {
						dispatch(authenticate(sessionId, options));
					}
				} else {
					dispatch(authenticate(sessionId, options));
				}

				SessionStorageUtils.set("sessionId", sessionId);
			} else {
				dispatch(authenticate(sessionId, options));
			}
		}
	};
};

export const authenticate = (sessionId, options = { isMobile: false }) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.AUTHENTICATE}`,
			method: Methods.POST,
			data: { sessionId }
		})
			.then(({ status, data }) => {
				if (status < 400) {
					dispatch(setPlayer({ ...data?.value }));
					dispatch(getSessionDetails(options));
				} else {
					initTranslations(null, "en", () => {
						dispatch(setSessionFailed(true));
					});
				}
			})
			.catch(() => {
				initTranslations(null, "en", () => {
					dispatch(setSessionFailed(true));
				});
			});
	};
};

export const getSessionDetails = (options = { isMobile: false }) => {
	return (dispatch, getState) => {
		const { sessionId } = getState().auth;

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_SESSION_DETAILS}`,
			method: Methods.GET
		})
			.then(({ status, data }) => {
				if (status < 400) {
					const session = { ...data?.value };
					let bonuses = session?.bonuses ?? [];
					bonuses = bonuses.filter((bonus) => moment.utc(bonus.endDate) > moment.utc(Date.now()));
					session.bonuses = bonuses;
					dispatch(setSession(session));

					if (options.isMobile && localStorage.get(`vs__isNewBonus__${sessionId}`) !== false && bonuses.some((b) => b.isNewBonus)) {
						localStorage.set(`vs__isNewBonus__${sessionId}`, true);
					}

					const onSuccess = () => {
						const desktopEnabled = session?.securitySettings?.desktop;
						const iosEnabled = session?.securitySettings?.ios;
						const androidEnabled = session?.securitySettings?.android;
						const activeGamesCount = (session?.games ?? []).filter((g) => g.state !== GAME_ACTIVITY_STATE.INACTIVE).length;

						if ((!options.isMobile && !desktopEnabled) || (options.isIOS && !iosEnabled) || (options.isAndroid && !androidEnabled)) {
							dispatch(setSessionFailed(true));
							showError({
								message: ERROR_MESSAGE.BLOCKED,
								status: ERROR_STATUSES.NOT_ALLOWED,
								resource: ERROR_RESOURCE.PLAYER_DEVICE
							});
						} else if (activeGamesCount === 0) {
							dispatch(setSessionFailed(true));
							showError({ message: ERROR_MESSAGE.INVALID_PLAYED_DATA });
						}

						dispatch(setSessionLoaded(true));
					};

					/**Append css */
					const cssId = session?.cssId;
					if (!getUrlVars()["cssFilePath"] && cssId && session.projectId && session.partnerId) {
						appendCss(buildCssUrl(cssId, session.partnerId, session.projectId));
					}

					initTranslations(session.languageFilePath, session.languageId, onSuccess);
				} else {
					dispatch(setSessionLoaded(true));
				}
			})
			.catch(() => {
				dispatch(setSessionLoaded(true));
				dispatch(setSessionFailed(true));
			});
	};
};

export const updateSessionDetails = (options = { isMobile: false }) => {
	return (dispatch, getState) => {
		const { sessionId } = getState().auth;

		dispatch(updateSessionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_SESSION_DETAILS}`,
			method: Methods.GET
		})
			.then(({ status, data }) => {
				if (status < 400) {
					const session = { ...data?.value };
					let bonuses = session?.bonuses ?? [];
					bonuses = bonuses.filter((bonus) => moment.utc(bonus.endDate) > moment.utc(Date.now()));
					session.bonuses = bonuses;
					dispatch(setSession(session));
					dispatch(updateSessionAfter());

					if (options.isMobile && localStorage.get(`vs__isNewBonus__${sessionId}`) !== false && bonuses.some((b) => b.isNewBonus)) {
						localStorage.set(`vs__isNewBonus__${sessionId}`, true);
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

const setRealModeBefore = () => ({
	type: SET_REAL_MODE_BEFORE
});

const setRealModeAfter = () => ({
	type: SET_REAL_MODE_AFTER
});

export const switchToRealMode = (options = { isMobile: false }) => {
	return (dispatch) => {
		dispatch(setRealModeBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.REAL_MODE}`,
			method: Methods.POST
		})
			.then(({ data }) => {
				dispatch(setRealModeAfter());
				dispatch(setPlayer({ ...data?.value }));

				dispatch(setRealMode());

				dispatch(updateSessionDetails(options));
			})
			.catch(() => {
				dispatch(setRealModeAfter());
			});
	};
};

export const setBalance = (balance) => ({
	type: SET_BALANCE,
	payload: { balance }
});

export const setLogoId = (logoId) => ({
	type: SET_LOGO_ID,
	payload: { logoId }
});

export const setMaintenanceMode = () => ({
	type: SET_MAINTENANCE_MODE
});

export const saveSettings = (data) => {
	return (dispatch, getState) => {
		const { session } = getState().auth;

		const settings = {
			...getCurrentSettings(session),
			...data
		};

		if (!session.isAuthorized || session.isDemo) {
			dispatch(setSettings(settings));
			localStorage.set(`vs__settings__${session.projectId}`, settings);
			return;
		}

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SAVE_SETTINGS}`,
			method: Methods.POST,
			data: settings
		}).then(({ data }) => {
			const value = data?.value ?? {};
			dispatch(setSettings(value));
			localStorage.set(`vs__settings__${session.projectId}`, value);
		}).catch(err => {
			if (!err.isAxiosError) { return; }
			const { data } = err.response;
			if (data.status === ERROR_STATUSES.INVALID_PARAMETERS && data.resource === ERROR_RESOURCE.PLAYER_SETTINGS) {
				dispatch(setSettings(settings));
				localStorage.set(`vs__settings__${session.projectId}`, settings);
			}
		});
	};
};

const setStreamConfiguration = (id, configuration) => ({
	type: SET_STREAM_CONFIGURATION,
	payload: { id, configuration }
});

/** Stream Configuration */

export const getStreamConfiguration = (id, fromStream) => {
	return (dispatch) => {
		const headers = {};
		if (fromStream) {
			headers.stream = true;
		}

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_STREAM_CONFIGURATION}`,
			method: Methods.GET,
			params: { id },
			headers: headers
		})
			.then(({ status, data: { value: configuration } }) => {
				if (status < 400) {
					dispatch(setStreamConfiguration(id, configuration));
				}
			})
			.catch(Function.prototype);
	};
};

/** Stream Access */

const createStreamAccess = () => {
	return (dispatch) => {
		const guid = getUrlVars()["guid"];
		const gameId = getUrlVars()["gameId"];

		if (!guid) {
			initTranslations(null, "en", () => {
				dispatch(setSessionFailed(true));
				showError({ message: ERROR_MESSAGE.GUID_NOT_FOUND });
			});
		} else if (!gameId) {
			initTranslations(null, "en", () => {
				dispatch(setSessionFailed(true));
				showError({ message: ERROR_MESSAGE.GAME_NOT_FOUND });
			});
		} else {
			return axios({
				url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CREATE_STREAM_ACCESS}`,
				method: Methods.POST,
				data: {
					id: guid
				}
			})
				.then(({ data }) => {
					dispatch(setPlayer({ ...data.value }));
					initTranslations(null, "en", () => {
						dispatch(
							setSession({
								games: [{ id: gameId }]
							})
						);
						dispatch(setSessionLoaded(true));
					});
				})
				.catch((err) => {
					initTranslations(null, "en", () => {
						dispatch(setSessionFailed(true));
						showError({
							message: err?.response?.data?.message ?? "",
							status: err?.response?.data?.status ?? "",
							resource: err?.response?.data?.resource ?? ""
						});
					});
				});
		}
	};
};
