import { BETSLIP_MODES } from "constants/betslip.constants";
import { useEffect, useMemo, useRef } from "react";
import { setBetslipMode } from "store/actions/betslip/betslip.actions";
import { getEventsRepeatedBets } from "utils/bets";
import useGlobalVariables from "./useGlobalVariables";
import { useDispatch, useSelector } from "react-redux";
import usePrevious from "./usePrevious";
import { getCurrentSettings } from "utils/settings";

const useBetSlipModeAutoChanger = () => {

	const { isMobile } = useGlobalVariables();
	const prevNonExpiredBetsRef = useRef();
	const bets = useSelector(state => state.betslip.bets);
	const dispatch = useDispatch();
	const isQuickBetEnabled = useSelector(state => getCurrentSettings(state.auth.session).isQuickBet);

	const { nonExpiredBets, nonHidden } = useMemo(() => {
		return {
			nonExpiredBets: bets.filter((b) => !b.isExpired),
			nonHidden: bets.filter(bet => !bet.hidden)
		};
	}, [bets]);

	prevNonExpiredBetsRef.current = usePrevious(nonExpiredBets);

	useEffect(() => {
		if (isQuickBetEnabled) {
			return;
		}
		if (nonExpiredBets.length < 2) {
			dispatch(setBetslipMode(BETSLIP_MODES.SINGLE));
		} else if ((prevNonExpiredBetsRef.current?.length === 1 && nonExpiredBets.length === 2) || (
			isMobile && nonExpiredBets.length >= 2
		)) {
			const groupedRepeatedBets = getEventsRepeatedBets(bets, BETSLIP_MODES.MULTI);
			const hasDuplicate = nonExpiredBets.some(element => Boolean(groupedRepeatedBets[element.eventId]));

			if (!hasDuplicate) {
				dispatch(setBetslipMode(BETSLIP_MODES.MULTI));
			}
		}
	}, [nonExpiredBets.length, bets.length, isMobile, dispatch]);

	return {
		nonHidden
	}

}
export default useBetSlipModeAutoChanger;