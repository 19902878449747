import { SET_HISTORY_FILTERS, RESET_HISTORY_FILTERS, HISTORY_BEFORE, HISTORY_AFTER, SET_HISTORY, ADD_HISTORY, RESET_HISTORY, CANCEL_HISTORY, UPDATE_HISTORY_BET_SLIP_BET, UPDATE_HISTORY_BET_SLIP, SET_HISTORY_OPENED_BET_SLIP_ID } from "../../actionTypes";

import { BET_STATE } from "constants/betslip.constants";

import { getDefaultDate } from "utils/dateTime";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case HISTORY_BEFORE:
			return {
				...state,
				loading: true
			};
		case HISTORY_AFTER:
			return {
				...state,
				loading: false
			};
		case SET_HISTORY:
			return {
				...state,
				data: payload.data.item2,
				total: payload.data.item1,
				loaded: true
			};
		case ADD_HISTORY:
			return {
				...state,
				data: state.data.concat(payload.data.item2),
				total: payload.data.item1,
				loaded: true
			};
		case RESET_HISTORY:
			return {
				...state,
				data: [],
				total: 0,
				loaded: false
			};
		case SET_HISTORY_FILTERS:
			return {
				...state,
				filters: {
					...state.filters,
					...payload.filters
				},
				filtersReset: false
			};
		case RESET_HISTORY_FILTERS:
			return {
				...state,
				filters: {
					page: 1,
					limit: 20,
					...getDefaultDate()
				},
				filtersReset: true
			};
		case CANCEL_HISTORY:
			return {
				...state,
				data: state.data.map((d) => (d.id !== payload.bet.id ? d : { ...d, state: BET_STATE.CANCELLED, bets: d.bets.map((det) => ({ ...det, state: BET_STATE.CANCELLED, allowCancel: false })) }))
			};
		case UPDATE_HISTORY_BET_SLIP_BET:
			return {
				...state,
				data: state.data.map((d) => ({ ...d, bets: d.bets.map((det) => (det.id === payload.bet.id ? payload.bet : det)) }))
			};
		case UPDATE_HISTORY_BET_SLIP:
			return {
				...state,
				data: state.data.map((d) => (d.id === payload.bet.id ? { ...d, ...payload.bet, bets: d.bets } : d))
			};
		case SET_HISTORY_OPENED_BET_SLIP_ID:
			return {
				...state,
				openedBetSlipId: payload.id
			};
		default:
			return state;
	}
};
