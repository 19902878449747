import { Fragment, useEffect, useState } from "react";

import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Tooltip from "components/ui/tooltip"
import Switch from "components/ui/switch";

import { makeCurrencyText, isRTL, mergeClassNames } from "utils/common";
import { runLoginScript } from "utils/iframe";
import { exitFromSession } from "utils/auth";
import { formatDate } from "utils/dateTime";

import { setUseBonus } from "store/actions/bonus/bonus.actions";
import { setShowMobileBetSlipsModal } from "store/actions/betslip/betslip.actions";
import { redirectToPage } from "utils/navigate";
import Paths from "constants/path.constants";

import { BONUS_TYPE } from "constants/bonus.constants";
import { SWITCH_SIZES } from "constants/ui.constants";
import useGlobalVariables from "hooks/useGlobalVariables";
import vsToast from "utils/toast";

const getIsDemoSession = state => state.auth.session.isDemo;
const getSessionCurrency = state => state.auth.session.currency ?? {};
const getSessionBonuses = state => state.auth.session.bonuses ?? [];
const getSessionBalance = state => state.auth.session.balance ?? 0;
const getIsHideHeaderInMobileFrame = state => state.auth.session.isHideHeaderInMobileFrame;
const getExitUrl = state => state.auth.session.exitUrl;
const getLogoId = state => state.auth.session.logoId;
const getProjectId = state => state.auth.session.projectId;
const getPartnerId = state => state.auth.session.partnerId;
const getMaintenanceMode = state => state.auth.session.maintenanceMode;
const getIsAuthorized = state => state.auth.session.isAuthorized;
const getIsRTLLanguage = (state) => isRTL(state.auth.session.languageId);

/** Mobile header Component */
const MobileHeader = ({
	useBonus,
	setUseBonus,
	showMobileBetslipsModal,
	setShowMobileBetSlipsModal
}) => {

	const { t } = useTranslation();
	const navigate = useNavigate();
	const globalVariables = useGlobalVariables();

	const isDemo = useSelector(getIsDemoSession);
	const bonuses = useSelector(getSessionBonuses);
	const balance = useSelector(getSessionBalance);
	const currency = useSelector(getSessionCurrency);
	const isHideHeaderInMobileFrame = useSelector(getIsHideHeaderInMobileFrame);
	const exitUrl = useSelector(getExitUrl);
	const logoId = useSelector(getLogoId);
	const projectId = useSelector(getProjectId);
	const partnerId = useSelector(getPartnerId);
	const maintenanceMode = useSelector(getMaintenanceMode);
	const isAuthorized = useSelector(getIsAuthorized);
	const isRTLLanguage = useSelector(getIsRTLLanguage);

	const { isMobile, isDemo: isDemoFromContext } = globalVariables;
	const isDeterminedDemo = isDemoFromContext || isDemo;

	const hideHeaderContent = isMobile && isHideHeaderInMobileFrame;
	const hideHeaderContentReal = hideHeaderContent && !isDeterminedDemo;

	const goBack = () => {
		if (exitUrl) {
			return exitFromSession()
		}

		if (showMobileBetslipsModal) {
			return setShowMobileBetSlipsModal(false);
		}

		redirectToPage(navigate, Paths.HOME);
	};

	const getHeaderText = (bonus) => {
		if (bonus?.bonusType === BONUS_TYPE.FREEBET) {
			return `${t("bonus.freeBet")} ${t("bonus.bonus")}`;
		}
		if (bonus?.bonusType === BONUS_TYPE.FREEAMOUNT) {
			return `${t("bonus.freeAmount")} ${t("bonus.bonus")}`;
		}

		return "";
	};

	useEffect(() => {
		if (bonuses.length === 0) {
			setUseBonus(false)
		}
	}, [bonuses]);

	/** Function to render bonus/balance JSX
		* @function
		* @returns {JSX}
		* @memberOf MobileHeader
	*/
	const renderBonusAndBalance = () => (
		<div className="vs--flex vs--flex-col vs--align-end vs--justify-center vs--mobile-header-balance">
			<b className="vs--title-white vs--font-small vs--font-regular">{useBonus ? getHeaderText(bonuses[0]) : t("bonus.balance")}</b>
			<span className="vs--title-brand vs--font-small vs--font-regular vs--mt-2">
				{
					useBonus
						? bonuses[0]?.bonusType === BONUS_TYPE.FREEBET
							? `${bonuses[0]?.roundCount ?? 0}x${bonuses[0]?.amount ?? 0} ${currency.code ?? ""}`
							: bonuses[0]?.bonusType === BONUS_TYPE.FREEAMOUNT
								? `${bonuses[0]?.amount ?? 0} ${currency.code ?? ""}`
								: ""
						: makeCurrencyText(balance, !isDemo ? currency : { code: "FUN", decimalCount: 2 })
				}
			</span>
		</div>
	)

	const handleSwitchChange = () => {
		if (!useBonus) {
			vsToast.success(t("bonus.bonusIsNowActive"))
		}
		setUseBonus(!useBonus)
	}

	if (hideHeaderContentReal && !bonuses.length) {
		return null
	}

	return (
		<div className={mergeClassNames("vs--header vs--ns vs--flex vs--justify-between vs--align-center vs--pl-16 vs--pr-16", ((hideHeaderContentReal && bonuses.length > 0) || hideHeaderContent) && "vs--justify-end")}>
			{
				hideHeaderContent
					? null
					: (
						<div className="vs--flex vs--align-center">
							<div
								className="vs--flex vs--mr-16"
								onClick={goBack}
							>
								<i className="vs--header-back ic_right vs--font-bigest"></i>
							</div>
							{logoId && projectId && partnerId && (
								<img
									alt="logo"
									src={`${import.meta.env.SYSTEM_CDN_URL}/${partnerId}/projects/${projectId}/ui/${logoId}_mobile_logo.png`}
								/>
							)}
						</div>
					)
			}
			{
				!maintenanceMode
					? (
						isAuthorized ? (
							<div className="vs--flex vs--align-center vs--justify-end">
								{
									useBonus
										? (
											<Tooltip
												title={(
													<Fragment>
														<b className="vs--title vs--font-medium vs--font-small vs--mb-2">
															{
																bonuses[0]?.bonusType === BONUS_TYPE.FREEBET ? t('bonus.freeBet') :
																	bonuses[0]?.bonusType === BONUS_TYPE.FREEAMOUNT ? t('bonus.freeAmount') : ""
															}
														</b>
														<b className="vs--title vs--font-regular vs--font-small">{`${t("bonus.availableUntil")}: ${formatDate(bonuses[0]?.endDate ?? "")}`}</b>
													</Fragment>
												)}
												placement="bottomRight"
												overlayClassName="vs--mobile-header-balance-tooltip"
											>
												{renderBonusAndBalance()}
											</Tooltip>
										)
										: hideHeaderContentReal && bonuses.length > 0
											? null
											: renderBonusAndBalance()
								}
								{
									bonuses.length > 0
										? (
											<div className="vs--flex vs--flex-row vs--align-center vs--justify-between vs--mobile-header-bonus vs--ml-8 vs--pl-8">
												<Switch
													dir={isRTLLanguage ? "ltr" : "rtl"}
													onChange={handleSwitchChange}
													checked={useBonus}
													size={SWITCH_SIZES.LARGE}
													className="vs--mobile-header-bonus-content-switch"
													indicatorContent={useBonus ? <i className="ic_bonus vs--font-small" /> : <span className="vs--title-white vs--font-small vs--font-medium">&#36;</span>}
													unCheckedBoxContent={<i className="ic_bonus vs--font-small" />}
													checkedBoxContent={<span className="vs--title-white vs--font-small vs--font-medium">&#36;</span>}
												/>
											</div>
										)
										: null
								}
							</div>
						)
							: (
								<div
									className="vs--flex vs--flex-row vs--align-center vs--justify-end vs--mobile-header-login"
									onClick={() => {
										runLoginScript(globalVariables)
									}}
								>
									<span className="vs--title vs--font-normal vs--font-regular">
										{t("common.login")}
									</span>
								</div>
							)
					)
					: null
			}
		</div>
	);
};

/** MobileHeader propTypes
 * PropTypes
 */
MobileHeader.propTypes = {
	/** Redux state property, bonus usage property */
	useBonus: PropTypes.bool,
	/** Redux action to set bonus data */
	setUseBonus: PropTypes.func,
	/** Redux property, state visibility of mobile betslips modal */
	showMobileBetslipsModal: PropTypes.bool,
	/** Redux action, sho/hide betslip modale for mobile */
	setShowMobileBetSlipsModal: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		useBonus: state.bonus.useBonus,
		showMobileBetslipsModal: state.betslip.showMobileBetslipsModal,
	};
};

const mapDispatchToProps = (dispatch) => ({
	setUseBonus: (event) => {
		dispatch(setUseBonus(event));
	},
	setShowMobileBetSlipsModal: (value) => {
		dispatch(setShowMobileBetSlipsModal(value))
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);