import i18n from "translations/config";

import BaseMarkets from "./baseMarkets";

import { PERIOD, PENALTY_SHOOTOUT_MARKETS_TABS, PENALTY_SHOOTOUT_MARKETS_GROUPS, PENALTY_MISS_CASE_MARKETS, PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS } from "constants/market.constants";

import { isNullish, groupBy } from "utils/common";

class PenaltyShotoutsMarkets extends BaseMarkets {
	/** Get All possible tabs
	 * @function
	 * @returns {object}
	 * @memberof PenaltyShotoutsMarkets
	 */
	getGroups() {
		return Object.keys(this.getGroupsStructure());
	}

	/** Get All possible market tabs with their markets
	 * @function
	 * @returns {object}
	 * @memberof PenaltyShotoutsMarkets
	 */
	getGroupsStructure() {
		return {
			[PENALTY_SHOOTOUT_MARKETS_TABS.ALL]: {
				groups: [
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.RESULT, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.RESULT },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.BOTH_TEAMS_TO_SCORE, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.BOTH_TEAMS_TO_SCORE },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_GOAL_MISS, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_GOAL_MISS },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_GOAL_MISS, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_GOAL_MISS },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.SUDDEN_DEATH_OUTCOME, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.SUDDEN_DEATH_OUTCOME },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.SUDDEN_DEATH_RESULT, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.SUDDEN_DEATH_RESULT },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE, statistics: 1 },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE_PER_SHOT, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE_PER_SHOT },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_GOALS, period: PERIOD.PENALTY_SHOOTOUT, argument: (arg) => Number(arg) >= 4.5, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_GOALS },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_PENALTIES, period: PERIOD.PENALTY_SHOOTOUT, argument: (arg) => Number(arg) >= 7.5, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_PENALTIES },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_MISS, period: PERIOD.PENALTY_SHOOTOUT, argument: (arg) => Number(arg) <= 5, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_MISS },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_GOALS_ASIAN, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_GOALS_ASIAN },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.MATCH_GOALS_COUNT, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.MATCH_GOALS_COUNT },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.HANDICAP, period: PERIOD.PENALTY_SHOOTOUT, argument: (arg) => Number(arg) % 0.5 === 0, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.HANDICAP },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.ASIAN_HANDICAP, period: PERIOD.PENALTY_SHOOTOUT, argument: (arg) => Number(arg) % 0.5 !== 0, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.ASIAN_HANDICAP }
				]
			},
			[PENALTY_SHOOTOUT_MARKETS_TABS.MAIN]: {
				groups: [
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.RESULT, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.RESULT },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.BOTH_TEAMS_TO_SCORE, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.BOTH_TEAMS_TO_SCORE },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_GOAL_MISS, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_GOAL_MISS },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_GOAL_MISS, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_GOAL_MISS },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.SUDDEN_DEATH_OUTCOME, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.SUDDEN_DEATH_OUTCOME },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.SUDDEN_DEATH_RESULT, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.SUDDEN_DEATH_RESULT }
				]
			},
			[PENALTY_SHOOTOUT_MARKETS_TABS.CORRECT_SCORE]: {
				groups: [
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE, statistics: 1 },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE_PER_SHOT, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE_PER_SHOT }
				]
			},
			[PENALTY_SHOOTOUT_MARKETS_TABS.TOTAL]: {
				groups: [
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_GOALS, period: PERIOD.PENALTY_SHOOTOUT, argument: (arg) => Number(arg) >= 4.5, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_GOALS },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_PENALTIES, period: PERIOD.PENALTY_SHOOTOUT, argument: (arg) => Number(arg) >= 7.5, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_PENALTIES },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_MISS, period: PERIOD.PENALTY_SHOOTOUT, argument: (arg) => Number(arg) <= 5, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_MISS },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_GOALS_ASIAN, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_GOALS_ASIAN },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.MATCH_GOALS_COUNT, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.MATCH_GOALS_COUNT }
				]
			},
			[PENALTY_SHOOTOUT_MARKETS_TABS.HANDICAP]: {
				groups: [
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.HANDICAP, period: PERIOD.PENALTY_SHOOTOUT, argument: (arg) => Number(arg) % 0.5 === 0, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.HANDICAP },
					{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.ASIAN_HANDICAP, period: PERIOD.PENALTY_SHOOTOUT, argument: (arg) => Number(arg) % 0.5 !== 0, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.ASIAN_HANDICAP }
				]
			}
		};
	}

	/** Function to sort rtps data by order Number
	 * @function
	 * @param {array} markets - given markets
	 * @param {array} rtps
	 * @param {Callback} getRtp
	 * @returns {string} data - sorted array
	 */
	sortMarketsByOrderNumber = (markets, rtps, getRtp = Function.prototype, period = null) => {
		const groupedRtps = rtps.reduce((acc, rtp) => {
			if (period !== null) {
				if (rtp.period === period) {
					acc[rtp.name] = rtp;
				}
				return acc;
			}
			if (!acc[rtp.name]) {
				acc[rtp.name] = rtp;
			}

			return acc;
		}, {});

		const compareCb = (item1, item2) => {
			const getOrderNumber = (market) => {
				const rtp = getRtp(groupedRtps, market);

				if (!rtp) {
					let generatedMarketRtp;

					[
						PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME,
						PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME
					].forEach((key) => {
						if (market.group === PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS[key]) {
							generatedMarketRtp = groupedRtps[key];
						}
					})

					return generatedMarketRtp?.orderNumber ?? 0;
				}

				return rtp.orderNumber;
			}

			const m1OrderNumber = getOrderNumber(item1);
			const m2OrderNumber = getOrderNumber(item2);

			return m1OrderNumber > m2OrderNumber ? 1 : -1;
		};

		const modifiedGeneratedMarkets = structuredClone(markets).map((market) => {
			Object.values(PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS).forEach((generatedGroup) => {
				if (generatedGroup !== market.group || groupedRtps[generatedGroup]) {
					return;
				}

				if (generatedGroup === PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS.PENALTY_SHOT_OUTCOME) {
					market?.markets.sort(compareCb);

					const setGeneratedMarketOrder = () => {
						const minOrderNumber = market?.markets.reduce((acc, groupedMarket) => {
							const orderNumber = getRtp(groupedRtps, groupedMarket)?.orderNumber;

							if (isNullish(orderNumber)) {
								return acc;
							}

							if (isNullish(acc)) {
								acc = orderNumber;
							}

							if (acc > orderNumber) {
								acc = orderNumber;
							}

							return acc;
						}, null);

						groupedRtps[generatedGroup] = { name: generatedGroup, orderNumber: minOrderNumber };
					}

					setGeneratedMarketOrder();
				}
			})

			return market;
		});

		return modifiedGeneratedMarkets.sort(compareCb);
	};

	/** Transform markets array to the array , useful for markets UI
	 * @function
	 * @param {array } data - data to transform
	 * @param {object} gameData - Event data
	 * @param {Array} rtps - Array of rtps to apply on markets
	 * @param {number} gameType - The gameType
	 * @returns {object}
	 * @memberof PenaltyShotoutsMarkets
	 */
	makeMarketsArray(data, gameData, rtps, gameType) {
		const obj = {};
		const grouped = groupBy(data, (e) => {
			if ([PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_GOAL_MISS, PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_GOAL_MISS, PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME, PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME].includes(e.groupTitle)) {
				return PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS.PENALTY_SHOT_OUTCOME + "__" + e.period;
			}

			return e.groupTitle + "__" + e.period;
		});

		Object.keys(grouped).forEach((o) => {
			const gr = o.split("__")[0];
			const period = o.split("__")[1] ? Number(o.split("__")[1]) : null;
			const col = (grouped[o][0].oddList || []).length > 2 && ![PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE].includes(gr) ? 3 : 2;
			obj[o] = {
				col: col,
				bets: [],
				period: period,
				group: gr === PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS.PENALTY_SHOT_OUTCOME ? PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS.PENALTY_SHOT_OUTCOME : grouped[o][0].group,
				statistics: grouped[o][0].statistics,
				isNew: grouped[o][0].isNew
			};

			grouped[o].forEach((g) => {
				let oddList = g.oddList || [];

				if (
					[
						PENALTY_SHOOTOUT_MARKETS_GROUPS.BOTH_TEAMS_TO_SCORE,
						PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_GOAL_MISS,
						PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_GOAL_MISS,
						PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME,
						PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME,
						PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE_PER_SHOT
					].includes(g.group)
				) {
					if (!obj[o].markets) {
						obj[o].markets = [];
					}

					const marketGroup = {
						col: col,
						bets: [],
						period: g.period,
						group: g.group,
						argument: g.argument,
						statistics: g.statistics,
						isNew: g.isNew
					};
					const preservedMarketGroup = {
						bets: [],
						period: g.period,
						argument: g.argument
					};

					g.oddList.forEach((odd) => {
						if ([PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME, PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME].includes(g.group) && [PENALTY_MISS_CASE_MARKETS.GOAL_SAVES, PENALTY_MISS_CASE_MARKETS.GOAL_WOODWORKS, PENALTY_MISS_CASE_MARKETS.OUT_OF_GOALS].includes(odd.outcome)) {
							preservedMarketGroup.group = PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS[g.group];

							preservedMarketGroup.bets.push({
								name: odd.outcome,
								factor: this.applyRtp(odd.factor, g.group, g.period, g.argument, odd.outcome, rtps),
								oddState: odd.state,
								outcome: odd.outcome,
								argument: g.argument,
								id: odd.id,
								showName: this.makeName(odd.outcome, gameType, true, `markets.${gameType}`)
							});
							preservedMarketGroup.col = preservedMarketGroup.bets.length > 2 ? 3 : 2;
						} else if (g.group === PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE_PER_SHOT) {
							marketGroup.bets.push({
								name: odd.outcome.replace("Home", "").replace("Away", ":"),
								factor: this.applyRtp(odd.factor, obj[o].group, g.period, g.argument, odd.outcome, rtps),
								oddState: odd.state,
								outcome: odd.outcome,
								argument: g.argument,
								id: odd.id,
								showName: this.makeName(odd.outcome.replace("Home", "").replace("Away", ":"), gameType)
							});
						} else {
							marketGroup.bets.push({
								name: odd.outcome,
								factor: this.applyRtp(odd.factor, g.group, g.period, g.argument, odd.outcome, rtps),
								oddState: odd.state,
								outcome: odd.outcome,
								argument: g.argument,
								id: odd.id,
								showName: g.group !== PENALTY_SHOOTOUT_MARKETS_GROUPS.BOTH_TEAMS_TO_SCORE ? this.makeName(odd.outcome, gameType, true, `markets.${gameType}`) : this.makeName(odd.outcome, gameType)
							});
						}
					});

					if (marketGroup.bets.length > 0) {
						obj[o].markets.push(marketGroup);
					}

					if (preservedMarketGroup.bets.length > 0) {
						obj[o].markets.push(preservedMarketGroup);
					}
				} else if ([PENALTY_SHOOTOUT_MARKETS_GROUPS.MATCH_GOALS_COUNT].includes(gr)) {
					oddList.forEach((odd) => {
						obj[o].bets.push({
							name: odd.outcome.replace("Goal", ""),
							factor: this.applyRtp(odd.factor, obj[o].group, g.period, g.argument, odd.outcome, rtps),
							oddState: odd.state,
							outcome: odd.outcome,
							argument: g.argument,
							id: odd.id,
							showName: this.makeName(odd.outcome.replace("Goal", ""), gameType)
						});
					});
				} else if ([PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_GOALS, PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_PENALTIES, PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_MISS, PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_GOALS_ASIAN].includes(gr)) {
					oddList.forEach((odd) => {
						obj[o].bets.push({
							name: odd.outcome + " " + g.argument,
							factor: this.applyRtp(odd.factor, gr, g.period, g.argument, odd.outcome, rtps),
							oddState: odd.state,
							outcome: odd.outcome,
							argument: g.argument,
							id: odd.id,
							showName: this.makeName("%" + odd.outcome + "% " + g.argument, gameType)
						});
					});
				} else if ([PENALTY_SHOOTOUT_MARKETS_GROUPS.HANDICAP, PENALTY_SHOOTOUT_MARKETS_GROUPS.ASIAN_HANDICAP].includes(gr)) {
					oddList.forEach((odd) => {
						obj[o].bets.push({
							name: "%" + odd.outcome + "% (" + (odd.outcome === "Home" ? g.argument : 0 - g.argument) + ")",
							factor: this.applyRtp(odd.factor, gr, g.period, g.argument, odd.outcome, rtps),
							oddState: odd.state,
							outcome: odd.outcome,
							argument: g.argument,
							id: odd.id,
							showName: this.makeName("%[" + odd.outcome + "]% (<span class='vs--text-ltr'>" + (odd.outcome === "Home" ? g.argument : 0 - g.argument) + "</span>)", gameType)
						});
					});
				} else if ([PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE].includes(gr)) {
					oddList.forEach((odd) => {
						obj[o].bets.push({
							name: odd.outcome.replace("Home", "").replace("Away", ":"),
							factor: this.applyRtp(odd.factor, obj[o].group, g.period, g.argument, odd.outcome, rtps),
							oddState: odd.state,
							outcome: odd.outcome,
							argument: g.argument,
							id: odd.id,
							showName: this.makeName(odd.outcome.replace("Home", "").replace("Away", ":"), gameType)
						});
					});
				} else if ([PENALTY_SHOOTOUT_MARKETS_GROUPS.RESULT, PENALTY_SHOOTOUT_MARKETS_GROUPS.SUDDEN_DEATH_RESULT].includes(gr)) {
					oddList.forEach((odd) => {
						obj[o].bets.push({
							name: "%" + odd.outcome + "%",
							factor: this.applyRtp(odd.factor, obj[o].group, g.period, g.argument, odd.outcome, rtps),
							oddState: odd.state,
							outcome: odd.outcome,
							argument: g.argument,
							id: odd.id,
							showName: this.makeName("%" + odd.outcome + "%", gameType)
						})
					})
				} else {
					oddList.forEach((odd) => {
						obj[o].bets.push({
							name: odd.outcome,
							factor: this.applyRtp(odd.factor, obj[o].group, g.period, g.argument, odd.outcome, rtps),
							oddState: odd.state,
							outcome: odd.outcome,
							argument: g.argument,
							id: odd.id,
							showName: this.makeName(odd.outcome, gameType)
						});
					});
				}
			});
		});

		const arr = [];

		Object.keys(obj).forEach((o) => {
			let item = {
				group: o.split("__")[0],
				col: obj[o].col,
				bets: obj[o].bets.map((b) => (b.factor >= 1.01 ? b : { ...b, disabled: true })),
				markets: obj[o].markets,
				period: o.split("__")[1] ? Number(o.split("__")[1]) : null,
				systemGroup: obj[o].group,
				statistics: obj[o].statistics,
				isNew: obj[o].isNew
			};

			if (item.bets.length > 1 || item?.markets.length > 1) {
				if ([PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_GOALS_ASIAN, PENALTY_SHOOTOUT_MARKETS_GROUPS.HANDICAP, PENALTY_SHOOTOUT_MARKETS_GROUPS.ASIAN_HANDICAP].includes(item.group)) {
					const blockedArguments = item.bets.reduce((acc, b) => {
						const boolResult = b.factor < 1.01;
						if (boolResult) {
							acc.push(b.argument);
						}
						return acc;
					}, []);
					const filteredBets = item.bets.filter((b) => !blockedArguments.includes(b.argument));
					const market = {
						...item,
						bets: filteredBets
					};

					arr.push(market);
				} else {
					arr.push(item);
				}
			}
		});

		return arr;
	}

	/** Add teams name into market name
	 * @function
	 * @param {string } str - market name
	 * @param {string} group - group name
	 * @param {number} gameType - Game type
	 * @returns {string}
	 * @memberof PenaltyShotoutsMarkets
	 */
	makeName(str, gameType, needToTranslateByKey = false, key = "") {
		let result = str;
		if (needToTranslateByKey) {
			return i18n.t(`${key}.${result}`);
		}
		switch (true) {
			case str.includes("%Home%"):
				result = str.replace("%Home%", i18n.t(`markets.${gameType}.resultTeam1`));
				break;
			case str.includes("%Away%"):
				result = str.replace("%Away%", i18n.t(`markets.${gameType}.resultTeam2`));
				break;
			case str.includes("%[Home]%"):
				result = str.replace("%[Home]%", i18n.t(`markets.${gameType}.team`) + "1");
				break;
			case str.includes("%[Away]%"):
				result = str.replace("%[Away]%", i18n.t(`markets.${gameType}.team`) + "2");
				break;
			case str.includes("%Over%"):
				result = str.replace("%Over%", i18n.t(`markets.${gameType}.over`));
				break;
			case str.includes("%Under%"):
				result = str.replace("%Under%", i18n.t(`markets.${gameType}.under`));
				break;
			case str.includes("%Goal%"):
				result = str.replace("%Goal%", "");
				break;
			case ["Yes", "No"].includes(str):
				result = i18n.t(`common.${str.toLowerCase()}`);
				break;
			case ["Odd", "Even"].includes(str):
				result = i18n.t(`markets.${gameType}.${str}`);
				break;
			case str.includes("%HomeWinAfterOvertime%"):
				result = str.replace("%HomeWinAfterOvertime%", i18n.t(`markets.${gameType}.resultTeam1`));
				break;
			case str.includes("%AwayWinAfterOvertime%"):
				result = str.replace("%AwayWinAfterOvertime%", i18n.t(`markets.${gameType}.resultTeam2`));
				break;
		}

		return result;
	}

	/** Make market beuty name, to show in winner market section
	 * @function
	 * @param {object} market - the market object
	 * @returns {string}
	 * @memberof PenaltyShotoutsMarkets
	 */
	makeWinnerMarketName(market) {
		let result = "";

		const team1 = i18n.t(`countries.${market.team1}`, market.team1);
		const team2 = i18n.t(`countries.${market.team2}`, market.team2);

		switch (market.outcome) {
			case "Home":
				result = team1;
				break;
			case "Away":
				result = team2;
				break;
			case "Yes":
				result = i18n.t("common.yes");
				break;
			case "No":
				result = i18n.t("common.no");
				break;
			case "Over":
				result = i18n.t(`markets.${market.gameType}.over`);
				break;
			case "Under":
				result = i18n.t(`markets.${market.gameType}.under`);
				break;
			case "HomeWinAfterOvertime":
				result = team1 + " " + i18n.t(`markets.${market.gameType}.winOverTime`);
				break;
			case "AwayWinAfterOvertime":
				result = team2 + " " + i18n.t(`markets.${market.gameType}.winOverTime`);
				break;
			default:
				if ([PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE, PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE_PER_SHOT].includes(market.group)) {
					result = market.outcome.replace("Home", "").replace("Away", " : ");
				} else if (PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS[market.group] || [PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME, PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME].includes(market.group)) {
					result = i18n.t(`markets.${market.gameType}.${market.outcome}`);
				} else if (market.outcome.includes("Goal")) {
					result = market.outcome.replace("Goal", "");
				} else {
					result = market.outcome;
				}
				break;
		}

		return result;
	}

	/** Make market beuty name from system name
	 * @function
	 * @param {object} market - the market object
	 * @returns {string}
	 * @memberof PenaltyShotoutsMarkets
	 */
	makeGroupTitle(market) {
		let res = "";

		let gr = Object.values(PENALTY_MISS_CASE_MARKETS).includes(market.outcome) ? PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS[market.group] : market.group;
		if (market.argument !== undefined) {
			if ([PENALTY_SHOOTOUT_MARKETS_GROUPS.HANDICAP].includes(market.group) && Number(market.argument) % 0.5 !== 0) {
				gr = "Asian" + market.group;
			}
		}

		res += i18n.t(`markets.${market.gameType}.${gr}`);
		res = res.replace("%team1%", market.team1).replace("%team2%", market.team2);

		const getArgument = () => {
			switch (true) {
				case [PENALTY_SHOOTOUT_MARKETS_GROUPS.HANDICAP, PENALTY_SHOOTOUT_MARKETS_GROUPS.ASIAN_HANDICAP].includes(market.group) && (market.outcome === "Away"):
					return 0 - market.argument;
				case market.argument !== null &&
					!isNaN(market.argument) &&
					([
						PENALTY_SHOOTOUT_MARKETS_GROUPS.BOTH_TEAMS_TO_SCORE,
						PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_GOAL_MISS,
						PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_GOAL_MISS,
						PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME,
						PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME,
						PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE_PER_SHOT,
						PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS[PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME],
						PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS[PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME]
					].includes(market.group) ||
						PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS[market.group]):
					return i18n.t(`markets.${market.gameType}.R${market.argument}`);
				default:
					return market.argument;
			}
		};

		const argument = getArgument();

		res += (argument || argument === 0 ? " " + ([PENALTY_SHOOTOUT_MARKETS_GROUPS.HANDICAP, PENALTY_SHOOTOUT_MARKETS_GROUPS.ASIAN_HANDICAP].includes(market.group) ? "<span class='vs--text-ltr'>" + argument + "</span>" : argument) : "");

		return res;
	}

	/** Make beuty text of bet
	 * @function
	 * @param {object} bet - bet
	 * @returns {string}
	 * @memberof PenaltyShotoutsMarkets
	 */
	makeBetText(bet) {
		const team1CountryCode = bet?.gameData?.team1?.countryCode ?? "Team 1";
		const team2CountryCode = bet?.gameData?.team2?.countryCode ?? "Team 2";

		return (
			this.makeGroupTitle({
				period: bet.period,
				group: bet.group,
				team1: team1CountryCode,
				team2: team2CountryCode,
				argument: bet.argument,
				outcome: bet.outcome,
				gameType: bet.gameType
			}) +
			": " +
			this.makeWinnerMarketName({
				outcome: bet.outcome,
				team1: team1CountryCode,
				team2: team2CountryCode,
				group: bet.group,
				argument: bet.argument,
				gameType: bet.gameType
			})
		);
	}
}

export default PenaltyShotoutsMarkets;
