import * as signalR from "@microsoft/signalr";
import store from 'store/configureStore';
import { isNullish } from "utils/common";

export default class SignalRUtils {
	constructor(connectionURL, callback) {
		this.connection = null;
		this.promise = null;
		this.connectionURL = connectionURL;
		this.callback = callback;
		this.buildConnection();

		document.addEventListener("visibilitychange", () => {
			if (!document.hidden) {
				this.startConnection();
			}
		});

		const navigation = performance.getEntriesByType("navigation");

		if (navigation[0].type === "back_forward") {
			this.startConnection();
		}
	}

	/** Init SignalR
	 * @function
	 * @memberof SignalRUtils
	 */
	buildConnection() {
		this.connection = new signalR.HubConnectionBuilder()
			.withUrl(this.connectionURL, {
				skipNegotiation: true,
				transport: signalR.HttpTransportType.WebSockets
			})
			.withAutomaticReconnect()
			.configureLogging(import.meta.env.SYSTEM_APP_MODE !== "production" ? signalR.LogLevel.Error : signalR.LogLevel.None)
			.build();

		this.startConnection();

		this.connection.onreconnected(() => {
			this.callback && (
				console.log("onreconnected"),
				this.callback(this)
			);
		});

		this.connection.onclose(err => {
			if (!isNullish(err)) {
				this.handleConnectionError();
			}
		})
	}

	/** Start signalR connection if it is not started yet
	 * @function
	 * @returns {Promise}
	 * @memberof SignalRUtils
	 */
	startConnection() {
		if (!this.promise) {
			this.promise = this.connection
				.start()
				.then(() => {
					this.callback && this.callback();
				}).catch((err) => {
					console.log("SIGNALR START FAILED");
					console.log(err);
					this.handleConnectionError()
				});
		}
		return this.promise;
	}

	/** Stop signalR connection if it necessary
	 * @function
	 * @returns {void}
	 * @memberof SignalRUtils
	 */
	stopConnection() {
		try {
			this.connection.stop();
		} catch (error) {
			console.log(error);
		}
	}

	/** Function to call when connection failed
	 * @function
	 * @memberof SignalRUtils
	 */
	handleConnectionError() {
		setTimeout(() => {
			this.promise = null;
			this.startConnection();
		}, 5000);
	}

	/** Function to get connection
	 * @function
	 * @returns {object} connection - signalR connection
	 * @memberof SignalRUtils
	 */
	getConnection() {
		return this.connection;
	}

	/** Init all connections
	 * @function
	 * @static
	 * @param {function} callback
	 * @memberof SignalRUtils
	 */
	static buildConnections(callback) {
		SignalRUtils.connections = [];
		const token = store.getState()?.auth?.player?.wsToken ?? null;
		const connectionObjects = {
			adminSingnalR: null,
			siteSingnalR: null,
			jobsSignalR: null
		};
		connectionObjects.adminSingnalR = new SignalRUtils(`${import.meta.env.SYSTEM_SIGNALR_URL_ADMIN}?accessToken=${token}`, () => callback(connectionObjects.adminSingnalR));
		connectionObjects.siteSingnalR = new SignalRUtils(`${import.meta.env.SYSTEM_SIGNALR_URL_SITE}?accessToken=${token}`, () => callback(connectionObjects.siteSingnalR));
		SignalRUtils.connections.push(connectionObjects.adminSingnalR);
		SignalRUtils.connections.push(connectionObjects.siteSingnalR);
		if (import.meta.env.SYSTEM_SIGNALR_URL_JOBS) {
			connectionObjects.jobsSignalR = new SignalRUtils(`${import.meta.env.SYSTEM_SIGNALR_URL_JOBS}?accessToken=${token}`, () => callback(connectionObjects.jobsSignalR));
			SignalRUtils.connections.push(connectionObjects.jobsSignalR);
		}
	}

	/** Remove all connections
	 * @function
	 * @static
	 * @memberof SignalRUtils
	 */
	static removeConnections() {
		SignalRUtils.getConnections().forEach((connection) => {
			connection.stopConnection();
		});
	}

	/** Init all connections
	 * @function
	 * @static
	 * @return {array} array of connections
	 * @memberof SignalRUtils
	 */
	static getConnections() {
		return SignalRUtils.connections || [];
	}
}
