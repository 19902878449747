export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const SET_BLOCKED_MESSAGE = "SET_BLOCKED_MESSAGE";
export const SET_VIDEO_LAYOUT = "SET_VIDEO_LAYOUT";
export const SET_VIDEO_MINIMIZED = "SET_VIDEO_MINIMIZED";
export const SET_LIVE_INFO_EVENT = "SET_LIVE_INFO_EVENT";
export const UPDATE_LIVE_INFO_EVENT = "UPDATE_LIVE_INFO_EVENT";
export const SET_SHOW_LIVE_INFO_EVENT = "SET_SHOW_LIVE_INFO_EVENT";
export const SET_LIVE_INFO_POSITIONS = "SET_LIVE_INFO_POSITIONS";
export const SET_CONNECTION_STATE = "SET_CONNECTION_STATE";
export const SET_COLLAPSED = "SET_COLLAPSED";

export const SET_PLAYER = "SET_PLAYER";
export const SET_SESSION = "SET_SESSION";
export const GAME_RULE_BEFORE = "GAME_RULE_BEFORE";
export const GAME_RULE_AFTER = "GAME_RULE_AFTER";
export const SET_GAME_RULE = "SET_GAME_RULE";
export const SET_SHOW_RULES = "SET_SHOW_RULES";
export const CLEAR_RULES = "CLEAR_RULES";
export const SET_SESSION_ID = "SET_SESSION_ID";
export const SET_REAL_MODE = "SET_REAL_MODE";
export const SET_SETTINGS_DATA = "SET_SETTINGS_DATA";
export const SET_TOKEN_EXPIRATION = "SET_TOKEN_EXPIRATION";
export const SET_SESSION_LOADED = "SET_SESSION_LOADED";
export const SET_SESSION_FAILED = "SET_SESSION_FAILED";
export const UPDATE_SESSION_BEFORE = "UPDATE_SESSION_BEFORE";
export const UPDATE_SESSION_AFTER = "UPDATE_SESSION_AFTER";
export const SET_REAL_MODE_BEFORE = "SET_REAL_MODE_BEFORE";
export const SET_REAL_MODE_AFTER = "SET_REAL_MODE_AFTER";
export const SET_TRANSLATIONS_LOADED = "SET_TRANSLATIONS_LOADED";
export const SET_BALANCE = "SET_BALANCE";
export const SET_BONUS = "SET_BONUS";
export const SET_BONUS_SEEN = "SET_BONUS_SEEN";
export const SET_LOGO_ID = "SET_LOGO_ID";
export const SET_MAINTENANCE_MODE = "SET_MAINTENANCE_MODE";
export const SET_STREAM_CONFIGURATION = "SET_STREAM_CONFIGURATION";

export const LIVE_AND_UPCOMINGS_BEFORE = "LIVE_AND_UPCOMINGS_BEFORE";
export const LIVE_AND_UPCOMINGS_AFTER = "LIVE_AND_UPCOMINGS_AFTER";
export const SET_LIVE_AND_UPCOMINGS = "SET_LIVE_AND_UPCOMINGS";
export const REMOVE_LIVE_AND_UPCOMINGS = "REMOVE_LIVE_AND_UPCOMINGS";
export const REMOVE_LIVE_AND_UPCOMINGS_BY_SEASON = "REMOVE_LIVE_AND_UPCOMINGS_BY_SEASON";
export const UPDATE_LIVE_AND_UPCOMINGS = "UPDATE_LIVE_AND_UPCOMINGS";
export const ADD_LIVE_AND_UPCOMINGS = "ADD_LIVE_AND_UPCOMINGS";

export const LAST_RESULTS_BEFORE = "LAST_RESULTS_BEFORE";
export const LAST_RESULTS_AFTER = "LAST_RESULTS_AFTER";
export const SET_LAST_RESULTS = "SET_LAST_RESULTS";
export const ADD_LAST_RESULT = "ADD_LAST_RESULT";

export const RESULTS_BEFORE = "RESULTS_BEFORE";
export const RESULTS_AFTER = "RESULTS_AFTER";
export const SET_RESULTS = "SET_RESULTS";
export const ADD_RESULTS = "ADD_RESULTS";
export const SET_RESULTS_FILTERS = "SET_RESULTS_FILTERS";
export const RESULTS_SEASON_BEFORE = "RESULTS_SEASON_BEFORE";
export const RESULTS_SEASON_AFTER = "RESULTS_SEASON_AFTER";
export const SET_SEASON_ID = "SET_SEASON_ID";
export const SET_SEASON_RESULTS = "SET_SEASON_RESULTS";
export const SET_SEASON_RESULTS_FILTERS = "SET_SEASON_RESULTS_FILTERS";

export const EVENT_BEFORE = "EVENT_BEFORE";
export const EVENT_AFTER = "EVENT_AFTER";
export const SET_EVENT = "SET_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const REMOVE_EVENT = "REMOVE_EVENT";

export const SET_CURRENT_EVENT = "SET_CURRENT_EVENT";
export const SET_CURRENT_GAME = "SET_CURRENT_GAME";
export const SET_CURRENT_GAME_BONUS_BET_DISABLED = "SET_CURRENT_GAME_BONUS_BET_DISABLED";
export const SET_GAME_COUNTDOWN = "SET_GAME_COUNTDOWN";
export const SET_CURRENT_TIME = "SET_CURRENT_TIME";

export const SET_MARKETS_COLLAPSIBLE_ACTIVE_KEYS = "SET_MARKETS_COLLAPSIBLE_ACTIVE_KEYS";

export const SET_SEASON_CURRENT_EVENT = "SET_SEASON_CURRENT_EVENT";
export const SET_SEASON_SKELETON_HIDE = "SET_SEASON_SKELETON_HIDE";
export const SET_SHOW_SEASON_MARKETS = "SET_SHOW_SEASON_MARKETS";
export const SET_SEASON_MARKETS = "SET_SEASON_MARKETS";
export const SET_SEASON_SELECTED_MARKET = "SET_SEASON_SELECTED_MARKET";
export const SET_SEASON_BEFORE = "SET_SEASON_BEFORE";
export const SET_SEASON_AFTER = "SET_SEASON_AFTER";
export const SET_SEASON_STRUCTURE = "SET_SEASON_STRUCTURE";
export const SET_SEASON_STANDINGS = "SET_SEASON_STANDINGS";
export const CLEAR_SEASON_STRUCTURE_STANDINGS = "CLEAR_SEASON_STRUCTURE_STANDINGS";
export const SET_SEASON_RESULT_MODAL_MOBILE = "SET_SEASON_RESULT_MODAL_MOBILE";
export const SHOW_NEXT_LIVE_AND_UPCOMINGS = "SHOW_NEXT_LIVE_AND_UPCOMINGS";

export const SET_RIGHTBAR_TAB = "SET_RIGHTBAR_TAB";
export const SET_SHOW_KEYBOARD = "SET_SHOW_KEYBOARD";
export const SET_BETSLIP_MODE = "SET_BETSLIP_MODE";
export const SET_BETSLIP_STAKE_MODE = "SET_BETSLIP_STAKE_MODE";
export const ADD_BET = "ADD_BET";
export const REMOVE_BET = "REMOVE_BET";
export const REMOVE_MATCH_BET = "REMOVE_MATCH_BET";
export const UPDATE_MATCH_BETS = "UPDATE_MATCH_BETS";
export const CLEAR_BETS = "CLEAR_BETS";
export const CLEAR_HIDDEN_BETS = "CLEAR_HIDDEN_BETS";
export const UPDATE_BET_STAKE = "UPDATE_BET_STAKE";
export const SET_STAKE = "SET_STAKE";
export const PLACE_BET_BEFORE = "PLACE_BET_BEFORE";
export const PLACE_BET_AFTER = "PLACE_BET_AFTER";
export const PLACE_BET_SUCCESS = "PLACE_BET_SUCCESS";
export const SET_BETSLIP = "SET_BETSLIP";
export const SET_BETSLIP_CANCELING_BEFORE = "SET_BETSLIP_CANCELING_BEFORE";
export const SET_BETSLIP_CANCELING_AFTER = "SET_BETSLIP_CANCELING_AFTER";
export const ADD_PENDING = "ADD_PENDING";
export const UPDATE_PENDING = "UPDATE_PENDING";
export const UPDATE_PENDING_BET = "UPDATE_PENDING_BET";
export const SET_PENDINGS = "SET_PENDINGS";
export const REMOVE_PENDING = "REMOVE_PENDING";
export const PENDING_BEFORE = "PENDING_BEFORE";
export const PENDING_AFTER = "PENDING_AFTER";
export const ADD_BETSLIP_RESULT = "ADD_BETSLIP_RESULT";
export const CLEAR_BETSLIP_RESULTS = "CLEAR_BETSLIP_RESULTS";
export const SHOW_MOBILE_BETSLIPS_MODAL = "SHOW_MOBILE_BETSLIPS_MODAL";

export const SET_STATISTICS_BEFORE = "SET_STATISTICS_BEFORE";
export const SET_STATISTICS_AFTER = "SET_STATISTICS_AFTER";
export const SET_STATISTICS_TEAM_1 = "SET_STATISTICS_TEAM_1";
export const SET_STATISTICS_TEAM_2 = "SET_STATISTICS_TEAM_2";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_TEAM_STATISTICS = "SET_TEAM_STATISTICS";
export const SET_TEAM_LAST_10_MATCHES = "SET_TEAM_LAST_10_MATCHES";
export const SET_HEAD_TO_HEAD_STATISTICS = "SET_HEAD_TO_HEAD_STATISTICS";
export const ADD_TEAM_MATCHES = "ADD_TEAM_MATCHES";
export const SET_TEAM_TO_TEAN_STATISTICS = "SET_TEAM_TO_TEAN_STATISTICS";
export const SET_PENALTY_TEAM_TO_TEAM_STATISTICS = "SET_PENALTY_TEAM_TO_TEAM_STATISTICS";
export const SET_PARTICIPANTS = "SET_PARTICIPANTS";
export const SET_CURRENT_PARTICIPANT = "SET_CURRENT_PARTICIPANT";
export const SET_PARTICIPANT_STATISTICS = "SET_PARTICIPANT_STATISTICS";
export const SET_RACING_CURRENT_EVENT = "SET_RACING_CURRENT_EVENT";
export const SET_QUICK_STATISTICS_BEFORE = "SET_QUICK_STATISTICS_BEFORE";
export const SET_QUICK_STATISTICS_AFTER = "SET_QUICK_STATISTICS_AFTER";
export const SET_QUICK_STATISTICS = "SET_QUICK_STATISTICS";
export const SET_QUICK_STATISTICS_TEAMS_DATA = "SET_QUICK_STATISTICS_TEAMS_DATA";
export const SET_QUICK_STATISTICS_STATE = "SET_QUICK_STATISTICS_STATE";
export const SET_SEASON_STATISTICS_BEFORE = "SET_SEASON_STATISTICS_BEFORE";
export const SET_SEASON_STATISTICS_AFTER = "SET_SEASON_STATISTICS_AFTER";
export const SET_SEASON_STATISTICS_IS_UPDATE_REQUEST_BEFORE = "SET_SEASON_STATISTICS_IS_UPDATE_REQUEST_BEFORE";
export const SET_SEASON_STATISTICS_IS_UPDATE_REQUEST_AFTER = "SET_SEASON_STATISTICS_IS_UPDATE_REQUEST_AFTER";
export const SET_SEASON_STATISTICS_ID = "SET_SEASON_STATISTICS_ID";
export const SET_SEASON_STATISTICS_STANDINGS = "SET_SEASON_STATISTICS_STANDINGS";
export const SET_SEASON_STATISTICS_STRUCTURE = "SET_SEASON_STATISTICS_STRUCTURE";
export const UPDATE_SEASON_STATISTICS_STRUCTURE = "UPDATE_SEASON_STATISTICS_STRUCTURE";
export const SET_SEASON_LIVE_MODAL_MOBILE = "SET_SEASON_LIVE_MODAL_MOBILE";
export const SET_KENO_STATISTICS = "SET_KENO_STATISTICS";
export const RESET_STATISTICS_STATE = "RESET_STATISTICS_STATE";

export const SET_HISTORY_FILTERS = "SET_HISTORY_FILTERS";
export const RESET_HISTORY_FILTERS = "RESET_HISTORY_FILTERS";
export const HISTORY_BEFORE = "HISTORY_BEFORE";
export const HISTORY_AFTER = "HISTORY_AFTER";
export const SET_HISTORY = "SET_HISTORY";
export const ADD_HISTORY = "ADD_HISTORY";
export const RESET_HISTORY = "RESET_HISTORY";
export const CANCEL_HISTORY = "CANCEL_HISTORY";
export const UPDATE_HISTORY_BET_SLIP_BET = "UPDATE_HISTORY_BET_SLIP_BET";
export const UPDATE_HISTORY_BET_SLIP = "UPDATE_HISTORY_BET_SLIP";
export const SET_HISTORY_OPENED_BET_SLIP_ID = "SET_HISTORY_OPENED_BET_SLIP_ID";

export const EVENT_BET_HISTORY_BEFORE = "EVENT_BET_HISTORY_BEFORE";
export const EVENT_BET_HISTORY_AFTER = "EVENT_BET_HISTORY_AFTER";
export const SET_EVENT_BET_HISTORY = "SET_EVENT_BET_HISTORY";

export const BONUS_HISTORY_BEFORE = "BONUS_HISTORY_BEFORE";
export const BONUS_HISTORY_AFTER = "BONUS_HISTORY_AFTER";
export const SET_BONUS_HISTORY = "SET_BONUS_HISTORY";
export const ADD_BONUS_HISTORY = "ADD_BONUS_HISTORY";
export const SET_BONUS_HISTORY_FILTERS = "SET_BONUS_HISTORY_FILTERS";
export const SET_USE_BONUS = "SET_USE_BONUS";

export const SHOW_FEEDBACK = "SHOW_FEEDBACK";
export const LEAVE_FEEDBACK_BEFORE = "LEAVE_FEEDBACK_BEFORE";
export const LEAVE_FEEDBACK_AFTER = "LEAVE_FEEDBACK_AFTER";

export const SET_KENO_SELECTED_BALLS = "SET_KENO_SELECTED_BALLS";
export const ADD_KENO_SELECTED_BALLS = "ADD_KENO_SELECTED_BALLS";
export const REMOVE_KENO_SELECTED_BALLS = "REMOVE_KENO_SELECTED_BALLS";
export const SET_KENO_ACTIVE_BALLS = "SET_KENO_ACTIVE_BALLS";
export const SET_KENO_LAST_ADDED_BALLS = "SET_KENO_LAST_ADDED_BALLS";
export const SET_KENO_HOVERED_BALLS = "SET_KENO_HOVERED_BALLS";
export const SET_KENO_LAST_BETS_BEFORE = "SET_KENO_LAST_BETS_BEFORE";
export const SET_KENO_LAST_BETS_AFTER = "SET_KENO_LAST_BETS_AFTER";
export const SET_KENO_CURRENT_BETS_BEFORE = "SET_KENO_CURRENT_BETS_BEFORE";
export const SET_KENO_CURRENT_BETS_AFTER = "SET_KENO_CURRENT_BETS_AFTER";
export const SET_KENO_CURRENT_BETS = "SET_KENO_CURRENT_BETS";
export const UPDATE_KENO_CURRENT_BETS = "UPDATE_KENO_CURRENT_BETS";
export const SET_KENO_LAST_BETS = "SET_KENO_LAST_BETS";
