import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addKenoSelectedBalls, removeKenoSelectedBalls } from "store/actions/keno/keno.actions"
import { KENO_BALL_SIZE, KENO_FORMAT_BALLS_QTY, GAME_STATUSES } from "constants/game.constants"
import eventType from 'types/event.type';
import useGlobalVariables from 'hooks/useGlobalVariables';
import { mergeClassNames } from 'utils/common';

/** Keno Balls component */
const KenoBalls = ({
	isBonusBetDisabled,
	balls,
	showWinner,
	showActive,
	size = KENO_BALL_SIZE.NORMAL,
	selectedBalls,
	activeBalls,
	splited,
	winnerBalls,
	removeKenoSelectedBalls,
	addKenoSelectedBalls,
	currentEvent,
	wrapperClassname = ''
}) => {
		const { isMobile } = useGlobalVariables();
	const selectedBallsMaxCount = KENO_FORMAT_BALLS_QTY[currentEvent?.gameData?.raceFormat ?? 0];
	const classNameOfIterableComponent = useMemo(() => {
		let classString = "vs--keno-balls-item vs--flex vs--justify-center vs--align-center";

		switch (true) {
			case isMobile:
				classString += " vs--mr-2";
				break;
			case size === KENO_BALL_SIZE.NORMAL:
				classString += " vs--mr-8";
				break;
			default:
				classString += " vs--mr-4";
				break;
		}

		classString += (" vs--keno-balls-item-" + size);
		return classString;
	}, [isMobile, size])

	/** Function to detect if the ball is winner
	  * @function
	  * @param {number} ball 
	  * @memberOf KenoBalls
  */
	const isBallWinner = ball => {
		if (!showWinner) return false;
		const winners = winnerBalls ? winnerBalls : activeBalls;
		return winners.some(b => b.number === ball);
	}

	/** Function to detect if the ball is selected
	  * @function
	  * @param {number} ball 
	  * @memberOf KenoBalls
  */
	const isBallSelected = ball => {
		if (!showActive) return false;
		return selectedBalls.includes(ball)
	}

	/** Function to select/unselectBall
		  * @function
		  * @param {number} ball 
		  * @memberOf KenoBalls
	*/
	const toggleBall = ball => {
		if (selectedBalls.includes(ball)) {
			return removeKenoSelectedBalls(ball);
		}
		
		if (selectedBalls.length < selectedBallsMaxCount) {
			return addKenoSelectedBalls(ball);
		}
	};

	/** Function fires on ball click
		  * @function
		  * @memberOf KenoBalls
	*/
	const handleBallClick = ball => {
		if (isBonusBetDisabled || !showActive || currentEvent?.status === GAME_STATUSES.CLOSE_FOR_BETTING) {
			return;
		}
		toggleBall(ball)
	}

	return (
		<div className={mergeClassNames('vs--keno-balls', 'vs--flex', 'vs--align-center', splited ? " vs--keno-balls-splited vs--flex-wrap" : "", wrapperClassname)}>
			{
				balls.map(ball => (
					<div
						className={
							classNameOfIterableComponent +
							(isBallWinner(ball) ? " vs--keno-winner-ball" : "") +
							(isBallSelected(ball) ? " vs--keno-selected-ball" : "")
						}
						key={ball}
						onClick={() => handleBallClick(ball)}
					>
						<div className="vs--keno-ball-small vs--flex vs--justify-center vs--align-center">
							<span className="vs--title-white vs--font-normal">{ball}</span>
						</div>
					</div>
				))
			}
		</div>
	)
};

/** KenoBalls propTypes
	 * PropTypes
*/
KenoBalls.propTypes = {
	/** Redux state property, is current usable bonus enabled/disabled for current game */
	isBonusBetDisabled: PropTypes.bool,
	/** Balls */
	balls: PropTypes.arrayOf(PropTypes.number),
	/** Show active balls */
	showActive: PropTypes.bool,
	/** Show winner balls */
	showWinner: PropTypes.bool,
	/** Keno Ball size */
	size: PropTypes.oneOf(Object.values(KENO_BALL_SIZE)),
	/** Redux state property, current selected balls */
	selectedBalls: PropTypes.arrayOf(PropTypes.number),
	/** Redux state property, active balls */
	activeBalls: PropTypes.arrayOf(PropTypes.shape({
		number: PropTypes.number,
		animating: PropTypes.bool
	})),
	/** Should show in two line */
	splited: PropTypes.bool,
	/** Array of winner balls */
	winnerBalls: PropTypes.array,
	/** Redux action to select ball/balls */
	addKenoSelectedBalls: PropTypes.func,
	/** Redux action to unselect ball/balls */
	removeKenoSelectedBalls: PropTypes.func,
	/** Redux state property, live and upcomings event */
	currentEvent: eventType,
	// Wrapper classname prop
	wrapperClassname: PropTypes.string
}


const mapStateToProps = (state) => {
	return {
		isBonusBetDisabled: state.game.isBonusBetDisabled,
		selectedBalls: state.keno.selectedBalls,
		activeBalls: state.keno.activeBalls,
		currentEvent: state.game.liveAndUpcomings.data[0]
	};
};

const mapDispatchToProps = (dispatch) => ({
	addKenoSelectedBalls: balls => {
		dispatch(addKenoSelectedBalls(balls))
	},
	removeKenoSelectedBalls: balls => {
		dispatch(removeKenoSelectedBalls(balls))
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(KenoBalls);