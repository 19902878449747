import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { setBetslipMode } from "store/actions/betslip/betslip.actions";

import { BETSLIP_MODES, DISABLED_BETSLIP_MODES } from "constants/betslip.constants";

import betType from "types/bet.type";

import { isMobile } from "utils/common";

/* Tabs of Betslip */
const BetSlipTabs = ({ setBetslipMode, mode, bets, scrollBarRef }) => {
	const { t } = useTranslation();

	const betsCountRef = useRef();

	useEffect(() => {
		if (setBetslipMode && scrollBarRef.current) {
			scrollBarRef.current.update();
		}
	}, [mode]);

	/** Function to check if tab should be disabled
	 * @function
	 * @param {string} mode - multi/single
	 * @returns {boolean}
	 * @memberOf BetSlipTabs
	 */
	const isModeDisabled = (mode) => {
		const disabledModes = [...DISABLED_BETSLIP_MODES];
		if (bets.filter((b) => !b.isExpired).length < 2) {
			disabledModes.push(BETSLIP_MODES.MULTI);
		}

		return disabledModes.includes(mode);
	};

	/** Function fires on "tab click" button clock
	 * @function
	 * @param {string} mode - multi/single
	 * @memberOf BetSlipTabs
	 */
	const onTabClick = (mode) => {
		if (!isModeDisabled(mode)) setBetslipMode(mode);
	};

	return (
		<div className="vs--flex">
			{Object.values(BETSLIP_MODES).map((tab) => (
				<div
					key={tab}
					className={"vs--betslip-tab vs--flex vs--flex-equal vs--align-center vs--justify-center vs--pt-10 vs--pb-10 " + (mode === tab && bets.length > 0 ? "vs--betslip-tab-active" : "") + (isModeDisabled(tab) ? " vs--betslip-tab-disabled" : "")}
					onClick={() => onTabClick(tab)}
					data-unique={tab === BETSLIP_MODES.SINGLE ? "single" : "multi"}
				>
					<span className="vs--betslip-tab-title vs--title-gray-80 vs--font-regular vs--font-normal">{t(`bet.${tab === BETSLIP_MODES.SINGLE ? "single" : "multi"}`) + (tab === BETSLIP_MODES.SINGLE || bets.length > 1 ? " (" + bets.length + ")" : "")}</span>
				</div>
			))}
		</div>
	);
};

/** BetSlipTabs propTypes
 * PropTypes
 */
BetSlipTabs.propTypes = {
	/** Redux action to set current bet slip mode(single/multi) */
	setBetslipMode: PropTypes.func,
	/** Redux state property, current betslip mode */
	mode: PropTypes.oneOf(Object.values(BETSLIP_MODES)),
	/** Redux state property, current bets in betslip section */
	bets: PropTypes.arrayOf(betType),
	/** React property, Betslip scrollbar container control object */
	scrollBarRef: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
	setBetslipMode: (mode) => {
		dispatch(setBetslipMode(mode));
	}
});

const mapStateToProps = (state) => {
	return {
		mode: state.betslip.mode,
		bets: state.betslip.bets
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BetSlipTabs);
