import TopBarGamesProvider from "providers/TopBarGamesProvider";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { isNullish } from "utils/common";
import { setCurrentGame } from "store/actions/game/game.actions";

const TopBarRulesHoc = (Component) => {
	const WrappedByTopBarRulesHocComponent = ({ selectedGame, setSelectedGame }) => {
		const dispatch = useDispatch();
		const setSelectedGameFromRedux = useCallback((gt) => dispatch(setCurrentGame(gt)), [dispatch]);
		return (
			<TopBarGamesProvider showCountdown={false} selectedGame={selectedGame} setSelectedGame={isNullish(setSelectedGame) ? setSelectedGameFromRedux : setSelectedGame}>
				<Component />
			</TopBarGamesProvider>
		);
	};

	WrappedByTopBarRulesHocComponent.displayName = "WrappedByTopBarRulesHocComponent_" + Component.displayName;

	return WrappedByTopBarRulesHocComponent;
};

export default TopBarRulesHoc;
