import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import LocalStorageUtils from "utils/localStorage";
import { leaveFeedback, toggleFeedbackVisibility } from "store/actions/feedback/feedback.actions";
import CommonView from "components/feedbackPopup/commonView";
import MobileView from "components/feedbackPopup/mobileView";
import SurveyType from "types/survey.type";
import useGlobalVariables from "hooks/useGlobalVariables";

const UserFeedbackPopup = ({ userId, feedback: { isVisible }, surveys: [survey], leaveFeedback, toggleFeedbackVisibility }) => {
	const { t } = useTranslation();
	const { isMobile } = useGlobalVariables();
	const [selectedEmojiValue, setSelectedEmojiValue] = useState(null);
	const [isThankYouMsgVisible, setIsThankYouMsgVisible] = useState(false);

	const storageKey = `vs__feedback_is_shown_${userId}`;

	const sendFeedback = ({ grade = selectedEmojiValue, message = "" }) => {
		setIsThankYouMsgVisible(true);
		leaveFeedback({ grade, surveyId: survey.id, message: message ? message : null });
	};

	const closeFeedback = () => {
		if (selectedEmojiValue === null) {
			sendFeedback({ grade: 0 });
		}

		LocalStorageUtils.set(storageKey, true);
		toggleFeedbackVisibility(storageKey);
		setSelectedEmojiValue(null);
		setIsThankYouMsgVisible(false);
	};

	const commonProps = {
		closeFeedback,
		title: t("common.shareYourFeedback"),
		closeElement: t("common.skip"),
		sendFeedback,
		setSelectedEmojiValue,
		selectedEmojiValue,
		survey,
		isThankYouMsgVisible,
		isVisible
	};

	/** Removes key from LocalStorage, when backend cash has already been cleared from GetDetails */
	useEffect(() => {
		if (survey === undefined && LocalStorageUtils.get(storageKey)) {
			LocalStorageUtils.remove(storageKey);
		}
	}, [survey]);

	/** Closing feedback popup during 3 seconds after submitting */
	useEffect(() => {
		let timeoutId;

		if (isThankYouMsgVisible) {
			timeoutId = setTimeout(closeFeedback, 3000);
		}

		return () => clearTimeout(timeoutId);
	}, [isThankYouMsgVisible]);

	if (!isVisible || survey === undefined) {
		return null;
	}

	return isMobile ? <MobileView {...commonProps} /> : <CommonView {...commonProps} />;
};

UserFeedbackPopup.propTypes = {
	/** Redux state property, current player id */
	userId: PropTypes.string,
	/** Redux state property, representing player feedback info */
	feedback: PropTypes.object,
	/** Redux state property, representing session surveys data */
	surveys: PropTypes.arrayOf(SurveyType),
	/** Redux action to send feedback data */
	leaveFeedback: PropTypes.func,
	/** Redux action to set feedback popup visibility */
	toggleFeedbackVisibility: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	leaveFeedback: (params) => {
		dispatch(leaveFeedback(params));
	},
	toggleFeedbackVisibility: (visibility) => {
		dispatch(toggleFeedbackVisibility(visibility));
	}
});

const mapStateToProps = (state) => {
	return {
		userId: state.auth.player.userId,
		feedback: state.feedback,
		surveys: state.auth.session.surveys
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserFeedbackPopup);
