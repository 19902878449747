import {
	SET_SESSION,
	SET_PLAYER,
	SET_SESSION_ID,
	SET_REAL_MODE,
	SET_SETTINGS_DATA,
	SET_TOKEN_EXPIRATION,
	SET_SESSION_LOADED,
	SET_SESSION_FAILED,
	UPDATE_SESSION_BEFORE,
	UPDATE_SESSION_AFTER,
	SET_REAL_MODE_BEFORE,
	SET_REAL_MODE_AFTER,
	SET_TRANSLATIONS_LOADED,
	SET_BALANCE,
	SET_BONUS,
	SET_BONUS_SEEN,
	SET_LOGO_ID,
	SET_MAINTENANCE_MODE,
	SET_STREAM_CONFIGURATION
} from "../../actionTypes";

import SessionStorageUtils from "utils/sessionStorage";

import { BONUS_TYPE } from "constants/bonus.constants";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case SET_SESSION:
			return {
				...state,
				session: {
					...state.session,
					...payload.session,
					bonuses: payload.session.bonuses
						? payload.session.bonuses.filter((b) => {
							return b.bonusType === BONUS_TYPE.FREEBET ? b.roundCount > 0 : b.bonusType === BONUS_TYPE.FREEAMOUNT ? b.amount > 0 : false;
						})
						: payload.session.bonuses
				}
			};
		case SET_SESSION_LOADED:
			return {
				...state,
				sessionLoaded: true
			};
		case SET_SESSION_FAILED:
			return {
				...state,
				sessionFailed: true
			};
		case UPDATE_SESSION_BEFORE:
			return {
				...state,
				isSessionUpdating: true
			};
		case UPDATE_SESSION_AFTER:
			return {
				...state,
				isSessionUpdating: false
			};
		case SET_STREAM_CONFIGURATION:
			return {
				...state,
				session: {
					...state.session,
					games: state.session.games.map((g) => (g.id === payload.id ? { ...g, streamConfiguration: payload.configuration } : g))
				}
			};
		case SET_REAL_MODE:
			return {
				...state,
				session: {
					...state.session,
					isDemo: false
				}
			};
		case SET_SETTINGS_DATA:
			return {
				...state,
				session: {
					...state.session,
					settings: {
						...state.session.settings,
						...payload
					}
				}
			};
		case SET_PLAYER:
			if (payload.player) {
				SessionStorageUtils.set("player", payload.player);
			} else {
				SessionStorageUtils.remove("player");
			}
			return {
				...state,
				player: payload.player
			};
		case SET_BALANCE:
			return {
				...state,
				session: {
					...state.session,
					balance: payload.balance
				}
			};
		case SET_BONUS:
			let bonuses = state.session.bonuses;
			if (payload.bonus && bonuses) {
				if (bonuses.some((b) => b.bonusId === payload.bonus.bonusId)) {
					bonuses = bonuses.map((b) => (b.bonusId === payload.bonus.bonusId ? { ...payload.bonus } : b));
				} else {
					bonuses = bonuses.concat([payload.bonus]);
				}
				bonuses = bonuses.filter((b) => {
					return b.bonusType === BONUS_TYPE.FREEBET ? b.roundCount > 0 : b.bonusType === BONUS_TYPE.FREEAMOUNT ? b.amount > 0 : false;
				});
			}

			return {
				...state,
				session: {
					...state.session,
					bonuses: bonuses
				}
			};
		case SET_BONUS_SEEN:
			return {
				...state,
				session: {
					...state.session,
					bonuses: state.session.bonuses.map((b) => ({ ...b, isNewBonus: false }))
				}
			};
		case SET_MAINTENANCE_MODE:
			return {
				...state,
				session: {
					...state.session,
					maintenanceMode: true
				}
			};
		case SET_LOGO_ID:
			return {
				...state,
				session: {
					...state.session,
					logoId: payload.logoId
				}
			};
		case SET_SESSION_ID:
			return {
				...state,
				sessionId: payload.sessionId
			};
		case SET_REAL_MODE_BEFORE:
			return {
				...state,
				switchingToRealMode: true
			};
		case SET_REAL_MODE_AFTER:
			return {
				...state,
				switchingToRealMode: false
			};
		case SET_TRANSLATIONS_LOADED:
			return {
				...state,
				translationsLoaded: true
			};
		default:
			return state;
	}
};
