import axios from "axios";
import i18n from "i18next";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { BETSLIP_BETS_LIMIT } from "constants/betslip.constants";

import { CLIENT_API } from "constants/integration.constants";
import { GAME_STATUSES, GAME_TYPE, GAME_EVENT_TYPE } from "constants/game.constants";
import { BONUS_TYPE } from "constants/bonus.constants";
import { BETSLIP_MODES, BETSLIP_STAKE_MODES, BET_STATE } from "constants/betslip.constants";
import { ON_BOARDING_TYPES, ON_BOARDING_COOKIE_NAMES, ON_BOARDING_TOOLTIP_VISIBILITY_EVENT } from "constants/onBoarding.constants";
import { updateHistoryBetSlip, updateHistoryBetSlipBet } from "../history/history.actions";
import { getCookie } from "utils/common";
import SessionStorageUtils from "utils/sessionStorage";
import runMarketUtilsFunction from "utils/markets/run";
import { sendPostMessageToParent } from "utils/iframe";
import { binaryToFlags } from "utils/binaryCalculations";
import { generateBetKey } from "utils/bets";
import { setBonus, saveSettings } from "store/actions/auth/auth.actions";
import { setUseBonus } from "store/actions/bonus/bonus.actions";
import { toggleFeedbackVisibility } from "store/actions/feedback/feedback.actions";
import { updateKenoCurrentBets } from "store/actions/keno/keno.actions";
import LocalStorageUtils from "utils/localStorage";
import { getCurrentSettings } from "utils/settings";
//TODO Need dynamic implementation with BO fore more flexibility
// import { onBoardingEventTarget, VSEvent } from "helpers/customEvents";

import {
	SET_RIGHTBAR_TAB,
	SET_SHOW_KEYBOARD,
	SET_BETSLIP_MODE,
	SET_BETSLIP_STAKE_MODE,
	ADD_BET,
	REMOVE_BET,
	REMOVE_MATCH_BET,
	UPDATE_MATCH_BETS,
	CLEAR_BETS,
	CLEAR_HIDDEN_BETS,
	UPDATE_BET_STAKE,
	SET_STAKE,
	PLACE_BET_BEFORE,
	PLACE_BET_AFTER,
	PLACE_BET_SUCCESS,
	SET_BETSLIP_CANCELING_BEFORE,
	SET_BETSLIP_CANCELING_AFTER,
	ADD_PENDING,
	UPDATE_PENDING,
	UPDATE_PENDING_BET,
	SET_PENDINGS,
	REMOVE_PENDING,
	PENDING_BEFORE,
	PENDING_AFTER,
	CANCEL_HISTORY,
	SET_BETSLIP,
	ADD_BETSLIP_RESULT,
	CLEAR_BETSLIP_RESULTS,
	SHOW_MOBILE_BETSLIPS_MODAL
} from "../../actionTypes";
import vsToast from "utils/toast";
import { ERROR_RESOURCE, ERROR_STATUSES } from "constants/message.constants";
import { showError } from "utils/message";

export const setRigthbarTab = (tab) => ({
	type: SET_RIGHTBAR_TAB,
	payload: { tab }
});

export const setShowKeyboard = (show) => ({
	type: SET_SHOW_KEYBOARD,
	payload: { show }
});

const setMode = (mode) => ({
	type: SET_BETSLIP_MODE,
	payload: { mode }
});

export const setUseQuickBet = (enabled) => (dispatch, getState) => {
	if (enabled) {
		const state = getState();
		const session = state?.auth?.session ?? {};
		const useBonus = state?.bonus?.useBonus;
		const singleMin = state.auth.session?.currency?.singleMin ?? 1;
		const quickBetAmount = getCurrentSettings(state?.auth?.session ?? {}).quickBetAmount;

		dispatch(setBetslipMode(BETSLIP_MODES.SINGLE));
		dispatch(clearBets());

		if (useBonus) {
			const currentBonus = session?.bonuses?.[0] ?? {};
			const isFreeAmountBonus = currentBonus?.bonusType === BONUS_TYPE.FREEAMOUNT;
			let bonusAmount = currentBonus.amount;

			if (isFreeAmountBonus) {
				const difference = currentBonus.amount - singleMin;
				const totalStack = difference < singleMin ? currentBonus.amount : singleMin;
				bonusAmount = totalStack;
			}

			dispatch(setStake(bonusAmount));
			if (bonusAmount !== Number(quickBetAmount)) {
				dispatch(saveSettings({ quickBetAmount: bonusAmount }));
			}
			return;
		}

		dispatch(setStake(quickBetAmount));
	}
};

const getMin = (currency, mode) => {
	if (!currency || !mode) {
		return 0;
	}
	if (mode === BETSLIP_MODES.SINGLE) {
		return currency.singleMin;
	}
	return currency.multiMin;
};

export const setBetslipMode = (mode) => {
	return (dispatch, getState) => {
		const state = getState();
		const currency = state?.auth?.session?.currency;
		const useBonus = state?.bonus?.useBonus;
		const session = state?.auth?.session ?? {};
		const bonuses = state?.auth?.session?.bonuses ?? [];
		const isQuickBetEnabled = getCurrentSettings(session).isQuickBet;
		let bonusType = 0,
			bonusRoundCount = 0,
			bonusAmount = 0;
		if (bonuses.length > 0) {
			bonusType = bonuses[0].bonusType;
			bonusRoundCount = bonuses[0].roundCount;
			bonusAmount = bonuses[0].amount;
		}

		if (useBonus && mode === BETSLIP_MODES.SINGLE) {
			const bets = state?.betslip?.bets ?? [];
			switch (bonusType) {
				case BONUS_TYPE.FREEBET:
					if (bets.length > bonusRoundCount) {
						return vsToast.error(i18n.t("errors.message.InappropriateQuantity"));
					}
					break;
				case BONUS_TYPE.FREEAMOUNT:
					if (bonusAmount < getMin(currency, mode) && bonusAmount > 0) {
						return vsToast.error(i18n.t("errors.message.InappropriateQuantity"));
					}
					dispatch(setStake(""));
					break;
				default:
					break;
			}
		} else if (useBonus && bonusType === BONUS_TYPE.FREEBET && mode === BETSLIP_MODES.MULTI) {
			dispatch(setStake(bonusAmount));
		} else if (!isQuickBetEnabled && (!useBonus || (useBonus && mode !== BETSLIP_MODES.MULTI))) {
			dispatch(setStake(""));
		}
		dispatch(setMode(mode));
	};
};

export const setStakeMode = (mode) => ({
	type: SET_BETSLIP_STAKE_MODE,
	payload: { mode }
});

export const setStake = (stake) => ({
	type: SET_STAKE,
	payload: { stake }
});

export const addBet = (bet, stake) => {
	return {
		type: ADD_BET,
		payload: {
			bet: {
				...bet,
				stake: stake,
				key: bet.key ?? generateBetKey(bet)
			}
		}
	};
};

export const removeBet = ({ key = null, betId, eventId, removeStake = true }) => ({
	type: REMOVE_BET,
	payload: { key, betId, eventId, removeStake }
});

export const doBet = ({ market, group, eventInfo, stake, globalVariables, onSuccess }) => {
	return (dispatch, getState) => {
		const state = getState();
		const betsCount = (state?.betslip?.bets ?? []).length;
		const session = state?.auth?.session ?? {};
		const bonuses = state?.auth?.session?.bonuses ?? [];
		const isQuickBetEnabled = getCurrentSettings(session).isQuickBet;
		const quickBetAmount = getCurrentSettings(session).quickBetAmount;

		let bonusType = 0,
			bonusGameType = 0,
			bonusAmount = 0,
			bonusRoundCount = 0;
		if (bonuses.length > 0) {
			bonusType = bonuses[0].bonusType;
			bonusGameType = bonuses[0].gameType;
			bonusAmount = bonuses[0].amount;
			bonusRoundCount = bonuses[0].roundCount;
		}

		const useBonus = state?.bonus?.useBonus;

		const isBetSelected = state.betslip.bets.some((b) => b.betId === market.id && b.eventId === eventInfo.id && state.game.currentGameType !== GAME_TYPE.KENO);

		//TODO Need dynamic implementation with BO fore more flexibility
		// const isQuickBetOnboardingShown = !!getCookie(ON_BOARDING_COOKIE_NAMES[ON_BOARDING_TYPES.QUICK_BETTING]);
		// const isQuickBetOnboardingShown = !!LocalStorageUtils.get(ON_BOARDING_COOKIE_NAMES[ON_BOARDING_TYPES.QUICK_BETTING]);;

		// if (isQuickBetOnboardingShown && !globalVariables?.isMobile) {
		// 	const quickBettingOnBoardSession = SessionStorageUtils.get(ON_BOARDING_COOKIE_NAMES[ON_BOARDING_TYPES.QUICK_BETTING]);
		// 	if (quickBettingOnBoardSession?.showTooltip) {
		// 		onBoardingEventTarget.dispatchEvent(new VSEvent(ON_BOARDING_TOOLTIP_VISIBILITY_EVENT, { state: false }));
		// 	}
		// }

		if (!isBetSelected) {
			if (betsCount < BETSLIP_BETS_LIMIT) {
				let stakeToBet = stake === undefined ? "" : stake;

				if (isQuickBetEnabled && !stake && !window.isNaN(quickBetAmount)) {
					stakeToBet = quickBetAmount;
				}

				if (useBonus) {
					switch (bonusType) {
						case BONUS_TYPE.FREEBET:
							const availableGamesTypes = (state?.auth?.session?.games ?? []).map((game) => game.type);
							const bonusGamesTypes = binaryToFlags(availableGamesTypes, bonusGameType);

							if ((betsCount >= bonusRoundCount && state.betslip.mode === BETSLIP_MODES.SINGLE) || state.betslip.bets.some((bet) => !bonusGamesTypes.includes(bet.gameType))) {
								const someBetOfSameEvent = state.betslip.bets.some((bet) => bet.eventId === eventInfo.id);
								if (bonusRoundCount !== 1 || someBetOfSameEvent) {
									vsToast.error(i18n.t("errors.message.InappropriateQuantity"));
									return;
								}
							}
							stakeToBet = bonusAmount;
							break;
						case BONUS_TYPE.FREEAMOUNT:
							if (!isQuickBetEnabled) {
								stakeToBet = stake === undefined ? "" : stake;
							}
							break;
						default:
							break;
					}
				}
				if ((!useBonus || bonusType !== BONUS_TYPE.FREEAMOUNT) && stakeToBet === "" && state.betslip.stakeMode === BETSLIP_STAKE_MODES.PER_BET && state.betslip.mode === BETSLIP_MODES.SINGLE) {
					stakeToBet = state.betslip.stake;
				}

				const betGeneratedkey = generateBetKey({ eventId: eventInfo.id, betId: market.id, factor: market.factor });

				dispatch(
					addBet(
						{
							key: betGeneratedkey,
							gameData: eventInfo.gameData,
							factor: market.factor,
							name: market.showName,
							group: group,
							eventId: eventInfo.id,
							eventType: eventInfo.type,
							weekId: eventInfo.type === GAME_EVENT_TYPE.EVENT ? eventInfo.parentEventId : null,
							seasonId: eventInfo.seasonId,
							id: eventInfo.id,
							outcome: market.outcome,
							period: market.period,
							argument: market.argument,
							betId: market.id,
							gameType: state.game.currentGameType,
							hidden: market.hidden
						},
						stakeToBet
					)
				);
				dispatch(setRigthbarTab(1));

				if (typeof onSuccess === "function") {
					const updatedState = getState();
					const bets = updatedState?.betslip?.bets ?? [];
					const bet = bets.find((b) => b.key === betGeneratedkey);

					onSuccess(bet);
				}
			}
		} else {
			dispatch(removeBet({
				key: null,
				betId: market.id,
				eventId: eventInfo.id,
				removeStake: !isQuickBetEnabled
			}));
		}
	};
};

export const updateMatchBets = (event) => ({
	type: UPDATE_MATCH_BETS,
	payload: { event }
});

export const removeMatchBet = (ids) => ({
	type: REMOVE_MATCH_BET,
	payload: { ids }
});

export const clearBets = () => ({
	type: CLEAR_BETS
});

export const clearHiddenBets = () => ({
	type: CLEAR_HIDDEN_BETS
});

export const updateBetStake = (stake, key) => ({
	type: UPDATE_BET_STAKE,
	payload: { stake, key }
});

const placeBetBefore = () => ({
	type: PLACE_BET_BEFORE
});

const placeBetAfter = () => ({
	type: PLACE_BET_AFTER
});

export const placeBetSuccess = (success) => ({
	type: PLACE_BET_SUCCESS,
	payload: { success }
});

export const placeQuickBet = (bet, onSuccess, onError) => {
	return (dispatch, getState) => {
		dispatch(placeBetBefore());
		const state = getState();
		const useBonus = state?.bonus?.useBonus;
		const bonuses = state?.auth?.session?.bonuses ?? [];

		let bonusId = null;
		if (bonuses.length > 0) {
			bonusId = bonuses[0].bonusId;
		}

		const data = {
			type: BETSLIP_MODES.SINGLE,
			bets: [bet]
				.filter((b) => !b.isExpired && b.stake)
				.map((b) => ({
					eventId: b.eventId,
					oddId: b.betId,
					amount: b.stake
				}))
		};

		if (useBonus && bonusId) {
			data.bonusId = bonusId;
		}

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.PLACE_BET}`,
			method: Methods.POST,
			data: data
		})
			.then(({ data: { value: data } }) => {
				sendPostMessageToParent({ type: CLIENT_API.BET, bet: data.totalAmount });

				const state = getState();
				const userId = state?.auth?.player?.userId ?? "";
				const session = state?.auth?.session ?? {};
				const betslipStake = state?.betslip?.stake;

				const quickBetAmount = getCurrentSettings(session).quickBetAmount;

				if (Number(betslipStake) !== quickBetAmount) {
					dispatch(setStake(String(quickBetAmount)));
				}

				dispatch(placeBetSuccess(true));
				dispatch(removeBet({ key: bet.key, removeStake: false }));
				dispatch(placeBetAfter());
				dispatch(addPending(data));
				dispatch(toggleFeedbackVisibility(`vs__feedback_is_shown_${userId}`));

				typeof onSuccess === "function" && onSuccess();
			})
			.catch((err) => {
				if (
					useBonus &&
					err?.isAxiosError &&
					(
						(err?.response?.data?.resource === ERROR_RESOURCE.PLAYER_BONUS && err?.response?.data?.status === ERROR_STATUSES.NOT_ALLOWED) ||
						(err?.response?.data?.resource === ERROR_RESOURCE.OPERATOR && err?.response?.data?.status === ERROR_STATUSES.INVALID_PARAMETERS)
					)
				) {
					const session = getState()?.auth?.session ?? {};
					const bonusForRemove = session?.bonuses?.[0] ?? {};
					const sessionId = session?.sessionId;
					if (bonusForRemove.bonusType === BONUS_TYPE.FREEBET) {
						bonusForRemove.roundCount = 0;
					} else {
						bonusForRemove.amount = 0;
					}
					dispatch(setUseBonus(false));
					dispatch(setBonus({ ...bonusForRemove }, sessionId));
				}

				if (!err?.isAxiosError) {
					showError({
						message: err?.response?.data?.message ?? "",
						status: err?.response?.data?.status ?? "",
						resource: err?.response?.data?.resource ?? ""
					});
				}

				onError(bet);
				dispatch(placeBetAfter());
			});
	};
};

export const placeBet = (onSuccess) => {
	return (dispatch, getState) => {
		dispatch(placeBetBefore());
		const state = getState();
		const betSlipData = state.betslip;
		const useBonus = state?.bonus?.useBonus;

		const bonuses = state?.auth?.session?.bonuses ?? [];

		let bonusId = null;
		if (bonuses.length > 0) {
			bonusId = bonuses[0].bonusId;
		}

		const data = {
			type: betSlipData.mode,
			bets: betSlipData.bets
				.filter((b) => !b.isExpired && b.stake)
				.map((b) => ({
					eventId: b.eventId,
					oddId: b.betId,
					amount: betSlipData.mode === BETSLIP_MODES.SINGLE ? b.stake : 0
				}))
		};

		if (useBonus && bonusId) {
			data.bonusId = bonusId;
		}

		if (betSlipData.mode === BETSLIP_MODES.MULTI) {
			data.totalAmount = betSlipData.stake;
		}

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.PLACE_BET}`,
			method: Methods.POST,
			data: data
		})
			.then(({ data: { value: data } }) => {
				sendPostMessageToParent({ type: CLIENT_API.BET, bet: data.totalAmount });

				const state = getState();
				const userId = state?.auth?.player?.userId;
				const session = state?.auth?.session ?? {};
				const isQuickBetEnabled = getCurrentSettings(session).isQuickBet;
				dispatch(clearBets());
				if(!isQuickBetEnabled) {
					dispatch(setStake(""));
				}

				dispatch(placeBetSuccess(true));
				dispatch(placeBetAfter());
				dispatch(addPending(data));
				dispatch(toggleFeedbackVisibility(`vs__feedback_is_shown_${userId}`));

				typeof onSuccess === "function" && onSuccess();
			})
			.catch((err) => {
				if (
					useBonus &&
					err?.isAxiosError &&
					(
						(err?.response?.data?.resource === ERROR_RESOURCE.PLAYER_BONUS && err?.response?.data?.status === ERROR_STATUSES.NOT_ALLOWED) ||
						(err?.response?.data?.resource === ERROR_RESOURCE.OPERATOR && err?.response?.data?.status === ERROR_STATUSES.INVALID_PARAMETERS)
					)
				) {
					const session = getState()?.auth?.session ?? {};
					const bonusForRemove = session?.bonuses?.[0] ?? {};
					const sessionId = session?.sessionId;
					if (bonusForRemove.bonusType === BONUS_TYPE.FREEBET) {
						bonusForRemove.roundCount = 0;
					} else {
						bonusForRemove.amount = 0;
					}
					dispatch(clearBets());
					dispatch(setStake(""));
					dispatch(setUseBonus(false));
					dispatch(setBonus({ ...bonusForRemove }, sessionId));
				} else {
					dispatch(clearHiddenBets());
				}
				if (!err?.isAxiosError) {
					showError({
						message: err?.response?.data?.message ?? "",
						status: err?.response?.data?.status ?? "",
						resource: err?.response?.data?.resource ?? ""
					});
				}
				dispatch(placeBetAfter());
			});
	};
};

export const setPendingBefore = () => ({
	type: PENDING_BEFORE
});

export const setPendingAfter = () => ({
	type: PENDING_AFTER
});

export const addPending = (pending) => ({
	type: ADD_PENDING,
	payload: { pending }
});

export const setPendings = (pendings) => ({
	type: SET_PENDINGS,
	payload: { pendings }
});

export const removePending = (pending) => ({
	type: REMOVE_PENDING,
	payload: { pending }
});

export const updatePending = (pending) => ({
	type: UPDATE_PENDING,
	payload: { pending }
});

export const updatePendingBet = (bet) => ({
	type: UPDATE_PENDING_BET,
	payload: { bet }
});

export const getPendingBets = () => {
	return (dispatch) => {
		dispatch(setPendingBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PENDINGS}`,
			method: Methods.GET
		})
			.then(({ data: { value: data } }) => {
				dispatch(setPendings(data));
				dispatch(setPendingAfter());
			})
			.catch((err) => {
				dispatch(setPendingAfter());
			});
	};
};

const cancelBetSlipFromHistory = (bet) => ({
	type: CANCEL_HISTORY,
	payload: { bet }
});

const cancelBetSlipBefore = () => ({
	type: SET_BETSLIP_CANCELING_BEFORE
});

const cancelBetSlipAfter = () => ({
	type: SET_BETSLIP_CANCELING_AFTER
});

export const cancelBetSlip = (id) => {
	return (dispatch) => {
		dispatch(cancelBetSlipBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CANCEL_BETSLIP}`,
			method: Methods.POST,
			data: { id }
		})
			.then(({ data: { value: data } }) => {
				dispatch(removePending(data));
				dispatch(cancelBetSlipFromHistory(data));
				dispatch(updateKenoCurrentBets(data));
				dispatch(cancelBetSlipAfter());
			})
			.catch((err) => {
				dispatch(cancelBetSlipAfter());
			});
	};
};

export const cancelSingleBet = (id, betId) => {
	return (dispatch) => {
		dispatch(cancelBetSlipBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CANCEL_BET}`,
			method: Methods.POST,
			data: { id, betId }
		})
			.then(({ data: { value: data } }) => {
				dispatch(updateHistoryBetSlip(data.item1));
				dispatch(updateHistoryBetSlipBet(data.item2));

				if (data.item1.state === BET_STATE.CANCELLED) {
					dispatch(removePending(data.item1));
				} else {
					dispatch(updatePending(data.item1));
					//dispatch(updatePendingBet(data.item2))
				}

				dispatch(cancelBetSlipAfter());
			})
			.catch((err) => {
				dispatch(cancelBetSlipAfter());
			});
	};
};

export const setBetslip = (betslip) => ({
	type: SET_BETSLIP,
	payload: { betslip }
});

export const addBetslipResult = (data) => ({
	type: ADD_BETSLIP_RESULT,
	payload: { data }
});

export const clearBetslipResults = () => ({
	type: CLEAR_BETSLIP_RESULTS
});

export const reBet = (successFn) => {
	return (dispatch, getState) => {
		const reBetDataTemp = getState().betslip.betslipLastResults.map((b) => ({
			market: b.market,
			oddId: b.oddId,
			gameType: b.gameType,
			period: b.period,
			stake: b.amount + ""
		}));

		const reBetData = [];

		reBetDataTemp.forEach((d) => {
			if (!reBetData.some((r) => r.oddId === d.oddId)) {
				reBetData.push(d);
			}
		});

		const liveAndUpcomings = getState().game.liveAndUpcomings.data;

		const nextUpcoming = liveAndUpcomings.find((ev) => ev.status === GAME_STATUSES.PREAMBLE_STARTED || ev.status === GAME_STATUSES.NEW);

		const nextUpcomingId = nextUpcoming ? nextUpcoming.id : null;

		const nextUpcomingEventData = getState().game.matches.data?.[nextUpcomingId];

		const upcomingEvent = nextUpcomingEventData?.event ?? {};

		const markets = nextUpcomingEventData?.markets ?? {};

		reBetData.forEach((bet, index) => {
			let found = null;
			let id = bet.oddId;
			Object.keys(markets).forEach((m) => {
				if (!found) {
					markets[m].forEach((item) => {
						if (!found) {
							if (item.bets.find((b) => b.id === id && !b.disabled)) {
								found = item.bets.find((b) => b.id === id);
							}
						}
					});
				}
			});
			if (found) {
				reBetData[index].data = found;
			}
		});

		reBetData.forEach((bet) => {
			if (bet.data) {
				let isBetSelected = getState().betslip.bets.some((b) => b.eventId === upcomingEvent.id && b.betId === bet.oddId);
				let group = runMarketUtilsFunction(
					"makeGroupTitle",
					[
						{
							group: bet.market,
							period: bet.period,
							argument: bet.argument,
							team1: upcomingEvent?.gameData?.team1?.countryCode,
							team2: upcomingEvent?.gameData?.team2?.countryCode,
							gameType: bet.gameType
						}
					],
					bet.gameType
				);
				if (!isBetSelected) {
					dispatch(doBet({
						market: { ...bet.data, period: bet.period },
						group,
						eventInfo: upcomingEvent,
						stake: bet.stake
					}));
				}
			}
		});

		if (reBetData.some((b) => b.data)) {
			successFn && successFn(reBetData.filter((b) => b.data).length);
		}
	};
};

export const setShowMobileBetSlipsModal = (value) => ({
	type: SHOW_MOBILE_BETSLIPS_MODAL,
	payload: { value }
});
