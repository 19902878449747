import { useSelector, useDispatch } from "react-redux";
import useGlobalVariables from "hooks/useGlobalVariables";
import { useTranslation } from "react-i18next";

import Loader from "components/ui/loader";

import { saveSettings } from "store/actions/auth/auth.actions";
import { placeBet } from "store/actions/betslip/betslip.actions";

import { playAudio, mergeClassNames, numberWithSpaces, buildPathToStaticFolderOfCDN, isRTL } from "utils/common";
import { playForReal } from "utils/auth";
import { navigateToPage } from "utils/navigate";
import { calculateTotalOdds, calculateTotalStake } from "utils/bets";
import { getCurrentSettings } from "utils/settings";
import { BETSLIP_MODES } from "constants/betslip.constants";
import Paths from "constants/path.constants";
import { useNavigate } from "react-router";
import { Fragment } from "react";

import SessionStorageUtils from "utils/sessionStorage";

const maskNaNDecorator = (fn) => {
	if (typeof fn !== "function") {
		return fn;
	}
	return (...args) => {
		const result = fn(...args);
		if (window.isNaN(result)) {
			return 0;
		}

		return result;
	};
};

const getIsDemoSession = state => state.auth.session.isDemo;
const getSession = state => state.auth.session;
const getSessionCurrency = state => state.auth.session.currency ?? {};
const getIsQuickBetEnabled = (state) => getCurrentSettings(state.auth.session).isQuickBet;
const getIsSoundOn = (state) => getCurrentSettings(state.auth.session).isSound;
const getQuickBetAmount = (state) => getCurrentSettings(state.auth.session).quickBetAmount;
const getSwitchingToRealMode = state => state.auth.switchingToRealMode;
const getUseBonus = state => state.bonus.useBonus;
const getBets = state => state.betslip.bets;
const getStake = state => state.betslip.stake;
const getMode = state => state.betslip.mode;
const getBetslipLoading = state => state.betslip.loading;
const getIsRTLLanguage = state => isRTL(state.auth.session.languageId);

const BetButtons = ({
	className = "",
	hasError = Function.prototype,
	focusedStakeId = null,
	placeBetHandler = null,
	checkPlaceBetAvailability = null,
	countTotalStake = null
}) => {
	const isDemo = useSelector(getIsDemoSession);
	const session = useSelector(getSession);
	const currency = useSelector(getSessionCurrency);
	const isSound = useSelector(getIsSoundOn);
	const isQuickBetEnabled = useSelector(getIsQuickBetEnabled);
	const quickBetAmount = useSelector(getQuickBetAmount);
	const switchingToRealMode = useSelector(getSwitchingToRealMode);
	const useBonus = useSelector(getUseBonus);
	const bets = useSelector(getBets);
	const stake = useSelector(getStake);
	const mode = useSelector(getMode);
	const isLoading = useSelector(getBetslipLoading);
	const isRTLLanguage = useSelector(getIsRTLLanguage);

	const dispatch = useDispatch();

	const { t } = useTranslation();
	const globalVariables = useGlobalVariables();
	const { isMobile, isPreview } = globalVariables;
	const navigate = useNavigate();

	const activeBets = bets.filter((bet) => !bet.isExpired);

	const isThereError = hasError();

	const totalStakeCb = (
		maskNaNDecorator(
			countTotalStake !== null
				? countTotalStake
				: calculateTotalStake
		)
	);

	const getMinLimit = () =>
		mode === BETSLIP_MODES.SINGLE
			? (currency.singleMin ?? 0)
			: currency.multiMin ?? 0;

	const getMaxLimit = () =>
		mode === BETSLIP_MODES.SINGLE
			? (currency.singleMax ?? Infinity)
			: currency.multiMax ?? Infinity;

	const isPlaceBetDisabled = () => {
		if (checkPlaceBetAvailability) {
			return checkPlaceBetAvailability();
		}

		const mapped = bets.map((b) => b.eventId);

		return (
			(mode === BETSLIP_MODES.MULTI && (stake === "" || mapped.length !== new Set(mapped).size)) ||
			isThereError ||
			isLoading ||
			(mode === BETSLIP_MODES.SINGLE && bets.filter((b) => ((Number(b.stake) < getMinLimit() || Number(b.stake) > getMaxLimit()) && b.stake !== "") || ["", "."].includes(b.stake)).length > 0)
		);
	};

	const isBetDisabled = isPlaceBetDisabled();

	const handlePlaceBet = () => {
		if (isBetDisabled) {
			return;
		}

		if (placeBetHandler) {
			return placeBetHandler();
		}

		SessionStorageUtils.set("repeatBet", false)

		dispatch(
			placeBet(() => {
				if (isMobile) {
					navigateToPage(navigate, Paths.HOME);

					if (isSound) {
						playAudio(buildPathToStaticFolderOfCDN("media/success.mp3"));
					}
				}
			})
		);
	};

	const handlePlayForRealClick = () => {
		playForReal({
			isPreview,
			isAuthorized: session?.isAuthorized,
			isAnonymous: session?.isAnonymous,
			params: globalVariables
		});

		SessionStorageUtils.set("repeatBet", false)
	};

	if (isMobile && focusedStakeId !== null && isBetDisabled) {
		return null;
	}

	return (
		<div className={mergeClassNames("vs--flex vs--align-center vs--justify-center", "vs--betslip-stake-buttons", className)}>
			{!isQuickBetEnabled && (
				<button
					type="button"
					className={
						mergeClassNames(
							"vs--betslip-stake-buttons-bet",
							"vs--button vs--pt-8 vs--pb-8 vs--font-normal vs--font-medium vs--text-cut",
							isPlaceBetDisabled() && "vs--button-disabled",
							isDemo && "vs--betslip-stake-buttons-demo"
						)
					}
					onClick={handlePlaceBet}
				>
					{
						useBonus
							? t("bet.placeBonusBet")
							: isDemo
								? t("bet.placeDemoBet")
								: (
									<Fragment>
										<span>{`${t("bet.placeBet")}: `}</span>

										<span className="vs--stake-btn">
											{numberWithSpaces(Number(totalStakeCb(activeBets, session, mode === BETSLIP_MODES.MULTI ? stake : null)), isRTLLanguage ? { reverse: true, separator: "" } : undefined)}
										</span>

										<span>{` ${currency.code}`}</span>
									</Fragment>
								)
					}
					{
						isLoading && <Loader />
					}
				</button>
			)}
			{isDemo && (
				<button
					type="button"
					className={mergeClassNames(
						"vs--button vs--button-primary vs--betslip-stake-buttons-play-for-real",
						"vs--pt-8 vs--pb-8 vs--font-normal vs--font-condensed vs--font-medium",
						isPreview && "vs--button-disabled"
					)}
					onClick={handlePlayForRealClick}
				>
					{t("bet.playForReal")}
					{switchingToRealMode && <Loader />}
				</button>
			)}
			{!isMobile && isQuickBetEnabled && !isDemo && (
				<button
					type="button"
					className={mergeClassNames("vs--button vs--button-primary", "vs--pt-8 vs--pb-8 vs--font-normal vs--font-condensed vs--font-medium", (quickBetAmount === Number(stake) || isThereError) && "vs--button-disabled")}
					disabled={quickBetAmount === Number(stake) || isThereError}
					onClick={() => {
						if(!isThereError) {
							dispatch(saveSettings({ quickBetAmount: stake }));
						}
					}}
				>
					{quickBetAmount === Number(stake) ? (
						<div className="vs--flex vs--align-center vs--justify-center">
							<div className="vs--mr-4">
							<span>{t("common.applied")}</span>
							<span>:</span>
							</div>
							<span>{quickBetAmount}</span>
							<span> {currency.code}</span>
						</div>
					) : (
						t("common.apply")
					)}
				</button>
			)}
		</div>
	);
};

export default BetButtons;
