import { useCallback, useEffect } from "react";
import "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { setSeasonStatisticsId, getSeasonStructure, resetStatisticsState, sendUpdateRequestForSeasonStructure } from "store/actions/statistics/statistics.actions";
import useUpdateEffect from "hooks/useUpdateEffect";
import { GAME_STATUSES } from "constants/game.constants";

const CupGamesLiveSeasonHoc = (ReactComponent) => {
	const getSeasonId = (state) => state.game.liveAndUpcomings?.data?.[0]?.seasonId ?? null;
	const getCurrentGameTypeState = (state) => state.game?.currentGameType ?? null;
	const getGamesState = (state) => state.auth.session?.games ?? [];

	const getStructureState = (state) => state.statistics?.season?.structure ?? {};
	const getLoadingState = (state) => state.statistics?.season?.loading || state.statistics?.season?.isUpdateRequestSended;

	const WrappedReactComponentOfCupGamesLiveSeasonHoc = (props) => {
		const seasonId = useSelector(getSeasonId);
		const currentGameType = useSelector(getCurrentGameTypeState);
		const games = useSelector(getGamesState);

		const game = games.find((g) => g.type === currentGameType);
		const gameId = game?.id;
		const structure = useSelector(getStructureState);
		const loading = useSelector(getLoadingState);

		const dispatch = useDispatch();
		const setStatisticsId = useCallback((id) => dispatch(setSeasonStatisticsId(id)), [dispatch]);
		const getStructure = useCallback((gameId) => dispatch(getSeasonStructure(gameId)), [dispatch]);
		const resetStandings = useCallback(() => dispatch(resetStatisticsState()), [dispatch]);
		const sendUpdateRequest = useCallback(() => dispatch(sendUpdateRequestForSeasonStructure()), [dispatch]);

		useEffect(() => {
			setStatisticsId(seasonId);
		}, [seasonId, setStatisticsId]);

		useEffect(() => {
			getStructure(gameId);
			return () => {
				resetStandings();
			};
		}, [gameId, getStructure, resetStandings]);

		useUpdateEffect(() => {
			if (structure.status === GAME_STATUSES.PREAMBLE_STARTED) {
				sendUpdateRequest();
			}
		}, [structure.status, sendUpdateRequest]);

		return <ReactComponent structure={structure} loading={loading} isFromResults={false} currentGameType={currentGameType} {...props} />;
	};

	return WrappedReactComponentOfCupGamesLiveSeasonHoc;
};

export default CupGamesLiveSeasonHoc;
