import store from 'store/configureStore';
import i18n from 'translations/config';
import { GAME_TYPE, SEASONAL_PERIODS, PREDICATE_MULTIPLIER_OF_SEASON_GAMES, SELECTED_SEASON_GAMES, PENALTY_SCENE_INFO_NAMES } from 'constants/game.constants';
import { MULTI_MODE_BETS_MAX_FACTORS } from 'constants/betslip.constants';

// TODO remove all import place to global variable context
export { isMobile, isIOS, isIE, isFirefox, isAndroid } from "utils/deviceDetect";

/**
	* Function to create full path for loading it from CDN
	* @param {string} path file location
	* @returns {string} full path of file from CDN
*/
export const buildPathToStaticFolderOfCDN = (path) => {
	return `${import.meta.env.SYSTEM_CDN_URL}/static/${path}`;
}

/** Get query params
 * @function
 * @param {string} path - location path
 * @returns {object} - query params
 */
export const getUrlVars = (path) => {
	const href = path || window.location.href;
	const urlSearchParams = new URLSearchParams(href);
	const tuples = urlSearchParams.entries();
	const vars = {};
	const proxyHandlers = {
		get: (target, name) => target[name.toLowerCase()]
	};
	for (const [key, value] of tuples) {
		/*
			Don't remove
			Fix issue:
			http://localhost:5000/results?user parsed http://localhost:5000/results?user insted of user
		*/
		const varKey = key.split("?").at(-1).toLowerCase();
		vars[varKey] = value;
	}
	const proxy = new Proxy(vars, proxyHandlers);
	return proxy;
};

export const groupBy = (collection, iteratee) => {
	// Create an empty object to store the groups
	const groups = {};

	// Iterate over the collection
	for (const element of collection) {
		// Get the key for this element based on the iteratee function
		const key = iteratee ? iteratee(element) : element;

		// If the group for this key doesn't exist, create it as an array
		if (!Object.hasOwn(groups, key)) {
			groups[key] = [];
		}

		// Push the element to the group's array
		groups[key].push(element);
	}

	// Return the object containing the grouped elements
	return groups;
}

/** Function using for merge not empty string arguments with spaces
 * @function
 * @param {string} path - location path
 * @returns {object} - query params
 */
export const mergeClassNames = (...classes) => {
	return (
		new Array()
			.concat(classes)
			.filter((str) => typeof str === "string" && str !== "")
			.join(" ") || null
	);
};

/** Function using for checking is value null or undefined
 * @function
 * @param {string} checkArgument - value for checking
 * @returns {boolean} - true if value is null or undefined, otherway false
 */
export const isNullish = (checkArgument) => {
	return checkArgument === null || checkArgument === undefined;
};

/** Make string to lowercase
 * @function
 * @param {string} str
 * @returns {string}
 */
export const toLowerCase = (str) => {
	return str && str.toLowerCase();
};

/** Make string first character to lowercase
 * @function
 * @param {string} str
 * @returns {string}
 */
export const toLowerCaseFirstLetter = (str) => str.charAt(0).toLowerCase() + str.slice(1);

/** Check if element is in viewport
 * @function
 * @param {object} el - Dom element to check
 * @returns {boolean}
 */
export const isElementInViewport = (el) => {
	if (!el) return false;
	let top = el.offsetTop;
	let left = el.offsetLeft;
	let width = el.offsetWidth;
	let height = el.offsetHeight;

	while (el.offsetParent) {
		el = el.offsetParent;
		top += el.offsetTop;
		left += el.offsetLeft;
	}

	return top < window.pageYOffset + window.innerHeight && left < window.pageXOffset + window.innerWidth && top + height > window.pageYOffset && left + width > window.pageXOffset;
};

/** Check if element top is in viewport
 * @function
 * @param {object} el - Dom element to check
 * @returns {boolean}
 */
export const isElementTopInViewport = (el) => {
	if (!el) return false;
	let top = el.offsetTop;

	while (el.offsetParent) {
		el = el.offsetParent;
		top += el.offsetTop;
	}

	return top < window.pageYOffset + window.innerHeight;
};

/** Check if element is in container visible part
 * @function
 * @param {object} element - Dom element to check
 * @param {object} container - Contener DOM element
 * @returns {boolean}
 */
export const isElementInContainerView = (element, container) => {
	if (!container || !element) return false;
	let cTop = container.scrollTop;
	let cBottom = cTop + container.clientHeight;
	let eTop = element.offsetTop;
	let eBottom = eTop + element.clientHeight;
	let isTotal = eTop >= cTop && eBottom <= cBottom;
	let isPartial = (eTop < cTop && eBottom > cTop) || (eBottom > cBottom && eTop < cBottom);

	return isTotal || isPartial;
};

/** Check if element is visible inside window
 * @function
 * @param {object} element - Dom element to check
 * @param {object} container - Contener DOM element
 * @returns {boolean}
 */
export const isScrolledIntoView = (element) => {
	let rect = element.getBoundingClientRect();
	let elemTop = rect.top;
	let elemBottom = rect.bottom;
	let isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
	return isVisible;
};

/** Make number to float(string)
 * @function
 * @param {number} num
 * @param {number} decimal - number counts after floating number
 * @returns {string}
 */
export const toFixed = (num, decimal) => Number(num).toFixed(decimal);

/** Make number to float(string) by flooring
 * @function
 * @param {number} num
 * @param {number} decimal - number counts after floating number
 * @returns {string}
 */
export const toFixedFloor = (num, decimal) => Math.trunc(Number(num) * Math.pow(10, decimal)) / Math.pow(10, decimal);

/** Validate if the value is correct for stake input field
 * @function
 * @param {string} amount
 * @param {number} decimal - number counts after floating number
 * @returns {boolean}
 */
export const validateForAmount = (amount, decimal) => {
	let rgx = /^[0-9]*\.?[0-9]*$/;
	if (decimal === 0) {
		rgx = /^[0-9]*$/;
	}

	if (amount.toString().startsWith("0") && !amount.toString().startsWith("0.") && amount.toString() !== "0") {
		return false;
	}

	return amount.toString().length <= 15 && amount.match(rgx) && (!amount.toString().split(".")[1] || amount.toString().split(".")[1].length <= decimal);
};

/** Remove duplicates from array
 * @function
 * @param {array} arr
 * @returns {array}
 */
export const array_unique = (arr) => Array.from(new Set(arr));

/** Lock screen orientation for mobile
 * @function
 * @param {string} mode - Orientation mode
 */
export const lockOrientation = (mode) =>
	(screen.orientation && screen.orientation.lock && screen.orientation.lock(mode).then(Function.prototype, (err) => console.log(err))) || (screen.mozLockOrientation && screen.mozLockOrientation(mode)) || (screen.msLockOrientation && screen.msLockOrientation(mode));

/** UnLock screen orientation for mobile
 * @function
 */
export const unLockOrientation = () => (screen.orientation && screen.orientation.unlock && screen.orientation.unlock()) || (screen.mozUnlockOrientation && screen.mozUnlockOrientation()) || (screen.msUnockOrientation && screen.msUnockOrientation());

/** Beutify number by adding spaces
 * @function
 * @param {number} num
 * @returns {string}
 */
export const numberWithSpaces = (num, argConfig = { reverse: false, separator: " " }) => {
	if (num === null || num === undefined) return "";
	const config = Object.assign({}, { reverse: false, separator: " " }, argConfig);
	const parts = num.toString().split(".");
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, config.separator);
	if (config.reverse) {
		parts[0] = parts[0].split("").reverse().join("")
		if (parts.length > 1) {
			parts[1] = parts[1].split("").reverse().join("");
			parts.reverse()
		}
	}
	return parts.join(".");
};

/** Beutify number by adding spaces
 * @function
 * @param {number} value
 * @param {object} currency
 * @returns {string}
 */
export const makeCurrencyText = (value, currency) => {
	const currencyCode = currency?.code;
	const decimalCount = currency?.decimalCount ?? 2;
	const reverse = isRTL(store.getState()?.auth?.session?.languageId);
	const num = Number(value);
	return [numberWithSpaces(toFixed(num, decimalCount)), currencyCode].sort(() => (reverse ? -1 : 1)).join(" ");
};

/** Returns all posible combinations from array items
 * @function
 * @param {string} str - all characters string
 * @returns {array}
 */
export const getAllCombinations = (str, curstr = "") => {
	if (curstr.length == str.length) return [curstr];
	var ret = [];
	for (var i = 0; i < str.length; i++) {
		if (!curstr.includes(str[i])) ret.push.apply(ret, getAllCombinations(str, curstr + str[i]));
	}
	return ret;
};

/** Cheks if the page is RTL
 * @function
 * @param {string} language - the language
 * @returns {boolean}
 */
export const isRTL = (language) => {
	const farsiLanguages = ["FA", "AR", "KU", "HE"];
	return farsiLanguages.includes(language);
};

/** Checks is the game is racing
 * @function
 * @param {number} type - the game type
 * @returns {boolean}
 */
export const isRacingGame = (type) => [GAME_TYPE.HORSES_RACE, GAME_TYPE.GREYHOUNDS_RACE, GAME_TYPE.HORSE_STEEPLECHASING].includes(type);

/** Checks is the game is league
 * @function
 * @param {number} type - the game type
 * @returns {boolean}
 */
export const isLeagueGame = type => [GAME_TYPE.ENGLISH_LEAGUE, GAME_TYPE.TURKISH_LEAGUE].includes(type);

/** Checks is the game is simple cup
 * @function
 * @param {number} type - the game type
 * @returns {boolean}
 */
export const isSimpleCupGame = (type) => [GAME_TYPE.AFRICAN_CUP, GAME_TYPE.WORLD_CUP, GAME_TYPE.EUROPEAN_CUP].includes(type);

/** Checks is the game is champions game
 * @function
 * @param {number} type - the game type
 * @returns {boolean}
 */
export const isChampionsCupGame = (type) => [GAME_TYPE.CHAMPIONS_LEAGUE, GAME_TYPE.COPA_LIBERTADORES].includes(type);

/** Checks is the game is cup
 * @function
 * @param {number} type - the game type
 * @returns {boolean}
 */
export const isCupGame = (type) => isSimpleCupGame(type) || isChampionsCupGame(type);

/** Checks is the game is season
 * @function
 * @param {number} type - the game type
 * @returns {boolean}
 */
export const isSeasonGame = (type) => isLeagueGame(type) || isCupGame(type);

export const renderSeasonId = (type) => {
	switch (true) {
		case isLeagueGame(type):
			return i18n.t("common.leagueId");
		case isCupGame(type):
			return i18n.t("common.cupId");
		default:
			break;
	}
};

/** Play audio file
 * @function
 * @param {object} file - audio file
 */
export const playAudio = (file) => {
	const context = new AudioContext();
	const source = context.createBufferSource();
	source.connect(context.destination);
	const request = new XMLHttpRequest();
	request.open("GET", file, true);
	request.responseType = "arraybuffer";
	request.onload = function () {
		context.decodeAudioData(
			request.response,
			function (response) {
				source.buffer = response;
				source.start(0);
			},
			function () {
				console.error("The request failed.");
			}
		);
	};
	request.send();
};

/** Function to get unique random numbers by specific range and count
 * @function
 * @param {number} count - Random numbers count
 * @param {number} range - Max range of random numbers
 * @param {number} movePoint - Number for moveing random value
 * @param {function} raundingFunction - Function for raunding random number
 * @returns {array}
 */
export const getUniqueRandomNumbersByRangeAndCount = (count = 0, range = 1) => {
	const container = new Set();
	while (container.size < count) {
		container.add(Math.floor(Math.random() * range + 1));
	}
	return [...container];
};

/** Function to calculate bets factor result
 * @function
 * @param {array} bets - contain each bet data
 * @returns {number}
 */
export const calcFactorsResult = (bets) => {
	return bets.reduce((accumulator, b) => accumulator * b.factor, 1);
};

/** Function to check keno bet in bets
 * @function
 * @param {array} bets - contain each bet data
 * @returns {boolean}
 */
export const isThereAtLeastOneKenoBet = (bets) => {
	return bets.some((bet) => bet.gameType === GAME_TYPE.KENO);
};

/** Function to check is factors result over limited
 * @function
 * @param {array} bets - contain each bet data
 * @returns {boolean}
 */
export const isFactorsResultOverLimited = (bets) => {
	const factorsResult = calcFactorsResult(bets);

	return isThereAtLeastOneKenoBet(bets) ? factorsResult > MULTI_MODE_BETS_MAX_FACTORS.WITH_KENO_BET : factorsResult > MULTI_MODE_BETS_MAX_FACTORS.WITHOUT_KENO_BET;
};

/** Function to set cookie
 * @function
 * @param {string} cookieName - cookie name
 * @param {string} cookieValue - cookie value
 * @param {number} expires - cookie expiration days
 */
export const setCookie = (cookieName, cookieValue, expires) => {
	let expiresString = "expires=";
	if (typeof expires === "number") {
		const date = new Date();
		date.setTime(date.getTime() + expires * 24 * 60 * 60 * 1000);
		expiresString += date.toUTCString();
	} else if (typeof expires === "string") {
		expiresString += expires;
	} else if ((typeof expires === "object") && (expires instanceof Date)) {
		expiresString += expires.toUTCString();
	}

	if (expiresString === "expires=") {
		expiresString = "";
	} else {
		expiresString += ";";
	}

	document.cookie = cookieName + "=" + encodeURIComponent(cookieValue) + "; SameSite=None; Secure; " + expiresString + "path=/";
};

/** Function to get cookie
 * @function
 * @param {string} cookieName - cookie name
 * @param {any} defaultValue - default value of cookie
 * @returns {null | string}
 */
export const getCookie = (cookieName, defaultValue = null) => {
	const name = cookieName + "=";
	const decodedCookie = decodeURIComponent(document.cookie);
	const cookieList = decodedCookie.split(";");
	for (let i = 0; i < cookieList.length; i++) {
		let cookieKeyValuePair = cookieList[i];
		while (cookieKeyValuePair.charAt(0) == " ") {
			cookieKeyValuePair = cookieKeyValuePair.substring(1);
		}
		if (cookieKeyValuePair.indexOf(name) == 0) {
			return cookieKeyValuePair.substring(name.length, cookieKeyValuePair.length);
		}
	}
	return defaultValue;
};

/** Function for reduce loop for every iterable object
 * @function
 * @param {object} iterable - object with required length property and optional indexed keys
 * @param {function} callback - callback for execution
 * @param {any} accumulator - value for collect execution result
 * @returns {any}
 */
export const ReduceOnIterable = (iterable = { length: 0 }, callback, accumulator) => {
	const len = iterable.length;
	if (!len) {
		return accumulator;
	}
	let innerAccumulator = accumulator ? accumulator : iterable[0];
	let i = accumulator ? 0 : 1;
	while (i < len) {
		innerAccumulator = callback(innerAccumulator, iterable[i], i, iterable);
		i++;
	}
	return accumulator;
};

/** Function to group data by qty
 * @function
 */
export const groupByQty = (arr, qty) => {
	if (!Array.isArray(arr) || typeof qty !== "number") {
		return [];
	}
	return arr.reduce((acc, elem) => {
		if (!Array.isArray(acc[acc.length - 1]) || acc[acc.length - 1].length === qty) {
			acc.push([]);
		}
		acc[acc.length - 1].push(elem);
		return acc;
	}, []);
};

/** Function to get elements from matrix by line
 * @function
 */
export const getLineFromMatrix = (matrix, index, isRow = true) => {
	if (isRow) {
		return matrix[index];
	}
	return matrix.map((arr) => arr[index]);
};

/** Get Round translated name of simple Cup Game
 * @function
 * @param {number} orderNumber - functions array what will be executed
 * @param {boolean} isShort - is need short name translation
 * @returns {string} - empty string or translated value
 */
export const getRoundNameOfSimpleCupGame = (orderNumber, isShort = false) => {
	switch (orderNumber) {
		case 1:
		case 2:
			return `${i18n.t(`common.${SEASONAL_PERIODS.ROUND_16}${isShort ? "Short" : ""}`)} 16 ${i18n.t("common.day")} ${orderNumber}`;
		case 3:
			return `${i18n.t(`common.${SEASONAL_PERIODS.QUARTER_FINALS}${isShort ? "Short" : ""}`)}`;
		case 4:
			return `${i18n.t(`common.${SEASONAL_PERIODS.SEMI_FINALS}${isShort ? "Short" : ""}`)}`;
		case 5:
			return `${i18n.t(`common.${SEASONAL_PERIODS.FINAL}${isShort ? "Short" : ""}`)}`;
		default:
			break;
	}
	return "";
};

/** Checking function is round Leg 1
 * @function
 * @param {number} orderNumber - orderNumber of round what need to check
 * @returns {boolean} - result of checking
 */
export const isLeg1 = (orderNumber) => orderNumber % 2 === 1;

/** Checking function is round Leg 2
 * @function
 * @param {number} orderNumber - orderNumber of round what need to check
 * @returns {boolean} - result of checking
 */
export const isLeg2 = (orderNumber) => orderNumber % 2 === 0;

/** Checking function is round par of Champions Cup and it is Leg 2 or final
 * @function
 * @param {number} orderNumber - orderNumber of round what need to check
 * @param {number} gameType - gameType of game
 * @returns {boolean} - result of checking
 */
export const isLeg2OrFinal = (orderNumber, gameType) => isChampionsCupGame(gameType) && (PREDICATE_MULTIPLIER_OF_SEASON_GAMES[gameType] === orderNumber || isLeg2(orderNumber));

/** Get Leg Number of round
 * @function
 * @param {number} orderNumber - orderNumber of round what need to check
 * @returns {number} - leg number of round
 */
export const getLegNo = (orderNumber) => (isLeg1(orderNumber) ? 1 : isLeg2(orderNumber) ? 2 : null);

/** Get Round translated name of champions Cup Game
 * @function
 * @param {number} orderNumber - functions array what will be executed
 * @param {boolean} isShort - is need short name translation
 * @returns {string} - empty string or translated value
 */
export const getRoundNameOfChampionsCupGame = (orderNumber, isShort = false) => {
	switch (orderNumber) {
		case 1:
		case 2:
			const r16No = getLegNo(orderNumber);
			const r16AdditionalText = isShort ? `(${r16No})` : `${i18n.t("common.leg")} ${r16No}`;
			return `${i18n.t(`common.${SEASONAL_PERIODS.ROUND_16}${isShort ? "Short" : ""}`)} 16 ${r16AdditionalText}`;
		case 3:
		case 4:
			const qfNo = getLegNo(orderNumber);
			const qfAdditionalText = isShort ? `(${qfNo})` : `${i18n.t("common.leg")} ${qfNo}`;
			return `${i18n.t(`common.${SEASONAL_PERIODS.QUARTER_FINALS}${isShort ? "Short" : ""}`)} ${qfAdditionalText}`;
		case 5:
		case 6:
			const sfNo = getLegNo(orderNumber);
			const sfAdditionalText = isShort ? `(${sfNo})` : `${i18n.t("common.leg")} ${sfNo}`;
			return `${i18n.t(`common.${SEASONAL_PERIODS.SEMI_FINALS}${isShort ? "Short" : ""}`)} ${sfAdditionalText}`;
		case 7:
			return `${i18n.t(`common.${SEASONAL_PERIODS.FINAL}${isShort ? "Short" : ""}`)}`;
		default:
			break;
	}
	return "";
};

/** Get Round translated name of Cup Game
 * @function
 * @param {number} orderNumber - functions array what will be executed
 * @param {number} type - game type
 * @param {boolean} isShort - is need short name translation
 * @returns {string} - empty string or translated value
 */
export const getRoundNameOfCupGame = (orderNumber, type, isShort = false) => {
	switch (true) {
		case isSimpleCupGame(type):
			return getRoundNameOfSimpleCupGame(orderNumber, isShort);
		case isChampionsCupGame(type):
			return getRoundNameOfChampionsCupGame(orderNumber, isShort);
		default:
			break;
	}
	return "";
};

/** Get Round translated name of League Game
 * @function
 * @param {number} orderNumber - functions array what will be executed
 * @param {boolean} isShort - is need short name translation
 * @returns {string} - empty string or translated value
 */
export const getRoundNameOfLeagueGame = (orderNumber, isShort = false) => {
	let traslation = i18n.t("common.week");
	if (isShort) {
		traslation = traslation.charAt(0);
	}
	return `${traslation} ${orderNumber}`;
};

/** Get Round translated name
 * @function
 * @param {number} orderNumber - functions array what will be executed
 * @param {number} type - game type
 * @param {boolean} isShort - is need short name translation
 * @returns {string} - empty string or translated value
 */
export const getRoundName = (orderNumber, type, isShort = false) => {
	if (isLeagueGame(type)) {
		return getRoundNameOfLeagueGame(orderNumber, isShort);
	}

	return getRoundNameOfCupGame(orderNumber, type, isShort);
};

export const textForSimpleCup = (roundOrderNumber, eventOrderNumber) => {
	switch (roundOrderNumber) {
		case 1:
		case 2:
			return i18n.t("common.roundStartsSoon");
		case 3:
			return `${i18n.t("common.winnerOf")} ${i18n.t(`common.${SEASONAL_PERIODS.ROUND_16}`)} 16 ${i18n.t("common.day")} ${Math.ceil(eventOrderNumber / 2)}`;
		case 4:
			return `${i18n.t("common.winnerOf")} ${i18n.t(`common.${SEASONAL_PERIODS.QUARTER_FINALS}`)}`;
		case 5:
			return `${i18n.t("common.winnerOf")} ${i18n.t(`common.${SEASONAL_PERIODS.SEMI_FINALS}`)}`;
		default:
			return "";
	}
};

export const textForChampionsCup = (roundOrderNumber) => {
	switch (roundOrderNumber) {
		case 1:
		case 2:
			return i18n.t("common.roundStartsSoon");
		case 3:
		case 4:
			return `${i18n.t("common.winnerOf")} ${i18n.t(`common.${SEASONAL_PERIODS.ROUND_16}`)} 16`;
		case 5:
		case 6:
			return `${i18n.t("common.winnerOf")} ${i18n.t(`common.${SEASONAL_PERIODS.QUARTER_FINALS}`)}`;
		case 7:
			return `${i18n.t("common.winnerOf")} ${i18n.t(`common.${SEASONAL_PERIODS.SEMI_FINALS}`)}`;
		default:
			return "";
	}
};

export const getSimpleCupEventsQty = (orderNumber) => {
	switch (orderNumber) {
		case 1:
		case 2:
			return 4;
		case 3:
			return 4;
		case 4:
			return 2;
		case 5:
			return 1;
		default:
			break;
	}
	return 0;
};

export const getChampionsCupEventsQty = (orderNumber) => {
	switch (orderNumber) {
		case 1:
		case 2:
			return 8;
		case 3:
		case 4:
			return 4;
		case 5:
		case 6:
			return 2;
		case 7:
			return 1;
		default:
			break;
	}
	return 0;
};

export const getEventsQtyBySeasonalPeriods = (gameType, seasonalPeriod) => {
	switch (seasonalPeriod) {
		case SEASONAL_PERIODS.ROUND_16:
			return isSimpleCupGame(gameType) ? 4 : 8;
		case SEASONAL_PERIODS.QUARTER_FINALS:
			return 4;
		case SEASONAL_PERIODS.SEMI_FINALS:
			return 2;
		case SEASONAL_PERIODS.FINAL:
			return 1;
		default:
			break;
	}
};

export const getEventsQty = (orderNumber, gameType) => {
	switch (true) {
		case isSimpleCupGame(gameType):
			return getSimpleCupEventsQty(orderNumber);
		case isChampionsCupGame(gameType):
			return getChampionsCupEventsQty(orderNumber);
		default:
			break;
	}
	return 0;
};

export const getSimpleCupSeasonNameByOrderNumber = (orderNumber) => {
	switch (orderNumber) {
		case 1:
		case 2:
			return SEASONAL_PERIODS.ROUND_16;
		case 3:
			return SEASONAL_PERIODS.QUARTER_FINALS;
		case 4:
			return SEASONAL_PERIODS.SEMI_FINALS;
		case 5:
			return SEASONAL_PERIODS.FINAL;
		default:
			break;
	}
	return "";
};

export const getChampionsCupSeasonNameByOrderNumber = (orderNumber) => {
	switch (orderNumber) {
		case 1:
		case 2:
			return SEASONAL_PERIODS.ROUND_16;
		case 3:
		case 4:
			return SEASONAL_PERIODS.QUARTER_FINALS;
		case 5:
		case 6:
			return SEASONAL_PERIODS.SEMI_FINALS;
		case 7:
			return SEASONAL_PERIODS.FINAL;
		default:
			break;
	}
	return "";
};

export const getSeasonNameByOrderNumber = (orderNumber, gameType) => {
	switch (true) {
		case isSimpleCupGame(gameType):
			return getSimpleCupSeasonNameByOrderNumber(orderNumber);
		case isChampionsCupGame(gameType):
			return getChampionsCupSeasonNameByOrderNumber(orderNumber);
		default:
			break;
	}
	return "";
};

/** Get sliced part of live and pcomings events by group reason
 * @function
 * @param {array} liveAndUpcomings - array of events
 * @param {number} groupOfSeason - season group, one of SELECTED_SEASON_GAMES enum
 * @returns {array} - array of events
 */
export const getSlicedLiveAndUpcomings = (liveAndUpcomings = [], groupOfSeason = SELECTED_SEASON_GAMES.NONE) => {
	const gameType = (liveAndUpcomings.find((lau) => lau.gameType))?.gameType;

	if (!gameType || !isSeasonGame(gameType)) {
		return liveAndUpcomings;
	}

	const groupOfPredicateCoefficent = groupOfSeason ? SELECTED_SEASON_GAMES.NEXT : SELECTED_SEASON_GAMES.CURRENT;
	const currentPredicateCoefficent = groupOfPredicateCoefficent - 1;
	const predicate = [currentPredicateCoefficent * PREDICATE_MULTIPLIER_OF_SEASON_GAMES[gameType], (currentPredicateCoefficent + 1) * PREDICATE_MULTIPLIER_OF_SEASON_GAMES[gameType]];
	return liveAndUpcomings.slice(...predicate);
};

export const getSeasonIdOfSlicedLiveAndUpcomings = (liveAndUpcomings, groupOfSeason, showSpecialMarkets) => {
	if (showSpecialMarkets === SELECTED_SEASON_GAMES.NONE) {
		return null;
	}
	const slicedLiveAndUpcoming = getSlicedLiveAndUpcomings(liveAndUpcomings, groupOfSeason);
	if (!slicedLiveAndUpcoming || !slicedLiveAndUpcoming[0]) {
		return null;
	}
	return slicedLiveAndUpcoming[0].seasonId;
};

export const callbackForEventSortingbyOrderNumber = (next, prev) => {
	if (next.id < prev.id) {
		return -1;
	}
	if (next.id > prev.id) {
		return 1;
	}
	return 0;
};

export const isReactComponent = (any) => {
	return any && Object.prototype.hasOwnProperty.call(any, "$$typeof") && any["$$typeof"] === Symbol.for("react.element");
};

export const isReactFragment = (child) => {
	return Object.hasOwn(child, "type") && child["type"] === Symbol.for("react.fragment");
};

/** Function group array by chuncks
 * @function
 * @param {array} arr - input array for group by chunck
 * @param {number} chunkSize - max size of each chunck array
 * @returns {array[]}
 */
export const arrayToChunck = (arr = [], chunkSize = 0) => {
	if (!Array.isArray(arr)) {
		throw new TypeError('Invalid argument "arr". "arr" must be array');
	}
	if (typeof chunkSize !== "number") {
		throw new TypeError('Invalid argument "chunkSize". "chunkSize" must be number');
	}
	const retVal = [];
	for (let i = 0; i < arr.length; i += chunkSize) {
		retVal.push(arr.slice(i, i + chunkSize));
	}
	return retVal;
};

export const mapPenaltyLiveInfoScene = (scene) => {
	/*
		--- sceneInfo data structure ---
		[
			Team1Name,
			Team2Name,
			[one of: PENALTY_SCENE_INFO_NAMES],
			[one of: G (goal) / NG (no goal)],
			[one of: T1 / T2 (scene owner team)],
			R[1-12](roundNo)
		]
	*/
	const sceneInfo = scene.name.split("_");
	const [team1Name, team2Name, enumName, goalInfo, sceneOwnerTeamInfo, penaltyRound] = sceneInfo;
	const teamNo = Number(sceneOwnerTeamInfo.match(/\d+/)?.at(0));
	const roundNo = Number(penaltyRound.match(/\d+/)?.at(0));
	if (Number.isNaN(teamNo) || Number.isNaN(roundNo)) {
		return null;
	}
	const hasGoal = goalInfo === "G";
	const enumEntry = Object.entries(PENALTY_SCENE_INFO_NAMES).find(([, value]) => value.toUpperCase() === enumName.toUpperCase());
	const enumValue = enumEntry ? Number(enumEntry.at(0)) : null;
	const teamName = `team${teamNo}`;
	return {
		team1Name,
		team2Name,
		teamName,
		hasGoal,
		teamNo,
		roundNo,
		enumName,
		enumValue
	};
};

export const getDefaultLiveInfoPositions = () => {
	return {
		[GAME_TYPE.HORSE_STEEPLECHASING]: {
			actualGameDuration: 66,
			leadingOutOfRaceParticipants: {
				leadingParticipants: [],
				outOfRaceParticipantNumbers: []
			}
		}
	};
};

export const debounce = (func, timeout = 200) => {
	let timer;
	return (...args) => {
	  clearTimeout(timer);
	  timer = setTimeout(() => { func(...args); }, timeout);
	};
 }