import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import moment from "moment";

import store from "store/configureStore";

import { setTranslationsLoaded } from "store/actions/auth/auth.actions";

const buildTranslationUrl = (path) => {
	return `${import.meta.env.SYSTEM_CDN_URL}/${path}`;
};

const buildSystemTranslationUrl = (language) => {
	return `system/translations/${language.toUpperCase()}.json`;
};

export const initTranslations = (path, language, onSuccess) => {
	const english = "en";

	let BackEndAPI = (t) => t;

	const resources = {};

	const currentLang = language.toLowerCase() || english;

	const configuration = {
		fallbackLng: english,
		debug: false,
		lng: currentLang,
		interpolation: {
			escapeValue: false
		},
		ns: ["common", "rules"],
		defaultNS: "common"
	};

	if (!path) {
		path = buildSystemTranslationUrl(currentLang);
	}

	BackEndAPI = HttpApi;
	configuration.backend = {
		loadPath: (l, ns) => {
			if (ns[0] === "common") {
				return buildTranslationUrl(path.toLowerCase());
			}
		},
		crossDomain: true,
		parse: (data) => {
			try {
				const result = JSON.parse(data);
				return result;
			} catch (ex) {
				console.log(ex);
			}
		}
	};

	i18n
		.use(BackEndAPI)
		.use(initReactI18next)
		.init(configuration)
		.then((resp) => {
			store.dispatch(setTranslationsLoaded());
			onSuccess();
			initializeLocalsForMomentJS();
		});
};

const initializeLocalsForMomentJS = () => {
	moment.updateLocale("en", {
		weekdaysMin: [i18n.t("common.sunday"), i18n.t("common.monday"), i18n.t("common.tuesday"), i18n.t("common.wednesday"), i18n.t("common.thursday"), i18n.t("common.friday"), i18n.t("common.saturday")],
		monthsShort: [
			i18n.t("common.january"),
			i18n.t("common.february"),
			i18n.t("common.march"),
			i18n.t("common.april"),
			i18n.t("common.may"),
			i18n.t("common.june"),
			i18n.t("common.july"),
			i18n.t("common.august"),
			i18n.t("common.september"),
			i18n.t("common.october"),
			i18n.t("common.november"),
			i18n.t("common.december")
		]
	});
};

export default i18n;
