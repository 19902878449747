const PATHS = {
	CREATE_SESSION: "/Session/Create",
	GET_SESSION_DETAILS: "/Session/GetDetails",
	GET_STREAM_CONFIGURATION: "/Session/StreamConfiguration",
	GET_GAME_RULE: "/Session/GetRule",
	CREATE_STREAM_ACCESS: "/Session/CreateStreamAccess",
	REFRESH_STREAM_ACCESS: "/Session/RefreshStreamAccess",
	AUTHENTICATE: "/Player/Authenticate",
	REFRESH_TOKEN: "/Player/RefreshToken",
	REAL_MODE: "/Player/RealMode",
	SAVE_SETTINGS: "/Player/SaveScheduledSettings",
	LEAVE_FEEDBACK: "/Player/LeaveFeedback",
	GET_BONUS_HISTORY: "/Player/BonusHistory",
	GET_LIVE_AND_UPCOMING: "/ScheduledEvents/LiveAndUpcomings",
	GET_LAST_FINISHED_RESULTS: "/ScheduledEvents/LastFinished",
	GET_LAST_RESULTS: "/ScheduledEvents/Results",
	GET_LAST_SEASON_RESULTS: "/ScheduledEvents/SeasonResults",
	GET_FOOTBALL_EVENT: "/ScheduledEvents/Event",
	GET_SEASON: "/ScheduledEvents/Season",
	PLACE_BET: "/Bet/PlaceBet",
	CANCEL_BETSLIP: "/Bet/CancelBetSlip",
	CANCEL_BET: "/Bet/CancelBet",
	GET_HISTORY: "/Bet/History",
	GET_EVENT_HISTORY: "/Bet/EventHistory",
	FOOTBALL_TEAM_STATISTICS: "/ScheduledStatistics/FootballTeamStatistics",
	FOOTBALL_TEAM_TO_TEAM_STATISTICS: "/ScheduledStatistics/FootballTeamToTeamStatistics",
	FOOTBALL_HEAD_TO_HEAD_STATISTICS: "/ScheduledStatistics/FootballHeadToHeadStatistics",
	PENALTY_TEAM_STATISTICS: "/ScheduledStatistics/PenaltyTeamStatistics",
	PENALTY_TEAM_TO_TEAM_STATISTICS: "/ScheduledStatistics/PenaltyTeamToTeamStatistics",
	COUNTRY_LIST: "/ScheduledStatistics/CountryList",
	RACE_PARTICIPANTS: "/ScheduledStatistics/RaceParticipants",
	RACE_PARTICIPANT_STATISTICS: "/ScheduledStatistics/RaceParticipantStatistics",
	FOOTBALL_LEAGUE_STANDINGS: "/ScheduledStatistics/FootballLeagueStandings",
	FOOTBALL_LEAGUE_STATISTICS: "/ScheduledStatistics/FootballLeagueStatistics",
	FOOTBALL_LEAGUE_QUICK_STATISTICS: "/ScheduledStatistics/FootballTeamToTeamStatisticsPositions",
	KENO_STATISTICS: "/ScheduledStatistics/KenoStatistics",
	GET_PENDINGS: "/Bet/PendingHistory",
	GET_KENO_CURRENT_BETS: "/Bet/GetKenoCurrentBets",
	GET_KENO_LAST_BETS: "/Bet/GetKenoLastBets",
	GET_RULES: "/System/Rules"
};

export default PATHS;
