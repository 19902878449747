import { useState } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import historyType from "types/history.type";

import AreYouSure from "components/ui/areYouSure";
import Tooltip from "components/ui/tooltip";
import { BETSLIP_MODES } from "constants/betslip.constants";
import { GAME_TYPE } from "constants/game.constants";

import { array_unique, makeCurrencyText, toFixed } from "utils/common";
import { formatDateTime } from "utils/dateTime";
import { isBetslipCancelable } from "utils/bets";

import { cancelBetSlip } from "store/actions/betslip/betslip.actions";

/* Single Pending Bet Component */
const PendingBet = ({ pending, cancelBetSlip, handleClick }) => {
	const { t } = useTranslation();

	const [showDeleteModal, setShowDeleteModal] = useState(false);

	/** Function fires on "cancel bet" button clock
	 * @function
	 * @description open cancelation popup
	 * @memberOf PendingBet
	 */
	const cancel = () => {
		cancelBetSlip(pending.id);
		setShowDeleteModal(false);
	};

	/** Function fires on cancelation modal close
	 * @function
	 * @description close cancelation modal
	 * @memberOf PendingBet
	 */
	const onModalCancel = () => {
		setShowDeleteModal(false);
	};

	const firstBetGameType =
		pending.eventIds[0] && pending.bets && (pending.bets.find((bet) => bet.eventId === pending.eventIds[0]))?.gameType;

	return (
		<div className="vs--pending-item vs--mb-8">
			<div className="vs--pending-item-head vs--flex vs--justify-between vs--align-center vs--pl-8 vs--pr-8">
				<Tooltip placement="top" title={`${t("bet.betslipId")} ${pending.id}`}>
					<span className="vs--title-white vs--font-small vs--font-regular">{`${t("bet.betslipId")} ${pending.id}`}</span>
				</Tooltip>
				<Tooltip title={t("bet.goToDetails")}>
					<div className="vs--flex vs--justify-between vs--align-center vs--cursor-pointer" onClick={handleClick}>
						<span
							className="vs--title-white vs--font-small vs--font-regular vs--mr-4"
							style={{ textAlign: 'right', width: 'calc(100% - 18px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
						>
							{t('bet.goToDetails')}
						</span>
						<i className="ic_right vs--font-bigest"></i>
					</div>
				</Tooltip>
			</div>
			<div className="vs--pending-item-content vs--pl-8 vs--pr-8 vs--pb-16 vs--pt-8">
				<div className="vs--flex vs--justify-between vs--align-center vs--mb-8">
					<span className="vs--title-gray-80 vs--font-normal vs--font-regular">{t("common.date")}</span>
					<Tooltip placement="top-end" title={formatDateTime(pending.betTime)}>
						<b className="vs--title-white vs--font-normal vs--font-regular">{formatDateTime(pending.betTime)}</b>
					</Tooltip>
				</div>
				<div className="vs--flex vs--justify-between vs--align-center vs--mb-8">
					<span className="vs--title-gray-80 vs--font-normal vs--font-regular">{t("bet.betType")}</span>
					<Tooltip placement="top-end" title={`${t(`bet.${pending.type === BETSLIP_MODES.SINGLE ? "single" : "multi"}`)} ${pending.betCount > 1 ? "(" + pending.betCount + ")" : ""}`}>
						<b className="vs--title-white vs--font-normal vs--font-regular">
							{t(`bet.${pending.type === BETSLIP_MODES.SINGLE ? "single" : "multi"}`)} {pending.betCount > 1 ? "(" + pending.betCount + ")" : null}
						</b>
					</Tooltip>
				</div>
				<div className="vs--flex vs--justify-between vs--align-center vs--mb-8">
					<span className="vs--title-gray-80 vs--font-normal vs--font-regular">{firstBetGameType === GAME_TYPE.KENO ? t("common.roundId") : t("common.eventId")}</span>
					<Tooltip placement="top-end" title={pending.eventIds[0] ? "" + pending.eventIds[0] + (array_unique(pending.eventIds).length > 1 ? " (+" + (array_unique(pending.eventIds).length - 1) + ")" : "") : ""}>
						<b className="vs--title-white vs--font-normal vs--font-regular">{pending.eventIds[0] ? "" + pending.eventIds[0] + (array_unique(pending.eventIds).length > 1 ? " (+" + (array_unique(pending.eventIds).length - 1) + ")" : "") : null}</b>
					</Tooltip>
				</div>
				{pending.type === BETSLIP_MODES.MULTI ? (
					<div className="vs--flex vs--justify-between vs--align-center vs--mb-8">
						<span className="vs--title-gray-80 vs--font-normal vs--font-regular">{t("bet.odds")}</span>
						<Tooltip placement="top-end" title={toFixed(pending.totalFactor, 2)}>
							<b className="vs--title-white vs--font-normal vs--font-regular">{toFixed(pending.totalFactor, 2)}</b>
						</Tooltip>
					</div>
				) : null}

				<div className="vs--flex vs--justify-between vs--align-center vs--mb-8">
					<span className="vs--title-gray-80 vs--font-normal vs--font-regular">{t("bet.stake")}</span>
					<Tooltip placement="top-end" title={makeCurrencyText(pending.totalAmount, { decimalCount: pending.decimalCount, code: pending.currencyId })}>
						<b className="vs--title-white vs--font-normal vs--font-regular vs--text-ltr">{makeCurrencyText(pending.totalAmount, { decimalCount: pending.decimalCount, code: pending.currencyId })}</b>
					</Tooltip>
				</div>
				<div className="vs--flex vs--justify-between vs--align-center vs--mb-8">
					<span className="vs--title-gray-80 vs--font-normal vs--font-regular">{t("bet.possibleWin")}</span>
					<Tooltip placement="top-end" title={makeCurrencyText(pending.possibleWin, { decimalCount: pending.decimalCount, code: pending.currencyId })}>
						<b className="vs--title-brand vs--font-normal vs--font-regular">{makeCurrencyText(pending.possibleWin, { decimalCount: pending.decimalCount, code: pending.currencyId })}</b>
					</Tooltip>
				</div>
			</div>
			{
				isBetslipCancelable(pending) && (
					<button
						type="button"
						className="vs--button vs--pending-item-cancel-bet-button vs--pt-10 vs--pb-10 vs--pl-16 vs--pr-16 vs--font-normal vs--font-medium"
						onClick={() => setShowDeleteModal(true)}
					>
						{t('bet.cancelBet')}
					</button>
				)
			}
			{
				(showDeleteModal && isBetslipCancelable(pending)) &&
				<AreYouSure
					onCancel={onModalCancel}
					onOk={cancel}
				/>
			}
		</div>
	);
};

/** PendingBet propTypes
 * PropTypes
 */
PendingBet.propTypes = {
	/** pending Bet object for component */
	pending: historyType,
	/** Redux action to cancel bet slip */
	cancelBetSlip: PropTypes.func,
	/** Function which fires on go to details click */
	handleClick: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	cancelBetSlip: (id) => {
		dispatch(cancelBetSlip(id));
	}
});

export default connect(null, mapDispatchToProps)(PendingBet);
