import axios from 'axios';
import Methods from 'constants/methods.constants';
import ApiUrls from 'constants/api.constants';
import { CLIENT_API } from "constants/integration.constants";
import store from 'store/configureStore';
import { setPlayer, switchToRealMode } from 'store/actions/auth/auth.actions';
import { sendPostMessageToParent, runLoginScript } from "utils/iframe";

export const exitFromSession = () => {
	const state = store.getState();
	const { exitUrl } = state.auth.session;

	if (exitUrl) {
		sendPostMessageToParent({ type: CLIENT_API.BACK_TO_HOME, value: exitUrl, mainDomain: exitUrl });
	}
	else {
		location.reload();
	}
}

export const logout = () => {
	exitFromSession();
}

let isRefreshing = false;

/** Sends request to server to refresh token
	* @function
	* @param {string} refresh_token - refresh token
	* @param {Object} requestConfig - the request configuration which will be sends, when new tokens got from server
	* @returns {Promise}
 */
export const refreshToken = (refresh_token, requestConfig) => {
	if (isRefreshing) return Promise.reject();
	isRefreshing = true;
	return axios({
		url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.REFRESH_TOKEN}`,
		method: Methods.POST,
		data: { refreshToken: refresh_token },
		headers: { grant_type: "refresh_token" }
	}).then(({ status, data: { value: authData } }) => {
		if (status === 200) {
			store.dispatch(setPlayer(authData));
			isRefreshing = false;
			return requestConfig && axios.request(requestConfig);
		} else {
			logout();
			isRefreshing = false;
		}
	}).catch(err => { isRefreshing = false; console.log(err) })
}

/** Sends request to server to refresh stream acesss token
	* @function
	* @param {string} refresh_token - refresh token
	* @param {Object} requestConfig - the request configuration which will be sends, when new tokens got from server
	* @returns {Promise}
 */
export const refreshStreamAccess = (refresh_token, requestConfig) => {
	if (isRefreshing) return Promise.reject();
	isRefreshing = true;
	return axios({
		url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.REFRESH_STREAM_ACCESS}`,
		method: Methods.POST,
		data: { refreshToken: refresh_token },
		headers: { grant_type: "refresh_token" }
	}).then(({ status, data: authData }) => {
		if (status === 200) {
			store.dispatch(setPlayer(authData));
			isRefreshing = false;
			return requestConfig && axios.request(requestConfig);
		} else {
			logout();
			isRefreshing = false;
		}
	}).catch(err => { isRefreshing = false; console.log(err) })
}

export const playForReal = ({ isPreview, isAuthorized, isAnonymous, params }) => {
	if (!isPreview) {
		if (isAuthorized && !isAnonymous) {
			store.dispatch(switchToRealMode(params));
		} else {
			runLoginScript(params);
		}
	}
};