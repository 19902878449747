import store from "store/configureStore";

import { LOGIN_CONFIGURATION_TYPE } from "constants/common.constants";
import { CLIENT_API } from "constants/integration.constants";

import { isMobile, getUrlVars } from "utils/common";

/* Send Post message to parent window if it exists (when site is integrated by some provider) */
export const sendPostMessageToParent = (message, targetOrigin = "*") => {
	if (!message || window.parent === window) {
		return;
	}
	window.parent.postMessage(message, targetOrigin);
};

/** Run login script in parent frame
 * @function
 */
export const runLoginScript = (options = { isMobile: false }) => {
	const loginConfiguration = store.getState()?.auth?.session?.loginConfiguration ?? {};

	if (!options.isMobile) {
		if (loginConfiguration.type === LOGIN_CONFIGURATION_TYPE.URL && loginConfiguration.data) {
			window.open(loginConfiguration.data, "_top");
		} else if (loginConfiguration.type === LOGIN_CONFIGURATION_TYPE.POPUP && loginConfiguration.data) {
			try {
				eval(loginConfiguration.data);
			} catch (ex) {
				console.log(ex);
			}
		}
	} else if (getUrlVars()["builder"]) {
		try {
			sendPostMessageToParent({ eventName: "Login", gameName: "football-single-match-virtualsport" });
		} catch (ex) {
			console.log(ex);
		}
	} else {
		if (loginConfiguration.mobileLoginURL) {
			try {
				sendPostMessageToParent({ type: CLIENT_API.LOGIN, value: loginConfiguration.mobileLoginURL, url: loginConfiguration.mobileLoginURL });
			} catch (ex) {
				console.log(ex);
			}
		}
	}
};
