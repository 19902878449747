import axios from "axios";

import { isLeagueGame } from "utils/common";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { GAME_TYPE } from "constants/game.constants";

import {
	SET_STATISTICS_BEFORE,
	SET_STATISTICS_AFTER,
	SET_STATISTICS_TEAM_1,
	SET_STATISTICS_TEAM_2,
	SET_COUNTRIES,
	SET_TEAM_STATISTICS,
	SET_TEAM_LAST_10_MATCHES,
	SET_HEAD_TO_HEAD_STATISTICS,
	ADD_TEAM_MATCHES,
	SET_TEAM_TO_TEAN_STATISTICS,
	SET_PENALTY_TEAM_TO_TEAM_STATISTICS,
	SET_PARTICIPANTS,
	SET_CURRENT_PARTICIPANT,
	SET_RACING_CURRENT_EVENT,
	SET_PARTICIPANT_STATISTICS,
	SET_QUICK_STATISTICS_BEFORE,
	SET_QUICK_STATISTICS_AFTER,
	SET_QUICK_STATISTICS,
	SET_QUICK_STATISTICS_TEAMS_DATA,
	SET_QUICK_STATISTICS_STATE,
	SET_SEASON_STATISTICS_BEFORE,
	SET_SEASON_STATISTICS_AFTER,
	SET_SEASON_STATISTICS_IS_UPDATE_REQUEST_BEFORE,
	SET_SEASON_STATISTICS_IS_UPDATE_REQUEST_AFTER,
	SET_SEASON_STATISTICS_ID,
	SET_SEASON_STATISTICS_STANDINGS,
	SET_SEASON_STATISTICS_STRUCTURE,
	UPDATE_SEASON_STATISTICS_STRUCTURE,
	SET_SEASON_LIVE_MODAL_MOBILE,
	SET_KENO_STATISTICS,
	RESET_STATISTICS_STATE
} from "../../actionTypes";

const setStatisticsBefore = () => ({
	type: SET_STATISTICS_BEFORE
});

const setStatisticsAfter = () => ({
	type: SET_STATISTICS_AFTER
});

export const setStatisticsTeam1 = (team, gameType) => ({
	type: SET_STATISTICS_TEAM_1,
	payload: { team, gameType }
});

export const setStatisticsTeam2 = (team, gameType) => ({
	type: SET_STATISTICS_TEAM_2,
	payload: { team, gameType }
});

const setCountries = (countries, gameType) => ({
	type: SET_COUNTRIES,
	payload: { countries, gameType }
});

const setTeamStatistics = ({ statistics, teamIndex, gameType }) => ({
	type: SET_TEAM_STATISTICS,
	payload: { statistics, teamIndex, gameType }
});

const setTeamLast10Matches = ({ lastMatches, teamIndex, gameType }) => ({
	type: SET_TEAM_LAST_10_MATCHES,
	payload: { lastMatches, teamIndex, gameType }
})

const setHeadToHeadStatistics = (statistics) => ({
	type: SET_HEAD_TO_HEAD_STATISTICS,
	payload: { statistics }
})

const addTeamMatches = ({ matches, teamIndex, loaded, gameType }) => ({
	type: ADD_TEAM_MATCHES,
	payload: { matches, teamIndex, loaded, gameType }
});

const setTeamToTeamStatistics = (data, gameType) => ({
	type: SET_TEAM_TO_TEAN_STATISTICS,
	payload: { data, gameType }
});

const setPenaltyTeamToTeamStatistics = (data, gameType) => ({
	type: SET_PENALTY_TEAM_TO_TEAM_STATISTICS,
	payload: { data, gameType }
});

const createStatisticsInitialState = () => {
	return {
		football: {
			team1: null,
			team2: null,
			countries: null,
			team1Statistics: null,
			team2Statistics: null,
			h2h: []
		},
		penalty: {
			team1: null,
			team2: null,
			countries: null,
			team1Statistics: null,
			team2Statistics: null,
			h2h: []
		},
		racing: {
			participants: [],
			currentParticipant: null,
			statistics: null,
			currentEvent: null
		},
		season: {
			id: null,
			standings: [],
			structure: {},
			loading: false,
			isUpdateRequestSended: false,
			showSeasonLiveModal: null
		},
		quickStatistics: {
			state: {
				visible: false
			},
			loading: false,
			statistics: []
		},
		kenoStatistics: []
	};
};

export const resetStatisticsState = () => {
	return {
		type: RESET_STATISTICS_STATE,
		payload: createStatisticsInitialState()
	};
};

export const getCountries = (gameType) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.COUNTRY_LIST}`,
			method: Methods.GET,
			params: { gameType }
		})
			.then(({ data: { value: countries } }) => {
				dispatch(setCountries(countries, gameType));
			})
			.catch(Function.prototype);
	};
};

export const getTeamStatistics = (countryCode, teamIndex, gameType, skip, take) => {
	return (dispatch) => {
		const getEndpoint = () => {
			switch (gameType) {
				case GAME_TYPE.PENALTY_SHOOTOUT:
					return ApiUrls.PENALTY_TEAM_STATISTICS;
				default:
					return ApiUrls.FOOTBALL_TEAM_STATISTICS;
			}
		};
		const params = { countryCode, gameType };
		if (skip) params.skip = skip;
		if (take) params.take = take;

		dispatch(setStatisticsBefore());

		const endPoint = getEndpoint();

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${endPoint}`,
			method: Methods.GET,
			params: params
		})
			.then(({ data: { value: data } }) => {
				if(endPoint === ApiUrls.FOOTBALL_TEAM_STATISTICS) {
					const { lastMatches } = data;

					dispatch(
						setTeamLast10Matches({
							lastMatches,
							teamIndex,
							gameType
						})
					);
				}
				else if (!skip || !take) {
					dispatch(
						setTeamStatistics({
							statistics: {
								...data,
								loaded: data.lastMatches.length < 10
							},
							teamIndex,
							gameType
						})
					);
				} else {
					const loaded = data.lastMatches.length < take;
					dispatch(addTeamMatches({ matches: data.lastMatches, teamIndex, loaded, gameType }));
				}
				dispatch(setStatisticsAfter());
			})
			.catch(() => {
				dispatch(setStatisticsAfter());
			});
	};
};

export const getTeamToTeamStatistics = (countryCode1, countryCode2, gameType) => {
	return (dispatch) => {
		dispatch(setStatisticsBefore());

		const getEndpoint = () => {
			switch (gameType) {
				case GAME_TYPE.PENALTY_SHOOTOUT:
					return ApiUrls.PENALTY_TEAM_TO_TEAM_STATISTICS;
				default:
					return ApiUrls.FOOTBALL_TEAM_TO_TEAM_STATISTICS;
			}
		};
		const getAction = (...args) => {
			switch (gameType) {
				case GAME_TYPE.PENALTY_SHOOTOUT:
					return setPenaltyTeamToTeamStatistics(...args);
				default:
					return setTeamToTeamStatistics(...args);
			}
		};

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${getEndpoint()}`,
			method: Methods.GET,
			params: { countryCode1, countryCode2, gameType }
		})
			.then(({ data: { value: data } }) => {
				dispatch(getAction(data, gameType));
				dispatch(setStatisticsAfter());
			})
			.catch(() => {
				dispatch(setStatisticsAfter());
			});
	};
};

export const getHeadToHeadAllTimeStatistics = ({ countryCode1, countryCode2, gameType, leagueId }) => {
	return (dispatch) => {
		dispatch(setStatisticsBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.FOOTBALL_HEAD_TO_HEAD_STATISTICS}`,
			method: Methods.GET,
			params: { countryCode1, countryCode2, gameType, leagueId }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setHeadToHeadStatistics(data));
				dispatch(setStatisticsAfter());
			})
			.catch(() => {
				dispatch(setStatisticsAfter());
			});
	};
};

//#region Racing

const setParticipants = (participants) => ({
	type: SET_PARTICIPANTS,
	payload: { participants }
});

export const getParticipants = (gameType) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.RACE_PARTICIPANTS}`,
			method: Methods.GET,
			params: { gameType }
		})
			.then(({ data: { value: participants } }) => {
				dispatch(setParticipants(participants));
			})
			.catch(Function.prototype);
	};
};

export const setCurrentParticipant = (participant) => ({
	type: SET_CURRENT_PARTICIPANT,
	payload: { participant }
});

export const setRacingCurrentEvent = (eventId) => ({
	type: SET_RACING_CURRENT_EVENT,
	payload: { eventId }
});

const setParticipantStatistics = (data) => ({
	type: SET_PARTICIPANT_STATISTICS,
	payload: { data }
});

export const getParticipantStatistics = (id, gameType) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.RACE_PARTICIPANT_STATISTICS}`,
			method: Methods.GET,
			params: { id, gameType }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setParticipantStatistics(data));
			})
			.catch(Function.prototype);
	};
};

//#endregion

//#region Season

const setSeasonStatisticsBefore = () => ({
	type: SET_SEASON_STATISTICS_BEFORE
});

const setSeasonStatisticsAfter = () => ({
	type: SET_SEASON_STATISTICS_AFTER
});

const setSeasonStatisticsisUpdateRequestBefore = () => ({
	type: SET_SEASON_STATISTICS_IS_UPDATE_REQUEST_BEFORE
});

const setSeasonStatisticsisUpdateRequestAfter = () => ({
	type: SET_SEASON_STATISTICS_IS_UPDATE_REQUEST_AFTER
});

export const setSeasonStatisticsId = (id) => ({
	type: SET_SEASON_STATISTICS_ID,
	payload: { id }
});

export const setSeasonStatistics = (data) => ({
	type: SET_SEASON_STATISTICS_STANDINGS,
	payload: { data }
});

export const setSeasonStructure = (data) => ({
	type: SET_SEASON_STATISTICS_STRUCTURE,
	payload: { data }
});

export const updateSeasonStatistics = (data) => {
	return (dispatch, getState) => {
		if (data.gameType !== getState()?.game?.currentGameType) {
			return;
		}
		dispatch(setSeasonStatistics(data.statistics));
	};
};

export const updateStructure = (data) => ({
	type: UPDATE_SEASON_STATISTICS_STRUCTURE,
	payload: { data }
});

export const updateSeasonStructure = (data) => {
	return (dispatch, getState) => {
		if (data.gameType !== getState()?.game?.currentGameType) {
			return;
		}
		dispatch(updateStructure(data));
	};
};

export const getSeasonStandings = (id) => {
	return (dispatch, getState) => {
		dispatch(setSeasonStatisticsBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.FOOTBALL_LEAGUE_STANDINGS}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setSeasonStatisticsId(id));
				dispatch(setSeasonStatistics(data));
			})
			.finally(() => dispatch(setSeasonStatisticsAfter()));
	};
};

export const getSeasonStatistics = (gameType) => {
	return (dispatch, getState) => {
		dispatch(setSeasonStatisticsBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.FOOTBALL_LEAGUE_STATISTICS}`,
			method: Methods.GET,
			params: { gameType }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setSeasonStatistics(data));
			})
			.finally(() => dispatch(setSeasonStatisticsAfter()));
	};
};

export const getSeasonStructure = (gameId) => {
	return (dispatch, getState) => {
		dispatch(setSeasonStatisticsBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_SEASON}`,
			method: Methods.GET,
			params: { gameId }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setSeasonStructure(data));
			})
			.finally(() => dispatch(setSeasonStatisticsAfter()));
	};
};

export const sendUpdateRequestForSeasonStructure = () => {
	return (dispatch, getState) => {
		const state = getState();
		const loading = state?.statistics?.season?.isUpdateRequestSended;
		if (loading) {
			return;
		}
		const games = state?.auth?.session?.games ?? [];
		const gameType = state?.game?.currentGameType;
		const gameId = (games.find((g) => g.type === gameType))?.id;
		if (!gameId) {
			return;
		}
		dispatch(setSeasonStatisticsBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_SEASON}`,
			method: Methods.GET,
			params: { gameId }
		})
			.then(({ data: { value: data } }) => {
				const currentState = getState();
				const currentGameType = currentState?.game?.currentGameType;
				if (gameType === currentGameType) {
					dispatch(setSeasonStructure(data));
				}
			})
			.finally(() => dispatch(setSeasonStatisticsAfter()));
	};
};

export const setSeasonLiveModalMobile = (show) => ({
	type: SET_SEASON_LIVE_MODAL_MOBILE,
	payload: { show }
});

//#endregion

//#region Quick Statistics

const setQuickStatisticsBefore = () => ({
	type: SET_QUICK_STATISTICS_BEFORE
});

const setQuickStatisticsAfter = () => ({
	type: SET_QUICK_STATISTICS_AFTER
});

const setQuickStatistics = (data) => ({
	type: SET_QUICK_STATISTICS,
	payload: { data }
});

const setQuickStatisticsTeamsData = (data) => ({
	type: SET_QUICK_STATISTICS_TEAMS_DATA,
	payload: { data }
})

export const setQuickStatisticsState = (state) => ({
	type: SET_QUICK_STATISTICS_STATE,
	payload: { state }
});

export const getQuickStatistics = ({ id, countryCode1, countryCode2, gameType }) => {
	return (dispatch) => {
		dispatch(setQuickStatisticsBefore());

		const getEndpoint = () => {
			switch (true) {
				case gameType === GAME_TYPE.PENALTY_SHOOTOUT:
					return ApiUrls.PENALTY_TEAM_TO_TEAM_STATISTICS;
				case isLeagueGame(gameType):
					return ApiUrls.FOOTBALL_LEAGUE_QUICK_STATISTICS;
				default:
					return ApiUrls.FOOTBALL_TEAM_TO_TEAM_STATISTICS;
			}
		};

		const getQueryParams = () => {
			const defaultQueries = { countryCode1, countryCode2, gameType };

			switch (true) {
				case isLeagueGame(gameType):
					return { ...defaultQueries, id };
				default:
					return defaultQueries;
			}
		}

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${getEndpoint()}`,
			method: Methods.GET,
			params: getQueryParams()
		})
			.then(({ data: { value: data } }) => {
				dispatch(setQuickStatistics(
					gameType === GAME_TYPE.PENALTY_SHOOTOUT || isLeagueGame(gameType) ? data.lastMatches.slice(0, 3) : data.slice(0, 3)
				));
				if (isLeagueGame(gameType)) {
					const { lastMatches, ...teamsData } = data;
					dispatch(setQuickStatisticsTeamsData(teamsData));
				}
				dispatch(setQuickStatisticsAfter());
			})
			.catch(() => {
				dispatch(setQuickStatisticsAfter());
			});
	};
};

//#endregion

//#region Keno

export const setKenoStatistics = (data) => ({
	type: SET_KENO_STATISTICS,
	payload: { data }
});

export const getKenoStatistics = () => {
	return (dispatch) => {
		dispatch(setStatisticsBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.KENO_STATISTICS}`,
			method: Methods.GET,
			params: {}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setKenoStatistics(data));
				dispatch(setStatisticsAfter());
			})
			.catch(() => {
				dispatch(setStatisticsAfter());
			});
	};
};

//#endregion
