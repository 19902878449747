import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { LEAVE_FEEDBACK_BEFORE, LEAVE_FEEDBACK_AFTER, SHOW_FEEDBACK } from "../../actionTypes";

const leaveFeedbackBefore = () => ({
	type: LEAVE_FEEDBACK_BEFORE
});

const leaveFeedbackAfter = () => ({
	type: LEAVE_FEEDBACK_AFTER
});

export const toggleFeedbackVisibility = (storageKey) => ({
	type: SHOW_FEEDBACK,
	payload: { storageKey }
});

export const leaveFeedback = ({ surveyId, grade, message = null }) => {
	return (dispatch) => {
		dispatch(leaveFeedbackBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.LEAVE_FEEDBACK}`,
			method: Methods.POST,
			data: { surveyId, grade, message }
		})
			.then(({ data }) => {
				dispatch(leaveFeedbackAfter());
			})
			.catch((ex) => {
				dispatch(leaveFeedbackAfter());
			});
	};
};
