import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { setErrorMessage } from "store/actions/common/common.actions";

/** Error Message Dialog Component */
const ErrorMessage = ({ message, closeMessage }) => {
	const { t } = useTranslation();

	return (
		<div className="vs--error">
			<div className="vs--error-inner vs--pl-30 vs--pr-30 vs--pt-30 vs--pb-30">
				<i className="ic_info vs--mb-12" />
				<span className="vs--title vs--font-normal vs--font-regular">{message}</span>
				<button type="button" className="vs--button vs--mt-16 vs--pt-6 vs--pb-6 vs--pl-16 vs--pr-16 vs--font-normal vs--font-condensed vs--font-bold" onClick={closeMessage}>
					{t("common.ok")}
				</button>
			</div>
		</div>
	);
};

/** ErrorMessage propTypes
 * PropTypes
 */
ErrorMessage.propTypes = {
	/** Redux state property, current error message to show */
	message: PropTypes.string,
	/** Redux action to close dialog */
	closeMessage: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		message: state.common.errorMessage
	};
};

const mapDispatchToProps = (dispatch) => ({
	closeMessage: () => {
		dispatch(setErrorMessage(""));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessage);
