import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Tooltip from "components/ui/tooltip";
import { isRTL } from "utils/common";

const TabStructureOverTimeInfo = ({ isEventHasOTPS, teamInfo, opponentTeamInfo, showConditionalJsx, isChampion = false, isSimple = false, isLeg2 = false, isLeg2OrFinal = false, rowIndex = null, isRtlFlag = false }) => {
	const { t } = useTranslation();
	if (isSimple) {
		if (!isEventHasOTPS) {
			return null;
		}
		if (typeof teamInfo.penaltyShootoutGoalCount === "number") {
			return null;
		}
	}

	if (isChampion && !isLeg2OrFinal) {
		return null;
	}

	return (
		<div className="vs--flex vs--align-center vs--justify-center vs--season-structure-list-item-result">
			<span className={"vs--title-white vs--font-medium vs--font-big" + (!isLeg2 && teamInfo.overTimeGoalCount > opponentTeamInfo.overTimeGoalCount ? " vs--text-green" : "")}>{typeof teamInfo.overTimeGoalCount === "number" ? teamInfo.overTimeGoalCount : " - "}</span>
			{showConditionalJsx ? (
				<Tooltip placement={isRtlFlag ? "right" : "left"} title={<span className="vs--title vs--font-normal vs--font-regular">{t("common.overtime")}</span>} overlayClassName="vs--season-structure-list-item-result-tooltip">
					<div className="vs--season-structure-list-item-result-ot">
						<span className="vs--title vs--font-medium vs--font-small">{t("common.OvertimeShort")}</span>
					</div>
				</Tooltip>
			) : null}
		</div>
	);
};

TabStructureOverTimeInfo.propTypes = {
	teamInfo: PropTypes.object,
	opponentTeamInfo: PropTypes.object,
	showConditionalJsx: PropTypes.bool,
	isEventHasOTPS: PropTypes.bool,
	isChampion: PropTypes.bool,
	isSimple: PropTypes.bool,
	isLeg2: PropTypes.bool,
	isLeg2OrFinal: PropTypes.bool,
	rowIndex: PropTypes.number,
	isRtlFlag: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		isRtlFlag: isRTL(state.auth.session.languageId)
	};
};

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(TabStructureOverTimeInfo);
