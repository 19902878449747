import i18n from "translations/config";

import BaseMarkets from "./baseMarkets";

import { PERIOD } from "constants/market.constants";
import { GAME_TYPE } from "constants/game.constants";

import { isLeagueGame, isCupGame, isSimpleCupGame, groupBy } from "utils/common";

class FootballMarkets extends BaseMarkets {
	/** Get All possible tabs
	 * @function
	 * @returns {object}
	 * @memberof FootballMarkets
	 */
	getGroups() {
		return Object.keys(this.getGroupsStructure());
	}

	/** Get All possible market tabs with their markets
	 * @function
	 * @returns {object}
	 * @memberof FootballMarkets
	 */
	getGroupsStructure() {
		return {
			fulltime: {
				groups: [
					{ group: "Winner3Way", period: PERIOD.MATCH, title: "Winner3Way", statistics: 1 },
					{ group: "Total", period: PERIOD.MATCH, argument: (arg) => Number(arg) % 0.5 === 0, title: "Total", statistics: 2 },
					{ group: "Handicap", period: PERIOD.MATCH, argument: (arg) => Number(arg) % 0.5 === 0, title: "Handicap" },
					{ group: "HomeTotal", period: PERIOD.MATCH, argument: (arg) => Number(arg) % 0.5 === 0, title: "HomeTotal" },
					{ group: "AwayTotal", period: PERIOD.MATCH, argument: (arg) => Number(arg) % 0.5 === 0, title: "AwayTotal" },
					{ group: "DoubleChance", period: PERIOD.MATCH, title: "DoubleChance" },
					{ group: "WillBothTeamsScore", period: PERIOD.MATCH, title: "WillBothTeamsScore" },
					{ group: "ResultWithoutDraw", period: PERIOD.MATCH, title: "ResultWithoutDraw" },
					{ group: "Total", period: PERIOD.MATCH, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianTotal" },
					{ group: "HomeTotal", period: PERIOD.MATCH, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianHomeTotal" },
					{ group: "AwayTotal", period: PERIOD.MATCH, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianAwayTotal" },
					{ group: "Total3Way", period: PERIOD.MATCH, title: "Total3Way" },
					{ group: "CorrectScore6Goals", period: PERIOD.MATCH, title: "CorrectScore6Goals", statistics: 1 },
					{ group: "Points", period: PERIOD.MATCH, title: "Points" },
					{ group: "HalfMatch", period: PERIOD.MATCH, title: "HalfMatch" },
					{ group: "Handicap", period: PERIOD.MATCH, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianHandicap" },
					{ group: "WillHomeTeamScore", period: PERIOD.MATCH, title: "WillHomeTeamScore" },
					{ group: "WillAwayTeamScore", period: PERIOD.MATCH, title: "WillAwayTeamScore" },
					{ group: "WinnerAndTotal", period: PERIOD.MATCH, argument: (arg) => [1.5, 2.5, 3.5].includes(Number(arg)), title: "WinnerAndTotal", statistics: 1 },
					{ group: "NextPoint", period: PERIOD.MATCH, argument: 1, title: "NextPoint" },
					{ group: "Correct6HomeGoalsCount", period: PERIOD.MATCH, title: "Correct6HomeGoalsCount" },
					{ group: "Correct6AwayGoalsCount", period: PERIOD.MATCH, title: "Correct6AwayGoalsCount" },
					{ group: "Correct6GoalsCount", period: PERIOD.MATCH, title: "Correct6GoalsCount" },
					{ group: "DoubleChanceAndTotal", period: PERIOD.MATCH, argument: (arg) => [1.5, 2.5, 3.5].includes(Number(arg)), title: "DoubleChanceAndTotal" },
					{ group: "Penalty", period: PERIOD.MATCH, title: "Penalty" },
					{ group: "YellowCard", period: PERIOD.MATCH, title: "YellowCard" },
					{ group: "RedCard", period: PERIOD.MATCH, title: "RedCard" },
					{ group: "Winner", period: PERIOD.NONE, title: "Winner" },
					{ group: "Loser", period: PERIOD.NONE, title: "Loser" },
					{ group: "Finalist", period: PERIOD.NONE, title: "Finalist" },
					{ group: "Champion", period: PERIOD.NONE, title: "Champion" }
				]
			},
			extra: {
				groups: [
					{ group: "Penalty", period: PERIOD.MATCH, title: "Penalty" },
					{ group: "Penalty", period: PERIOD.FIRSTHALF, title: "Penalty" },
					{ group: "Penalty", period: PERIOD.SECONDHALF, title: "Penalty" },
					{ group: "YellowCard", period: PERIOD.MATCH, title: "YellowCard" },
					{ group: "YellowCard", period: PERIOD.FIRSTHALF, title: "YellowCard" },
					{ group: "YellowCard", period: PERIOD.SECONDHALF, title: "YellowCard" },
					{ group: "RedCard", period: PERIOD.MATCH, title: "RedCard" },
					{ group: "RedCard", period: PERIOD.FIRSTHALF, title: "RedCard" },
					{ group: "RedCard", period: PERIOD.SECONDHALF, title: "RedCard" },
					{ group: "MethodOfVictoryOvertime", period: PERIOD.MATCH, title: "MethodOfVictoryOvertime", isNew: true },
					{ group: "MatchEndsInOvertime", period: PERIOD.MATCH, title: "MatchEndsInOvertime", isNew: true },
					{ group: "MatchEndsInPenaltyShootouts", period: PERIOD.MATCH, title: "MatchEndsInPenaltyShootouts", isNew: true },
					{ group: "Winner2Way", period: PERIOD.MATCH, title: "Winner2Way", isNew: true }
				],
				isNew: true
			},
			"1Half": {
				groups: [
					{ group: "Winner3Way", period: PERIOD.FIRSTHALF, title: "Winner3Way" },
					{ group: "Total", period: PERIOD.FIRSTHALF, argument: (arg) => Number(arg) % 0.5 === 0, title: "Total" },
					{ group: "Handicap", period: PERIOD.FIRSTHALF, argument: (arg) => Number(arg) % 0.5 === 0, title: "Handicap" },
					{ group: "HomeTotal", period: PERIOD.FIRSTHALF, argument: (arg) => Number(arg) % 0.5 === 0, title: "HomeTotal" },
					{ group: "AwayTotal", period: PERIOD.FIRSTHALF, argument: (arg) => Number(arg) % 0.5 === 0, title: "AwayTotal" },
					{ group: "DoubleChance", period: PERIOD.FIRSTHALF, title: "DoubleChance" },
					{ group: "WillBothTeamsScore", period: PERIOD.FIRSTHALF, title: "WillBothTeamsScore" },
					{ group: "ResultWithoutDraw", period: PERIOD.FIRSTHALF, title: "ResultWithoutDraw" },
					{ group: "CorrectScore6Goals", period: PERIOD.FIRSTHALF, title: "CorrectScore6Goals" },
					{ group: "Total", period: PERIOD.FIRSTHALF, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianTotal" },
					{ group: "HomeTotal", period: PERIOD.FIRSTHALF, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianHomeTotal" },
					{ group: "AwayTotal", period: PERIOD.FIRSTHALF, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianAwayTotal" },
					{ group: "Total3Way", period: PERIOD.FIRSTHALF, title: "Total3Way" },
					{ group: "Points", period: PERIOD.FIRSTHALF, title: "Points" },
					{ group: "HalfMatch", period: PERIOD.FIRSTHALF, title: "HalfMatch" },
					{ group: "Handicap", period: PERIOD.FIRSTHALF, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianHandicap" },
					{ group: "WillHomeTeamScore", period: PERIOD.FIRSTHALF, title: "WillHomeTeamScore" },
					{ group: "WillAwayTeamScore", period: PERIOD.FIRSTHALF, title: "WillAwayTeamScore" },
					{ group: "WinnerAndTotal", period: PERIOD.FIRSTHALF, argument: (arg) => [1.5, 2.5, 3.5].includes(Number(arg)), title: "WinnerAndTotal" },
					{ group: "NextPoint", period: PERIOD.FIRSTHALF, argument: 1, title: "NextPoint" },
					{ group: "Correct6HomeGoalsCount", period: PERIOD.FIRSTHALF, title: "Correct6HomeGoalsCount" },
					{ group: "Correct6AwayGoalsCount", period: PERIOD.FIRSTHALF, title: "Correct6AwayGoalsCount" },
					{ group: "Correct6GoalsCount", period: PERIOD.FIRSTHALF, title: "Correct6GoalsCount" },
					{ group: "DoubleChanceAndTotal", period: PERIOD.FIRSTHALF, argument: (arg) => [1.5, 2.5, 3.5].includes(Number(arg)), title: "DoubleChanceAndTotal" },
					{ group: "Penalty", period: PERIOD.FIRSTHALF, title: "Penalty" },
					{ group: "YellowCard", period: PERIOD.FIRSTHALF, title: "YellowCard" },
					{ group: "RedCard", period: PERIOD.FIRSTHALF, title: "RedCard" }
				]
			},
			"2Half": {
				groups: [
					{ group: "Winner3Way", period: PERIOD.SECONDHALF, title: "Winner3Way" },
					{ group: "Total", period: PERIOD.SECONDHALF, argument: (arg) => Number(arg) % 0.5 === 0, title: "Total" },
					{ group: "Handicap", period: PERIOD.SECONDHALF, argument: (arg) => Number(arg) % 0.5 === 0, title: "Handicap" },
					{ group: "HomeTotal", period: PERIOD.SECONDHALF, argument: (arg) => Number(arg) % 0.5 === 0, title: "HomeTotal" },
					{ group: "AwayTotal", period: PERIOD.SECONDHALF, argument: (arg) => Number(arg) % 0.5 === 0, title: "AwayTotal" },
					{ group: "DoubleChance", period: PERIOD.SECONDHALF, title: "DoubleChance" },
					{ group: "WillBothTeamsScore", period: PERIOD.SECONDHALF, title: "WillBothTeamsScore" },
					{ group: "ResultWithoutDraw", period: PERIOD.SECONDHALF, title: "ResultWithoutDraw" },
					{ group: "CorrectScore6Goals", period: PERIOD.SECONDHALF, title: "CorrectScore6Goals" },
					{ group: "Total", period: PERIOD.SECONDHALF, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianTotal" },
					{ group: "HomeTotal", period: PERIOD.SECONDHALF, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianHomeTotal" },
					{ group: "AwayTotal", period: PERIOD.SECONDHALF, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianAwayTotal" },
					{ group: "Total3Way", period: PERIOD.SECONDHALF, title: "Total3Way" },
					{ group: "Points", period: PERIOD.SECONDHALF, title: "Points" },
					{ group: "HalfMatch", period: PERIOD.SECONDHALF, title: "HalfMatch" },
					{ group: "Handicap", period: PERIOD.SECONDHALF, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianHandicap" },
					{ group: "WillHomeTeamScore", period: PERIOD.SECONDHALF, title: "WillHomeTeamScore" },
					{ group: "WillAwayTeamScore", period: PERIOD.SECONDHALF, title: "WillAwayTeamScore" },
					{ group: "WinnerAndTotal", period: PERIOD.SECONDHALF, argument: (arg) => [1.5, 2.5, 3.5].includes(Number(arg)), title: "WinnerAndTotal" },
					{ group: "NextPoint", period: PERIOD.SECONDHALF, argument: 1, title: "NextPoint" },
					{ group: "Correct6HomeGoalsCount", period: PERIOD.SECONDHALF, title: "Correct6HomeGoalsCount" },
					{ group: "Correct6AwayGoalsCount", period: PERIOD.SECONDHALF, title: "Correct6AwayGoalsCount" },
					{ group: "Correct6GoalsCount", period: PERIOD.SECONDHALF, title: "Correct6GoalsCount" },
					{ group: "DoubleChanceAndTotal", period: PERIOD.SECONDHALF, argument: (arg) => [1.5, 2.5, 3.5].includes(Number(arg)), title: "DoubleChanceAndTotal" },
					{ group: "Penalty", period: PERIOD.SECONDHALF, title: "Penalty" },
					{ group: "YellowCard", period: PERIOD.SECONDHALF, title: "YellowCard" },
					{ group: "RedCard", period: PERIOD.SECONDHALF, title: "RedCard" }
				]
			},
			total: {
				groups: [
					{ group: "Total", period: PERIOD.MATCH, argument: (arg) => Number(arg) % 0.5 === 0, title: "Total" },
					{ group: "HomeTotal", period: PERIOD.MATCH, argument: (arg) => Number(arg) % 0.5 === 0, title: "HomeTotal" },
					{ group: "AwayTotal", period: PERIOD.MATCH, argument: (arg) => Number(arg) % 0.5 === 0, title: "AwayTotal" },
					{ group: "Total", period: PERIOD.MATCH, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianTotal" },
					{ group: "HomeTotal", period: PERIOD.MATCH, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianHomeTotal" },
					{ group: "AwayTotal", period: PERIOD.MATCH, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianAwayTotal" },
					{ group: "Total3Way", period: PERIOD.MATCH, title: "Total3Way" },
					{ group: "WinnerAndTotal", period: PERIOD.MATCH, argument: (arg) => [1.5, 2.5, 3.5].includes(Number(arg)), title: "WinnerAndTotal" },
					{ group: "DoubleChanceAndTotal", period: PERIOD.MATCH, argument: (arg) => [1.5, 2.5, 3.5].includes(Number(arg)), title: "DoubleChanceAndTotal" },
					{ group: "Total", period: PERIOD.FIRSTHALF, argument: (arg) => Number(arg) % 0.5 === 0, title: "Total" },
					{ group: "HomeTotal", period: PERIOD.FIRSTHALF, argument: (arg) => Number(arg) % 0.5 === 0, title: "HomeTotal" },
					{ group: "AwayTotal", period: PERIOD.FIRSTHALF, argument: (arg) => Number(arg) % 0.5 === 0, title: "AwayTotal" },
					{ group: "Total", period: PERIOD.FIRSTHALF, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianTotal" },
					{ group: "HomeTotal", period: PERIOD.FIRSTHALF, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianHomeTotal" },
					{ group: "AwayTotal", period: PERIOD.FIRSTHALF, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianAwayTotal" },
					{ group: "Total3Way", period: PERIOD.FIRSTHALF, title: "Total3Way" },
					{ group: "WinnerAndTotal", period: PERIOD.FIRSTHALF, argument: (arg) => [1.5, 2.5, 3.5].includes(Number(arg)), title: "WinnerAndTotal" },
					{ group: "DoubleChanceAndTotal", period: PERIOD.FIRSTHALF, argument: (arg) => [1.5, 2.5, 3.5].includes(Number(arg)), title: "DoubleChanceAndTotal" },
					{ group: "Total", period: PERIOD.SECONDHALF, argument: (arg) => Number(arg) % 0.5 === 0, title: "Total" },
					{ group: "HomeTotal", period: PERIOD.SECONDHALF, argument: (arg) => Number(arg) % 0.5 === 0, title: "HomeTotal" },
					{ group: "AwayTotal", period: PERIOD.SECONDHALF, argument: (arg) => Number(arg) % 0.5 === 0, title: "AwayTotal" },
					{ group: "Total", period: PERIOD.SECONDHALF, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianTotal" },
					{ group: "HomeTotal", period: PERIOD.SECONDHALF, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianHomeTotal" },
					{ group: "AwayTotal", period: PERIOD.SECONDHALF, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianAwayTotal" },
					{ group: "Total3Way", period: PERIOD.SECONDHALF, title: "Total3Way" },
					{ group: "WinnerAndTotal", period: PERIOD.SECONDHALF, argument: (arg) => [1.5, 2.5, 3.5].includes(Number(arg)), title: "WinnerAndTotal" },
					{ group: "DoubleChanceAndTotal", period: PERIOD.SECONDHALF, argument: (arg) => [1.5, 2.5, 3.5].includes(Number(arg)), title: "DoubleChanceAndTotal" }
				]
			},
			handicap: {
				groups: [
					{ group: "Handicap", period: PERIOD.MATCH, argument: (arg) => Number(arg) % 0.5 === 0, title: "Handicap" },
					{ group: "Handicap", period: PERIOD.MATCH, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianHandicap" },
					{ group: "Handicap", period: PERIOD.FIRSTHALF, argument: (arg) => Number(arg) % 0.5 === 0, title: "Handicap" },
					{ group: "Handicap", period: PERIOD.FIRSTHALF, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianHandicap" },
					{ group: "Handicap", period: PERIOD.SECONDHALF, argument: (arg) => Number(arg) % 0.5 === 0, title: "Handicap" },
					{ group: "Handicap", period: PERIOD.SECONDHALF, argument: (arg) => Number(arg) % 0.5 !== 0, title: "AsianHandicap" }
				]
			},
			correctScore: {
				groups: [
					{ group: "CorrectScore6Goals", period: PERIOD.MATCH, title: "CorrectScore6Goals", statistics: 1 },
					{ group: "CorrectScore6Goals", period: PERIOD.FIRSTHALF, title: "CorrectScore6Goals" },
					{ group: "CorrectScore6Goals", period: PERIOD.SECONDHALF, title: "CorrectScore6Goals" }
				]
			},
			round: {
				groups: [{ group: "RoundTotal", period: PERIOD.NONE, argument: (arg) => Number(arg) % 0.5 === 0, title: "RoundTotal" }]
			}
		};
	}

	/** Function to sort rtps data by order Number
	 * @function
	 * @param {array} markets - given markets
	 * @param {array} rtps
	 * @param {Callback} getRtp
	 * @returns {string} data - sorted array
	 */
	sortMarketsByOrderNumber = (markets, rtps, getRtp = Function.prototype, period = null) => {
		const groupedRtps = rtps.reduce((acc, rtp) => {
			if (period !== null) {
				if (rtp.period === period) {
					acc[rtp.name] = rtp;
				}
				return acc;
			}
			if (!acc[rtp.name]) {
				acc[rtp.name] = rtp;
			}

			return acc;
		}, {});

		return [...markets].sort((item1, item2) => {
			const market1 = getRtp(groupedRtps, item1);
			const market2 = getRtp(groupedRtps, item2);
			const m1OrderNumber = market1 ? market1.orderNumber : 0;
			const m2OrderNumber = market2 ? market2.orderNumber : 0;

			if (m1OrderNumber === m2OrderNumber &&
				market1.name === market2.name &&
				!Number.isNaN(item1.period) && !Number.isNaN(item2.period)
			) {
				return item1.period > item2.period ? 1 : -1;
			}

			return m1OrderNumber > m2OrderNumber ? 1 : -1;
		});
	};

	/** Transform markets array to the array , useful for markets UI
	 * @function
	 * @param {array } data - data to transform
	 * @param {object} gameData - Event data
	 * @param {Array} rtps - Array of rtps to apply on markets
	 * @param {number} gameType - The gameType
	 * @returns {object}
	 * @memberof FootballMarkets
	 */
	makeMarketsArray(data, gameData, rtps, gameType) {
		const obj = {};
		const grouped = groupBy(data, (e) => e.groupTitle + "__" + e.period);
		Object.keys(grouped).forEach((o) => {
			const gr = o.split("__")[0];
			const period = o.split("__")[1] ? Number(o.split("__")[1]) : null;
			const col = (grouped[o][0].oddList || []).length > 2 && ["Winner3Way", "DoubleChance", "NextPoint", "Total3Way", "HalfMatch", "CorrectScore6Goals"].includes(gr) ? 3 : 2;
			obj[o] = {
				col: col,
				bets: [],
				period: period,
				group: grouped[o][0].group,
				statistics: grouped[o][0].statistics,
				isNew: grouped[o][0].isNew
			};
			grouped[o].forEach((g) => {
				let oddList = g.oddList || [];
				if (["Total", "HomeTotal", "AwayTotal", "AsianTotal", "AsianHomeTotal", "AsianAwayTotal", "Total3Way", "RoundTotal"].includes(gr)) {
					oddList.forEach((odd) => {
						obj[o].bets.push({
							name: odd.outcome + " " + g.argument,
							factor: this.applyRtp(odd.factor, gr, g.period, g.argument, odd.outcome, rtps),
							oddState: odd.state,
							outcome: odd.outcome,
							argument: g.argument,
							id: odd.id,
							showName: this.makeName("%" + odd.outcome + "% " + g.argument, gameType)
						});
					});
				} else if (["Handicap", "AsianHandicap"].includes(gr)) {
					oddList.forEach((odd) => {
						obj[o].bets.push({
							name: "%" + odd.outcome + "% (" + (odd.outcome === "Home" ? g.argument : 0 - g.argument) + ")",
							factor: this.applyRtp(odd.factor, gr, g.period, g.argument, odd.outcome, rtps),
							oddState: odd.state,
							outcome: odd.outcome,
							argument: g.argument,
							id: odd.id,
							showName: this.makeName("%[" + odd.outcome + "]% (<span class='vs--text-ltr'>" + (odd.outcome === "Home" ? g.argument : 0 - g.argument) + "</span>)", gameType)
						});
					});
				} else if (["CorrectScore6Goals"].includes(gr)) {
					oddList.forEach((odd) => {
						obj[o].bets.push({
							name: odd.outcome.replace("Home", "").replace("Away", ":"),
							factor: this.applyRtp(odd.factor, obj[o].group, g.period, g.argument, odd.outcome, rtps),
							oddState: odd.state,
							outcome: odd.outcome,
							argument: g.argument,
							id: odd.id,
							showName: this.makeName(odd.outcome.replace("Home", "").replace("Away", ":"), gameType)
						});
					});
				} else if (["Winner3Way", "DoubleChance", "ResultWithoutDraw", "Winner2Way"].includes(gr)) {
					oddList.forEach((odd) => {
						obj[o].bets.push({
							name: "%" + odd.outcome + "%",
							factor: this.applyRtp(odd.factor, obj[o].group, g.period, g.argument, odd.outcome, rtps),
							oddState: odd.state,
							outcome: odd.outcome,
							argument: g.argument,
							id: odd.id,
							showName: this.makeName("%" + odd.outcome + "%", gameType)
						});
					});
				} else if (["NextPoint"].includes(gr)) {
					oddList.forEach((odd) => {
						obj[o].bets.push({
							name: "%" + odd.outcome + "%",
							factor: this.applyRtp(odd.factor, obj[o].group, g.period, g.argument, odd.outcome, rtps),
							oddState: odd.state,
							outcome: odd.outcome,
							argument: g.argument,
							id: odd.id,
							showName: this.makeName("%[" + odd.outcome + "]%", gameType)
						});
					});
				} else if (["HalfMatch", "MethodOfVictoryOvertime"].includes(gr)) {
					oddList.forEach((odd) => {
						obj[o].bets.push({
							name: "%" + odd.outcome + "%",
							factor: this.applyRtp(odd.factor, obj[o].group, g.period, g.argument, odd.outcome, rtps),
							oddState: odd.state,
							outcome: odd.outcome,
							argument: g.argument,
							id: odd.id,
							showName: this.makeName("%" + odd.outcome + "%", gameType)
						});
					});
				} else if (["WinnerAndTotal"].includes(gr)) {
					oddList.forEach((odd) => {
						obj[o].bets.push({
							name: "%" + odd.outcome + "%" + " " + g.argument,
							factor: this.applyRtp(odd.factor, obj[o].group, g.period, g.argument, odd.outcome, rtps),
							oddState: odd.state,
							outcome: odd.outcome,
							argument: g.argument,
							id: odd.id,
							showName: this.makeName("%" + odd.outcome + "%" + " " + g.argument, gameType)
						});
					});
				} else if (["Correct6HomeGoalsCount", "Correct6AwayGoalsCount", "Correct6GoalsCount"].includes(gr)) {
					oddList.forEach((odd) => {
						obj[o].bets.push({
							name: odd.outcome.replace("Goal", ""),
							factor: this.applyRtp(odd.factor, obj[o].group, g.period, g.argument, odd.outcome, rtps),
							oddState: odd.state,
							outcome: odd.outcome,
							argument: g.argument,
							id: odd.id,
							showName: this.makeName(odd.outcome.replace("Goal", ""), gameType)
						});
					});
				} else if (["DoubleChanceAndTotal"].includes(gr)) {
					oddList.forEach((odd) => {
						obj[o].bets.push({
							name: "%" + odd.outcome + "%" + " " + g.argument,
							factor: this.applyRtp(odd.factor, obj[o].group, g.period, g.argument, odd.outcome, rtps),
							oddState: odd.state,
							outcome: odd.outcome,
							argument: g.argument,
							id: odd.id,
							showName: this.makeName("%" + odd.outcome + "%" + " " + g.argument, gameType)
						});
					});
				} else if (["Winner", "Loser"].includes(gr) && isLeagueGame(gameType)) {
					oddList.forEach((odd) => {
						obj[o].bets.push({
							name: odd.outcome,
							factor: this.applyRtp(odd.factor, obj[o].group, g.period, g.argument, odd.outcome, rtps),
							oddState: odd.state,
							outcome: odd.outcome,
							argument: g.argument,
							id: odd.id,
							showName: this.makeName(odd.outcome, gameType),
							withFlag: true
						});
					});
				} else if (["Champion", "Finalist"].includes(gr) && isCupGame(gameType)) {
					oddList.forEach((odd) => {
						obj[o].bets.push({
							name: odd.outcome,
							factor: this.applyRtp(odd.factor, obj[o].group, g.period, g.argument, odd.outcome, rtps),
							oddState: odd.state,
							outcome: odd.outcome,
							argument: g.argument,
							id: odd.id,
							showName: this.makeName(odd.outcome, gameType, ...(isSimpleCupGame(gameType) ? [true, "countries"] : [false, ""])),
							withFlag: true
						});
					});
				} else {
					oddList.forEach((odd) => {
						obj[o].bets.push({
							name: odd.outcome,
							factor: this.applyRtp(odd.factor, obj[o].group, g.period, g.argument, odd.outcome, rtps),
							oddState: odd.state,
							outcome: odd.outcome,
							argument: g.argument,
							id: odd.id,
							showName: this.makeName(odd.outcome, gameType)
						});
					});
				}
			});
		});

		const arr = [];

		Object.keys(obj).forEach((o) => {
			let item = {
				group: o.split("__")[0],
				col: obj[o].col,
				bets: obj[o].bets.map((b) => (b.factor >= 1.01 ? b : { ...b, disabled: true })),
				period: o.split("__")[1] ? Number(o.split("__")[1]) : null,
				systemGroup: obj[o].group,
				statistics: obj[o].statistics,
				isNew: obj[o].isNew
			};
			if (item.bets.length > 1) {
				if (["Total", "HomeTotal", "AwayTotal", "AsianTotal", "AsianHomeTotal", "AsianAwayTotal", "Handicap", "AsianHandicap", "Total3Way", "RoundTotal"].includes(item.group)) {
					const blockedArguments = item.bets.reduce((acc, b) => {
						const boolResult = b.factor < 1.01;
						if (boolResult) {
							if (item.group === "RoundTotal") {
								const siblingOddOutcome = b.outcome === "Over" ? "Under" : "Over";
								const siblingOdd = item.bets.find((siblingB) => siblingB.outcome === siblingOddOutcome && siblingB.argument === b.argument);
								if (siblingOdd && siblingOdd.factor >= 1.01) {
									return acc;
								}
							}
							acc.push(b.argument);
						}
						return acc;
					}, []);
					const filteredBets = item.bets.filter((b) => !blockedArguments.includes(b.argument));
					const market = {
						...item,
						bets: filteredBets
					};
					if (item.group === "Total3Way") {
						market.bets.sort((a, b) => {
							if (a.argument < b.argument) return -1;
							if (b.argument < a.argument) return 1;
							if (a.outcome === "Over" && b.outcome === "Under") return -1;
							if (b.outcome === "Over" && a.outcome === "Under") return 1;
							if (a.outcome === "Over" && b.outcome === "Equal") return -1;
							if (b.outcome === "Over" && a.outcome === "Equal") return 1;
							if (a.outcome === "Equal" && b.outcome === "Under") return -1;
							if (b.outcome === "Equal" && a.outcome === "Under") return 1;
						});
					}

					arr.push(market);
				} else if (["RedCard", "YellowCard", "Penalty", "WillHomeTeamScore", "WillAwayTeamScore", "WillBothTeamsScore", "Points"].includes(item.group)) {
					if (item.bets.every((b) => b.factor >= 1.01)) {
						arr.push(item);
					}
				} else {
					arr.push(item);
				}
			}
		});

		return arr;
	}

	/** Add teams name into market name
	 * @function
	 * @param {string } str - market name
	 * @param {string} group - group name
	 * @param {number} gameType - Game type
	 * @returns {string}
	 * @memberof FootballMarkets
	 */
	makeName(str, gameType, needToTranslateByKey = false, key = "") {
		let result = str;
		if (needToTranslateByKey) {
			return i18n.t(`${key}.${result}`);
		}
		switch (true) {
			case str.includes("%Home%"):
				result = str.replace("%Home%", i18n.t(`markets.${gameType}.resultTeam1`));
				break;
			case str.includes("%Away%"):
				result = str.replace("%Away%", i18n.t(`markets.${gameType}.resultTeam2`));
				break;
			case str.includes("%Draw%"):
				result = str.replace("%Draw%", i18n.t(`markets.${gameType}.Draw`));
				break;
			case str.includes("%[Draw]%"):
				result = str.replace("%[Draw]%", i18n.t(`markets.${gameType}.Draw`));
				break;
			case str.includes("%[Home]%"):
				result = str.replace("%[Home]%", i18n.t(`markets.${gameType}.team`) + "1");
				break;
			case str.includes("%[Away]%"):
				result = str.replace("%[Away]%", i18n.t(`markets.${gameType}.team`) + "2");
				break;
			case str.includes("%Neither%"):
				result = str.replace("%Neither%", i18n.t(`markets.${gameType}.Neither`));
				break;
			case str.includes("%[Neither]%"):
				result = str.replace("%[Neither]%", i18n.t(`markets.${gameType}.Neither`));
				break;
			case str.includes("%HomeOrDraw%"):
				result = str.replace("%HomeOrDraw%", i18n.t(`markets.${gameType}.resultTeam1`) + " " + i18n.t(`markets.${gameType}.or`) + " " + i18n.t(`markets.${gameType}.Draw`));
				break;
			case str.includes("%HomeOrAway%"):
				result = str.replace("%HomeOrAway%", i18n.t(`markets.${gameType}.resultTeam1`) + " " + i18n.t(`markets.${gameType}.or`) + " " + i18n.t(`markets.${gameType}.resultTeam2`));
				break;
			case str.includes("%AwayOrDraw%"):
				result = str.replace("%AwayOrDraw%", i18n.t(`markets.${gameType}.resultTeam2`) + " " + i18n.t(`markets.${gameType}.or`) + " " + i18n.t(`markets.${gameType}.Draw`));
				break;
			case str.includes("%P1P1%"):
				result = str.replace("%P1P1%", i18n.t(`markets.${gameType}.resultTeam1`) + "/" + i18n.t(`markets.${gameType}.resultTeam1`));
				break;
			case str.includes("%P1H%"):
				result = str.replace("%P1H%", i18n.t(`markets.${gameType}.resultTeam1`) + "/" + i18n.t(`markets.${gameType}.Draw`));
				break;
			case str.includes("%P1P2%"):
				result = str.replace("%P1P2%", i18n.t(`markets.${gameType}.resultTeam1`) + "/" + i18n.t(`markets.${gameType}.resultTeam2`));
				break;
			case str.includes("%HP1%"):
				result = str.replace("%HP1%", i18n.t(`markets.${gameType}.Draw`) + "/" + i18n.t(`markets.${gameType}.resultTeam1`));
				break;
			case str.includes("%HH%"):
				result = str.replace("%HH%", i18n.t(`markets.${gameType}.Draw`) + "/" + i18n.t(`markets.${gameType}.Draw`));
				break;
			case str.includes("%HP2%"):
				result = str.replace("%HP2%", i18n.t(`markets.${gameType}.Draw`) + "/" + i18n.t(`markets.${gameType}.resultTeam2`));
				break;
			case str.includes("%P2P2%"):
				result = str.replace("%P2P2%", i18n.t(`markets.${gameType}.resultTeam2`) + "/" + i18n.t(`markets.${gameType}.resultTeam2`));
				break;
			case str.includes("%P2H%"):
				result = str.replace("%P2H%", i18n.t(`markets.${gameType}.resultTeam2`) + "/" + i18n.t(`markets.${gameType}.Draw`));
				break;
			case str.includes("%P2P1%"):
				result = str.replace("%P2P1%", i18n.t(`markets.${gameType}.resultTeam2`) + "/" + i18n.t(`markets.${gameType}.resultTeam1`));
				break;
			case str.includes("%HomeAndUnder%"):
				result = str.replace("%HomeAndUnder%", i18n.t(`markets.${gameType}.resultTeam1`) + " " + i18n.t(`markets.${gameType}.and`) + " " + i18n.t(`markets.${gameType}.under`));
				break;
			case str.includes("%DrawAndUnder%"):
				result = str.replace("%DrawAndUnder%", i18n.t(`markets.${gameType}.Draw`) + " " + i18n.t(`markets.${gameType}.and`) + " " + i18n.t(`markets.${gameType}.under`));
				break;
			case str.includes("%AwayAndUnder%"):
				result = str.replace("%AwayAndUnder%", i18n.t(`markets.${gameType}.resultTeam2`) + " " + i18n.t(`markets.${gameType}.and`) + " " + i18n.t(`markets.${gameType}.under`));
				break;
			case str.includes("%HomeAndOver%"):
				result = str.replace("%HomeAndOver%", i18n.t(`markets.${gameType}.resultTeam1`) + " " + i18n.t(`markets.${gameType}.and`) + " " + i18n.t(`markets.${gameType}.over`));
				break;
			case str.includes("%DrawAndOver%"):
				result = str.replace("%DrawAndOver%", i18n.t(`markets.${gameType}.Draw`) + " " + i18n.t(`markets.${gameType}.and`) + " " + i18n.t(`markets.${gameType}.over`));
				break;
			case str.includes("%AwayAndOver%"):
				result = str.replace("%AwayAndOver%", i18n.t(`markets.${gameType}.resultTeam2`) + " " + i18n.t(`markets.${gameType}.and`) + " " + i18n.t(`markets.${gameType}.over`));
				break;
			case str.includes("AndMore"):
				result = str.replace("AndMore", " " + i18n.t(`markets.${gameType}.andMore`));
				break;
			case str.includes("%HomeOrDrawAndOver%"):
				result = str.replace("%HomeOrDrawAndOver%", i18n.t(`markets.${gameType}.resultTeam1`) + " " + i18n.t(`markets.${gameType}.or`) + " " + i18n.t(`markets.${gameType}.Draw`) + " " + i18n.t(`markets.${gameType}.and`) + " " + i18n.t(`markets.${gameType}.dchance_over`));
				break;
			case str.includes("%HomeOrAwayAndOver%"):
				result = str.replace("%HomeOrAwayAndOver%", i18n.t(`markets.${gameType}.resultTeam1`) + " " + i18n.t(`markets.${gameType}.or`) + " " + i18n.t(`markets.${gameType}.resultTeam2`) + " " + i18n.t(`markets.${gameType}.and`) + " " + i18n.t(`markets.${gameType}.dchance_over`));
				break;
			case str.includes("%AwayOrDrawAndOver%"):
				result = str.replace("%AwayOrDrawAndOver%", i18n.t(`markets.${gameType}.resultTeam2`) + " " + i18n.t(`markets.${gameType}.or`) + " " + i18n.t(`markets.${gameType}.Draw`) + " " + i18n.t(`markets.${gameType}.and`) + " " + i18n.t(`markets.${gameType}.dchance_over`));
				break;
			case str.includes("%HomeOrDrawAndUnder%"):
				result = str.replace("%HomeOrDrawAndUnder%", i18n.t(`markets.${gameType}.resultTeam1`) + " " + i18n.t(`markets.${gameType}.or`) + " " + i18n.t(`markets.${gameType}.Draw`) + " " + i18n.t(`markets.${gameType}.and`) + " " + i18n.t(`markets.${gameType}.dchance_under`));
				break;
			case str.includes("%HomeOrAwayAndUnder%"):
				result = str.replace("%HomeOrAwayAndUnder%", i18n.t(`markets.${gameType}.resultTeam1`) + " " + i18n.t(`markets.${gameType}.or`) + " " + i18n.t(`markets.${gameType}.resultTeam2`) + " " + i18n.t(`markets.${gameType}.and`) + " " + i18n.t(`markets.${gameType}.dchance_under`));
				break;
			case str.includes("%AwayOrDrawAndUnder%"):
				result = str.replace("%AwayOrDrawAndUnder%", i18n.t(`markets.${gameType}.resultTeam2`) + " " + i18n.t(`markets.${gameType}.or`) + " " + i18n.t(`markets.${gameType}.Draw`) + " " + i18n.t(`markets.${gameType}.and`) + " " + i18n.t(`markets.${gameType}.dchance_under`));
				break;
			case str.includes("%Over%"):
				result = str.replace("%Over%", i18n.t(`markets.${gameType}.over`));
				break;
			case str.includes("%Under%"):
				result = str.replace("%Under%", i18n.t(`markets.${gameType}.under`));
				break;
			case str.includes("%Equal%"):
				result = str.replace("%Equal%", i18n.t(`markets.${gameType}.equal`));
				break;
			case ["Yes", "No"].includes(str):
				result = i18n.t(`common.${str.toLowerCase()}`);
				break;
			case ["Odd", "Even"].includes(str):
				result = i18n.t(`markets.${gameType}.${str}`);
				break;
			case str.includes("%HomeWinAfterOvertime%"):
				result = str.replace("%HomeWinAfterOvertime%", i18n.t(`markets.${gameType}.team`) + "1" + " " + i18n.t(`markets.${gameType}.winOverTime`));
				break;
			case str.includes("%AwayWinAfterOvertime%"):
				result = str.replace("%AwayWinAfterOvertime%", i18n.t(`markets.${gameType}.team`) + "2" + " " + i18n.t(`markets.${gameType}.winOverTime`));
				break;
			case str.includes("%HomeWinAfterPenalties%"):
				result = str.replace("%HomeWinAfterPenalties%", i18n.t(`markets.${gameType}.team`) + "1" + " " + i18n.t(`markets.${gameType}.winPenalty`));
				break;
			case str.includes("%AwayWinAfterPenalties%"):
				result = str.replace("%AwayWinAfterPenalties%", i18n.t(`markets.${gameType}.team`) + "2" + " " + i18n.t(`markets.${gameType}.winPenalty`));
				break;
		}
		return result;
	}

	/** Make market beuty name, to show in winner market section
	 * @function
	 * @param {object} market - the market object
	 * @returns {string}
	 * @memberof FootballMarkets
	 */
	makeWinnerMarketName(market) {
		let result = "";

		const team1 = market.gameType === GAME_TYPE.FOOTBALL_SINGLE_MATCH || isSimpleCupGame(market.gameType) ? i18n.t(`countries.${market.team1}`) : market.team1;
		const team2 = market.gameType === GAME_TYPE.FOOTBALL_SINGLE_MATCH || isSimpleCupGame(market.gameType) ? i18n.t(`countries.${market.team2}`) : market.team2;

		switch (market.outcome) {
			case "Home":
				result = team1;
				break;
			case "Away":
				result = team2;
				break;
			case "Draw":
				result = i18n.t(`markets.${market.gameType}.Draw`);
				break;
			case "Neither":
				result = i18n.t(`markets.${market.gameType}.Neither`);
				break;
			case "HomeOrDraw":
				result = team1 + " " + i18n.t(`markets.${market.gameType}.or`) + " " + i18n.t(`markets.${market.gameType}.Draw`);
				break;
			case "HomeOrAway":
				result = team1 + " " + i18n.t(`markets.${market.gameType}.or`) + " " + team2;
				break;
			case "AwayOrDraw":
				result = team2 + " " + i18n.t(`markets.${market.gameType}.or`) + " " + i18n.t(`markets.${market.gameType}.Draw`);
				break;
			case "HomeAndUnder":
				result = team1 + " " + i18n.t(`markets.${market.gameType}.and`) + " " + i18n.t(`markets.${market.gameType}.under`);
				break;
			case "HomeAndOver":
				result = team1 + " " + i18n.t(`markets.${market.gameType}.and`) + " " + i18n.t(`markets.${market.gameType}.over`);
				break;
			case "DrawAndUnder":
				result = i18n.t(`markets.${market.gameType}.Draw`) + " " + i18n.t(`markets.${market.gameType}.and`) + " " + i18n.t(`markets.${market.gameType}.under`);
				break;
			case "DrawAndOver":
				result = i18n.t(`markets.${market.gameType}.Draw`) + " " + i18n.t(`markets.${market.gameType}.and`) + " " + i18n.t(`markets.${market.gameType}.over`);
				break;
			case "AwayAndUnder":
				result = team2 + " " + i18n.t(`markets.${market.gameType}.and`) + " " + i18n.t(`markets.${market.gameType}.under`);
				break;
			case "AwayAndOver":
				result = team2 + " " + i18n.t(`markets.${market.gameType}.and`) + " " + i18n.t(`markets.${market.gameType}.over`);
				break;
			case "HomeOrDrawAndOver":
				result = team1 + " " + i18n.t(`markets.${market.gameType}.or`) + " " + i18n.t(`markets.${market.gameType}.Draw`) + " " + i18n.t(`markets.${market.gameType}.and`) + " " + i18n.t(`markets.${market.gameType}.over`);
				break;
			case "HomeOrAwayAndOver":
				result = team1 + " " + i18n.t(`markets.${market.gameType}.or`) + " " + team2 + " " + i18n.t(`markets.${market.gameType}.and`) + " " + i18n.t(`markets.${market.gameType}.over`);
				break;
			case "AwayOrDrawAndOver":
				result = team2 + " " + i18n.t(`markets.${market.gameType}.or`) + " " + i18n.t(`markets.${market.gameType}.Draw`) + " " + i18n.t(`markets.${market.gameType}.and`) + " " + i18n.t(`markets.${market.gameType}.over`);
				break;
			case "HomeOrDrawAndUnder":
				result = team1 + " " + i18n.t(`markets.${market.gameType}.or`) + " " + i18n.t(`markets.${market.gameType}.Draw`) + " " + i18n.t(`markets.${market.gameType}.and`) + " " + i18n.t(`markets.${market.gameType}.under`);
				break;
			case "HomeOrAwayAndUnder":
				result = team1 + " " + i18n.t(`markets.${market.gameType}.or`) + " " + team2 + " " + i18n.t(`markets.${market.gameType}.and`) + " " + i18n.t(`markets.${market.gameType}.under`);
				break;
			case "AwayOrDrawAndUnder":
				result = team2 + " " + i18n.t(`markets.${market.gameType}.or`) + " " + i18n.t(`markets.${market.gameType}.Draw`) + " " + i18n.t(`markets.${market.gameType}.and`) + " " + i18n.t(`markets.${market.gameType}.under`);
				break;
			case "Yes":
				result = i18n.t("common.yes");
				break;
			case "No":
				result = i18n.t("common.no");
				break;
			case "Odd":
				result = i18n.t(`markets.${market.gameType}.Odd`);
				break;
			case "Even":
				result = i18n.t(`markets.${market.gameType}.Even`);
				break;
			case "Over":
				result = i18n.t(`markets.${market.gameType}.over`);
				break;
			case "Equal":
				result = i18n.t(`markets.${market.gameType}.equal`);
				break;
			case "Under":
				result = i18n.t(`markets.${market.gameType}.under`);
				break;
			case "HomeWinAfterOvertime":
				result = team1 + " " + i18n.t(`markets.${market.gameType}.winOverTime`);
				break;
			case "AwayWinAfterOvertime":
				result = team2 + " " + i18n.t(`markets.${market.gameType}.winOverTime`);
				break;
			case "HomeWinAfterPenalties":
				result = team1 + " " + i18n.t(`markets.${market.gameType}.winPenalty`);
				break;
			case "AwayWinAfterPenalties":
				result = team2 + " " + i18n.t(`markets.${market.gameType}.winPenalty`);
				break;
			default:
				if (["Finalist", "Champion"].includes(market.group)) {
					return isSimpleCupGame(market.gameType) ? i18n.t(`countries.${market.outcome}`) : market.outcome;
				}
				if (market.group === "CorrectScore6Goals") result = market.outcome.replace("Home", "").replace("Away", " : ");
				else if (["Correct6HomeGoalsCount", "Correct6AwayGoalsCount", "Correct6GoalsCount"].includes(market.group)) result = market.outcome.replace("Goal", "").replace("AndMore", " " + i18n.t(`markets.${market.gameType}.andMore`));
				else if (market.group === "HalfMatch")
					result = market.outcome
						.replace("P1P1", team1 + "/" + team1)
						.replace("P1H", team1 + "/" + i18n.t(`markets.${market.gameType}.Draw`))
						.replace("P1P2", team1 + "/" + team2)
						.replace("HP1", i18n.t(`markets.${market.gameType}.Draw`) + "/" + team1)
						.replace("HH", i18n.t(`markets.${market.gameType}.Draw`) + "/" + i18n.t(`markets.${market.gameType}.Draw`))
						.replace("HP2", i18n.t(`markets.${market.gameType}.Draw`) + "/" + team2)
						.replace("P2P2", team2 + "/" + team2)
						.replace("P2H", team2 + "/" + i18n.t(`markets.${market.gameType}.Draw`))
						.replace("P2P1", team2 + "/" + team1);
				else result = market.outcome;
				break;
		}

		return result;
	}

	/** Make market beuty name from system name
	 * @function
	 * @param {object} market - the market object
	 * @returns {string}
	 * @memberof FootballMarkets
	 */
	makeGroupTitle(market) {
		let res = "";
		if (market.period === PERIOD.FIRSTHALF) {
			res += i18n.t(`markets.${market.gameType}.1Half`) + " ";
		} else if (market.period === PERIOD.SECONDHALF) {
			res += i18n.t(`markets.${market.gameType}.2Half`) + " ";
		}

		let gr = market.group;
		if (market.argument !== undefined) {
			if (["Handicap", "Total", "AwayTotal", "HomeTotal", "RoundTotal"].includes(market.group) && Number(market.argument) % 0.5 !== 0) {
				gr = "Asian" + market.group;
			}
		}

		const team1 = market.gameType === GAME_TYPE.FOOTBALL_SINGLE_MATCH || isSimpleCupGame(market.gameType) ? i18n.t(`countries.${market.team1}`) : market.team1;
		const team2 = market.gameType === GAME_TYPE.FOOTBALL_SINGLE_MATCH || isSimpleCupGame(market.gameType) ? i18n.t(`countries.${market.team2}`) : market.team2;

		res += i18n.t(`markets.${market.gameType}.${gr}`);
		res = res.replace("%team1%", team1).replace("%team2%", team2);

		const argument = market.group === "Handicap" && market.outcome === "Away" && market.argument ? 0 - market.argument : market.argument;

		res += argument || argument === 0 ? " " + (market.group === "Handicap" ? "<span class='vs--text-ltr'>" + argument + "</span>" : argument) : "";

		return res;
	}

	/** Make beuty text of bet
	 * @function
	 * @param {object} bet - bet
	 * @returns {string}
	 * @memberof FootballMarkets
	 */
	makeBetText(bet) {
		const team1 = bet?.gameData?.team1 ?? {};
		const team2 = bet?.gameData?.team2 ?? {};

		return (
			this.makeGroupTitle({ period: bet.period, group: bet.group, team1: team1.countryCode ?? "Team 1", team2: team2.countryCode ?? "Team 2", argument: bet.argument, outcome: bet.outcome, gameType: bet.gameType }) +
			": " +
			this.makeWinnerMarketName({ outcome: bet.outcome, team1: team1.countryCode ?? "Team 1", team2: team2.countryCode ?? "Team 2", group: bet.group, argument: bet.argument, gameType: bet.gameType })
		);
	}
}

export default FootballMarkets;
