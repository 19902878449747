import { EVENT_BET_HISTORY_BEFORE, EVENT_BET_HISTORY_AFTER, SET_EVENT_BET_HISTORY } from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case EVENT_BET_HISTORY_BEFORE:
			return {
				...state,
				loading: true
			};
		case EVENT_BET_HISTORY_AFTER:
			return {
				...state,
				loading: false
			};
		case SET_EVENT_BET_HISTORY:
			return {
				...state,
				history: {
					...state.history,
					[payload.id]: payload.bets
				}
			};
		default:
			return state;
	}
};
