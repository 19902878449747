import PropTypes from "prop-types";
import Loader from "components/ui/loader";
import Table from "components/ui/table";
import { mergeClassNames } from "utils/common";
import useGlobalVariables from "hooks/useGlobalVariables";
import useStandingsColumns from "hooks/useStandingsColumns";
import footballTeamStatisticsType from "types/football-team-statistics.type";
import gamePropType from "types/game.type";
import { GAME_TYPE } from "constants/game.constants";

/** Season Standings Component */
const SeasonStandings = ({
	loading,
	standings,
	gameType,
	isFromResults = false,
}) => {
	const { isMobile } = useGlobalVariables();

	const columns = useStandingsColumns({ isFromResults, gameType });

	if (loading) {
		return (
			<div className="standings-loader">
				<Loader />
			</div>
		);
	}
	
	return (
		<Table
			wrapperClassName="vs--table-standings-wrapper"
			className={mergeClassNames("vs--table-standings", isMobile ? "vs--table-standings-mobile" : "vs--table-standings-web")}
			data-result={isFromResults}
			textClassName="vs--title-white vs--font-medium vs--font-normal"
			columns={columns}
			data={standings}
			allowScrolling={true}
			columnClassName={isFromResults ? "vs--table-column-result" : null}
		/>
	);
};

/** SeasonStandings propTypes
 * PropTypes
 */
SeasonStandings.propTypes = {
	/** React property, can be boolean for gettin request with empty params or can be equal season id */
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
	/** Redux action to get season statistics */
	getSeasonStandings: PropTypes.func,
	/** Redux action to get season statistics */
	getSeasonStatistics: PropTypes.func,
	/** Redux state property, current season statistics */
	standings: PropTypes.arrayOf(footballTeamStatisticsType),
	/** React property, game type */
	gameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	/** Redux state property, is true when loading season statistics */
	loading: PropTypes.bool,
	/** React property, will component mount from Result page */
	isFromResults: PropTypes.bool
};

export default SeasonStandings;
