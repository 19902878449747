import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { setSeasonResultModalMobile } from "store/actions/season/season.actions";
import Standings from "components/ui/season/standings";
import CupStructure from "components/ui/season/cupStructure";
import LeagueGamesResultsHoc from "hocs/seasonGamesInfo/leagueGamesResultsHoc";
import CupGamesResultsHoc from "hocs/seasonGamesInfo/cupGamesResultsHoc";
import useSwipeableModal from "hooks/useSwipeableModal";
import { GAME_TYPE } from "constants/game.constants";
import { isCupGame, isLeagueGame } from "utils/common";

const WrappedReactComponentOfLeagueGamesResultSeasonHoc = LeagueGamesResultsHoc(Standings);
const WrappedReactComponentOfCupGamesResultSeasonHoc = CupGamesResultsHoc(CupStructure);

/** Season Result Modal Component */
const SeasonResultModal = ({ showSeasonResultModalBySeasonId, setSeasonResultModalMobile, currentGameType }) => {
	const { t } = useTranslation();

	const data = useSwipeableModal({
		title: isLeagueGame(currentGameType) ? t("common.teamStandings") : isCupGame(currentGameType) ? t("common.cupStructure") : "",
		onClose: () => setSeasonResultModalMobile(null),
		visibilityState: !!showSeasonResultModalBySeasonId,
		children: isLeagueGame(currentGameType) ? <WrappedReactComponentOfLeagueGamesResultSeasonHoc id={showSeasonResultModalBySeasonId} gameType={currentGameType} /> : isCupGame(currentGameType) ? <WrappedReactComponentOfCupGamesResultSeasonHoc id={showSeasonResultModalBySeasonId} /> : null
	});

	return data?.component;
};

/** SeasonResultModal propTypes
 * PropTypes
 */
SeasonResultModal.propTypes = {
	/** Redux state property, if true will show season modal on mobile, the field include current Game Event Id */
	showSeasonModal: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
	/** Redux action to show mobile season modal */
	setSeasonResultModalMobile: PropTypes.func,
	/** Redux state property, Current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE))
};

const mapStateToProps = (state) => {
	return {
		showSeasonResultModalBySeasonId: state.season.showSeasonResultModalBySeasonId,
		currentGameType: state.game.currentGameType
	};
};

const mapDispatchToProps = (dispatch) => ({
	setSeasonResultModalMobile: (id) => {
		dispatch(setSeasonResultModalMobile(id));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(SeasonResultModal);
