import { Fragment, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PostMessagesIntegration from "components/postMessagesIntegration";
import Main from "components/main";
import Header from "components/header";
import TopBar from "components/topBar";
import RightBar from "components/rightBar";
import MobileHeader from "components/mobileHeader";
import MobileMenu from "components/ui/mobileMenu";
import SeasonLiveModal from "components/ui/season/modal/seasonLive";
import SeasonResultModal from "components/ui/season/modal/seasonResult";
import BetslipWonPopup from "components/betslipWonPopup";
import BonusActivationPopup from "components/bonusActivationPopup";
import UserFeedbackPopup from "components/feedbackPopup";
import BlockedMessage from "components/ui/blockedMessage";
import { placeBetSuccess } from "store/actions/betslip/betslip.actions";
import useGlobalVariables from "hooks/useGlobalVariables";
import { GAME_TYPE, GAME_ACTIVITY_STATE, GAME_NAVIGATION_BY_SCROLL_CLASSES } from "constants/game.constants";
import { buildPathToStaticFolderOfCDN } from "utils/common";
import eventType from "types/event.type";
import gameType from "types/game.type";
import vsToast from "utils/toast";
import OnBoarding from "components/onBoarding";

/** Main Layout Component */
const Layout = ({ showSeasonLiveModal, showSeasonResultModalBySeasonId, games, currentGameType, liveAndUpcomings, isliveAndUpcomingsLoading, success, hideSuccessMessage }) => {
	const { t } = useTranslation();
	const location = useLocation();
	const isCurrentGameDeactivated =
	((games.find((game) => game.type === currentGameType))?.state ?? GAME_ACTIVITY_STATE.ACTIVE) === GAME_ACTIVITY_STATE.INACTIVE;
	const { isMobile, isIOS, isBuilderIntegration } = useGlobalVariables();

	useEffect(() => {
		if (!isIOS) {
			return;
		}
		document.getElementsByClassName("vs--container")[0].classList.add("vs--container-ios");
	}, [isIOS]);

	/** Detect case for landscape mode in mobile */
	useEffect(() => {
		if (!isMobile) {
			return;
		}

		/* function listener for window orientation change */
		const handleOrientationChange = () => {
			if (window.orientation > 0) {
				document.body.classList.add("vs--orientation-landscape");
				return;
			}
			document.body.classList.remove("vs--orientation-landscape");
		};

		handleOrientationChange();
		window.addEventListener("orientationchange", handleOrientationChange);

		return () => {
			window.removeEventListener("orientationchange", handleOrientationChange);
		};
	}, [isMobile]);

	useEffect(() => {
		if (isMobile && success) {
			vsToast.success(
				<div>
					<div>{t("bet.betslipSuccessText")}</div>
					<div>{t("bet.betslipSuccessSubtext")}</div>
				</div>
				,
				{ onClose: hideSuccessMessage }
			)
		}
	}, [isMobile, success])

	return (
		<Fragment>
			<Main />
			<PostMessagesIntegration />
			<div className="vs--container" data-pathname={location.pathname}>
				<div className="vs--wrapper vs--flex vs--flex-col">
					{isMobile && !isBuilderIntegration ? <MobileHeader /> : null}
					<TopBar isCurrentGameDeactivated={isCurrentGameDeactivated} />
					{isMobile ? null : <Header />}
					<div className={GAME_NAVIGATION_BY_SCROLL_CLASSES.VS_WRAPPER_SECTION}>
						{
							(
								isCurrentGameDeactivated &&
								!isliveAndUpcomingsLoading &&
								liveAndUpcomings.length === 0
							)
								? (
									<BlockedMessage
										title='common.gameIsDeactivated'
										message='common.gameIsDeactivatedMessage'
										imageSrc={buildPathToStaticFolderOfCDN("images/misc/plug.svg")}
									/>
								)
								: (
									<Fragment>
										{
											isMobile
												? <Outlet />
												: (
													<div className="vs--flex-equal vs--flex">
														<div className={`${GAME_NAVIGATION_BY_SCROLL_CLASSES.VS_WRAPPER_SECTION_INNER} vs--flex-equal`}>
															<Outlet />
														</div>
														<RightBar />
													</div>
												)
										}
									</Fragment>
								)
						}
					</div>
					{isMobile ? (
						<Fragment>
							<MobileMenu />
						</Fragment>
					) : null}
				</div>
				<BetslipWonPopup />
				{isMobile ? (
					<Fragment>
						{showSeasonLiveModal ? <SeasonLiveModal /> : null}
						{showSeasonResultModalBySeasonId ? <SeasonResultModal /> : null}
					</Fragment>
				) : null}
				<BonusActivationPopup />
				<UserFeedbackPopup />
				{/* //TODO Need dynamic implementation with BO fore more flexibility */}
				<OnBoarding />
			</div>
		</Fragment>
	);
};

/** Layout propTypes
 * PropTypes
 */
Layout.propTypes = {
	/** Redux state property, if true will show season live modal on mobile */
	showSeasonLiveModal: PropTypes.bool,
	/** Redux state property, if true will show season result modal on mobile */
	showSeasonResultModalBySeasonId: PropTypes.number,
	/** Redux state property, Current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	/** Redux state property, Games */
	games: PropTypes.arrayOf(gameType),
	/** Redux state property, live and upcoming matches */
	liveAndUpcomings: PropTypes.arrayOf(eventType),
	/** Redux state property, Live and upcoming matches loading */
	isliveAndUpcomingsLoading: PropTypes.bool,
	success: PropTypes.bool,
	hideSuccessMessage: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		showSeasonLiveModal: state.statistics.season.showSeasonLiveModal,
		showSeasonResultModalBySeasonId: state.season.showSeasonResultModalBySeasonId,
		games: state.auth.session.games,
		currentGameType: state.game.currentGameType,
		liveAndUpcomings: state.game.liveAndUpcomings.data,
		isliveAndUpcomingsLoading: state.game.liveAndUpcomings.loading,
		success: state.betslip.success
	};
};

const mapDispatchToProps = (dispatch) => ({
	hideSuccessMessage: () => {
		dispatch(placeBetSuccess(false));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
