import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import i18n from "translations/config";

import { GAME_TYPE } from "constants/game.constants";

import { formatDateTime } from "utils/dateTime";
import { isMobile } from "utils/common";

import {
	SET_ERROR_MESSAGE,
	SET_BLOCKED_MESSAGE,
	SET_VIDEO_LAYOUT,
	SET_VIDEO_MINIMIZED,
	SET_LIVE_INFO_EVENT,
	UPDATE_LIVE_INFO_EVENT,
	SET_SHOW_LIVE_INFO_EVENT,
	SET_LIVE_INFO_POSITIONS,
	SET_CONNECTION_STATE,
	SET_CURRENT_TIME,
	SET_COLLAPSED,
	GAME_RULE_BEFORE,
	GAME_RULE_AFTER,
	SET_GAME_RULE,
	SET_SHOW_RULES,
	CLEAR_RULES
} from "../../actionTypes";

export const setErrorMessage = (message) => ({
	type: SET_ERROR_MESSAGE,
	payload: { message }
});

export const setBlockedMessage = (message) => ({
	type: SET_BLOCKED_MESSAGE,
	payload: { message }
});

export const setVideoLayout = (layout) => ({
	type: SET_VIDEO_LAYOUT,
	payload: { layout }
});

export const setVideoMinimized = (minimized) => ({
	type: SET_VIDEO_MINIMIZED,
	payload: { minimized }
});

export const setLiveInfoEvent = (event) => {
	return {
		type: SET_LIVE_INFO_EVENT,
		payload: { event }
	};
};

export const updateLiveInfoEvent = (event) => ({
	type: UPDATE_LIVE_INFO_EVENT,
	payload: { event }
});

export const setShowLiveInfoEvent = (show) => ({
	type: SET_SHOW_LIVE_INFO_EVENT,
	payload: { show }
});

export const setEventPostions = (positions) => ({
	type: SET_LIVE_INFO_POSITIONS,
	payload: { positions }
});

export const setConnectionState = (state) => ({
	type: SET_CONNECTION_STATE,
	payload: { state }
});

export const setCurrentTime = () => ({
	type: SET_CURRENT_TIME
});

export const setCollapsed = (collapsed) => ({
	type: SET_COLLAPSED,
	payload: { collapsed }
});

const setGameRule = (gameType, data) => ({
	type: SET_GAME_RULE,
	payload: { gameType, data }
});

const setGameRuleBefore = () => ({
	type: GAME_RULE_BEFORE
});

const setGameRuleAfter = () => ({
	type: GAME_RULE_AFTER
});

export const setShowRules = (showRules) => ({
	type: SET_SHOW_RULES,
	payload: { showRules }
});

export const clearRules = () => ({
	type: CLEAR_RULES
});

export const getGameRule = (gameType) => {
	return (dispatch) => {
		dispatch(setGameRuleBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_GAME_RULE}`,
			method: Methods.GET,
			params: {
				gameType
			}
		})
			.then(({ data: { value: rules } }) => {
				dispatch(setGameRule(gameType, rules));
				dispatch(setGameRuleAfter());
			})
			.catch(() => {
				dispatch(setGameRuleAfter());
			});
	};
};
