import LocalStorageUtils from "utils/localStorage";

import { SHOW_FEEDBACK } from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case SHOW_FEEDBACK:
			return {
				...state,
				isVisible: !LocalStorageUtils.get(payload.storageKey)
			};
		default:
			return state;
	}
};
