import {
	SET_SEASON_SKELETON_HIDE,
	SET_SEASON_CURRENT_EVENT,
	SET_SEASON_SELECTED_MARKET,
	SET_SEASON_MARKETS,
	SET_SHOW_SEASON_MARKETS,
	SET_SEASON_STRUCTURE,
	SET_SEASON_STANDINGS,
	CLEAR_SEASON_STRUCTURE_STANDINGS,
	SET_SEASON_RESULT_MODAL_MOBILE,
	SET_SEASON_BEFORE,
	SET_SEASON_AFTER,
	SHOW_NEXT_LIVE_AND_UPCOMINGS
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case SET_SEASON_SKELETON_HIDE:
			return {
				...state,
				hideSkeleton: payload.hide
			};
		case SET_SEASON_CURRENT_EVENT:
			return {
				...state,
				current: payload.id
			};
		case SET_SEASON_SELECTED_MARKET:
			return {
				...state,
				selectedMarket: payload.market
			};
		case SET_SHOW_SEASON_MARKETS:
			return {
				...state,
				special: {
					...state.special,
					show: payload.show
				},
				current: null
			};
		case SET_SEASON_MARKETS:
			return {
				...state,
				special: {
					...state.special,
					...payload.data
				}
			};
		case SET_SEASON_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case SET_SEASON_AFTER:
			return {
				...state,
				isLoading: false
			};
		case SET_SEASON_STRUCTURE:
			return {
				...state,
				structure: payload.data
			};
		case SET_SEASON_STANDINGS:
			return {
				...state,
				standings: payload.data
			};
		case CLEAR_SEASON_STRUCTURE_STANDINGS:
			return {
				...state,
				structure: {},
				standings: []
			};
		case SET_SEASON_RESULT_MODAL_MOBILE:
			return {
				...state,
				showSeasonResultModalBySeasonId: payload.id
			};
		case SHOW_NEXT_LIVE_AND_UPCOMINGS:
			return {
				...state,
				showNextLiveAndUpcomings: payload.value
			};
		default:
			return state;
	}
};
