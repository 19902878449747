import { filterFnUpdateMatchBets, mapperFnUpdateMatchBets } from "utils/bets";
import {
	SET_RIGHTBAR_TAB,
	SET_SHOW_KEYBOARD,
	SET_BETSLIP_MODE,
	SET_BETSLIP_STAKE_MODE,
	ADD_BET,
	REMOVE_BET,
	REMOVE_MATCH_BET,
	UPDATE_MATCH_BETS,
	CLEAR_BETS,
	CLEAR_HIDDEN_BETS,
	UPDATE_BET_STAKE,
	SET_STAKE,
	SET_BETSLIP,
	PLACE_BET_BEFORE,
	PLACE_BET_AFTER,
	PLACE_BET_SUCCESS,
	SET_BETSLIP_CANCELING_BEFORE,
	SET_BETSLIP_CANCELING_AFTER,
	ADD_PENDING,
	UPDATE_PENDING,
	UPDATE_PENDING_BET,
	SET_PENDINGS,
	REMOVE_PENDING,
	PENDING_BEFORE,
	PENDING_AFTER,
	ADD_BETSLIP_RESULT,
	CLEAR_BETSLIP_RESULTS,
	SHOW_MOBILE_BETSLIPS_MODAL
} from "../../actionTypes";

import { GAME_STATUSES } from "constants/game.constants";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case SET_RIGHTBAR_TAB:
			return {
				...state,
				rightbarTab: payload.tab
			}
		case SET_SHOW_KEYBOARD:
			return {
				...state,
				showKeyboard: payload.show
			}	
		case SET_BETSLIP_MODE:
			return {
				...state,
				mode: payload.mode
			};
		case SET_BETSLIP_STAKE_MODE:
			return {
				...state,
				stakeMode: payload.mode
			};
		case ADD_BET:
			return {
				...state,
				success: false,
				bets: [{ ...payload.bet, isExpired: false }, ...state.bets]
			};
		case REMOVE_BET:
			const updated_after_remove = state.bets.filter((bet) => (
				(payload.betId && bet.betId !== payload.betId) ||
				(payload.eventId && bet.eventId !== payload.eventId) ||
				(payload.key && bet.key !== payload.key)
			));

			return {
				...state,
				bets: updated_after_remove,
				stake: updated_after_remove.length > 0 || !payload.removeStake ? state.stake : ""
			};
		case REMOVE_MATCH_BET:
			const updated = state.bets.filter((b) => !payload.ids.includes(b.eventId));
			return {
				...state,
				bets: updated,
				stake: updated.length > 0 ? state.stake : ""
			};
		case UPDATE_MATCH_BETS:
			if (state.bets.length === 0) {
				return state;
			}
			const isFinished_updateMatchBets = payload.event.status === GAME_STATUSES.FINISHED;
			const isExpired_updateMatchBets = payload.event.status === GAME_STATUSES.STARTED || payload.event.status === GAME_STATUSES.CLOSE_FOR_BETTING;

			if (!isFinished_updateMatchBets && !isExpired_updateMatchBets) {
				return state;
			}

			const mapped_updateMatchBets = mapperFnUpdateMatchBets(isExpired_updateMatchBets, filterFnUpdateMatchBets(isFinished_updateMatchBets, state, payload), payload);
			if (state.bets === mapped_updateMatchBets) {
				return state;
			}
			return {
				...state,
				bets: mapped_updateMatchBets
			};
		case CLEAR_BETS:
			return {
				...state,
				bets: []
			};
		case CLEAR_HIDDEN_BETS:
			return {
				...state,
				bets: state.bets.filter((b) => !b.hidden)
			};
		case UPDATE_BET_STAKE:
			return {
				...state,
				bets: state.bets.map((b) => (b.key !== payload.key ? b : { ...b, stake: payload.stake }))
			};
		case SET_STAKE:
			return {
				...state,
				stake: payload.stake
			};
		case SET_BETSLIP:
			const newData = {};
			if (payload.betslip.stake !== undefined) {
				newData.stake = payload.betslip.stake;
			}
			if (payload.betslip.stakeMode !== undefined) {
				newData.stakeMode = payload.betslip.stakeMode;
			}
			if (payload.betslip.mode !== undefined) {
				newData.mode = payload.betslip.mode;
			}
			if (payload.betslip.bets !== undefined) {
				newData.bets = payload.betslip.bets;
			}
			return {
				...state,
				...newData
			};
		case SET_BETSLIP_CANCELING_BEFORE:
			return {
				...state,
				isCanceling: true
			};
		case SET_BETSLIP_CANCELING_AFTER:
			return {
				...state,
				isCanceling: false
			};
		case PLACE_BET_BEFORE:
			return {
				...state,
				loading: true
			};
		case PLACE_BET_AFTER:
			return {
				...state,
				loading: false
			};
		case PLACE_BET_SUCCESS:
			return {
				...state,
				success: payload.success
			};
		case PENDING_BEFORE:
			return {
				...state,
				pendingLoading: true
			};
		case PENDING_AFTER:
			return {
				...state,
				pendingLoading: false
			};
		case ADD_PENDING:
			return {
				...state,
				pendings: [payload.pending, ...state.pendings]
			};
		case SET_PENDINGS:
			return {
				...state,
				pendings: payload.pendings
			};
		case REMOVE_PENDING:
			return {
				...state,
				pendings: state.pendings.filter((p) => p.id !== payload.pending.id)
			};
		case UPDATE_PENDING:
			return {
				...state,
				pendings: state.pendings.map((p) => (p.id !== payload.pending.id ? p : payload.pending))
			};
		case UPDATE_PENDING_BET:
			return {
				...state,
				pendings: state.pendings.map((p) => ({ ...p, bets: p.bets.map((b) => (b.id === payload.bet.id ? payload.bet : b)) }))
			};
		case ADD_BETSLIP_RESULT:
			return {
				...state,
				betslipLastResults: [...payload.data, ...state.betslipLastResults]
			};
		case CLEAR_BETSLIP_RESULTS:
			return {
				...state,
				betslipLastResults: []
			};
		case SHOW_MOBILE_BETSLIPS_MODAL:
			return {
				...state,
				showMobileBetslipsModal: payload.value
			};
		default:
			return state;
	}
};
