import { BONUS_HISTORY_BEFORE, BONUS_HISTORY_AFTER, SET_BONUS_HISTORY, ADD_BONUS_HISTORY, SET_BONUS_HISTORY_FILTERS, SET_USE_BONUS } from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case BONUS_HISTORY_BEFORE:
			return {
				...state,
				loading: true
			};
		case BONUS_HISTORY_AFTER:
			return {
				...state,
				loading: false
			};
		case SET_BONUS_HISTORY:
			return {
				...state,
				history: payload.data.item2,
				total: payload.data.item1
			};
		case ADD_BONUS_HISTORY:
			return {
				...state,
				history: [...state.history, ...payload.data.item2],
				total: payload.data.item1
			};
		case SET_BONUS_HISTORY_FILTERS:
			return {
				...state,
				filters: {
					...state.filters,
					...payload.filters
				}
			};
		case SET_USE_BONUS:
			return {
				...state,
				useBonus: payload
			};
		default:
			return state;
	}
};
