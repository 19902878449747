import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Input from "components/ui/input";

import { setStake, setShowKeyboard } from "store/actions/betslip/betslip.actions";

import {
	toFixed,
	validateForAmount,
	numberWithSpaces,
	makeCurrencyText,
	isThereAtLeastOneKenoBet,
	isFactorsResultOverLimited,
	mergeClassNames,
	isRTL
} from "utils/common";
import { getCurrentSettings } from "utils/settings";

import { BETSLIP_MODES, BETSLIP_KEEP_FOCUSED_STAKE_DATA_ATTRS, MULTI_MODE_BETS_MAX_FACTORS } from "constants/betslip.constants";
import { BONUS_TYPE, BONUS_WIN_TYPE } from "constants/bonus.constants";

import useGlobalVariables from "hooks/useGlobalVariables";
import useComboBoost from "hooks/useComboBoost";

const getIsDemoSession = state => state.auth.session.isDemo;
const getIsAuthorized = state => state.auth.session.isAuthorized;
const getSessionCurrency = state => state.auth.session.currency ?? {};
const getSessionBonuses = state => state.auth.session.bonuses ?? [];
const getIsSessionUpdating = state => state.auth.isSessionUpdating;
const getIsQuickBetEnabled = (state) => getCurrentSettings(state.auth.session).isQuickBet;
const getQuickBetAmount = (state) => getCurrentSettings(state.auth.session).quickBetAmount;
const getIsRTLLanguage = (state) => isRTL(state.auth.session.languageId);
const getUseBonus = state => state.bonus.useBonus;
const getStake = state => state.betslip.stake;
const getBets = state => state.betslip.bets;
const getMode = state => state.betslip.mode;

const StakeInput = ({
	disabled = false, 
	showKeyboard = false,
	focusedStakeId = null,
	setFocusedStakeId = Function.prototype,
	checkIsThereFreeAmountBonusError = Function.prototype,
	hasError = Function.prototype
}) => {
	const { t } = useTranslation();
	const { isMobile } = useGlobalVariables();
	const isQuickBetEnabled = useSelector(getIsQuickBetEnabled);
	const isRTLLanguage = useSelector(getIsRTLLanguage);
	const quickBetAmount = useSelector(getQuickBetAmount);
	const isDemo = useSelector(getIsDemoSession);
	const isAuthorized = useSelector(getIsAuthorized);
	const currency = useSelector(getSessionCurrency);
	const bonuses = useSelector(getSessionBonuses);
	const useBonus = useSelector(getUseBonus);
	const stake = useSelector(getStake);
	const bets = useSelector(getBets);
	const mode = useSelector(getMode);
	const isSessionUpdating = useSelector(getIsSessionUpdating);

	const dispatch = useDispatch();

	const comboBoostInfo = useComboBoost()

	const currentBonus = useBonus && (bonuses?.[0] ?? {});
	const isFreeAmountBonus = useBonus && currentBonus.bonusType === BONUS_TYPE.FREEAMOUNT;
	
	const isRealMode = isAuthorized && !isDemo;

	// true if Mobile view, or true if user can't change stake
	const readOnly = isMobile || (useBonus && !isFreeAmountBonus);
	const isQuickBetStakeDisabled = isQuickBetEnabled ? isDemo : false;

	const numberWithSpacesConfig = isRTLLanguage ? { reverse: false, separator: "" } : undefined;

	const minLimit = (mode === BETSLIP_MODES.SINGLE ? (currency.singleMin ?? 0) : (currency.multiMin ?? 0));
	const maxLimit = (mode === BETSLIP_MODES.SINGLE ? (currency.singleMax ?? Infinity) : (currency.multiMax ?? Infinity));
	const maxPossibleWin = (mode === BETSLIP_MODES.SINGLE ? (currency.singlePossibleWinMax ?? Infinity) : (currency.multiPossibleWinMax ?? Infinity));

	const activeBets = bets.filter((bet) => !bet.isExpired);

	const isThereError = !isSessionUpdating && hasError();

	const calculatePossibleWin = () => {
		let possibleWin = 0;
		if (mode === BETSLIP_MODES.SINGLE) {
			possibleWin = activeBets.reduce((total, bet) => total + Number(bet.stake) * bet.factor - (useBonus && currentBonus.winType === BONUS_WIN_TYPE.PURE ? bet.stake : 0), 0);
		} else if (mode === BETSLIP_MODES.MULTI) {
			possibleWin = activeBets.reduce((total, bet) => total * bet.factor, 1) * stake - (useBonus ? stake : 0);
			if (comboBoostInfo.isComboBoostActive) {
				const boostFactor = comboBoostInfo.getCurrentBoostFactor();
				if (boostFactor > 0) {
					const boostPossibleWin = comboBoostInfo.calculatePossibleWin();
					const amount = boostFactor * boostPossibleWin / 100;
					possibleWin += amount;
				}
			}
		}
		return toFixed(possibleWin, currency.decimalCount ?? 2);
	};

	const onChange = (e) => {
		const value = e.target.value.replace(/\s/g, "");
		if (validateForAmount(value, currency.decimalCount ?? 0)){
			dispatch(setStake(value));
		};
	};

	const renderPossibleWinAmount = () => {
		const possibleWinAmount = calculatePossibleWin()
		return numberWithSpaces(
			toFixed(
				window.isNaN(possibleWinAmount) ? 0 : possibleWinAmount,
				currency.decimalCount
			)
		)
	};

	useEffect(() => {
		if (isRealMode && isQuickBetEnabled && !useBonus) {
			dispatch(setStake(quickBetAmount));
		}
	}, [isRealMode, isQuickBetEnabled, quickBetAmount, useBonus]);

	return (
		<>
			{(focusedStakeId === null || !isMobile) && (
				<div className="vs--flex vs--justify-between">
					<Input
						wrapperProps={
							{
								[BETSLIP_KEEP_FOCUSED_STAKE_DATA_ATTRS.BETSLIP_STAKE]: true
							}
						}
						id="vs--betslip-stake-id"
						className={
							mergeClassNames(
								"vs--betslip-stake-input vs--mt-4",
								isQuickBetEnabled && "vs--betslip-stake-input-quick-bet",
								isThereError && "vs--stake-input-error",
								showKeyboard && "vs--stake-input-with-keyboard",
								(readOnly || disabled) && "vs--stake-input-disabled"
							)
						}
						placeholder={`${t('common.min')} ${numberWithSpaces(Number(minLimit), numberWithSpacesConfig)} - ${t('common.max')} ${numberWithSpaces(Number(maxLimit), numberWithSpacesConfig)}`}
						readOnly={readOnly}
						disabled={disabled || ((useBonus && !isFreeAmountBonus) || isQuickBetStakeDisabled)}
						value={numberWithSpaces(stake)}
						onChange={isMobile ? undefined : onChange}
						onFocus={() => setFocusedStakeId(null)}
						onClick={
							isMobile ? () => {
								if(!disabled) {
									dispatch(setShowKeyboard(!(useBonus && !isFreeAmountBonus) && !isQuickBetStakeDisabled))
								}
							} : undefined
						}
						prefix={isQuickBetEnabled && quickBetAmount === Number(stake) ? () => <i className="ic_check vs--title-green" /> : null}
						suffix={useBonus && (
							<i className="ic_bonus vs--stake-input-bonus-icon vs--stake-input-bonus-icon-right" />
						)}
					/>
					{!isQuickBetEnabled && (
						<div className="vs--betslip-stake-possible-win vs--flex vs--flex-col vs--justify-center vs--align-end">
							<span className="vs--betslip-stake-possible-win-text vs--title-white vs--font-regular vs--font-normal">
								{
									useBonus
										? `${t("bonus.bonus")} ${t("bet.possibleWin")}:`
										: `${t("bet.possibleWin")}:`
								}
							</span>
							<div
								className={
									mergeClassNames(
										"vs--flex vs--justify-end vs--align-center vs--font-small",
										(comboBoostInfo.isComboBoostActive && comboBoostInfo.getCurrentBoostFactor() > 0) ? "vs--bet-slip-active-boost-color" : "vs--title-brand"
									)
								}
							>
								<b className="vs--font-medium vs--text-ltr">
									{renderPossibleWinAmount()}
								</b>
								<b className="vs--font-medium vs--pl-4">
									{currency.code}
								</b>
							</div>
						</div>
					)}
				</div>
			)}

			{
				isThereError
					? (
						<div className="vs--stake-error vs--flex vs--flex-row vs--align-center vs--pl-4 vs--pr-4 vs--pt-4">
							{
								Number(stake) < minLimit && ((stake !== "" && mode === BETSLIP_MODES.MULTI) || isQuickBetEnabled)
									? (
										<span className="vs--title-red vs--font-regular vs--font-small">
											{t("bet.minBetAmountIs")}{" "}
											<span className="vs--font-regular vs--font-small vs--text-ltr">
												{makeCurrencyText(minLimit, currency)}
											</span>
										</span>
									) : Number(stake) > maxLimit && ((stake !== "" && mode === BETSLIP_MODES.MULTI) || isQuickBetEnabled)
										? (
											<span className="vs--title-red vs--font-regular vs--font-small">
												{t("bet.maxBetAmountIs")}{" "}
												<span className="vs--font-regular vs--font-small vs--text-ltr">
													{makeCurrencyText(maxLimit, currency)}
												</span>
											</span>
										)
										: isFreeAmountBonus && checkIsThereFreeAmountBonusError()
											? (
												<div className="vs--single-bet-stake-text vs--flex vs--flex-col vs--justify-center vs--text-right vs--single-bet-stake-error">
													<span className="vs--title-red vs--font-regular vs--font-small vs--pb-4">
														{t("bet.unallowedStack")}
													</span>
												</div>
											)
											: mode === BETSLIP_MODES.MULTI && isFactorsResultOverLimited(bets)
												? (
													<span className="vs--title-red vs--font-regular vs--font-small">
														{`${t("bet.maxFactorIs")} `}
														<span className="vs--title-red vs--font-regular vs--font-small vs--text-ltr">
															{isThereAtLeastOneKenoBet(bets)
																? MULTI_MODE_BETS_MAX_FACTORS.WITH_KENO_BET
																: MULTI_MODE_BETS_MAX_FACTORS.WITHOUT_KENO_BET}
														</span>
													</span>
												)
												: (
													<span className="vs--title-red vs--font-regular vs--font-small">
														{t("bet.maxPosWinAmountIs")}{" "}
														<span>
															{makeCurrencyText(maxPossibleWin, currency)}
														</span>
													</span>
												)
							}
						</div>
					)
					: null
			}
		</>
	)
};

export default StakeInput;