import i18n from "i18next";
import store from "store/configureStore";
import { setErrorMessage, setBlockedMessage } from "store/actions/common/common.actions";
import { ERROR_MESSAGE, ERROR_RESOURCE, ERROR_STATUSES } from "constants/message.constants";
import vsToast from "./toast";

const parseResourceText = (status, resource) => {
	let text = i18n.t(`errors.message.${status}`);
	let res = (
		resource && resource !== ERROR_RESOURCE.NONE
			? i18n.t(`errors.resources.${resource}`)
			: ""
	);

	if (text) {
		text = text.replace("%resource%", res);
	}
	return text || "";
}

const statusNotAllowedErrors = (resource, message) => {
	if ([ERROR_RESOURCE.PLAYER, ERROR_RESOURCE.PLAYER_GEO_LOCATION].includes(resource)) {
		return store.dispatch(setBlockedMessage("errors.message.Blocked"));
	}

	if (resource === ERROR_RESOURCE.SESSION && [ERROR_MESSAGE.NOT_SUPPORTED_CURRENCY, ERROR_MESSAGE.INVALID_PLAYER_CURRENCY].includes(message)) {
		return store.dispatch(setBlockedMessage("errors.message.CurencyBlocked"));
	}

	if (resource === ERROR_RESOURCE.PLAYER_DEVICE && message === ERROR_MESSAGE.BLOCKED) {
		return store.dispatch(setBlockedMessage("errors.message.DeviceBlocked"));
	}

	if (resource === ERROR_RESOURCE.PLAYER_BROWSER && message === ERROR_MESSAGE.BLOCKED) {
		return store.dispatch(setBlockedMessage("errors.message.DeviceNotSupported"));
	}

	if (resource === ERROR_RESOURCE.EVENT && message === ERROR_MESSAGE.IMPERMISSIBLE_EVENT_STATE) {
		return vsToast.error(message);
	}

	return vsToast.error(message);
}

const statusInvalidParametersErrors = (resource, message) => {
	if (resource === ERROR_RESOURCE.PLAYER_GEO_LOCATION) {
		return store.dispatch(setBlockedMessage("errors.message.Blocked"));
	}

	if (resource === ERROR_RESOURCE.PLAYER_SETTINGS && message === ERROR_MESSAGE.PLAYER_SETTINGS_INVALID_PARAMETER) {
		return vsToast.error(i18n.t(`errors.message.InvalidParameters`).replace("%resource%", i18n.t(`errors.resources.${ERROR_RESOURCE.PLAYER_SETTINGS}`)))
	}

	if (resource === ERROR_RESOURCE.CUSTOM && typeof message === "string") {
		try {
			const parsedMsg = JSON.parse(message);
			const { messageKey, parameters: { minAmount, maxAmount } } = parsedMsg;
			vsToast.error(i18n.t(`errors.message.${messageKey}`).replace("%minAmount%", minAmount).replace("%maxAmount%", maxAmount));
		} catch (error) {
			console.error(error);
		}
		return;
	}

	let text = parseResourceText(ERROR_STATUSES.INVALID_PARAMETERS, resource)

	if (resource === ERROR_RESOURCE.OPERATOR) {
		if (message === "Player does not have enough funds to process an action.") {
			text = i18n.t("errors.message.NotEnoughBalance");
		} else {
			text = i18n.t(`errors.message.${message}`);
		}
		return store.dispatch(setErrorMessage(text));
	}

	if (resource !== ERROR_RESOURCE.BET_SLIP_BET) {
		text += (message ?? "")
	}
	
	return vsToast.error(text);
}

/** Show error notification
 * @function
 * @param {object} showErrorConfig
 * @param {string} showErrorConfig.message - error message
 * @param {string} showErrorConfig.status - error status
 * @param {string} showErrorConfig.resource - error resource
 */

export const showError = ({ message, status, resource }) => {
	switch (status) {
		case ERROR_STATUSES.NOT_ALLOWED:
			return statusNotAllowedErrors(resource, message);
		case ERROR_STATUSES.INVALID_PARAMETERS:
			return statusInvalidParametersErrors(resource, message);
		default:
			break;
	}
	if (message === ERROR_MESSAGE.INVALID_PLAYED_DATA) {
		return store.dispatch(setBlockedMessage("errors.message.Blocked"));
	}
	const errorText = status ? parseResourceText(status, resource) : message;
	return vsToast.error(errorText);
};
