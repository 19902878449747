import { useCallback, useEffect } from "react";
import "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { setSeasonStatisticsId, getSeasonStatistics, resetStatisticsState } from "store/actions/statistics/statistics.actions";

const LeagueGamesLiveSeasonHoc = (ReactComponent) => {
	const getSeasonId = state => state.game.liveAndUpcomings?.data?.[0]?.seasonId;
	const getCurrentGameTypeState = state => state.game?.currentGameType;
	const getStandingsState = state => state.statistics.season?.standings ?? [];
	const getLoadingState = state => state.statistics.season?.loading ?? false;

	const WrappedReactComponentOfLeagueGamesLiveSeasonHoc = (props) => {
		const seasonId = useSelector(getSeasonId);
		const currentGameType = useSelector(getCurrentGameTypeState);
		const standings = useSelector(getStandingsState);
		const loading = useSelector(getLoadingState);

		const dispatch = useDispatch();
		const setStatisticsId = useCallback((id) => dispatch(setSeasonStatisticsId(id)), [dispatch])
		const getStandings = useCallback((gameType) => dispatch(getSeasonStatistics(gameType)), [dispatch])
		const resetStandings = useCallback(() => dispatch(resetStatisticsState()), [dispatch])

		useEffect(() => {
			setStatisticsId(seasonId);
			return () => {
				setStatisticsId(null);
			};
		}, [seasonId, setStatisticsId]);

		useEffect(() => {
			getStandings(currentGameType);
			return () => {
				resetStandings();
			}
		}, [currentGameType, getStandings, resetStandings])

		return (
			<ReactComponent
				standings={standings}
				gameType={currentGameType}
				loading={loading}
				isFromResults={false}
				{...props}
			/>
		);
	}

	return WrappedReactComponentOfLeagueGamesLiveSeasonHoc;
};

export default LeagueGamesLiveSeasonHoc;
