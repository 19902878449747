import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { SET_HISTORY_FILTERS, RESET_HISTORY_FILTERS, HISTORY_BEFORE, HISTORY_AFTER, SET_HISTORY, ADD_HISTORY, RESET_HISTORY, UPDATE_HISTORY_BET_SLIP_BET, UPDATE_HISTORY_BET_SLIP, SET_HISTORY_OPENED_BET_SLIP_ID } from "../../actionTypes";

export const setHistoryFilters = (filters) => ({
	type: SET_HISTORY_FILTERS,
	payload: { filters }
});

export const resetHistoryFilters = () => ({
	type: RESET_HISTORY_FILTERS
});

const setHistoryBefore = () => ({
	type: HISTORY_BEFORE
});

const setHistoryAfter = () => ({
	type: HISTORY_AFTER
});

export const setHistory = (data) => ({
	type: SET_HISTORY,
	payload: { data }
});

const addHistory = (data) => ({
	type: ADD_HISTORY,
	payload: { data }
});

export const resetHistory = () => ({
	type: RESET_HISTORY
});

export const setHistoryOpenedBetSlipId = (id) => ({
	type: SET_HISTORY_OPENED_BET_SLIP_ID,
	payload: { id }
});

export const getHistory = (action, betslipId) => {
	return (dispatch, getState) => {
		if (action !== "add") {
			dispatch(setHistoryBefore());
		}

		const filters = { ...getState().history.filters };
		if (betslipId) {
			filters.betSlipId = betslipId;
		}

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_HISTORY}`,
			method: Methods.GET,
			params: filters
		})
			.then(({ data: { value: data } }) => {
				action !== "add" ? dispatch(setHistory(data)) : dispatch(addHistory(data));
				if (action !== "add") {
					dispatch(setHistoryAfter());
				}
			})
			.catch(() => {
				if (action !== "add") {
					dispatch(setHistoryAfter());
				}
			});
	};
};

export const updateHistoryBetSlipBet = (bet) => ({
	type: UPDATE_HISTORY_BET_SLIP_BET,
	payload: { bet }
});

export const updateHistoryBetSlip = (bet) => ({
	type: UPDATE_HISTORY_BET_SLIP,
	payload: { bet }
});
