import { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import BetSlip from "../../rightBar/partials/betslip";
import RightBarTabs from "../../rightBar/partials/tabs";
import PendingBets from "../../rightBar/partials/pendingBets";
import MobileHeader from "components/mobileHeader";

import { getUrlVars, mergeClassNames } from "utils/common";
import useGlobalVariables from "hooks/useGlobalVariables";

/** Mobile Bet Slip Component */
const MobileBetSlip = ({ rightbarTab }) => {
	const { isMobile, isBuilderIntegration } = useGlobalVariables();
	return (
		<div className={mergeClassNames("vs--betslip-mobile vs--flex vs--flex-col", isMobile && "vs--overflow-x-hidden")}>
			{!isBuilderIntegration && <MobileHeader />}
			<RightBarTabs />
			{rightbarTab === 1 ? <BetSlip /> : <PendingBets />}
		</div>
	);
};

/** MobileBetSlip propTypes
 * PropTypes
 */
MobileBetSlip.propTypes = {
	/** Redux state property, selected tab(betslip/pendings) */
	rightbarTab: PropTypes.oneOf([1, 2])
};

const mapStateToProps = (state) => {
	return {
		rightbarTab: state.betslip.rightbarTab
	};
};

export default connect(mapStateToProps, null)(MobileBetSlip);
