import { Fragment, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { createSession } from "store/actions/auth/auth.actions";
import Loader from "components/ui/loader";
import ErrorMessage from "components/ui/errorMessage";
import BlockedMessage from "components/ui/blockedMessage";
import Maintenance from "components/ui/maintenance";
import MobileHeader from "components/mobileHeader";
import ConditionalStyles from "components/ui/conditionalStyles";
import useGlobalVariables from "hooks/useGlobalVariables";
import Paths from "constants/path.constants";
import sessionType from "types/session.type";

const RootLayout = ({ session, sessionLoaded, sessionFailed, errorMessage, blockedMessage, translationsLoaded, createSession }) => {
	const globalVariables = useGlobalVariables();
	const variableRef = useRef();
	variableRef.current = globalVariables;
	const { isMobile, isBuilderIntegration } = globalVariables;

	/** Create sesion on application start */
	useEffect(() => {
		const isStream = window.location.pathname === Paths.STREAM;
		createSession(isStream, variableRef.current);
	}, []);

	switch (true) {
		case sessionFailed && translationsLoaded:
			return (
				<Fragment>
					<ConditionalStyles />
					{blockedMessage ? <BlockedMessage title="common.gamesAreBlocked" message={blockedMessage} /> : null}
					{errorMessage ? <ErrorMessage /> : null}
					{session?.maintenanceMode ? (
						<Fragment>
							{isMobile && !isBuilderIntegration ? <MobileHeader /> : null}
							<Maintenance />
						</Fragment>
					) : null}
				</Fragment>
			);
		case !sessionLoaded:
			return (
				<Fragment>
					{errorMessage ? <ErrorMessage /> : null}
					<Loader full={true} />
				</Fragment>
			);
		case sessionLoaded && !sessionFailed:
			return (
				<Fragment>
					<ConditionalStyles />
					{errorMessage ? <ErrorMessage /> : null}
					<Outlet />
				</Fragment>
			);
		default:
			return <Fragment />;
	}
};

RootLayout.propTypes = {
	/** Redux state property, current session */
	session: sessionType,
	/** Redux state property, is true when session loaded */
	sessionLoaded: PropTypes.bool,
	/** Redux state property, is true when session failed */
	sessionFailed: PropTypes.bool,
	/** Redux state property, current error message to show */
	errorMessage: PropTypes.string,
	/** Redux state property, current blocked message to show */
	blockedMessage: PropTypes.string,
	/** Redux state property, is true when translations are loaded */
	translationsLoaded: PropTypes.bool,
	/** Redux action to create session */
	createSession: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		sessionLoaded: state.auth.sessionLoaded,
		sessionFailed: state.auth.sessionFailed,
		session: state.auth.session,
		errorMessage: state.common.errorMessage,
		blockedMessage: state.common.blockedMessage,
		translationsLoaded: state.auth.translationsLoaded
	};
};

const mapDispatchToProps = (dispatch) => ({
	createSession: (isStream, options) => {
		dispatch(createSession(isStream, options));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(RootLayout);
