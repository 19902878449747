import moment from "moment";
import { BETSLIP_MODES, BETSLIP_STAKE_MODES, COMBO_BOOST_STEP_FORM_NAMES, COMBO_BOOST_STEPS } from "constants/betslip.constants";
import { CONNECTION_STATE } from "constants/common.constants";

import { getDefaultDate } from "utils/dateTime";

import { VIDEO_LAYOUT } from "constants/common.constants";
import { BONUS_STATES } from "constants/bonus.constants";
import { GAME_TYPE, SELECTED_SEASON_GAMES } from "../constants/game.constants";

import LocalStorageUtils from "utils/localStorage";
import { getDefaultLiveInfoPositions } from "utils/common";

export default {
	common: {
		errorMessage: "",
		blockedMessage: "",
		videoLayout: VIDEO_LAYOUT.NORMAL,
		videoMinimized: true,
		liveInfoEvent: null,
		liveInfoPositions: getDefaultLiveInfoPositions(),
		showLiveInfoEvent: false,
		connectionState: CONNECTION_STATE.NORMAL,
		currentTime: moment().unix(),
		collapsed: LocalStorageUtils.get("vs__topBar_collapsed") || false,
		rules: {
			isLoading: false,
			showRules: false,
			current: {},
			prev: {}
		}
	},
	auth: {
		sessionLoaded: false,
		sessionFailed: false,
		isSessionUpdating: false,
		session: {
			games: [],
			settings: {},
			bonuses: [],
			surveys: [],
			comboBoostSettings: {
				isComboBoostEnabled: false,
				minOddFactor: 0,
				boostFactors: {
					[COMBO_BOOST_STEP_FORM_NAMES[COMBO_BOOST_STEPS.FOUR_BETS]]: 0,
					[COMBO_BOOST_STEP_FORM_NAMES[COMBO_BOOST_STEPS.FIVE_BETS]]: 0,
					[COMBO_BOOST_STEP_FORM_NAMES[COMBO_BOOST_STEPS.SIX_BETS]]: 0,
					[COMBO_BOOST_STEP_FORM_NAMES[COMBO_BOOST_STEPS.SEVEN_BETS]]: 0,
					[COMBO_BOOST_STEP_FORM_NAMES[COMBO_BOOST_STEPS.EIGHT_BETS]]: 0,
					[COMBO_BOOST_STEP_FORM_NAMES[COMBO_BOOST_STEPS.NINE_BETS]]: 0,
					[COMBO_BOOST_STEP_FORM_NAMES[COMBO_BOOST_STEPS.TEN_BETS]]: 0
				}
			}
		},
		player: {},
		sessionId: null,
		switchingToRealMode: false,
		translationsLoaded: false
	},
	game: {
		isBonusBetDisabled: false,
		currentGameType: null,
		liveAndUpcomings: {
			loading: false,
			data: []
		},
		lastResults: {
			loading: false,
			data: []
		},
		results: {
			loading: false,
			data: [],
			filters: {
				limit: 20,
				page: 1,
				...getDefaultDate(),
				id: ""
			},
			total: 0
		},
		seasonResult: {
			seasonId: null,
			loading: false,
			data: {},
			filters: {
				id: ""
			},
			total: 0
		},
		matches: {
			loading: false,
			data: {}
		},
		current: null,
		gameCountDown: {},
		marketsActiveKeys: {}
	},
	season: {
		current: null,
		selectedMarket: "Winner3Way",
		special: {
			markets: {
				[SELECTED_SEASON_GAMES.NONE]: [],
				[SELECTED_SEASON_GAMES.CURRENT]: [],
				[SELECTED_SEASON_GAMES.NEXT]: []
			},
			show: SELECTED_SEASON_GAMES.NONE
		},
		structure: {},
		standings: [],
		showSeasonResultModalBySeasonId: null,
		isLoading: false,
		hideSkeleton: false,
		showNextLiveAndUpcomings: false
	},
	betslip: {
		rightbarTab: 1,
		mode: BETSLIP_MODES.SINGLE,
		stake: "",
		bets: [],
		stakeMode: BETSLIP_STAKE_MODES.PER_BET,
		showKeyboard: false,
		success: false,
		loading: false,
		isCanceling: false,
		pendings: [],
		pendingLoading: false,
		betslipLastResults: [],
		showMobileBetslipsModal: false
	},
	statistics: {
		loading: false,
		football: {
			team1: null,
			team2: null,
			countries: null,
			team1Statistics: null,
			team2Statistics: null,
			h2h: []
		},
		penalty: {
			team1: null,
			team2: null,
			countries: null,
			team1Statistics: null,
			team2Statistics: null,
			h2h: {}
		},
		racing: {
			participants: [],
			currentParticipant: null,
			statistics: null,
			currentEvent: null
		},
		season: {
			id: null,
			standings: [],
			structure: {},
			loading: false,
			isUpdateRequestSended: false,
			showSeasonLiveModal: null
		},
		quickStatistics: {
			state: {
				visible: false
			},
			loading: false,
			statistics: [],
			teamsData: null
		},
		kenoStatistics: []
	},
	history: {
		loaded: false,
		loading: false,
		data: [],
		filters: {
			limit: 20,
			page: 1,
			...getDefaultDate()
		},
		filtersReset: false,
		total: 0,
		openedBetSlipId: null
	},
	event: {
		loading: false,
		history: {}
	},
	bonus: {
		useBonus: false,
		history: [],
		loading: false,
		filters: {
			status: BONUS_STATES.ALL
		}
	},
	feedback: {
		isVisible: false
	},
	keno: {
		hoveredBalls: [],
		selectedBalls: [],
		activeBalls: [],
		lastAddedBalls: [],
		isCurrentBetsLoading: false,
		isLastBetsLoading: false,
		currentBets: [],
		lastBets: []
	}
};
