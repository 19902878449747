import { useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Modal from "components/ui/modal";
import Rules from "components/rules";

import { setShowRules } from "store/actions/common/common.actions";

/* rules Component in Header */
const RulesTab = ({ showRules, setShowRules }) => {
	const { t } = useTranslation();
	const coordinatesRef = useRef();

	return (
		<>
			<div
				className="vs--header-rules vs--flex vs--align-center vs--pl-16 vs--pr-16"
				onClick={(event) => {
					coordinatesRef.current = { clientX: event.clientX + "px", clientY: event.clientY + "px", topOffset: "60px" };
					setShowRules(true);
				}}
			>
				<span className="vs--header-rules-title vs--title-gray-50 vs--font-normal vs--font-regular ">{t("menu.rules")}</span>
			</div>
			<Modal coordinates={coordinatesRef.current} headerContent={t("menu.rules")} isOpen={showRules} onCancel={() => setShowRules(false)} classNames={{ content: "vs--modal-wrapper vs--modal-rules" }} onOutsideClick={Function.prototype}>
				<Rules />
			</Modal>
		</>
	);
};

RulesTab.propTypes = {
	/** Redux property, whether to show/hide rules */
	showRules: PropTypes.bool,
	/** Redux action, for showing/hiding rules */
	setShowRules: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		showRules: state.common.rules.showRules
	};
};

const mapDispatchToProps = (dispatch) => ({
	setShowRules: (flag) => {
		dispatch(setShowRules(flag));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(RulesTab);
