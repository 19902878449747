import { lazy, Fragment, Suspense, useLayoutEffect } from "react";
import { connect } from "react-redux";
import { isRTL } from "utils/common";
import sessionType from "types/session.type";

const RTL = lazy(() => import("./rtl"));
const LTR = lazy(() => import("./ltr"));

/** Component to include styles */
const ConditionalStyles = ({ session }) => {
	useLayoutEffect(() => {
		if (!session.languageId) {
			return;
		}

		if (isRTL(session.languageId)) {
			document.body.classList.remove("vs--ltr");
			document.body.classList.add("vs--rtl");
		} else {
			document.body.classList.remove("vs--rtl");
			document.body.classList.add("vs--ltr");
		}

		document.documentElement.setAttribute("lang", session.languageId);
	}, [session.languageId]);

	return <Suspense fallback={<Fragment />}>{session.languageId && isRTL(session.languageId) ? <RTL /> : <LTR />}</Suspense>;
};

/** ConditionalStyles propTypes
 * PropTypes
 */
ConditionalStyles.propTypes = {
	/** Redux state property, current session */
	session: sessionType
};

const mapStateToProps = (state) => {
	return {
		session: state.auth.session
	};
};

export default connect(mapStateToProps, null)(ConditionalStyles);
