import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setBetslipMode, setShowKeyboard } from "store/actions/betslip/betslip.actions";
import SingleBet from "./singleBet";
import MobileSingleBet from "./mobileSingleBet";
import BetSlipEmpty from "./empty";
import BetSlipSuccess from "./success";
import BetSlipTabs from "./betslipTabs";
import Stake from "./stake";
import ScrollBar from "components/ui/scrollBar";

import useGlobalVariables from "hooks/useGlobalVariables";
import useDocumentListener from "hooks/useDocumentListener";
import usePrevious from "hooks/usePrevious";

import { mergeClassNames } from "utils/common";
import { getEventsRepeatedBets } from "utils/bets";
import { getCurrentSettings } from "utils/settings";

import { BETSLIP_MODES, BETSLIP_KEEP_FOCUSED_STAKE_DATA_ATTRS } from "constants/betslip.constants";
import useBetSlipModeAutoChanger from "hooks/useBetSlipModeAutoChanger";

const getSession = state => state.auth.session;
const getBets = state => state.betslip.bets;
const getBetslipSuccess = state => state.betslip.success;
const getMode = state => state.betslip.mode;

/* Betslip Tab Component */
const BetSlip = () => {
	const [focusedStakeId, setFocusedStakeId] = useState(null);
	const { t } = useTranslation();
	const { isMobile } = useGlobalVariables();

	const session = useSelector(getSession);
	const bets = useSelector(getBets);
	const success = useSelector(getBetslipSuccess);
	const mode = useSelector(getMode);
	const isQuickBetEnabled = getCurrentSettings(session).isQuickBet;

	const scrollBarRef = useRef();
	const dispatch = useDispatch();

	const groupedRepeatedBets = useMemo(() => getEventsRepeatedBets(bets, mode), [bets, mode]);

	useDocumentListener('click', event => {
		if (event?.target) {
			const hasDataAttribute = (element, attr) => {
				return element.closest(`[${attr}]`) !== null;
			};

			const hasRelevantAttribute = Object.values(BETSLIP_KEEP_FOCUSED_STAKE_DATA_ATTRS).some(attr => hasDataAttribute(event.target, attr));

			if (!hasRelevantAttribute) {
				setFocusedStakeId(null);

				if (isMobile) {
					dispatch(setShowKeyboard(false));
				}
			}
		}
	});

	const { nonHidden } = useBetSlipModeAutoChanger()

	return (
		<Fragment>
			{nonHidden.length > 0 && !isQuickBetEnabled && <BetSlipTabs scrollBarRef={scrollBarRef} />}
			<div className="vs--rightBar-content" data-element-id="RightBarContent">
				<ScrollBar
					className="vs--scrollbar-thin vs--rightBar-scrollbar"
					vertical={true}
					ref={scrollBarRef}
				>
					{
						nonHidden.length > 0 && !isQuickBetEnabled
							? (
								<div className={mergeClassNames(
									"vs--betslip-content",
									isMobile ? "vs--mt-4" : "vs--pl-8 vs--pr-8 vs--pb-8 vs--pt-16"
								)}>
									{
										nonHidden.map((bet, ind) => {
											if (isMobile) {
												return (
													<div className="vs--betslip-content-card" key={bet.key}>
														<MobileSingleBet
															bet={bet}
															index={ind}
															groupedRepeatedBets={groupedRepeatedBets}
															focusedStakeId={focusedStakeId}
															setFocusedStakeId={setFocusedStakeId}
														/>
													</div>
												);
											}
											return (
												<div className="vs--betslip-content-card" key={bet.key}>
													<SingleBet
														bet={bet}
														index={ind}
														groupedRepeatedBets={groupedRepeatedBets}
														focusedStakeId={focusedStakeId}
														setFocusedStakeId={setFocusedStakeId}
													/>
												</div>
											);
										})
									}
								</div>
							)
							: success && !isMobile && !isQuickBetEnabled
								? (
									<BetSlipSuccess />
								)
								: (
									<BetSlipEmpty
										isQuickBetEnabled={isQuickBetEnabled}
										text={isQuickBetEnabled ? t("bet.quickBetText") : t("bet.betslipEmptyText")}
										subtext={isQuickBetEnabled ? t("bet.quickBetSubText") : t("bet.betslipEmptySubtext")}
									/>
								)
					}
				</ScrollBar>
				{(!isMobile && (nonHidden.length > 0 || isQuickBetEnabled)) && (
					<Stake
						groupedRepeatedBets={groupedRepeatedBets}
						focusedStakeId={focusedStakeId}
						setFocusedStakeId={setFocusedStakeId}
					/>
				)}
				{(isMobile && (nonHidden.length > 0 || isQuickBetEnabled)) && (
					<Stake
						groupedRepeatedBets={groupedRepeatedBets}
						focusedStakeId={focusedStakeId}
						setFocusedStakeId={setFocusedStakeId}
					/>
				)}
			</div>
		</Fragment>
	);
};

export default BetSlip;
