import { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Games from "components/topBar/games";
import { mergeClassNames } from "utils/common";
import LocalStorageUtils from "utils/localStorage";
import { setCollapsed } from "store/actions/common/common.actions";
import useGlobalVariables from "hooks/useGlobalVariables";
import TopBarMenuHoc from "hocs/topBar/topBarMenuHoc";
import eventType from "types/event.type";

const WrappedGames = TopBarMenuHoc(Games);

/* topBar Component */
const topBar = ({ collapsed, setCollapsed, liveAndUpcomings, isCurrentGameDeactivated }) => {
	const { isMobile } = useGlobalVariables();

	/** Save video layout value in local storage */
	useEffect(() => {
		LocalStorageUtils.set("vs__topBar_collapsed", collapsed);
	}, [collapsed]);

	return (
		<div className={mergeClassNames("vs--topBar", isMobile ? "vs--mt-4 vs--mb-4 vs--ns" : "vs--mb-8", !isMobile && liveAndUpcomings.length === 0 && isCurrentGameDeactivated && "vs--topBar-games-deactivated")}>
			<WrappedGames />
		</div>
	);
};

/** topBar propTypes
 * PropTypes
 */
topBar.propTypes = {
	/** Redux state property, is topBar collapsed */
	collapsed: PropTypes.bool,
	/** Redux action, to change topBar collapsed state */
	setCollapsed: PropTypes.func,
	/** Redux state property, lave and upcoming matches */
	liveAndUpcomings: PropTypes.arrayOf(eventType),
	/** Current game active flag */
	isCurrentGameDeactivated: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		collapsed: state.common.collapsed,
		liveAndUpcomings: state.game.liveAndUpcomings.data
	};
};

const mapDispatchToProps = (dispatch) => ({
	setCollapsed: (collapsed) => {
		dispatch(setCollapsed(collapsed));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(topBar);
