import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import {
	SET_KENO_SELECTED_BALLS,
	ADD_KENO_SELECTED_BALLS,
	REMOVE_KENO_SELECTED_BALLS,
	SET_KENO_ACTIVE_BALLS,
	SET_KENO_LAST_ADDED_BALLS,
	SET_KENO_HOVERED_BALLS,
	SET_KENO_LAST_BETS_BEFORE,
	SET_KENO_LAST_BETS_AFTER,
	SET_KENO_CURRENT_BETS_BEFORE,
	SET_KENO_CURRENT_BETS_AFTER,
	SET_KENO_CURRENT_BETS,
	UPDATE_KENO_CURRENT_BETS,
	SET_KENO_LAST_BETS
} from "../../actionTypes";

export const setKenoSelectedBalls = (balls) => ({
	type: SET_KENO_SELECTED_BALLS,
	payload: { balls }
});

export const addKenoSelectedBalls = (balls) => ({
	type: ADD_KENO_SELECTED_BALLS,
	payload: { balls }
});

export const removeKenoSelectedBalls = (balls) => ({
	type: REMOVE_KENO_SELECTED_BALLS,
	payload: { balls }
});

export const setKenoActiveBalls = (balls, add) => ({
	type: SET_KENO_ACTIVE_BALLS,
	payload: { balls, add }
});

export const setKenoLastAddedBalls = (balls) => ({
	type: SET_KENO_LAST_ADDED_BALLS,
	payload: { balls }
});

export const setKenoHoveredBalls = (balls) => ({
	type: SET_KENO_HOVERED_BALLS,
	payload: { balls }
});

const setKenoLastBetsBefore = () => ({
	type: SET_KENO_LAST_BETS_BEFORE
});

const setKenoLastBetsAfter = () => ({
	type: SET_KENO_LAST_BETS_AFTER
});

const setKenoCurrentBetsBefore = () => ({
	type: SET_KENO_CURRENT_BETS_BEFORE
});

const setKenoCurrentBetsAfter = () => ({
	type: SET_KENO_CURRENT_BETS_AFTER
});

const setKenoCurrentBets = (bets) => ({
	type: SET_KENO_CURRENT_BETS,
	payload: { bets }
});

export const updateKenoCurrentBets = (canceledBet) => ({
	type: UPDATE_KENO_CURRENT_BETS,
	payload: { canceledBet }
});

const setKenoLastBets = (bets) => ({
	type: SET_KENO_LAST_BETS,
	payload: { bets }
});

export const getKenoCurrentBets = (id) => {
	return (dispatch) => {
		dispatch(setKenoCurrentBetsBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_KENO_CURRENT_BETS}`,
			method: Methods.GET,
			params: {}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setKenoCurrentBets(data));
				dispatch(setKenoCurrentBetsAfter());
			})
			.catch((ex) => {
				dispatch(setKenoCurrentBetsAfter());
			});
	};
};

export const getKenoLastBets = (id) => {
	return (dispatch) => {
		dispatch(setKenoLastBetsBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_KENO_LAST_BETS}`,
			method: Methods.GET,
			params: {}
		})
			.then(({ data: { value: data } }) => {
				dispatch(setKenoLastBets(data));
				dispatch(setKenoLastBetsAfter());
			})
			.catch((ex) => {
				dispatch(setKenoLastBetsAfter());
			});
	};
};
