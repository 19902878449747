import useBetSlipModeAutoChanger from "hooks/useBetSlipModeAutoChanger"
import useComboBoost from "hooks/useComboBoost"
import { mergeClassNames } from "utils/common"
import ComboBoostTitle from "./comboBoostTitle"
import ComboBoostCoin from "./comboBoostCoin"
import ComboBoostStepProgress from "./comboBoostStepProgress"
import ComboBoostWebDescription from "./comboBoostWebDescription"
import ComboBoostBoostText from "./comboBoostBoostText"
import { useCallback, useEffect, useRef } from "react"
import useUpdateEffect from "hooks/useUpdateEffect"
import { useSelector } from "react-redux"

const animations = {
	from: {
		show: "vs--menu-combo-boost-show-from",
		hide: "vs--menu-combo-boost-hide-from"
	},
	to: {
		show: "vs--menu-combo-boost-show-to",
		hide: "vs--menu-combo-boost-hide-to"
	}
}

const MenuComboBoost = () => {

	const showMobileBetslipsModal = useSelector(state => state.betslip.showMobileBetslipsModal)
	const {
		isComboBoostActive, comboBoostSettings, betsCount, availableBoosts,
		checkBoostIsActive, checkBoostIsCompleted, getBoostWebDescriptionText, getCurrentBoostFactor, calculatePossibleWin,
		stepsForDraw, currency
	} = useComboBoost()

	const currentBoostFactor = getCurrentBoostFactor()

	const ref = useRef()
	const isPlayedRef = useRef(false);
	const fromRef = useRef();
	const toRef = useRef();

	const playForward = useCallback(() => {
		if (!fromRef.current || !toRef.current ) {
			return
		}
		fromRef.current.classList.remove(animations.from.show)
		fromRef.current.classList.add(animations.from.hide)
		toRef.current.classList.remove(animations.to.hide)
		toRef.current.classList.add(animations.to.show)
	}, [])

	const playBackward = useCallback(() => {
		if (!fromRef.current || !toRef.current ) {
			return
		}
		fromRef.current.classList.remove(animations.from.hide)
		fromRef.current.classList.add(animations.from.show)
		toRef.current.classList.remove(animations.to.show)
		toRef.current.classList.add(animations.to.hide)
	}, [])

	useEffect(() => {
		if (!ref.current) {
			return
		}

		ref.current.style.visibility = showMobileBetslipsModal ? "hidden" : "visible"
		ref.current.style.opacity = showMobileBetslipsModal ? "0" : "1";
		
	}, [showMobileBetslipsModal])

	useEffect(() => {
		if (!isComboBoostActive) {
			return () => (
				isPlayedRef.current = false
			)
		}
		if (isPlayedRef.current) {
			return
		}

		playBackward()

		const timeoutId = setTimeout(() => {
			playForward()
			isPlayedRef.current = true
		}, 5000)

		return () => {
			clearTimeout(timeoutId)
		}
		
	}, [playForward, isComboBoostActive, betsCount])

	useUpdateEffect(() => {

		if (!currentBoostFactor || !availableBoosts[betsCount]) {
			return
		}

		playBackward()

		const timeoutId = setTimeout(() => {
			playForward()
		}, 6500)

		return () => {
			clearTimeout(timeoutId)
			playForward()
		}

		
	}, [currentBoostFactor, playBackward, playForward, availableBoosts, betsCount])

	useBetSlipModeAutoChanger()

	if (!isComboBoostActive) {
		return null
	}

	return (
		<div
			ref={ref}
			className={
				mergeClassNames(
					"vs--menu-combo-boost",
					showMobileBetslipsModal && "vs--menu-combo-boost-hidden"
				)
			}
		>
			<div ref={fromRef} className="vs--menu-combo-boost-prototype-from">
				<div className="vs--bet-slip-combo-boost vs--bet-slip-combo-boost-black">
					<div className="vs--bet-slip-combo-boost-header vs--flex vs--align-enter">
						<ComboBoostTitle minOddFactor={comboBoostSettings.minOddFactor}>
							<i
								className="ic_close vs--font-20 vs--ml-4 vs--mr-4"
								onClick={() => {
									playForward()
								}}
							/>
						</ComboBoostTitle>
					</div>
					<div className="vs--bet-slip-combo-boost-body vs--bet-slip-combo-boost-body-transparent">
						<ComboBoostStepProgress boostFactors={comboBoostSettings.boostFactors} checkBoostIsActive={checkBoostIsActive} checkBoostIsCompleted={checkBoostIsCompleted} stepsForDraw={stepsForDraw} />
					</div>
					<div className="vs--bet-slip-combo-boost-footer vs--bet-slip-combo-boost-footer-transparent">
						<ComboBoostWebDescription getBoostWebDescriptionText={getBoostWebDescriptionText} />
						<ComboBoostBoostText getCurrentBoostFactor={getCurrentBoostFactor} calculatePossibleWin={calculatePossibleWin} currency={currency} />
					</div>
				</div>
			</div>
			<div ref={toRef} className="vs--menu-combo-boost-prototype-to">
				<div className="vs--bet-slip-combo-boost-body vs--bet-slip-combo-boost-body-black">
					<ComboBoostCoin />
					<ComboBoostStepProgress boostFactors={comboBoostSettings.boostFactors} checkBoostIsActive={checkBoostIsActive} checkBoostIsCompleted={checkBoostIsCompleted} stepsForDraw={stepsForDraw} />
				</div>
			</div>
		</div>
	)
}
export default MenuComboBoost