import { useSelector } from "react-redux";
import ComboBoostWebDescription from "./comboBoostWebDescription";
import ComboBoostStepProgress from "./comboBoostStepProgress";
import ComboBoostTitle from "./comboBoostTitle";
import { BETSLIP_MODES } from "constants/betslip.constants";
import useComboBoost from "hooks/useComboBoost";
import ComboBoostBoostText from "./comboBoostBoostText";
import useGlobalVariables from "hooks/useGlobalVariables";
import ComboBoostCoin from "./comboBoostCoin";
import ComboBoostMobileDescription from "./comboBoostMobileDescription";
import { mergeClassNames } from "utils/common";

const BetSlipComboBoost = () => {
	const { isMobile } = useGlobalVariables()
	const {
		isComboBoostActive, comboBoostSettings,
		checkBoostIsActive, checkBoostIsCompleted, getBoostWebDescriptionText, getBoostMobileDescriptionText, getCurrentBoostFactor, calculatePossibleWin,
		stepsForDraw, currency
	} = useComboBoost()
	
	if (!isComboBoostActive) {
		return null
	}
	return (
		<div className={mergeClassNames("vs--bet-slip-combo-boost", isMobile ? "vs--bet-slip-combo-boost-black" : "vs--bet-slip-combo-boost-grey")}>
			{
				isMobile
					? null
					: (
						<div className="vs--bet-slip-combo-boost-header">
							<ComboBoostTitle minOddFactor={comboBoostSettings.minOddFactor} />
						</div>
					)
			}
			<div className={mergeClassNames("vs--bet-slip-combo-boost-body", isMobile ? "vs--bet-slip-combo-boost-body-transparent" : "vs--bet-slip-combo-boost-body-black")}>
				{
					isMobile
						? (
							<ComboBoostCoin />
						)
						: null
				}
				<ComboBoostStepProgress boostFactors={comboBoostSettings.boostFactors} checkBoostIsActive={checkBoostIsActive} checkBoostIsCompleted={checkBoostIsCompleted} stepsForDraw={stepsForDraw} />
			</div>
			<div className={mergeClassNames("vs--bet-slip-combo-boost-footer", isMobile ? "vs--bet-slip-combo-boost-footer-transparent" : "vs--bet-slip-combo-boost-footer-black")}>
				{
					isMobile
						? <ComboBoostMobileDescription getBoostMobileDescriptionText={getBoostMobileDescriptionText} />
						: <ComboBoostWebDescription getBoostWebDescriptionText={getBoostWebDescriptionText} />
				}
				<ComboBoostBoostText getCurrentBoostFactor={getCurrentBoostFactor} calculatePossibleWin={calculatePossibleWin} currency={currency} />
			</div>
		</div>
	)
}

export default BetSlipComboBoost;