import { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import InfoTooltip from "components/ui/infoTooltip";
import Dropdown from "components/ui/dropdown";
import Switch from "components/ui/switch";

import { mergeClassNames } from "utils/common";
import { formatDateTime } from "utils/dateTime";
import { binaryToFlags } from "utils/binaryCalculations";

import { GAME_TYPE_MAPPER } from "constants/game.constants";
import { BONUS_TYPE } from "constants/bonus.constants";
import { SWITCH_SIZES } from "constants/ui.constants";

import { setUseBonus } from "store/actions/bonus/bonus.actions";

import Table from "components/ui/table";
import { TableColumnNode } from "helpers/table.halper";

const getSessionGames = state => state.auth.session.games ?? [];
const getSessionBonuses = state => state.auth.session.bonuses ?? [];
const getSessionCurrency = state => state.auth.session.currency ?? {};
const getUseBonus = state => state.bonus.useBonus;

/* Settings Component at right of Header */
const Settings = ({ setUseBonus }) => {
	const { t } = useTranslation();
	const containerRef = useRef(null);

	const useBonus = useSelector(getUseBonus);
	const games = useSelector(getSessionGames);
	const bonuses = useSelector(getSessionBonuses);
	const currency = useSelector(getSessionCurrency);

	const availableGamesTypes = games.map((game) => game.type);
	const currencyCode = currency.code ?? "";

	const modifiedBonuses = bonuses.map((bonus) => {
		const bonusGamesTypes = binaryToFlags(availableGamesTypes, bonus.gameType);

		return {
			...bonus,
			games: bonusGamesTypes.map((gameType) => t(`common.${GAME_TYPE_MAPPER[gameType]}`))
		};
	});

	const getOverlay = (modifiedBonuses) => (
		<div ref={containerRef} className="vs--header-bonus-content vs--pl-8 vs--pr-8 vs--pt-16">
			<div className="vs--flex vs--justify-between vs--pl-8 vs--mb-32">
				<span className="vs--header-bonus-content-title vs--title vs--font-medium vs--font-big">{t("bonus.bonus")}</span>
				<div className="vs--flex vs--justify-between vs--align-center">
					<span className="vs--title vs--font-normal">
						{t("bonus.useBonus")}
					</span>
					<Switch
						onChange={setUseBonus}
						checked={useBonus}
						className="vs--ml-8 vs--mr-8"
						size={SWITCH_SIZES.SMALL}
					/>
				</div>
			</div>

			<Table
				className={mergeClassNames("vs--table-bonus vs--table-border-radius", useBonus && "vs--table-bonus-active")}
				columnClassName="vs--font-normal vs--text-cut vs--pl-12 vs--pr-16"
				textAlign="left"
				separate={true}
				columns={[
					new TableColumnNode(
						"bonusType",
						<span title={t("bonus.bonusType")}>{t("bonus.bonusType")}</span>,
						(bonusType, ...args) => {
							const bonusTypeText = bonusType === BONUS_TYPE.FREEBET ? t("bonus.freeBet") : bonusType === BONUS_TYPE.FREEAMOUNT ? t("bonus.freeAmount") : "";
							const options = args.at(-1);
							const { tableTextClassName } = options;
							return (
								<span title={bonusTypeText} className={tableTextClassName}>
									{bonusTypeText}
								</span>
							);
						},
						{ colProps: { style: { maxWidth: 102 } } }
					),
					new TableColumnNode(
						"amount",
						<span title={t("bonus.amount")}>{t("bonus.amount")}</span>,
						(...args) => {
							const record = args.at(1);
							const options = args.at(-1);
							const { tableTextClassName } = options;
							const bonusAmountText = (record.bonusType === BONUS_TYPE.FREEBET ? `${record.roundCount}x` : "") + `${record.amount} ${currencyCode}`;
							return (
								<span title={bonusAmountText} className={tableTextClassName}>
									{bonusAmountText}
								</span>
							);
						},
						{ colProps: { style: { maxWidth: 130 } } }
					),
					new TableColumnNode(
						"games",
						<span title={t("bonus.games")}>{t("bonus.games")}</span>,
						(games, ...args) => {
							const options = args.at(-1);
							const { tableTextClassName } = options;

							const content = games.length === availableGamesTypes.length ? t("common.allGames") : games.length === 1 ? games[0] : `${games.length} ${t("bonus.games")}`;

							return (
								<div className="vs--flex vs--align-center">
									<span title={content} className={mergeClassNames(tableTextClassName, "vs--text-cut vs--mr-4")}>
										{content}
									</span>
									{games.length !== availableGamesTypes.length && games.length > 1 ? <InfoTooltip getPopupContainer={() => containerRef.current} content={games} /> : null}
								</div>
							);
						},
						{ colProps: { style: { maxWidth: 112 } } }
					),
					new TableColumnNode(
						"endDate",
						<span title={t("bonus.end")}>{t("bonus.end")}</span>,
						(date, ...args) => {
							const options = args.at(-1);
							const { tableTextClassName } = options;
							const formattedDateTime = formatDateTime(date);

							return (
								<span title={formattedDateTime} className={tableTextClassName}>
									{formattedDateTime}
								</span>
							);
						},
						{ colProps: { style: { maxWidth: 150 } } }
					)
				]}
				data={modifiedBonuses}
			/>
		</div>
	);

	useEffect(() => {
		if (modifiedBonuses.length === 0) {
			setUseBonus(false);
		}
	}, [modifiedBonuses]);

	return modifiedBonuses.length > 0 ? (
		<div className="vs--header-bonus vs--flex vs--align-center vs--pl-16 vs--pr-16">
			<Dropdown dropdownContent={getOverlay(modifiedBonuses)}>
				<div className="vs--header-bonus-status vs--flex vs--align-center vs--cursor-pointer">
					<i className={mergeClassNames("ic_bonus vs--header-bonus-status-bonus-icon vs--font-20", useBonus && "vs--header-bonus-status-bonus-icon-active")} />
					<span className="vs--title-white vs--font-small vs--ml-8 vs--mr-4">
						{modifiedBonuses[0].bonusType === BONUS_TYPE.FREEBET ? `${modifiedBonuses[0].roundCount}x${modifiedBonuses[0].amount} ${currencyCode}` : modifiedBonuses[0].bonusType === BONUS_TYPE.FREEAMOUNT ? `${modifiedBonuses[0].amount} ${currencyCode}` : ""}
					</span>
					<i className="ic_back vs--cursor-pointer vs--header-bonus-status-open-icon vs--font-bigest" />
				</div>
			</Dropdown>
		</div>
	) : null;
};

/** Settings propTypes
 * PropTypes
 */
Settings.propTypes = {
	/** Redux action to set bonus data */
	setUseBonus: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	setUseBonus: (event) => {
		dispatch(setUseBonus(event));
	}
});

export default connect(null, mapDispatchToProps)(Settings);
