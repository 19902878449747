import moment from "moment";

import {
	SET_ERROR_MESSAGE,
	SET_BLOCKED_MESSAGE,
	SET_VIDEO_LAYOUT,
	SET_VIDEO_MINIMIZED,
	SET_LIVE_INFO_EVENT,
	UPDATE_LIVE_INFO_EVENT,
	SET_SHOW_LIVE_INFO_EVENT,
	SET_LIVE_INFO_POSITIONS,
	SET_CONNECTION_STATE,
	SET_CURRENT_TIME,
	SET_COLLAPSED,
	GAME_RULE_BEFORE,
	GAME_RULE_AFTER,
	SET_GAME_RULE,
	SET_SHOW_RULES,
	CLEAR_RULES
} from "../../actionTypes";

import { GAME_STATUSES } from "constants/game.constants";
import { getDefaultLiveInfoPositions } from "utils/common";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case SET_ERROR_MESSAGE:
			return {
				...state,
				errorMessage: payload.message
			};
		case SET_BLOCKED_MESSAGE:
			return {
				...state,
				blockedMessage: payload.message
			};
		case SET_VIDEO_LAYOUT:
			return {
				...state,
				videoLayout: payload.layout
			};
		case SET_VIDEO_MINIMIZED:
			return {
				...state,
				videoMinimized: payload.minimized
			};
		case SET_LIVE_INFO_EVENT:
			return {
				...state,
				liveInfoEvent: payload.event
					? {
							...payload.event
						}
					: payload.event,
				showLiveInfoEvent: payload.event ? true : false,
				liveInfoPositions: !payload.event || payload.event.id === state?.liveInfoPositions?.[payload.event.gameType]?.eventId ? state.liveInfoPositions : getDefaultLiveInfoPositions()
			};
		case UPDATE_LIVE_INFO_EVENT:
			let needUpdateLiveInfo = false;
			let updatedLiveInfoEvent = state.liveInfoEvent ? state.liveInfoEvent : null;
			if (state.liveInfoEvent && state.liveInfoEvent.id === payload.event.id && payload.event.status !== GAME_STATUSES.FINISHED) {
				updatedLiveInfoEvent = {
					...payload.event,
					events: state.liveInfoEvent.events
				};
				needUpdateLiveInfo = true;
			} else if (state.liveInfoEvent && payload.event.status !== GAME_STATUSES.FINISHED && state.liveInfoEvent.events) {
				updatedLiveInfoEvent = {
					...state.liveInfoEvent,
					events: state.liveInfoEvent.events.map((e) => (e.id === payload.event.id ? payload.event : e))
				};
				needUpdateLiveInfo = true;
			}

			if (!needUpdateLiveInfo) {
				return state
			}

			return {
				...state,
				liveInfoEvent: updatedLiveInfoEvent,
				showLiveInfoEvent: state.liveInfoEvent && state.liveInfoEvent.id === payload.event.id && payload.event.status === GAME_STATUSES.FINISHED ? false : state.showLiveInfoEvent
			};
		case SET_LIVE_INFO_POSITIONS:
			return {
				...state,
				liveInfoPositions: {
					...state.liveInfoPositions,
					[payload.positions.gameType]: payload.positions
				}
			};
		case SET_SHOW_LIVE_INFO_EVENT:
			return {
				...state,
				showLiveInfoEvent: payload.show
			};
		case SET_CONNECTION_STATE:
			return {
				...state,
				connectionState: payload.state
			};
		case SET_CURRENT_TIME:
			return {
				...state,
				currentTime: moment().unix()
			};
		case SET_COLLAPSED:
			return {
				...state,
				collapsed: payload.collapsed
			};
		case GAME_RULE_BEFORE:
			return {
				...state,
				rules: {
					...state.rules,
					isLoading: true
				}
			};
		case GAME_RULE_AFTER:
			return {
				...state,
				rules: {
					...state.rules,
					isLoading: false
				}
			};
		case SET_GAME_RULE:
			return {
				...state,
				rules: {
					...state.rules,
					prev: {
						...state.rules.prev,
						[payload.gameType]: payload?.data?.[1] ?? null
					},
					current: {
						...state.rules.current,
						[payload.gameType]: payload?.data?.[0] ?? null
					}
				}
			};
		case SET_SHOW_RULES:
			return {
				...state,
				rules: {
					...state.rules,
					showRules: payload.showRules
				}
			};
		case CLEAR_RULES:
			return {
				...state,
				rules: {
					...state.rules,
					prev: {},
					current: {}
				}
			};
		default:
			return state;
	}
};
