import { useTranslation } from "react-i18next"
import ComboBoostCoin from "./comboBoostCoin"

const ComboBoostTitle = ({ minOddFactor, children }) => {
	const { t } = useTranslation()
	return (
		<div className="vs--bet-slip-combo-boost-title">
			<ComboBoostCoin />
			<span className="vs--font-normal vs--font-bold vs--title-brand vs--font-uppercase">
				{t("common.comboboost")}
			</span>
			<span className="vs--bet-slip-combo-boost-min-odd-factor vs--title-gray-80 vs--font-regular vs--font-small">
				{t("common.min")} {t("bet.odds")}:&nbsp;{minOddFactor}
			</span>
			{children}
		</div>
	)
}
export default ComboBoostTitle