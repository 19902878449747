import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { getCurrentSettings } from "utils/settings";
import {
	LIVE_AND_UPCOMINGS_BEFORE,
	LIVE_AND_UPCOMINGS_AFTER,
	SET_LIVE_AND_UPCOMINGS,
	REMOVE_LIVE_AND_UPCOMINGS,
	UPDATE_LIVE_AND_UPCOMINGS,
	REMOVE_LIVE_AND_UPCOMINGS_BY_SEASON,
	ADD_LIVE_AND_UPCOMINGS,
	LAST_RESULTS_BEFORE,
	LAST_RESULTS_AFTER,
	SET_LAST_RESULTS,
	ADD_LAST_RESULT,
	RESULTS_BEFORE,
	RESULTS_AFTER,
	SET_RESULTS,
	ADD_RESULTS,
	SET_RESULTS_FILTERS,
	EVENT_BEFORE,
	EVENT_AFTER,
	SET_EVENT,
	REMOVE_EVENT,
	SET_CURRENT_EVENT,
	UPDATE_EVENT,
	SET_CURRENT_GAME,
	SET_CURRENT_GAME_BONUS_BET_DISABLED,
	SET_GAME_COUNTDOWN,
	SET_MARKETS_COLLAPSIBLE_ACTIVE_KEYS,
	RESULTS_SEASON_BEFORE,
	RESULTS_SEASON_AFTER,
	SET_SEASON_ID,
	SET_SEASON_RESULTS,
	SET_SEASON_RESULTS_FILTERS
} from "../../actionTypes";
import { GAME_STATUSES } from "constants/game.constants";
import { setLiveInfoEvent } from "store/actions/common/common.actions";
import { isMobile, isSeasonGame } from "utils/common";

const setLiveAndUpcomingBefore = () => ({
	type: LIVE_AND_UPCOMINGS_BEFORE
});

const setLiveAndUpcomingAfter = () => ({
	type: LIVE_AND_UPCOMINGS_AFTER
});

const setLiveAndUpcoming = (data) => ({
	type: SET_LIVE_AND_UPCOMINGS,
	payload: { data }
});

const setLastResultsBefore = () => ({
	type: LAST_RESULTS_BEFORE
});

const setLastResultsAfter = () => ({
	type: LAST_RESULTS_AFTER
});

const setLastResults = (data) => ({
	type: SET_LAST_RESULTS,
	payload: { data }
});

export const clearLastResults = () => setLastResults([]);

const setResultsBefore = () => ({
	type: RESULTS_BEFORE
});

const setResultsAfter = () => ({
	type: RESULTS_AFTER
});

const setSeasonResultsBefore = () => ({
	type: RESULTS_SEASON_BEFORE
});

const setSeasonResultsAfter = () => ({
	type: RESULTS_SEASON_AFTER
});

const setResults = (data, gameType) => ({
	type: SET_RESULTS,
	payload: { data, gameType }
});

const addResults = (data) => ({
	type: ADD_RESULTS,
	payload: { data }
});

const setSeasonResults = (data) => ({
	type: SET_SEASON_RESULTS,
	payload: { data }
});

export const setEventBefore = () => ({
	type: EVENT_BEFORE
});

export const setEventAfter = () => ({
	type: EVENT_AFTER
});

const setEvent = (data, rtps) => ({
	type: SET_EVENT,
	payload: { data, rtps }
});

export const removeEvent = (id) => ({
	type: REMOVE_EVENT,
	payload: { id }
});

export const getLiveAndUpcomings = () => {
	return (dispatch, getState) => {
		dispatch(setLiveAndUpcomingBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_LIVE_AND_UPCOMING}`,
			method: Methods.GET,
			params: { type: getState().game.currentGameType }
		})
			.then(({ data: { value: data } }) => {
				const currentGameType = getState().game.currentGameType;

				if (data.map((e) => e.gameType).includes(currentGameType)) {
					let upcommingData = [...data];
					let nextUpcommingData = [];

					if (isSeasonGame(currentGameType) && data) {
						const sortedData = [...data].sort((next, prev) => (next.id > prev.id ? 1 : -1));

						upcommingData = sortedData[0] ? sortedData[0].events || [] : [];
						nextUpcommingData = sortedData[1] ? sortedData[1].events || [] : [];
					}
					dispatch(setLiveAndUpcoming(upcommingData.concat(nextUpcommingData)));
				}
				dispatch(setLiveAndUpcomingAfter());
			})
			.catch((ex) => {
				dispatch(setLiveAndUpcomingAfter());
			});
	};
};

export const removeFromLiveAndUpcomings = (id) => ({
	type: REMOVE_LIVE_AND_UPCOMINGS,
	payload: { id }
});

export const updateFromLiveAndUpcomings = (event) => ({
	type: UPDATE_LIVE_AND_UPCOMINGS,
	payload: { event }
});

export const removeFromLiveAndUpcomingsBySeason = (id) => ({
	type: REMOVE_LIVE_AND_UPCOMINGS_BY_SEASON,
	payload: { id }
});

export const addLiveAndUpcomings = (event) => ({
	type: ADD_LIVE_AND_UPCOMINGS,
	payload: { event }
});

export const getLastResults = () => {
	return (dispatch, getState) => {
		dispatch(setLastResultsBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_LAST_FINISHED_RESULTS}`,
			method: Methods.GET,
			params: { type: getState().game.currentGameType }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setLastResults(data));
				dispatch(setLastResultsAfter());
			})
			.catch((ex) => {
				dispatch(setLastResultsAfter());
			});
	};
};

export const addLastResult = (result) => ({
	type: ADD_LAST_RESULT,
	payload: { result }
});

export const getResults = (action) => {
	return (dispatch, getState) => {
		const currentGameType = getState().game.currentGameType;
		const currentResultsFilters = getState().game.results.filters;
		if (action !== "add") {
			dispatch(setResultsBefore());
		}
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_LAST_RESULTS}`,
			method: Methods.GET,
			params: {
				type: currentGameType,
				...currentResultsFilters
			}
		})
			.then(({ data: { value: data } }) => {
				if (action === "add") {
					return dispatch(addResults(data));
				}
				dispatch(setResults(data, currentGameType));
			})
			.finally(() => {
				if (action !== "add") {
					dispatch(setResultsAfter());
				}
			});
	};
};

const setSeasonId = (seasonId) => ({
	type: SET_SEASON_ID,
	payload: { seasonId }
});

export const getSeasonResults = (id) => {
	return (dispatch, getState) => {
		dispatch(setSeasonId(id));
		dispatch(setSeasonResultsBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_LAST_SEASON_RESULTS}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value } }) => {
				dispatch(setSeasonResults(value));
			})
			.finally(() => {
				dispatch(setSeasonResultsAfter());
			});
	};
};

export const cleanSeasonResult = () => {
	return (dispatch) => {
		dispatch(setSeasonId(null));
		dispatch(setSeasonResults({}));
	};
};

export const setResultsFilters = (filters) => ({
	type: SET_RESULTS_FILTERS,
	payload: { filters }
});

export const setSeasonResultsFilters = (filters) => ({
	type: SET_SEASON_RESULTS_FILTERS,
	payload: { filters }
});

export const clearSeasonResultsFilters = () => setSeasonResultsFilters({ id: "" });

export const getEvent = (id, options = { isMobile: false }) => {
	return requestEvent(id, false, null, options);
};

export const getEventInBackground = (id, updateLiveInfo, options = { isMobile: false }) => {
	return requestEvent(id, true, updateLiveInfo, options);
};

const requestEvent = (id, isBackground, updateLiveInfo, options = { isMobile: false }) => {
	if (!requestEvent.requestPendingHistory) {
		requestEvent.requestPendingHistory = {};
	}
	return (dispatch, getState) => {
		if (requestEvent.requestPendingHistory[id]) {
			return;
		} else {
			requestEvent.requestPendingHistory[id] = true;
		}

		if (!isBackground) {
			dispatch(setEventBefore());
		}

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_FOOTBALL_EVENT}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: data } }) => {
				const state = getState();
				const games = state?.auth?.session?.games ?? [];
				const currentGameType = state?.game?.currentGameType ?? 1;
				const currentGame = games.find((g) => g.type === currentGameType);
				const gameRtps = currentGame?.rtPs ?? [];

				let liveInfoEvent = null;
				let isAnimation = null;
				let session = null;

				if (isBackground && updateLiveInfo) {
					liveInfoEvent = state?.common?.liveInfoEvent;
					session = state?.auth?.session ?? {};
					isAnimation = getCurrentSettings(session)?.isAnimation;
				}

				dispatch(setEvent(data, gameRtps));

				if (isBackground && updateLiveInfo) {
					if ((liveInfoEvent && liveInfoEvent.status !== GAME_STATUSES.FINISHED) || (!liveInfoEvent && !isAnimation && !options.isMobile)) {
						dispatch(setLiveInfoEvent(data));
					}
				}
			})
			.catch((ex) => {
				console.log(ex);
			})
			.finally(() => {
				if (!isBackground) {
					dispatch(setEventAfter());
				}

				if (requestEvent.requestPendingHistory[id]) {
					delete requestEvent.requestPendingHistory[id];
				}
			});
	};
};

export const getEventFromCache = () => {
	return (dispatch) => {
		dispatch(setEventBefore());
		setTimeout(() => {
			dispatch(setEventAfter());
		}, 100);
	};
};

export const setCurrentEvent = (id) => ({
	type: SET_CURRENT_EVENT,
	payload: { id }
});

export const updateEvent = (id, data, rtps) => ({
	type: UPDATE_EVENT,
	payload: { id, data, rtps }
});

export const setCurrentGameBonusBetDisabled = (isDisabled) => ({
	type: SET_CURRENT_GAME_BONUS_BET_DISABLED,
	payload: { isDisabled }
});

export const setCurrentGame = (type) => ({
	type: SET_CURRENT_GAME,
	payload: { type }
});

export const setGameCountDown = (data) => ({
	type: SET_GAME_COUNTDOWN,
	payload: { data }
});

export const setMarketsCollapsibleActiveKeys = (keys, tab) => ({
	type: SET_MARKETS_COLLAPSIBLE_ACTIVE_KEYS,
	payload: { keys, tab }
});
