import { useEffect, Fragment, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import moment from "moment";

import { setBalance, setLogoId } from "store/actions/auth/auth.actions";
import { removePending, updatePending, updatePendingBet, setBetslip, updateMatchBets, addBetslipResult } from "store/actions/betslip/betslip.actions";
import {
	getLiveAndUpcomings,
	removeFromLiveAndUpcomings,
	removeFromLiveAndUpcomingsBySeason,
	getEvent,
	getEventFromCache,
	getEventInBackground,
	addLastResult,
	updateFromLiveAndUpcomings,
	addLiveAndUpcomings,
	updateEvent,
	setGameCountDown,
	setCurrentGameBonusBetDisabled
} from "store/actions/game/game.actions";
import { updateHistoryBetSlipBet, updateHistoryBetSlip } from "store/actions/history/history.actions";
import { updateLiveInfoEvent, setLiveInfoEvent, setEventPostions, setConnectionState, setCurrentTime } from "store/actions/common/common.actions";
import { getKenoLastBets } from "store/actions/keno/keno.actions";
import { updateSeasonStatistics, updateSeasonStructure, setKenoStatistics } from "store/actions/statistics/statistics.actions";
import { setBonus } from "store/actions/auth/auth.actions.js";
import { updateSeasonMarkets, clearSeasonMarkets } from "store/actions/season/season.actions";
import { CLIENT_API } from "constants/integration.constants";
import { GAME_STATUSES, GAME_TYPE, GAME_EVENT_TYPE } from "constants/game.constants";
import { BET_STATE, BETSLIP_STAKE_MODES, BETSLIP_MODES } from "constants/betslip.constants";
import { ANALYTICAL_TOOL_TYPE, CONNECTION_STATE, TASK_SCHEDULER_TIME_PERIODS } from "constants/common.constants";

import SignalRUtils from "utils/signalR";
import LocalStorageUtils from "utils/localStorage";
import { isSeasonGame, isCupGame } from "utils/common";
import { refreshToken, logout } from "utils/auth";
import { sendEventStatus } from "utils/postMessagesIntegration";
import { binaryToFlags } from "utils/binaryCalculations";
import { initializeGA, sendGAPageView } from "utils/ga";
import { initializeHotjar } from "utils/hotjar";
import { initializeWVO } from "utils/vwo";
import { initializeYandexMetrica } from "utils/yandexMetrica";
import { sendPostMessageToParent } from "utils/iframe";
import { generateCssFromProperties } from "utils/css";

import playerType from "types/player.type";
import betType from "types/bet.type";
import eventType from "types/event.type";
import useGlobalVariables from "hooks/useGlobalVariables";
import matchesType from "types/matches.type";
import useTaskScheduler from "hooks/useTaskScheduler";
import useEvent from "hooks/useEvent";
import { REFRESH_TOKEN_UPDATE } from "constants/date.constants";

let refreshTokenTimer = null;

const getSessionGames = state => state.auth.session.games ?? [];
const getSessionBonuses = state => state.auth.session.bonuses ?? [];
const getSessionProjectId = state => state.auth.session.projectId;
const getIsSplitStakeEnabled = state => state.auth.session.isSplitStakeEnabled;
const getAnalyticalTools = state => state.auth.session.analyticalTools ?? [];

/* Main functional Component - Initial functionality for all pages */
const Main = ({
	getLiveAndUpcomings,
	removeFromLiveAndUpcomings,
	removeFromLiveAndUpcomingsBySeason,
	addLastResult,
	updateFromLiveAndUpcomings,
	addLiveAndUpcomings,
	updateEvent,
	setGameCountDown,
	player,
	sessionId,
	sessionLoaded,
	sessionFailed,
	useBonus,
	setCurrentGameBonusBetDisabled,
	setBonus,
	setBalance,
	setLogoId,
	currentGameType,
	current,
	seasonCurrent,
	liveAndUpcomings,
	updateHistoryBetSlipBet,
	updateHistoryBetSlip,
	removePending,
	updatePending,
	updatePendingBet,
	bets,
	stake,
	stakeMode,
	mode,
	updateMatchBets,
	setBetslip,
	addBetslipResult,
	matches,
	getEvent,
	getEventFromCache,
	getEventInBackground,
	updateSeasonMarkets,
	updateSeasonStructure,
	updateLiveInfoEvent,
	setLiveInfoEvent,
	setEventPostions,
	liveInfoEvent,
	updateSeasonStatistics,
	setKenoStatistics,
	setConnectionState,
	setCurrentTime,
	getKenoLastBets,
	clearSeasonMarkets
}) => {
	const globalVariables = useGlobalVariables();

	const games = useSelector(getSessionGames);
	const bonuses = useSelector(getSessionBonuses);
	const projectId = useSelector(getSessionProjectId);
	const isSplitStakeEnabled = useSelector(getIsSplitStakeEnabled);
	const analyticalTools = useSelector(getAnalyticalTools);

	const variableRef = useRef();
	variableRef.current = globalVariables;
	const currentGameTypeRef = useRef(currentGameType);

	/*
		get active event and next upcoming event from live and upcomings events array
	*/
	const { activeEventId, upcomingEventId } = useMemo(() => {
		let _activeEventId = null;
		let _upcomingEventId = null;

		const statusesForActive = [GAME_STATUSES.STARTED, GAME_STATUSES.PREAMBLE_STARTED, GAME_STATUSES.CLOSE_FOR_BETTING];
		const statusesForUpcoming = [GAME_STATUSES.PREAMBLE_STARTED, GAME_STATUSES.NEW];

		liveAndUpcomings.some((lau) => {
			if (_activeEventId === null && statusesForActive.includes(lau.status)) {
				_activeEventId = lau.id;
			}

			if (_upcomingEventId === null && statusesForUpcoming.includes(lau.status)) {
				_upcomingEventId = lau.id;
			}

			return _activeEventId !== null && _upcomingEventId !== null;
		});

		return { activeEventId: _activeEventId, upcomingEventId: _upcomingEventId };
	}, [liveAndUpcomings]);

	/** Function to get current game rtps
	 * @function
	 * @returns {array}
	 * @memberOf MarketsTabs
	 */
	const getCurrentGameRtps = () => {
		let rtps = [];
		let game = games.find((g) => g.type === currentGameType);
		if (game) {
			rtps = game.rtPs;
		}
		return rtps;
	};

	/** Sending event/week status to the RGS, when getting upgrade from SignalR */
	const sendEventStatusToRGS = (event) => {
		if ((event.status === GAME_STATUSES.FINISHED || event.status === GAME_STATUSES.STARTED) && event.type !== GAME_EVENT_TYPE.LEAGUE) {
			if (isSeasonGame(event.gameType)) {
				if (event.type === GAME_EVENT_TYPE.WEEK) {
					sendPostMessageToParent({ type: CLIENT_API.EVENT, state: event.status === GAME_STATUSES.STARTED ? "start" : "finish" });
				}
			} else {
				sendPostMessageToParent({ type: CLIENT_API.EVENT, state: event.status === GAME_STATUSES.STARTED ? "start" : "finish" });
			}
		}
	};

	const allowConectEventUpdates = (connectionURL, necessaryConnectionURL) => {
		try {
			if (!Boolean( necessaryConnectionURL )) {
				return true
			}
			const url = new URL(connectionURL);
			if (necessaryConnectionURL === (url.origin  + url.pathname)) {
				return true
			}
		} catch (error) {
			console.log(error);
		}

		return false
	}

	/** Function to subscribe and handle signalR events
	 * @function
	 * @description checks to allow only numeric characters
	 * @memberOf Container
	 */
	const handleSignalREvents = useEvent((connectionArg) => {
		SignalRUtils.getConnections().forEach((con, index) => {
			if (con !== connectionArg) {
				return
			}
			const connection = con.getConnection();
			if ((index === 0 || index === 2) && connection.state === "Connected") {
				games.forEach((game) => {
					connection.invoke("Subscribe", `Events_${game.type}_${game.id}`);
				});
			} else if (index === 1 && connection.state === "Connected") {
				setInterval(() => {
					if (connection.state === "Connected") {
						connection.invoke("UpdateSession", sessionId, 1, player.userId);
					}
				}, 60000);
			}

			connection.off("Events");
			connection.off("EventPositions");
			connection.off("Logout");
			connection.off("EventCountDown");
			connection.off("BetSlip");
			connection.off("BetSlipBet");
			connection.off("WonPopup");
			connection.off("Balance");
			connection.off("Bonus");
			connection.off("KenoStatistics");
			connection.off("TeamStandings");
			connection.off("Logout");

			const onEvent = (data) => {
				const d = JSON.parse(data);
				console.vsLogger("Events", d);
				if (d.gameType === currentGameTypeRef.current) {
					sendEventStatusToRGS(d);
					if (d.status === GAME_STATUSES.FINISHED) {
						if (!isSeasonGame(d.gameType)) {
							removeFromLiveAndUpcomings(d.id);
							addLastResult(d);
						} else if (d.type === GAME_EVENT_TYPE.LEAGUE) {
							removeFromLiveAndUpcomingsBySeason(d.id);
							clearSeasonMarkets();
						} else {
							updateFromLiveAndUpcomings(d);
						}
					} else if (
						[
							GAME_STATUSES.STARTED,
							GAME_STATUSES.CLOSE_FOR_BETTING,
							GAME_STATUSES.PREAMBLE_STARTED,
						].includes(d.status)
					) {
						if (!isSeasonGame(d.gameType) || d.type === GAME_EVENT_TYPE.WEEK) {
							updateFromLiveAndUpcomings(d);
						}
						updateLiveInfoEvent(d);
					} else if (d.status === GAME_STATUSES.NEW) {
						if (!isSeasonGame(d.gameType)) {
							addLiveAndUpcomings(d);
						} else {
							if (d.type === GAME_EVENT_TYPE.LEAGUE) {
								addLiveAndUpcomings(d);
							}
						}
					}

					/** If season markets are updated */
					if (isSeasonGame(d.gameType) && d.markets) {
						updateSeasonMarkets(d);
					}
				}
				updateEvent(d.id, d, getCurrentGameRtps());
				updateMatchBets(d);
				if (isCupGame(d.gameType)) {
					updateSeasonStructure(d);
				}
			}

			const onEventCountDown = (data) => {
				const d = JSON.parse(data);
				console.vsLogger("EventCountDown", d);
				setGameCountDown(d);
			}

			const onEventPositions = (data) => {
				const d = JSON.parse(data);
				console.vsLogger("EventPositions", d);
				setEventPostions(d);
			}

			const onTeamStandings = (data) => {
				const d = JSON.parse(data);
				console.vsLogger("TeamStandings", d);
				updateSeasonStatistics(d);
			}

			const onKenoStatistics = (data) => {
				if (currentGameTypeRef.current === GAME_TYPE.KENO) {
					const d = JSON.parse(data);
					setKenoStatistics(d);
					getKenoLastBets();
				}
			}

			const onWonPopup = (data) => {
				const d = JSON.parse(data);
				console.vsLogger("WonPopup", d);
				addBetslipResult(d.bets);
			}
			if (allowConectEventUpdates(con.connectionURL, import.meta.env.SYSTEM_SIGNALR_URL_JOBS)) {
				connection.on("Events", onEvent);
				connection.on("EventCountDown", onEventCountDown);
				connection.on("EventPositions", onEventPositions);
				connection.on("TeamStandings", onTeamStandings);
				connection.on("KenoStatistics", onKenoStatistics);
				connection.on("WonPopup", onWonPopup);
			}

			connection.on("BetSlip", (data) => {
				const d = JSON.parse(data);
				console.vsLogger("BetSlip", d);
				updateHistoryBetSlip(d);
				if (d.state !== BET_STATE.PENDING) {
					removePending(d);
				} else {
					updatePending(d);
				}
			});

			connection.on("BetSlipBet", (data) => {
				const d = JSON.parse(data);
				console.vsLogger("BetSlipBet", d);
				updateHistoryBetSlipBet(d);
				updatePendingBet(d);
			});

			connection.on("Bonus", (data) => {
				const bonus = JSON.parse(data);
				console.vsLogger("Bonus", bonus);
				if (bonus === null) {
					return;
				}
				if (moment.utc(bonus.endDate) <= moment.utc(Date.now())) {
					bonus.roundCount = 0;
					bonus.amount = 0;
				}
				setBonus({ ...bonus }, sessionId, variableRef.current);
			});

			connection.on("Balance", (data) => {
				const { bonus, balance } = JSON.parse(data);
				setBalance(Number(balance));
				if (bonus === null) {
					return;
				}
				if (moment.utc(bonus.endDate) <= moment.utc(Date.now())) {
					bonus.roundCount = 0;
					bonus.amount = 0;
				}
				setBonus(bonus, sessionId, variableRef.current);
			});

			connection.on("Logout", () => {
				logout();
			});

		});
	});

	/** Keep current time */
	useEffect(() => {
		setCurrentTime();
		setInterval(() => {
			setCurrentTime();
		}, 1000);
	}, []);

	/** Load Live and Upcomings Matches */
	useEffect(() => {
		if (currentGameType !== null) {
			getLiveAndUpcomings();
		}
		currentGameTypeRef.current = currentGameType;
		const containerElem = document.getElementsByClassName("vs--container")[0];
		containerElem && containerElem.setAttribute("data-game", currentGameType);
	}, [currentGameType]);

	/** Enable/Disable bonus bet for current game when useBonus is activated by user */
	useEffect(() => {
		if (!useBonus) {
			setCurrentGameBonusBetDisabled(useBonus);
		} else {
			const availableGamesTypes = games.map((game) => game.type);
			const bonusGamesTypes = binaryToFlags(availableGamesTypes, bonuses[0].gameType);

			setCurrentGameBonusBetDisabled(!bonusGamesTypes.includes(currentGameType));
		}
	}, [currentGameType, useBonus]);

	/** Always get next upcoming event, to have its markets */
	useEffect(() => {
		if (upcomingEventId) {
			if (!matches?.[upcomingEventId]) {
				getEventInBackground(upcomingEventId, false, variableRef.current);
			}
		}
	}, [upcomingEventId]);

	/** Always get current active event, to keep liveInfo updated */
	useEffect(() => {
		if (activeEventId) {
			if (!matches?.[activeEventId]) {
				getEventInBackground(activeEventId, true, variableRef.current);
			} else {
				if (liveInfoEvent && liveInfoEvent.status !== GAME_STATUSES.FINISHED) {
					setLiveInfoEvent(matches?.[activeEventId]?.event ?? null);
				}
			}
		}
	}, [activeEventId]);

	/** Load current match data and markets */
	useEffect(() => {
		if (current) {
			if (!matches?.[current]) {
				getEvent(current, variableRef.current);
			} else {
				getEventFromCache();
			}
		}
	}, [current]);

	/** Load current event data and markets for season */
	useEffect(() => {
		if (isSeasonGame(currentGameType) && seasonCurrent) {
			if (!matches?.[seasonCurrent]) {
				getEvent(seasonCurrent, variableRef.current);
			} else {
				getEventFromCache();
			}
		}
	}, [seasonCurrent, currentGameType]);

	/** If less then 2 minute left to refresh token expiration, then refresh it */
	useEffect(() => {
		clearTimeout(refreshTokenTimer);
		if (player.refreshToken) {
			refreshTokenTimer = setTimeout(() => {
				refreshToken(player.refreshToken);
			}, REFRESH_TOKEN_UPDATE * 1000);
		}
	}, [player.refreshToken]);

	/** Subscribe to signalR when session loaded */
	useEffect(() => {
		if (!sessionLoaded || sessionFailed) {
			return;
		}
		const timeoutId = setTimeout(() => {
			console.log("CONNECTED");
			SignalRUtils.buildConnections(handleSignalREvents);
		}, 1000);
		return () => {
			SignalRUtils.removeConnections()
			clearTimeout(timeoutId)
			console.log("DISCONNECTED")
		}

	}, [sessionLoaded, player.wsToken]);

	useEffect(() => {
		if (!sessionLoaded || sessionFailed) {
			return;
		}
		const betslip = LocalStorageUtils.get("vs__" + projectId);
		if (betslip) {
			setBetslip({
				...betslip,
				stakeMode: isSplitStakeEnabled ? betslip.stakeMode : BETSLIP_STAKE_MODES.PER_BET
			});
		}
	}, [sessionLoaded]);

	/** keep redux sync with localstorage, for the data which need to be saved in browser */
	useEffect(() => {
		if (projectId) {
			LocalStorageUtils.set("vs__" + projectId, {
				bets: bets,
				stake: stake,
				stakeMode: stakeMode,
				mode: mode
			});
		}
	}, [bets, stake, stakeMode, mode]);

	/** Initialize analytical tools */
	useEffect(() => {
		if (sessionLoaded) {
			analyticalTools.forEach((tool) => {
				if (tool.integrationId) {
					if (tool.type === ANALYTICAL_TOOL_TYPE.GOOGLE_ANALYTICS) {
						initializeGA(tool.integrationId);
						sendGAPageView();
					} else if (tool.type === ANALYTICAL_TOOL_TYPE.HOTJAR) {
						initializeHotjar(tool.integrationId);
					} else if (tool.type === ANALYTICAL_TOOL_TYPE.VWO) {
						initializeWVO(tool.integrationId);
					} else if (tool.type === ANALYTICAL_TOOL_TYPE.YANDEX_METRICA) {
						initializeYandexMetrica(tool.integrationId);
					}
				}
			});
		}
	}, [sessionLoaded]);

	/** Initialize message events */
	useEffect(() => {
		try {
			sendPostMessageToParent({ eventName: "vs--customization-ready" });
		} catch (ex) {
			console.log(ex);
		}
		window.addEventListener(
			"message",
			(e) => {
				const d = e.data;
				if (d) {
					if (d.eventName === "vs--customization") {
						let properties = d.data;
						if (!Array.isArray(properties) && typeof properties === "object") {
							const arr = [];
							Object.keys(properties).forEach((prop) => {
								arr.push({ key: prop, value: properties[prop] });
							});
							properties = arr;
						}
						generateCssFromProperties(properties, "vs--customization-css");
					} else if (d.eventName === "vs--customization-mobile-logo") {
						setLogoId(d.data);
					}
				}
			},
			false
		);
	}, []);

	/** Detect online/offline connection */
	useEffect(() => {
		const onOnline = () => {
			console.log("online");
			setConnectionState(CONNECTION_STATE.NORMAL);
		};

		const onOffline = () => {
			console.log("offline");
			setConnectionState(CONNECTION_STATE.OFFLINE);
		};

		window.addEventListener("online", onOnline);
		window.addEventListener("offline", onOffline);

		return () => {
			window.removeEventListener("online", onOnline);
			window.removeEventListener("offline", onOffline);
		};
	}, []);

	/** Check if live and upcomings contains duplicates, then reload */
	useTaskScheduler(() => {
		let hasDuplicate = false;

		const { possibleSameStartTime, possibleSameEventId } = liveAndUpcomings.reduce(
			(acc, lau) => {
				acc.possibleSameStartTime.push(lau.startTime);
				acc.possibleSameEventId.push(lau.id);
				return acc;
			},
			{ possibleSameStartTime: [], possibleSameEventId: [] }
		);

		const hasEventsWithSameStartTime = new Set(possibleSameStartTime).size < liveAndUpcomings.length;
		const hasEventsWithSameEventId = new Set(possibleSameEventId).size < liveAndUpcomings.length;
		if (hasEventsWithSameStartTime || hasEventsWithSameEventId) {
			hasDuplicate = true;
		}

		if (!hasDuplicate) {
			const duplicateStatuses = [GAME_STATUSES.STARTED, GAME_STATUSES.CLOSE_FOR_BETTING, GAME_STATUSES.PREAMBLE_STARTED];
			const hasMultipleEventsWithNewOrPreambleState = liveAndUpcomings.filter((e) => duplicateStatuses.includes(e.status)).length > 1;
			if (hasMultipleEventsWithNewOrPreambleState) {
				hasDuplicate = true;
			}
		}

		if (hasDuplicate) {
			getLiveAndUpcomings();
		}
	}, TASK_SCHEDULER_TIME_PERIODS.LIVE_AND_UPCOMMINGS);

	return <Fragment />;
};

/** Main propTypes
 * PropTypes
 */
Main.propTypes = {
	/** Redux action to set current time */
	setCurrentTime: PropTypes.func,
	/** Redux action to get live and upcomings matches */
	getLiveAndUpcomings: PropTypes.func,
	/** Redux action to remove match from live and upcomings matches */
	removeFromLiveAndUpcomings: PropTypes.func,
	/** Redux action to remove match from live and upcomings matches by season */
	removeFromLiveAndUpcomingsBySeason: PropTypes.func,
	/** Redux action to add match to last results */
	addLastResult: PropTypes.func,
	/** Redux action to update match in live and upcomings matches */
	updateFromLiveAndUpcomings: PropTypes.func,
	/** Redux action to add match to live and upcomings matches */
	addLiveAndUpcomings: PropTypes.func,
	/** Redux action to update match in loaded matches */
	updateEvent: PropTypes.func,
	/** Redux action to update game countdown */
	setGameCountDown: PropTypes.func,
	/** Redux state property, current authenticated user */
	player: playerType,
	/** Redux state property, current session id */
	sessionId: PropTypes.string,
	/** Redux state property, is true when session loaded */
	sessionLoaded: PropTypes.bool,
	/** Redux state property, is true when session failed */
	sessionFailed: PropTypes.bool,
	/** Redux state property, Current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	/** Redux action to update single bet of betslip in history page */
	updateHistoryBetSlipBet: PropTypes.func,
	/** Redux action to update betslip in history page */
	updateHistoryBetSlip: PropTypes.func,
	/** Redux action to remove bet from pending bets */
	removePending: PropTypes.func,
	/** Redux action to update bet in pending bets */
	updatePending: PropTypes.func,
	/** Redux action to update single bet in pending bets */
	updatePendingBet: PropTypes.func,
	/** Redux state property, current bets in betslip section */
	bets: PropTypes.arrayOf(betType),
	/** Redux state property, stake mode in betslip section */
	stakeMode: PropTypes.oneOf(Object.values(BETSLIP_STAKE_MODES)),
	/** Redux state property, current stake in betslip section */
	stake: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/** Redux state property, current bet mode(single/multi) in betslip section */
	mode: PropTypes.oneOf(Object.values(BETSLIP_MODES)),
	/** Redux action to update all the bets from betslip section, for specified match */
	updateMatchBets: PropTypes.func,
	/** Redux action to add bet data to won popup reults */
	addBetslipResult: PropTypes.func,
	/** Redux action to update whole betslip */
	setBetslip: PropTypes.func,
	/** Redux state property, current error message to show */
	errorMessage: PropTypes.string,
	/** Redux state property, is true when translations are loaded */
	translationsLoaded: PropTypes.bool,
	/** Redux state property, current match id */
	current: PropTypes.number,
	/** Redux state property , the current selected event for season */
	seasonCurrent: PropTypes.number,
	/** Redux state property, all loaded matches */
	matches: matchesType,
	/** Redux action to get match details */
	getEvent: PropTypes.func,
	/** Redux action to get event from cahce */
	getEventFromCache: PropTypes.func,
	/** Redux action to get match details, in background mode */
	getEventInBackground: PropTypes.func,
	/** Redux action to update season markets */
	updateSeasonMarkets: PropTypes.func,
	/** Redux action to update balance*/
	setBalance: PropTypes.func,
	/** Redux state property, use bonus value */
	useBonus: PropTypes.bool,
	/** Redux action to set current usable bonus bet enabled/disabled for current game */
	setCurrentGameBonusBetDisabled: PropTypes.func,
	/** Redux action to update bonus*/
	setBonus: PropTypes.func,
	/** Redux action to update logo id */
	setLogoId: PropTypes.func,
	/** Redux action to update live info event */
	updateLiveInfoEvent: PropTypes.func,
	/** Redux action to set season statistics */
	updateSeasonStatistics: PropTypes.func,
	/** Redux action to set keno statistics */
	setKenoStatistics: PropTypes.func,
	/** Redux action to update season data */
	updateSeasonStructure: PropTypes.func,
	/** Redux action to set live info event */
	setLiveInfoEvent: PropTypes.func,
	/** Redux action to set live info event positions */
	setEventPostions: PropTypes.func,
	/** Redux state property, current live info event */
	liveInfoEvent: eventType,
	/** Redux action to set connection state */
	setConnectionState: PropTypes.func,
	/** Redux state property, the connection state */
	connectionState: PropTypes.oneOf(Object.values(CONNECTION_STATE)),
	/** Redux state property, lave and upcoming matches */
	liveAndUpcomings: PropTypes.arrayOf(eventType),
	/** Redux action to get keno last bets */
	getKenoLastBets: PropTypes.func,
	/** Redux action to clear season markets */
	clearSeasonMarkets: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getLiveAndUpcomings: () => {
		dispatch(getLiveAndUpcomings());
	},
	removeFromLiveAndUpcomings: (id) => {
		dispatch(removeFromLiveAndUpcomings(id));
	},
	removeFromLiveAndUpcomingsBySeason: (id) => {
		dispatch(removeFromLiveAndUpcomingsBySeason(id));
	},
	addLastResult: (result) => {
		dispatch(addLastResult(result));
	},
	updateFromLiveAndUpcomings: (game) => {
		dispatch(updateFromLiveAndUpcomings(game));
	},
	addLiveAndUpcomings: (game) => {
		dispatch(addLiveAndUpcomings(game));
	},
	updateEvent: (id, data, rtps) => {
		dispatch(updateEvent(id, data, rtps));
	},
	setGameCountDown: (data) => {
		dispatch(setGameCountDown(data));
	},
	updateHistoryBetSlipBet: (bet) => {
		dispatch(updateHistoryBetSlipBet(bet));
	},
	updateHistoryBetSlip: (bet) => {
		dispatch(updateHistoryBetSlip(bet));
	},
	removePending: (pending) => {
		dispatch(removePending(pending));
	},
	updatePending: (pending) => {
		dispatch(updatePending(pending));
	},
	updatePendingBet: (bet) => {
		dispatch(updatePendingBet(bet));
	},
	updateMatchBets: (event) => {
		dispatch(updateMatchBets(event));
	},
	setBetslip: (betslip) => {
		dispatch(setBetslip(betslip));
	},
	addBetslipResult: (data) => {
		dispatch(addBetslipResult(data));
	},
	getEvent: (id, options) => {
		dispatch(getEvent(id, options));
	},
	getEventFromCache: () => {
		dispatch(getEventFromCache());
	},
	getEventInBackground: (id, updateLiveInfo, options) => {
		dispatch(getEventInBackground(id, updateLiveInfo, options));
	},
	updateSeasonMarkets: (season) => {
		dispatch(updateSeasonMarkets(season));
	},
	setBalance: (balance) => {
		dispatch(setBalance(balance));
	},
	setBonus: (bonus, sessionId, options) => {
		dispatch(setBonus(bonus, sessionId, options));
	},
	setCurrentGameBonusBetDisabled: (isDisabled) => {
		dispatch(setCurrentGameBonusBetDisabled(isDisabled));
	},
	setLogoId: (logoId) => {
		dispatch(setLogoId(logoId));
	},
	updateLiveInfoEvent: (event) => {
		dispatch(updateLiveInfoEvent(event));
	},
	setLiveInfoEvent: (event) => {
		dispatch(setLiveInfoEvent(event));
	},
	updateSeasonStructure: (event) => {
		dispatch(updateSeasonStructure(event));
	},
	setEventPostions: (postions) => {
		dispatch(setEventPostions(postions));
	},
	updateSeasonStatistics: (data) => {
		dispatch(updateSeasonStatistics(data));
	},
	setConnectionState: (state) => {
		dispatch(setConnectionState(state));
	},
	setCurrentTime: () => {
		dispatch(setCurrentTime());
	},
	setKenoStatistics: (data) => {
		dispatch(setKenoStatistics(data));
	},
	getKenoLastBets: () => {
		dispatch(getKenoLastBets());
	},
	clearSeasonMarkets: () => {
		dispatch(clearSeasonMarkets());
	}
});

const mapStateToProps = (state) => {
	return {
		sessionLoaded: state.auth.sessionLoaded,
		sessionFailed: state.auth.sessionFailed,
		sessionId: state.auth.sessionId,
		player: state.auth.player,
		bets: state.betslip.bets,
		stake: state.betslip.stake,
		stakeMode: state.betslip.stakeMode,
		mode: state.betslip.mode,
		currentGameType: state.game.currentGameType,
		current: state.game.current,
		seasonCurrent: state.season.current,
		matches: state.game.matches.data,
		errorMessage: state.common.errorMessage,
		translationsLoaded: state.auth.translationsLoaded,
		connectionState: state.common.connectionState,
		liveAndUpcomings: state.game.liveAndUpcomings.data,
		liveInfoEvent: state.common.liveInfoEvent,
		useBonus: state.bonus.useBonus
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
