import { useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import LocalStorageUtils from "utils/localStorage";

import { VIDEO_LAYOUT } from "constants/common.constants";

import { setVideoLayout } from "store/actions/common/common.actions";

import sessionType from "types/session.type";

/* Player Switcher Component of Header */
const PlayerSwitcher = ({ setVideoLayout, videoLayout, session }) => {
	/** Get video layout from local storage */
	useEffect(() => {
		if (session.projectId) {
			const videoLayout = LocalStorageUtils.get("vs__video_layout_" + session.projectId);
			if (videoLayout !== null) {
				setVideoLayout(videoLayout);
			}
		}
	}, [session.projectId]);

	/** Save video layout value in local storage */
	useEffect(() => {
		if (session.projectId) {
			LocalStorageUtils.set("vs__video_layout_" + session.projectId, videoLayout);
		}
	}, [videoLayout]);

	return (
		//   <div className="vs--player-switcher vs-flex vs--flex-row vs--flex-equal">
		//       <div className="vs--player-switcher-actions vs-flex vs--flex-row vs--align-center">
		//           <div className={"vs--mr-16 vs--player-switcher-action" + (videoLayout === VIDEO_LAYOUT.NORMAL ? " vs--player-switcher-action-active" : "")} onClick={() => setVideoLayout(VIDEO_LAYOUT.NORMAL)}><i className="ic_desktop-right vs--font-bigest"></i></div>
		//           <div className={"vs--player-switcher-action" + (videoLayout === VIDEO_LAYOUT.REVERSED ? " vs--player-switcher-action-active" : "")} onClick={() => setVideoLayout(VIDEO_LAYOUT.REVERSED)}><i className="ic_desktop-left vs--font-bigest"></i></div>
		//       </div>
		//   </div>
		""
	);
};

/** PlayerSwitcher propTypes
 * PropTypes
 */
PlayerSwitcher.propTypes = {
	/** Redux action to set video layout */
	setVideoLayout: PropTypes.func,
	/** Redux state property, Video layout */
	videoLayout: PropTypes.oneOf(Object.values(VIDEO_LAYOUT)),
	/** Redux state property, current session */
	session: sessionType
};

const mapDispatchToProps = (dispatch) => ({
	setVideoLayout: (layout) => {
		dispatch(setVideoLayout(layout));
	}
});

const mapStateToProps = (state) => {
	return {
		videoLayout: state.common.videoLayout,
		session: state.auth.session
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerSwitcher);
