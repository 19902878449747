import { combineReducers } from "redux";

import CommonReducer from "./common/common.reducer";
import AuthReducer from "./auth/auth.reducer";
import GameReducer from "./game/game.reducer";
import BetslipReducer from "./betslip/betslip.reducer";
import StatisticsReducer from "./statistics/statistics.reducer";
import HistoryReducer from "./history/history.reducer";
import EventReducer from "./event/event.reducer";
import BonusReducer from "./bonus/bonus.reducer";
import SeasonReducer from "./season/season.reducer";
import FeedbackReducer from "./feedback/feedback.reducer";
import KenoReducer from "./keno/keno.reducer";

export default () =>
	combineReducers({
		common: CommonReducer,
		auth: AuthReducer,
		game: GameReducer,
		betslip: BetslipReducer,
		statistics: StatisticsReducer,
		history: HistoryReducer,
		event: EventReducer,
		bonus: BonusReducer,
		season: SeasonReducer,
		feedback: FeedbackReducer,
		keno: KenoReducer
	});
