import { useReducer } from "react";

const initialValue = 0;
const reducer = (v) => v + 1;

const useForceUpdate = () => {
	const [updateValue, updateFunc] = useReducer(reducer, initialValue);
	return [updateFunc, updateValue];
};

export default useForceUpdate;
