import { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { getSeasonStandings, clearSeasonStructureAndStandings } from "store/actions/season/season.actions";
import useGlobalVariables from "hooks/useGlobalVariables";

const LeagueGamesResultsHoc = (ReactComponent) => {
	const getStandingsState = (state) => state.season?.standings ?? [];
	const getLoadingState = (state) => state.season.isLoading ?? false;

	const WrappedReactComponentOfLeagueGamesResultsHoc = ({ id, ...props }) => {
		const { isMobile } = useGlobalVariables();

		const standings = useSelector(getStandingsState);
		const loading = useSelector(getLoadingState);

		const dispatch = useDispatch();
		const getStandings = useCallback((id) => dispatch(getSeasonStandings(id)), [dispatch]);
		const clearStructureAndStandings = useCallback(() => dispatch(clearSeasonStructureAndStandings()), [dispatch]);

		useEffect(() => {
			getStandings(id);
			return () => {
				clearStructureAndStandings();
			};
		}, [id, getStandings, clearStructureAndStandings]);

		return <ReactComponent standings={standings} loading={loading} isFromResults={!isMobile} id={id} {...props} />;
	};

	WrappedReactComponentOfLeagueGamesResultsHoc.propTypes = {
		id: PropTypes.number
	};

	return WrappedReactComponentOfLeagueGamesResultsHoc;
};

export default LeagueGamesResultsHoc;
