import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { setShowMobileBetSlipsModal } from "store/actions/betslip/betslip.actions";
import Paths from "constants/path.constants";

import MobileBetslip from "components/ui/mobileBetslip";

import { mergeClassNames } from "utils/common";
import { navigateToPage, redirectToPage } from "utils/navigate";
import { getCurrentSettings } from "utils/settings";

import { QUICK_BET_ANIMATABLE_EL_DATA_ATTRS } from "constants/betslip.constants";

import betType from "types/bet.type";

import OutsideAlerter from "components/ui/outsideAlerter";

import { placeBetSuccess } from "store/actions/betslip/betslip.actions";
import MenuComboBoost from "components/comboBoost/menuComboBoost";

const getIsQuickBetEnabled = (state) => getCurrentSettings(state.auth.session).isQuickBet;
const getPendingBets = (state) => state.betslip.pendings;

/* Mobile Menu Component */
const MobileMenu = ({ bets, showMobileBetslipsModal, setShowMobileBetSlipsModal, hideSuccessMessage }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();

	const [subOpened, setSubOpened] = useState(false);

	const betsCount = bets.filter((bet) => !bet.isExpired && !bet.hidden).length;

	const isQuickBetEnabled = useSelector(getIsQuickBetEnabled);
	const pendings = useSelector(getPendingBets);

	/** Function to check if the menu item is active
	 * @function
	 * @param {string} path - path to check
	 * @returns {boolean}
	 * @memberOf MobileMenu
	 */
	const isActiveLink = (path) => (path !== "/" ? location.pathname.includes(path) : location.pathname === path) && !showMobileBetslipsModal;

	/** Function to navigate to another page
	 * @function
	 * @param {string} path - path to navigate
	 * @memberOf MobileMenu
	 */
	const handleNavigate = (path) => {
		setShowMobileBetSlipsModal(false);
		setSubOpened(false);

		if (path === Paths.SETTINGS && showMobileBetslipsModal) {
			redirectToPage(navigate, path);
		} else {
			navigateToPage(navigate, path);
		}
	};

	useEffect(() => {
		if (showMobileBetslipsModal) {
			document.body.classList.add("vs--overflow-hidden");
		} else {
			document.body.classList.remove("vs--overflow-hidden");
		}
	}, [showMobileBetslipsModal]);

	return (
		<div className="vs--mobile-menu">
			<MenuComboBoost />
			<div className="vs--flex">
				<div className={"vs--flex-equal vs--flex vs--flex-col vs--justify-center vs--mobile-menu-item" + (isActiveLink(Paths.HOME) ? " vs--mobile-menu-item-active" : "")} onClick={() => handleNavigate(Paths.HOME)}>
					<i className="ic_home vs--font-bigest"></i>
					<span className="vs--title-white vs--font-small vs--font-regular vs--mt-2">{t("menu.home")}</span>
				</div>
				<div className={"vs--flex-equal vs--flex vs--flex-col vs--justify-center vs--mobile-menu-item" + (isActiveLink(Paths.STATISTICS) ? " vs--mobile-menu-item-active" : "")} onClick={() => handleNavigate(Paths.STATISTICS)}>
					<i className="ic_statistics vs--font-bigest"></i>
					<span className="vs--title-white vs--font-small vs--font-regular vs--mt-2">{t("menu.statistics")}</span>
				</div>

				<div
					className={"vs--flex-equal vs--flex vs--flex-col vs--justify-center vs--mobile-menu-item" + (showMobileBetslipsModal ? " vs--mobile-menu-item-active" : "")}
					data-element-id={QUICK_BET_ANIMATABLE_EL_DATA_ATTRS.BETSLIP}
					onClick={() => {
						hideSuccessMessage();
						setShowMobileBetSlipsModal(true);
					}}
				>
					<i className={mergeClassNames("vs--font-bigest", isQuickBetEnabled ? "ic_thunder" : "ic_betslip")} />
					<span className="vs--title-white vs--font-small vs--font-regular vs--mt-2">{t("menu.betslip")}</span>
					{betsCount > 0 && !isQuickBetEnabled ? <b className="vs--mobile-menu-item-count vs--title-black vs--font-smallest vs--font-bold">{betsCount}</b> : null}
				</div>
				<div 
					className={"vs--flex-equal vs--flex vs--flex-col vs--justify-center vs--mobile-menu-item" + (isActiveLink(Paths.HISTORY) ? " vs--mobile-menu-item-active" : "")} 
					data-element-id={QUICK_BET_ANIMATABLE_EL_DATA_ATTRS.BET_HISTORY}
					onClick={() => handleNavigate(Paths.HISTORY)}
				>
					<i className="ic_history vs--font-bigest"></i>
					<span className="vs--title-white vs--font-small vs--font-regular vs--mt-2">{t("menu.betsHistory")}</span>
					{pendings.length > 0 ? <b className="vs--mobile-menu-item-count vs--title-black vs--font-smallest vs--font-bold">{pendings.length}</b> : null}
				</div>
				<div
					className={
						"vs--flex-equal vs--flex vs--flex-col vs--justify-center vs--mobile-menu-item vs--mobile-menu-item-preventClass" +
						(isActiveLink(Paths.RESULTS) || isActiveLink(Paths.SETTINGS) || isActiveLink(Paths.RULES) || isActiveLink(Paths.BONUS) || isActiveLink(Paths.EVENT.replace("/:id", "")) || subOpened ? " vs--mobile-menu-item-active" : "")
					}
					onClick={() => setSubOpened(!subOpened)}
				>
					<i className="ic_more vs--font-bigest"></i>
					<span className="vs--title-white vs--font-small vs--font-regular vs--mt-2">{t("menu.more")}</span>
				</div>
			</div>
			<OutsideAlerter callback={() => setSubOpened(false)} preventClassName="vs--mobile-menu-item-preventClass">
				<div className={"vs--flex vs--flex-col vs--flex-grow vs--mobile-submenu vs-font-regular vs-font-small" + (!subOpened ? " vs--mobile-submenu-hidden" : "")}>
					<div className={"vs--flex-equal vs--flex vs--flex-col vs--justify-center vs--mobile-submenu-item" + (isActiveLink(Paths.BONUS) ? " vs--mobile-submenu-item-active" : "")} onClick={() => handleNavigate(Paths.BONUS)}>
						<i className="ic_bonus vs--font-bigest"></i>
						<span className="vs--title-white vs--font-small vs--font-regular vs--mt-2">{t("bonus.bonus")}</span>
					</div>
					<div className={"vs--flex-equal vs--flex vs--flex-col vs--justify-center vs--mobile-submenu-item" + (isActiveLink(Paths.RESULTS) || isActiveLink(Paths.EVENT.replace("/:id", "")) ? " vs--mobile-submenu-item-active" : "")} onClick={() => handleNavigate(Paths.RESULTS)}>
						<i className="ic_results vs--font-bigest"></i>
						<span className="vs--title-white vs--font-small vs--font-regular vs--mt-2">{t("menu.results")}</span>
					</div>
					<div className={"vs--flex-equal vs--flex vs--flex-col vs--justify-center vs--mobile-submenu-item" + (isActiveLink(Paths.RULES) ? " vs--mobile-submenu-item-active" : "")} onClick={() => handleNavigate(Paths.RULES)}>
						<i className="ic_rules vs--font-bigest"></i>
						<span className="vs--title-white vs--font-small vs--font-regular vs--mt-2">{t("menu.rules")}</span>
					</div>
					<div className={"vs--flex-equal vs--flex vs--flex-col vs--justify-center vs--mobile-submenu-item" + (isActiveLink(Paths.SETTINGS) ? " vs--mobile-submenu-item-active" : "")} onClick={() => handleNavigate(Paths.SETTINGS)}>
						<i className="ic_settings vs--font-bigest"></i>
						<span className="vs--title-white vs--font-small vs--font-regular vs--mt-2">{t("menu.settings")}</span>
					</div>
				</div>
			</OutsideAlerter>
			{showMobileBetslipsModal && (
				<MobileBetslip />
			)}
		</div>
	);
};

/** MobileMenu propTypes
 * PropTypes
 */
MobileMenu.propTypes = {
	/** Redux state property, current bets in betslip section */
	bets: PropTypes.arrayOf(betType),
	/** Redux property, state visibility of mobile betslips modal */
	showMobileBetslipsModal: PropTypes.bool,
	/** Redux action, sho/hide betslip modale for mobile */
	setShowMobileBetSlipsModal: PropTypes.func,
	hideSuccessMessage: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		bets: state.betslip.bets,
		showMobileBetslipsModal: state.betslip.showMobileBetslipsModal
	};
};

const mapDispatchToProps = (dispatch) => ({
	setShowMobileBetSlipsModal: (value) => {
		dispatch(setShowMobileBetSlipsModal(value));
	},
	hideSuccessMessage: () => {
		dispatch(placeBetSuccess(false));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
