import { connect, useSelector } from "react-redux";
import Clock from "./clock";
import Settings from "./settings";
import Menu from "./menu";
import RulesTab from "./rules";
import Bonus from "./bonus";
import PlayerSwitcher from "./playerSwitcher";
import PATHS from "constants/path.constants";
import useCurrentPath from "hooks/useCurrentPath";
import sessionType from "types/session.type";
import useGlobalVariables from "hooks/useGlobalVariables";

import { isRTL, makeCurrencyText } from "utils/common";
import { getCurrentSettings } from "utils/settings";

const getSessionBalance = state => state.auth.session.balance ?? 0;
const getSessionLanguageid = state => state.auth.session.languageId;

/* Header Component */
const Header = ({ session }) => {
	const path = useCurrentPath();
	const balance = useSelector(getSessionBalance);
	const languageId = useSelector(getSessionLanguageid);
	const { isMobile } = useGlobalVariables();

	return (
		<div className="vs--header vs-flex vs--flex-row vs--justify-between vs--mb-8">
			<Menu dir={isRTL(languageId) ? "rtl" : "ltr"} />
			<div className="vs--header-right-part vs--flex vs--pr-16">
				{path === PATHS.HOME && <PlayerSwitcher />}
				{session.isDemo && !isMobile && (
					<div className="vs--header-balance vs--flex vs--align-center vs--pl-16 vs--pr-16">
						<span className="vs--header-balance-title vs--title-gray-80 vs--font-normal vs--font-regular">{makeCurrencyText(balance, { code: "FUN", decimalCount: 2 })}</span>
					</div>
				)}
				<Bonus />
				<RulesTab />
				<Clock />
				<Settings />
			</div>
		</div>
	);
};

/** Header propTypes
 * PropTypes
 */
Header.propTypes = {
	/** Redux state property, current session */
	session: sessionType
};

const mapStateToProps = (state) => {
	return {
		session: state.auth.session
	};
};

export default connect(mapStateToProps, null)(Header);
