import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import moment from "moment";
import { connect } from "react-redux";

/* Clock Component at right of Header */
const Clock = ({ currentTime }) => {
	return (
		<div className="vs--header-clock vs--flex vs--align-center vs--pl-16">
			<span className="vs--header-clock-title vs--title-gray-80 vs--font-normal vs--font-regular ">{moment.unix(currentTime).format("HH:mm:ss")}</span>
		</div>
	);
};

/** Clock propTypes
 * PropTypes
 */
Clock.propTypes = {
	/** Redux state property, current time */
	currentTime: PropTypes.number
};

const mapStateToProps = (state) => {
	return {
		currentTime: state.common.currentTime
	};
};

export default connect(mapStateToProps, null)(Clock);
