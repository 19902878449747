import { toFixed } from "utils/common";

const ComboBoostBoostText = ({ getCurrentBoostFactor, calculatePossibleWin, currency }) => {
	const factor = getCurrentBoostFactor()
	const possibleWin = calculatePossibleWin()
	const amount = factor * possibleWin / 100;

	return (
		<div className="vs--bet-slip-combo-boost-text vs--title-white vs--font-regular vs--font-small">
			{amount > 0 ? `+ ${toFixed(amount, currency.decimalCount ?? 2)} ${currency.code}` : null}
		</div>
	)
}
export default ComboBoostBoostText 