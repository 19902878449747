import { useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import Game from "components/topBar/game";

import { setCurrentGame } from "store/actions/game/game.actions";

import { GAME_TYPE } from "constants/game.constants";
import sessionType from "types/session.type";

const GameSelectionComponent = ({ session, setCurrentGame, currentGameType, onClickCallback }) => {
	const games = session?.games ?? [];

	return (
		<div className="vs--game-list">
			{games.map((game, ind) => (
				<Game key={ind} game={game} isFromModal={true} onClickCallback={onClickCallback} selectedGame={currentGameType} setSelectedGame={setCurrentGame} />
			))}
		</div>
	);
};

GameSelectionComponent.propTypes = {
	/** Redux state property, current session */
	session: sessionType,
	/** Redux action to set current game */
	setCurrentGame: PropTypes.func,
	/** Redux state property, current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	/** React property function, callback of element on click */
	onClickCallback: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		session: state.auth.session,
		currentGameType: state.game.currentGameType
	};
};

const mapDispatchToProps = (dispatch) => ({
	setCurrentGame: (type) => {
		dispatch(setCurrentGame(type));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(GameSelectionComponent);
