import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { SELECTED_SEASON_GAMES, PREDICATE_MULTIPLIER_OF_SEASON_GAMES } from "constants/game.constants";
import runMarketUtilsFunction from "utils/markets/run";

import {
	SET_SEASON_SKELETON_HIDE,
	SET_SEASON_MARKETS,
	SET_SHOW_SEASON_MARKETS,
	SET_SEASON_CURRENT_EVENT,
	SET_SEASON_SELECTED_MARKET,
	SET_SEASON_STRUCTURE,
	SET_SEASON_STANDINGS,
	CLEAR_SEASON_STRUCTURE_STANDINGS,
	SET_SEASON_RESULT_MODAL_MOBILE,
	SET_SEASON_BEFORE,
	SET_SEASON_AFTER,
	SHOW_NEXT_LIVE_AND_UPCOMINGS
} from "../../actionTypes";

export const setSeasonSkeletonHide = (hide) => ({
	type: SET_SEASON_SKELETON_HIDE,
	payload: { hide }
})

export const setSeasonCurrentEvent = (id) => {
	return (dispatch, getState) => {
		if (id) {
			dispatch(showSeasonMarkets(SELECTED_SEASON_GAMES.NONE));
		}

		dispatch({
			type: SET_SEASON_CURRENT_EVENT,
			payload: { id }
		});
	};
};

export const setSeasonSelectedMarket = (market) => ({
	type: SET_SEASON_SELECTED_MARKET,
	payload: { market }
});

export const showSeasonMarkets = (show) => ({
	type: SET_SHOW_SEASON_MARKETS,
	payload: { show }
});

const setSeasonMarkets = (data) => ({
	type: SET_SEASON_MARKETS,
	payload: { data }
});

export const clearSeasonMarkets = () =>
	setSeasonMarkets({
		markets: {
			[SELECTED_SEASON_GAMES.NONE]: [],
			[SELECTED_SEASON_GAMES.CURRENT]: [],
			[SELECTED_SEASON_GAMES.NEXT]: []
		},
		show: SELECTED_SEASON_GAMES.NONE
	});

export const getSeasonMarkets = (id, selectedSeasonGames, needClean = false) => {
	return (dispatch, getState) => {
		if (needClean) {
			dispatch(clearSeasonMarkets());
		}
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_FOOTBALL_EVENT}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: data } }) => {
				const games = getState()?.auth?.session?.games ?? [];
				const currentGame = games.find((g) => g.type === data.gameType);
				const rtps = currentGame?.rtPs ?? [];
				let markets = runMarketUtilsFunction("makeAllMarkets", [data?.markets ?? [], null, rtps, data.gameType], data.gameType);
				markets = markets.fulltime;
				markets = markets.map((m) => ({
					...m,
					bets: m.bets.filter((b) => !b.disabled).sort((b1, b2) => (b1.factor < b2.factor ? -1 : 1))
				}));

				let seasonSelector = SELECTED_SEASON_GAMES.NONE;
				if (!selectedSeasonGames) {
					const liveAndUpcomings = getState()?.game?.liveAndUpcomings?.data ?? [];
					const index = liveAndUpcomings.findIndex((lau) => lau.seasonId === data.id);
					if (index !== -1) {
						seasonSelector = index < PREDICATE_MULTIPLIER_OF_SEASON_GAMES[data.gameType] ? SELECTED_SEASON_GAMES.CURRENT : SELECTED_SEASON_GAMES.NEXT;
					} else {
						markets = [];
					}
				} else {
					seasonSelector = selectedSeasonGames;
				}
				const dataForUpdate = {
					markets: {
						...getState()?.season?.special?.markets,
						[seasonSelector]: markets
					}
				};
				if (seasonSelector === SELECTED_SEASON_GAMES.CURRENT) {
					dataForUpdate.seasonId = data.id;
					dataForUpdate.status = data.status;
				}
				dispatch(setSeasonMarkets(dataForUpdate));
			})
			.catch((ex) => {
				console.log(ex);
			});
	};
};

export const updateSeasonMarkets = (season) => {
	return (dispatch, getState) => {
		const stateObj = getState();
		if (season.id === stateObj?.season?.special?.seasonId) {
			const games = stateObj?.auth?.session?.games ?? [];
			const currentGameType = stateObj?.game?.currentGameType ?? 1;
			const currentGame = games.find((g) => g.type === currentGameType);
			const rtps = currentGame?.rtPs ?? [];

			let markets = runMarketUtilsFunction("makeAllMarkets", [season.markets, null, rtps, currentGameType], currentGameType);
			markets = markets.fulltime;
			markets = markets.map((m) => ({
				...m,
				bets: m.bets.filter((b) => !b.disabled).sort((b1, b2) => (b1.factor < b2.factor ? -1 : 1))
			}));

			let seasonSelector = SELECTED_SEASON_GAMES.NONE;
			const liveAndUpcomings = stateObj?.game?.liveAndUpcomings?.data ?? [];
			const index = liveAndUpcomings.findIndex((lau) => lau.seasonId === season.id);
			if (index !== -1) {
				seasonSelector = index < PREDICATE_MULTIPLIER_OF_SEASON_GAMES[season.gameType] ? SELECTED_SEASON_GAMES.CURRENT : SELECTED_SEASON_GAMES.NEXT;
			} else {
				markets = [];
			}

			const dataForUpdate = {
				markets: {
					...stateObj?.season?.special?.markets ?? {},
					[seasonSelector]: markets
				}
			};

			if (seasonSelector === SELECTED_SEASON_GAMES.CURRENT) {
				dataForUpdate.seasonId = season.id;
				dataForUpdate.status = season.status;
			}

			if ((stateObj?.season?.special?.show ?? SELECTED_SEASON_GAMES.NONE) === SELECTED_SEASON_GAMES.CURRENT && markets.length === 0) {
				dataForUpdate.show = SELECTED_SEASON_GAMES.NONE;
			}

			dispatch(setSeasonMarkets(dataForUpdate));
		}
	};
};

const setSeasonBefore = () => ({
	type: SET_SEASON_BEFORE
});

const setSeasonAfter = () => ({
	type: SET_SEASON_AFTER
});

const setSeasonStructure = (data) => ({
	type: SET_SEASON_STRUCTURE,
	payload: { data }
});

const setSeasonStandings = (data) => ({
	type: SET_SEASON_STANDINGS,
	payload: { data }
});

export const clearSeasonStructureAndStandings = () => ({
	type: CLEAR_SEASON_STRUCTURE_STANDINGS
});

export const getSeasonStandings = (id) => {
	return (dispatch, getState) => {
		dispatch(setSeasonBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.FOOTBALL_LEAGUE_STANDINGS}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setSeasonStandings(data));
			})
			.finally(() => dispatch(setSeasonAfter()));
	};
};

export const getSeasonStructure = (id) => {
	return (dispatch, getState) => {
		dispatch(setSeasonBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_LAST_SEASON_RESULTS}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value } }) => {
				dispatch(setSeasonStructure(value));
			})
			.finally(() => dispatch(setSeasonAfter()));
	};
};

export const setShowNextLiveAndUpcomings = (value) => ({
	type: SHOW_NEXT_LIVE_AND_UPCOMINGS,
	payload: { value }
});

export const setSeasonResultModalMobile = (id) => ({
	type: SET_SEASON_RESULT_MODAL_MOBILE,
	payload: { id }
});
