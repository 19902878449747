import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { setShowRules } from "store/actions/common/common.actions";
import { saveSettings } from "store/actions/auth/auth.actions";

import { getCurrentSettings } from "utils/settings";
import { sendEventStatus } from "utils/postMessagesIntegration";
import { sendPostMessageToParent } from "utils/iframe";
import { CLIENT_API } from "constants/integration.constants";
import { GAME_TYPE, GAME_STATUSES } from "constants/game.constants";
import sessionType from "types/session.type";
import eventType from "types/event.type";

const PostMessagesIntegration = ({ liveAndUpcomings, currentGameType, session, setShowRules, saveSettings }) => {
	const isMessageSentRef = useRef(false);
	const balance = session?.balance ?? 0;
	const isAnimationOn = getCurrentSettings(session).isAnimation;
	const isSoundOn = getCurrentSettings(session).isSound;
	const balanceRef = useRef();
	balanceRef.current = balance;

	/** Listening to the post messages sent by RGS */
	useEffect(() => {
		const onMessage = (event) => {
			switch (event?.data?.type) {
				case CLIENT_API.BALANCE:
					sendPostMessageToParent({ type: CLIENT_API.BALANCE, balance: balanceRef.current });
					break;
				case CLIENT_API.RULES:
					const dataState = event?.data?.state;
					if (dataState === "toggle") {
						setShowRules((prev) => !prev);
						break;
					}
					if (dataState === "show") {
						setShowRules(true);
						break;
					}
					if (dataState === "hide") {
						setShowRules(false);
						break;
					}
					break;
				case CLIENT_API.SETTINGS:
					const settings = {};

					if (event?.data?.isSoundOn) {
						settings.isSound = event.data.isSoundOn;
					}
					if (event?.data?.isAnimationOn) {
						settings.isAnimation = event.data.isAnimationOn;
					}

					saveSettings(settings);
					break;
				default:
					break;
			}
		};
		window.addEventListener("message", onMessage);
		return () => {
			window.removeEventListener("message", onMessage);
		};
	}, []);

	/** Sending message to the RGS on current game change */
	useEffect(() => {
		if (currentGameType) {
			sendPostMessageToParent({ type: CLIENT_API.GAME, value: currentGameType });
		}

		return () => {
			isMessageSentRef.current = false;
		};
	}, [currentGameType]);

	/** Sending message to the RGS first time, when getting game started status from request */
	useEffect(() => {
		if (!isMessageSentRef.current && liveAndUpcomings.length > 0) {
			isMessageSentRef.current = true;
			const liveMatch = liveAndUpcomings.find((event) => event?.status === GAME_STATUSES.STARTED);

			if (liveMatch) {
				sendEventStatus(liveMatch);
			}
		}
	}, [liveAndUpcomings]);

	/** Sending message to the RGS on balance change */
	useEffect(() => {
		sendPostMessageToParent({ type: CLIENT_API.BALANCE, balance });
	}, [balance]);

	/** Sending message to the RGS on user settings change */
	useEffect(() => {
		sendPostMessageToParent({ type: CLIENT_API.SETTINGS, isSoundOn, isAnimationOn });
	}, [isAnimationOn, isSoundOn]);

	return <></>;
};

PostMessagesIntegration.propTypes = {
	/** Redux state property, current session */
	session: sessionType,
	/** Redux state property, lave and upcoming matches */
	liveAndUpcomings: PropTypes.arrayOf(eventType),
	/** Redux state property, Current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	/** Redux action, for showing/hiding rules */
	setShowRules: PropTypes.func,
	/** Redux action to switch player to the real mode */
	saveSettings: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		session: state.auth.session,
		currentGameType: state.game.currentGameType,
		liveAndUpcomings: state.game.liveAndUpcomings.data
	};
};

const mapDispatchToProps = (dispatch) => ({
	setShowRules: (flag) => {
		dispatch(setShowRules(flag));
	},
	saveSettings: (data) => {
		dispatch(saveSettings(data));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(PostMessagesIntegration);
