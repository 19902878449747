import { updateSeasonStatisticsStructure } from "utils/statistics";
import {
	SET_STATISTICS_BEFORE,
	SET_STATISTICS_AFTER,
	SET_STATISTICS_TEAM_1,
	SET_STATISTICS_TEAM_2,
	SET_COUNTRIES,
	SET_TEAM_STATISTICS,
	SET_TEAM_LAST_10_MATCHES,
	SET_HEAD_TO_HEAD_STATISTICS,
	ADD_TEAM_MATCHES,
	SET_TEAM_TO_TEAN_STATISTICS,
	SET_PENALTY_TEAM_TO_TEAM_STATISTICS,
	SET_PARTICIPANTS,
	SET_CURRENT_PARTICIPANT,
	SET_RACING_CURRENT_EVENT,
	SET_PARTICIPANT_STATISTICS,
	SET_QUICK_STATISTICS_BEFORE,
	SET_QUICK_STATISTICS_AFTER,
	SET_QUICK_STATISTICS,
	SET_QUICK_STATISTICS_TEAMS_DATA,
	SET_QUICK_STATISTICS_STATE,
	SET_SEASON_STATISTICS_BEFORE,
	SET_SEASON_STATISTICS_AFTER,
	SET_SEASON_STATISTICS_IS_UPDATE_REQUEST_BEFORE,
	SET_SEASON_STATISTICS_IS_UPDATE_REQUEST_AFTER,
	SET_SEASON_STATISTICS_ID,
	SET_SEASON_STATISTICS_STANDINGS,
	SET_SEASON_STATISTICS_STRUCTURE,
	UPDATE_SEASON_STATISTICS_STRUCTURE,
	SET_SEASON_LIVE_MODAL_MOBILE,
	SET_KENO_STATISTICS,
	RESET_STATISTICS_STATE
} from "../../actionTypes";

import { GAMES_REDUCER_KEYS } from "constants/statistics.constants";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case SET_STATISTICS_BEFORE:
			return {
				...state,
				loading: true
			};
		case SET_STATISTICS_AFTER:
			return {
				...state,
				loading: false
			};
		case SET_STATISTICS_TEAM_1:
			return {
				...state,
				[GAMES_REDUCER_KEYS[payload.gameType]]: {
					...state[GAMES_REDUCER_KEYS[payload.gameType]],
					team1: payload.team
				}
			};
		case SET_STATISTICS_TEAM_2:
			return {
				...state,
				[GAMES_REDUCER_KEYS[payload.gameType]]: {
					...state[GAMES_REDUCER_KEYS[payload.gameType]],
					team2: payload.team
				}
			};
		case SET_COUNTRIES:
			return {
				...state,
				[GAMES_REDUCER_KEYS[payload.gameType]]: {
					...state[GAMES_REDUCER_KEYS[payload.gameType]],
					countries: payload.countries
				}
			};	
		case SET_TEAM_STATISTICS:
			return {
				...state,
				[GAMES_REDUCER_KEYS[payload.gameType]]: {
					...state[GAMES_REDUCER_KEYS[payload.gameType]],
					[payload.teamIndex === 1 ? "team1Statistics" : "team2Statistics"]: payload.statistics
				}
			};
		case SET_TEAM_LAST_10_MATCHES:
			return {
				...state,
				[GAMES_REDUCER_KEYS[payload.gameType]]: {
					...state[GAMES_REDUCER_KEYS[payload.gameType]],
					[payload.teamIndex === 1 ? "team1Statistics" : "team2Statistics"]: {
						...state[GAMES_REDUCER_KEYS[payload.gameType]][payload.teamIndex === 1 ? "team1Statistics" : "team2Statistics"],
						last10Matches: payload.lastMatches
					}
				}
			}	
		case SET_HEAD_TO_HEAD_STATISTICS:
			const { team1, team2, ...rest } = payload.statistics;

			return {
				...state,
				football: {
					...state.football,
					team1Statistics: { ...state.football.team1Statistics, ...team1 },
					team2Statistics: { ...state.football.team2Statistics, ...team2 },
					...rest
				}
			}	
		case ADD_TEAM_MATCHES:
			const statistics = payload.teamIndex === 1 ? state.football.team1Statistics : state.football.team2Statistics;

			return {
				...state,
				[GAMES_REDUCER_KEYS[payload.gameType]]: {
					...state[GAMES_REDUCER_KEYS[payload.gameType]],
					[payload.teamIndex === 1 ? "team1Statistics" : "team2Statistics"]: {
						...statistics,
						lastMatches: statistics.lastMatches.concat(payload.matches),
						loaded: payload.loaded
					}
				}
			};
		case SET_TEAM_TO_TEAN_STATISTICS:
			return {
				...state,
				[GAMES_REDUCER_KEYS[payload.gameType]]: {
					...state[GAMES_REDUCER_KEYS[payload.gameType]],
					h2h: payload.data
				}
			};
		case SET_PENALTY_TEAM_TO_TEAM_STATISTICS:
			return {
				...state,
				[GAMES_REDUCER_KEYS[payload.gameType]]: {
					...state[GAMES_REDUCER_KEYS[payload.gameType]],
					h2h: payload.data
				}
			};
		case SET_PARTICIPANTS:
			return {
				...state,
				racing: {
					...state.racing,
					participants: payload.participants
				}
			};
		case SET_CURRENT_PARTICIPANT:
			return {
				...state,
				racing: {
					...state.racing,
					currentParticipant: payload.participant
				}
			};
		case SET_PARTICIPANT_STATISTICS:
			return {
				...state,
				racing: {
					...state.racing,
					statistics: payload.data
				}
			};
		case SET_RACING_CURRENT_EVENT:
			return {
				...state,
				racing: {
					...state.racing,
					currentEvent: payload.eventId
				}
			};
		case SET_QUICK_STATISTICS_BEFORE:
			return {
				...state,
				quickStatistics: {
					...state.quickStatistics,
					loading: true,
					teamsData: null
				}
			};
		case SET_QUICK_STATISTICS_AFTER:
			return {
				...state,
				quickStatistics: {
					...state.quickStatistics,
					loading: false
				}
			};
		case SET_QUICK_STATISTICS:
			return {
				...state,
				quickStatistics: {
					...state.quickStatistics,
					statistics: payload.data
				}
			};
		case SET_QUICK_STATISTICS_TEAMS_DATA:
			return {
				...state,
				quickStatistics: {
					...state.quickStatistics,
					teamsData: payload.data
				}
			}	
		case SET_QUICK_STATISTICS_STATE:
			return {
				...state,
				quickStatistics: {
					...state.quickStatistics,
					state: payload.state
				}
			};
		case SET_SEASON_STATISTICS_BEFORE:
			return {
				...state,
				season: {
					...state.season,
					loading: true
				}
			};
		case SET_SEASON_STATISTICS_AFTER:
			return {
				...state,
				season: {
					...state.season,
					loading: false
				}
			};
		case SET_SEASON_STATISTICS_ID:
			return {
				...state,
				season: {
					...state.season,
					id: payload.id
				}
			};
		case SET_SEASON_STATISTICS_STANDINGS:
			return {
				...state,
				season: {
					...state.season,
					standings: payload.data
				}
			};
		case SET_SEASON_STATISTICS_STRUCTURE:
			return {
				...state,
				season: {
					...state.season,
					structure: payload.data
				}
			};
		case UPDATE_SEASON_STATISTICS_STRUCTURE:
			const newStatisticsStructure = updateSeasonStatisticsStructure(state.season.structure, payload.data);
			return {
				...state,
				season: {
					...state.season,
					structure: newStatisticsStructure
				}
			};
		case SET_SEASON_STATISTICS_IS_UPDATE_REQUEST_BEFORE:
			return {
				...state,
				season: {
					...state.season,
					isUpdateRequestSended: true
				}
			};
		case SET_SEASON_STATISTICS_IS_UPDATE_REQUEST_AFTER:
			return {
				...state,
				season: {
					...state.season,
					isUpdateRequestSended: false
				}
			};
		case SET_SEASON_LIVE_MODAL_MOBILE:
			return {
				...state,
				season: {
					...state.season,
					showSeasonLiveModal: payload.show
				}
			};
		case SET_KENO_STATISTICS:
			return {
				...state,
				kenoStatistics: payload.data
			};
		case RESET_STATISTICS_STATE:
			return {
				...state,
				...payload
			};
		default:
			return state;
	}
};
