import { Fragment, useLayoutEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import useGlobalVariables from "hooks/useGlobalVariables";
import Loader from "components/ui/loader";
import BetSlipEmpty from "../betslip/empty";
import PendingBet from "./pendingBet";
import PendingBetDetails from "./pendingBetDetails";
import ScrollBar from "components/ui/scrollBar";
import { isMobile } from "utils/common";
import historyType from "types/history.type";

/* Pending Bets Tab Component */
const PendingBets = ({ pendings, loading, isCanceling }) => {
	const { t } = useTranslation();
	const { isMobile } = useGlobalVariables();
	const [currentViewingPending, setCurrentViewingPending] = useState(null);
	const ref = useRef();

	useLayoutEffect(() => {
		if (isMobile) {
			return;
		}
		if (loading) {
			return;
		}
		if (pendings.length === 0) {
			return;
		}
		if (currentViewingPending) {
			return;
		}
		if (!ref.current) {
			return;
		}

		/*
			method getBoundingClientRect is overriden in ScrollBar
			native method name is _getBoundingClientRect
		*/
		const { top } = ref.current._getBoundingClientRect();
		ref.current.style.maxHeight = `${document.body.offsetHeight - top}px`;
	}, [isMobile, loading, pendings, currentViewingPending]);

	return loading ? (
		<Loader />
	) : (
		<Fragment>
			{pendings.length > 0 ? (
				currentViewingPending ? (
					<PendingBetDetails id={currentViewingPending} handleBack={() => setCurrentViewingPending(null)} />
				) : (
					<div className="vs--rightBar-content">
					<ScrollBar className="vs--pending vs--pl-8 vs--pr-8 vs--pt-16 vs--pb-8" vertical={true} containerRef={ref}>
						{pendings.map((pending) => (
							<PendingBet pending={pending} key={pending.id} handleClick={() => setCurrentViewingPending(pending.id)} />
						))}
					</ScrollBar>
					</div>
				)
			) : (
				<div className="vs--rightBar-content">
					<BetSlipEmpty text={t("bet.pendingEmptyText")} subtext={""} />
				</div>
			)}

			{isCanceling && <Loader className="vs--pending-loader" fixed={true} />}
		</Fragment>
	);
};

/** PendingBets propTypes
 * PropTypes
 */
PendingBets.propTypes = {
	/** Redux state property, pending bets */
	pendings: PropTypes.arrayOf(historyType),
	/** Redux state property, is true when pending bets are loading */
	loading: PropTypes.bool,
	/** Redux state property, is true when canceling betslip or bet */
	isCanceling: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		pendings: state.betslip.pendings,
		loading: state.betslip.pendingLoading,
		isCanceling: state.betslip.isCanceling
	};
};

export default connect(mapStateToProps, null)(PendingBets);
