import {
	LIVE_AND_UPCOMINGS_BEFORE,
	LIVE_AND_UPCOMINGS_AFTER,
	SET_LIVE_AND_UPCOMINGS,
	REMOVE_LIVE_AND_UPCOMINGS,
	UPDATE_LIVE_AND_UPCOMINGS,
	REMOVE_LIVE_AND_UPCOMINGS_BY_SEASON,
	ADD_LIVE_AND_UPCOMINGS,
	LAST_RESULTS_BEFORE,
	LAST_RESULTS_AFTER,
	SET_LAST_RESULTS,
	ADD_LAST_RESULT,
	RESULTS_BEFORE,
	RESULTS_AFTER,
	SET_RESULTS,
	ADD_RESULTS,
	SET_RESULTS_FILTERS,
	EVENT_BEFORE,
	EVENT_AFTER,
	SET_EVENT,
	REMOVE_EVENT,
	SET_CURRENT_EVENT,
	UPDATE_EVENT,
	SET_CURRENT_GAME,
	SET_CURRENT_GAME_BONUS_BET_DISABLED,
	SET_SEASON_SELECTED_MARKET,
	SET_GAME_COUNTDOWN,
	SET_MARKETS_COLLAPSIBLE_ACTIVE_KEYS,
	RESULTS_SEASON_BEFORE,
	RESULTS_SEASON_AFTER,
	SET_SEASON_ID,
	SET_SEASON_RESULTS,
	SET_SEASON_RESULTS_FILTERS,
} from "../../actionTypes";

import { GAME_STATUSES, GAME_EVENT_TYPE, GAME_TYPE } from "constants/game.constants";
import runMarketUtilsFunction from "utils/markets/run";
import { isCupGame, isSeasonGame } from 'utils/common'

export default (state = {}, { type, payload }) => {
	switch (type) {
		case LIVE_AND_UPCOMINGS_BEFORE:
			return {
				...state,
				liveAndUpcomings: {
					...state.liveAndUpcomings,
					loading: true,
				},
			};
		case LIVE_AND_UPCOMINGS_AFTER:
			return {
				...state,
				liveAndUpcomings: {
					...state.liveAndUpcomings,
					loading: false,
				},
			};

		case SET_LIVE_AND_UPCOMINGS:
			const currentMatch = payload.data.find((d) => d.status !== GAME_STATUSES.STARTED && d.status !== GAME_STATUSES.FINISHED) || payload.data[0];
			return {
				...state,
				current: currentMatch.id,
				liveAndUpcomings: {
					...state.liveAndUpcomings,
					data: [...payload.data],
				},
			};
		case REMOVE_LIVE_AND_UPCOMINGS:
			const removeLiveAndUpcomingsStatuses = [GAME_STATUSES.STARTED, GAME_STATUSES.CLOSE_FOR_BETTING];
			const { filtered, nextGame } = state.liveAndUpcomings.data.reduce((acc, lau) => {
				if (payload.id === lau.id) { return acc; }
				acc.filtered.push(lau);
				if (!acc.nextGame && !removeLiveAndUpcomingsStatuses.includes(lau.status)) {
					acc.nextGame = lau;
				}
				return acc;
			}, { filtered: [], nextGame: null })
			const removeLiveAndUpcomingsMatchesData = { ...state.matches.data };
			delete removeLiveAndUpcomingsMatchesData[payload.id];
			return (
				{
					...state,
					liveAndUpcomings: {
						...state.liveAndUpcomings,
						data: filtered,
					},
					current: (
						(
							payload.id === state.current ||
							filtered.some(({ id, status }) => removeLiveAndUpcomingsStatuses.includes(status) && id === state.current)
						)
							? nextGame
								? nextGame.id
								: state.current
							: state.current
					),
					matches: {
						...state.matches,
						data: removeLiveAndUpcomingsMatchesData
					}
				}
			);
		case REMOVE_LIVE_AND_UPCOMINGS_BY_SEASON:
			const removeLiveAndUpcomingsBySeasonStatuses = [GAME_STATUSES.STARTED, GAME_STATUSES.FINISHED];
			const { updatedBySeason, currentIdAfterRemove = updatedBySeason[0] } = state.liveAndUpcomings.data.reduce((acc, lau) => {
				if (payload.id === lau.seasonId) { return acc; }
				acc.updatedBySeason.push(lau);
				if (!acc.currentIdAfterRemove && !removeLiveAndUpcomingsBySeasonStatuses.includes(lau.status)) {
					acc.currentIdAfterRemove = lau;
				}
				return acc;
			}, { updatedBySeason: [], currentIdAfterRemove: undefined })
			return {
				...state,
				liveAndUpcomings: {
					...state.liveAndUpcomings,
					data: updatedBySeason,
				},
				current: currentIdAfterRemove ? currentIdAfterRemove.id : state.current
			}
		case UPDATE_LIVE_AND_UPCOMINGS:
			const updated = state.liveAndUpcomings.data.map(d => payload.event.id === d.id ? payload.event : d  );
			return {
				...state,
				liveAndUpcomings: {
					...state.liveAndUpcomings,
					data: updated,
				},
			};
		case ADD_LIVE_AND_UPCOMINGS:
			let needAdd = false;
			let addedLiveAndUpcomings = [];

			if(isSeasonGame(payload.event.gameType)) {
				if(payload.event.type === GAME_EVENT_TYPE.LEAGUE && Array.isArray(payload.event.events)) {
					needAdd = true;
					addedLiveAndUpcomings = [ ...state.liveAndUpcomings.data ];
					payload.event.events.forEach(subEvent => {
						const index = addedLiveAndUpcomings.findIndex(e => e.id === subEvent.id);
						if(index > -1) {
							addedLiveAndUpcomings[index] = subEvent;
						} else {
							addedLiveAndUpcomings = addedLiveAndUpcomings.concat(subEvent);
						}
					})
				}
			} else {
				needAdd = true;
				addedLiveAndUpcomings = [ ...state.liveAndUpcomings.data ];
				const index = addedLiveAndUpcomings.findIndex(e => e.id === payload.event.id);
				if(index > -1) {
					addedLiveAndUpcomings[index] = payload.event;
				} else {
					addedLiveAndUpcomings = addedLiveAndUpcomings.concat(payload.event);
				}
			}
			if (!needAdd) { return state; }
			return {
				...state,
				liveAndUpcomings: {
					...state.liveAndUpcomings,
					data: addedLiveAndUpcomings
				},
			};
		case LAST_RESULTS_BEFORE:
			return {
				...state,
				lastResults: {
					...state.lastResults,
					loading: true,
				},
			};
		case LAST_RESULTS_AFTER:
			return {
				...state,
				lastResults: {
					...state.lastResults,
					loading: false,
				},
			};
		case SET_LAST_RESULTS:
			return {
				...state,
				lastResults: {
					...state.lastResults,
					data: payload.data,
				},
			};
		case ADD_LAST_RESULT:
			return {
				...state,
				lastResults: {
					...state.lastResults,
					data: [payload.result, ...state.lastResults.data].slice(0, 5),
				},
			};
		case RESULTS_BEFORE:
			return {
				...state,
				results: {
					...state.results,
					loading: true,
				},
			};
		case RESULTS_AFTER:
			return {
				...state,
				results: {
					...state.results,
					loading: false,
				},
			};
		case SET_RESULTS:
			if (state.currentGameType !== payload.gameType) { return state }
			return {
				...state,
				results: {
					...state.results,
					data: payload.data.item2,
					total: payload.data.item1,
				},
			};
		case ADD_RESULTS:
			return {
				...state,
				results: {
					...state.results,
					data: state.results.data.concat(payload.data.item2),
					total: payload.data.item1,
				},
			};
		case SET_RESULTS_FILTERS:
			return {
				...state,
				results: {
					...state.results,
					filters: {
						...state.results.filters,
						...payload.filters,
					},
				},
			};
		case EVENT_BEFORE:
			return {
				...state,
				matches: {
					...state.matches,
					loading: true,
				},
			};
		case EVENT_AFTER:
			return {
				...state,
				matches: {
					...state.matches,
					loading: false,
				},
			};
		case SET_EVENT:
			const setEventMatchesData = { ...state.matches.data };
			setEventMatchesData[payload.data.id] = {
				id: payload.data.id,
				event: payload.data,
				markets: (
					[GAME_EVENT_TYPE.EVENT, GAME_EVENT_TYPE.WEEK].includes(payload.data.type) && payload.data.markets
							? runMarketUtilsFunction(
								"makeAllMarkets",
								[
									payload.data.markets,
									payload.data.gameData,
									payload.rtps,
									state.currentGameType
								],
								state.currentGameType
							)
							: {}
				)
			};
			return {
				...state,
				matches: {
					...state.matches,
					data: setEventMatchesData
				},
			};
		case REMOVE_EVENT:
			const removeEventMatchesData = { ...state.matches.data };
			delete removeEventMatchesData[payload.id];
			return {
				...state,
				matches: removeEventMatchesData
			}
		case SET_CURRENT_EVENT:
			return {
				...state,
				current: payload.id,
			};
		case SET_SEASON_SELECTED_MARKET:
			return {
				...state,
				seasonSelectedMarket: payload.market,
			};
		case UPDATE_EVENT:
			let needUpdate = false;
			let updateEventMatchesData = null;

			if (state.matches.data[payload.id]) {
				needUpdate = true;
				updateEventMatchesData = { ...state.matches.data };
				updateEventMatchesData[payload.id] = {
					...updateEventMatchesData[payload.id],
					event: {
						...updateEventMatchesData[payload.id].event,
						status: payload.data.status,
						gameData: payload.data.gameData,
						markets: (
							payload.data.markets && [GAME_EVENT_TYPE.EVENT, GAME_EVENT_TYPE.WEEK].includes(payload.data.type)
								? payload.data.markets
								: updateEventMatchesData[payload.id].event.markets
						),
						marketsCount: (
							payload.data.marketsCount && [GAME_EVENT_TYPE.EVENT, GAME_EVENT_TYPE.WEEK].includes(payload.data.type)
								? payload.data.marketsCount
								: updateEventMatchesData[payload.id].event.marketsCount
						)
					},
					markets: (
						payload.data.markets && [GAME_EVENT_TYPE.EVENT, GAME_EVENT_TYPE.WEEK].includes(payload.data.type) && isSeasonGame(payload.data.gameType)
							? runMarketUtilsFunction(
								"makeAllMarkets",
								[
									payload.data.markets,
									payload.data.gameData,
									payload.rtps,
									state.currentGameType
								],
								state.currentGameType
							)
							: updateEventMatchesData[payload.id].markets
					)
				};
			}
			
			if (
				payload.data.type === GAME_EVENT_TYPE.EVENT &&
				state.matches.data[payload.data.parentEventId] &&
				state.matches.data[payload.data.parentEventId].event &&
				Array.isArray(state.matches.data[payload.data.parentEventId].event.events)
			) {
				needUpdate = true;
				updateEventMatchesData = updateEventMatchesData || { ...state.matches.data };
				const updatedSubEvents = updateEventMatchesData[payload.data.parentEventId].event.events.map(e => {
					if (e.id !== payload.id) { return e; }
					return (
						{
							...payload.data,
							markets: payload.data.markets && isSeasonGame(payload.data.gameType) ? payload.data.markets : e.markets,
							marketsCount: payload.data.markets && isSeasonGame(payload.data.gameType) ? payload.data.marketsCount : e.marketsCount,
						}
					);
				});
				updateEventMatchesData[payload.data.parentEventId] = {
					...updateEventMatchesData[payload.data.parentEventId],
					event: {
						...updateEventMatchesData[payload.data.parentEventId].event,
						events: updatedSubEvents
					}
				};
			}
			if (!needUpdate) { return state; }
			return {
				...state,
				matches: {
					...state.matches,
					data: updateEventMatchesData
				},
			};
		case SET_CURRENT_GAME:
			return {
				...state,
				currentGameType: payload.type,
				matches: {
					...state.matches,
					data: {},
				},
				liveAndUpcomings: {
					...state.liveAndUpcomings,
					data: [],
				},
				current: null,
				lastResults: {
					...state.lastResults,
					data: [],
				},
				seasonResult: {
					...state.seasonResult,
					seasonId: null
				}
			};
		case SET_CURRENT_GAME_BONUS_BET_DISABLED:
			return {
				...state,
				isBonusBetDisabled: payload.isDisabled
			}		
		case SET_GAME_COUNTDOWN:
			return {
				...state,
				gameCountDown: {
					...state.gameCountDown,
					[payload.data.gameId]: payload.data,
				},
			};
		case SET_MARKETS_COLLAPSIBLE_ACTIVE_KEYS:
			return {
				...state,
				marketsActiveKeys: {
					...state.marketsActiveKeys,
					[payload.tab]: payload.keys,
				},
			};
		case RESULTS_SEASON_BEFORE:
			return {
				...state,
				seasonResult: {
					...state.seasonResult,
					loading: true,
				},
			};
		case RESULTS_SEASON_AFTER:
			return {
				...state,
				seasonResult: {
					...state.seasonResult,
					loading: false,
				},
			};
		case SET_SEASON_ID:
			return {
				...state,
				seasonResult: {
					...state.seasonResult,
					seasonId: payload.seasonId
				}
			}
		case SET_SEASON_RESULTS:
			return {
				...state,
				seasonResult: {
					...state.seasonResult,
					data: payload.data,
				},
			};
		case SET_SEASON_RESULTS_FILTERS:
			return {
				...state,
				seasonResult: {
					...state.seasonResult,
					filters: {
						...state.seasonResult.filters,
						...payload.filters,
					},
				},
			};
		default:
			return state;
	}
};
