import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { setStake, updateBetStake } from "store/actions/betslip/betslip.actions";

import {
	checkingFavBetCalculatedValue,
	calculatedFavBetValue,
	onFavoriteCalculation
} from "utils/bets";
import { BETSLIP_KEEP_FOCUSED_STAKE_DATA_ATTRS } from "constants/betslip.constants";

import { mergeClassNames } from "utils/common";
import { Fragment } from "react";

import { BONUS_TYPE } from "constants/bonus.constants";

const getSession = state => state.auth.session;
const getBets = state => state.betslip.bets;
const getStake = state => state.betslip.stake;
const getMode = state => state.betslip.mode;
const getUseBonus = state => state.bonus.useBonus;

const FavoriteBets = ({
	className = "",
	value: valueProp = null,
	focusedStakeId = null,
	favoriteBetCalcCb = null
}) => {
	const session = useSelector(getSession);
	const bets = useSelector(getBets);
	const stake = useSelector(getStake);
	const mode = useSelector(getMode);
	const useBonus = useSelector(getUseBonus);

	const currentBonus = useBonus && (session.bonuses?.[0] ?? {});
	const isFreeBetBonus = useBonus && currentBonus?.bonusType === BONUS_TYPE.FREEBET;

	const dispatch = useDispatch();

	const value = valueProp !== null ? valueProp : stake;

	const getFocusedStakeBet = () => {
		if (focusedStakeId === null) {
			return { stake: value };
		}

		const focusedBet = bets.find(bet => bet.key === focusedStakeId);

		return focusedBet ? focusedBet : null;
	};

	const focusedStakeBet = getFocusedStakeBet();

	const config = { stake: focusedStakeBet?.stake ?? stake, session, mode };

	const handleFavoriteCalculation = (calculatedValue) => {
		if (favoriteBetCalcCb) {
			favoriteBetCalcCb(calculatedValue.toString());
			return;
		}

		if (focusedStakeId === null || focusedStakeBet === null) {
			return dispatch(setStake(calculatedValue.toString()));
		}

		return dispatch(updateBetStake(calculatedValue, focusedStakeBet.key));
	}

	/** Function which fires on favorite bet click
		 * @function
		 * @param {string} fav - favorite bet value
		 * @memberOf Stake
		 */
	const onFavoriteBetClick = onFavoriteCalculation(handleFavoriteCalculation, config)

	if (!session?.currency?.favoriteBets) {
		return <Fragment />
	}

	return (
		<div className={mergeClassNames("vs--flex vs--betslip-stake-fast-wrapper", className)}>
			{
				(session?.currency?.favoriteBets ?? [])
					.reduce((acc, f, ind) => {
						const isEnabled = !isFreeBetBonus && checkingFavBetCalculatedValue(calculatedFavBetValue(f, config), config)
						acc.push(
							<div
								{...{ [BETSLIP_KEEP_FOCUSED_STAKE_DATA_ATTRS.FAV_BET_ITEM]: true }}
								className={mergeClassNames(
									"vs--flex vs--align-center vs--justify-center vs--pl-4 vs--pr-4 vs--mr-2",
									"vs--betslip-stake-fast-wrapper-block",
									isEnabled ? "vs--cursor-pointer" : "vs--betslip-stake-fast-disabled"
								)}
								key={f}
								onClick={() => isEnabled && onFavoriteBetClick(f)}
							>
								<span className="vs--title-white vs--font-normal vs--font-regular">{f}</span>
							</div>
						);

						return acc;
					}, [])
			}
		</div>
	)
};

FavoriteBets.propTypes = {
	/** React property, main className  */
	className: PropTypes.string,
	/** React property, focused Betslip/Bet stake  */
	focusedStakeId: PropTypes.string
};

export default FavoriteBets;