import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { setSeasonLiveModalMobile } from "store/actions/statistics/statistics.actions";
import Standings from "components/ui/season/standings";
import CupStructure from "components/ui/season/cupStructure";
import LeagueGamesLiveSeasonHoc from "hocs/seasonGamesInfo/leagueGamesLiveSeasonHoc";
import CupGamesLiveSeasonHoc from "hocs/seasonGamesInfo/cupGamesLiveSeasonHoc";
import useSwipeableModal from "hooks/useSwipeableModal";
import { GAME_TYPE } from "constants/game.constants";
import { isCupGame, isLeagueGame } from "utils/common";

const WrappedReactComponentOfLeagueGamesLiveSeasonHoc = LeagueGamesLiveSeasonHoc(Standings);
const WrappedReactComponentOfCupGamesLiveSeasonHoc = CupGamesLiveSeasonHoc(CupStructure);

/** Season Live Modal Component */
const SeasonLiveModal = ({ showSeasonLiveModal, setSeasonLiveModalMobile, currentGameType }) => {
	const { t } = useTranslation();

	const data = useSwipeableModal({
		title: isLeagueGame(currentGameType) ? t("common.teamStandings") : isCupGame(currentGameType) ? t("common.cupStructure") : "",
		onClose: () => setSeasonLiveModalMobile(null),
		visibilityState: showSeasonLiveModal,
		children: isLeagueGame(currentGameType) ? <WrappedReactComponentOfLeagueGamesLiveSeasonHoc id={showSeasonLiveModal} /> : isCupGame(currentGameType) ? <WrappedReactComponentOfCupGamesLiveSeasonHoc id={showSeasonLiveModal} /> : null
	});

	return data?.component;
};

/** SeasonLiveModal propTypes
 * PropTypes
 */
SeasonLiveModal.propTypes = {
	/** Redux state property, if true will show season modal on mobile, the field include current Game Event Id */
	showSeasonLiveModal: PropTypes.bool,
	/** Redux action to show mobile season modal */
	setSeasonLiveModalMobile: PropTypes.func,
	/** Redux state property, Current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE))
};

const mapStateToProps = (state) => {
	return {
		showSeasonLiveModal: state.statistics.season.showSeasonLiveModal,
		currentGameType: state.game.currentGameType
	};
};

const mapDispatchToProps = (dispatch) => ({
	setSeasonLiveModalMobile: (show) => {
		dispatch(setSeasonLiveModalMobile(show));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(SeasonLiveModal);
