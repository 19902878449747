import { Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import BetStakeMode from "../stakeMode";
import StakeInput from "./input";
import FavoriteBets from "./favoriteBets";
import BetButtons from "./betButtons";
import Keyboard from "components/ui/keyboard";

import { saveSettings } from "store/actions/auth/auth.actions";
import { clearBets, setStake, updateBetStake, setShowKeyboard, setShowMobileBetSlipsModal } from "store/actions/betslip/betslip.actions";

import {
	toFixed,
	numberWithSpaces,
	isFactorsResultOverLimited
} from "utils/common";
import { navigateToPage } from "utils/navigate";
import {
	calculateTotalOdds
} from "utils/bets";
import { getCurrentSettings } from "utils/settings";

import Paths from "constants/path.constants";

import { BETSLIP_MODES, BETSLIP_KEEP_FOCUSED_STAKE_DATA_ATTRS } from "constants/betslip.constants";
import { BONUS_TYPE, BONUS_WIN_TYPE } from "constants/bonus.constants";
import { VIRTUAL_KEYBOARD_KEYS } from "constants/keyboard.constants";

import betType from "types/bet.type";
import sessionType from "types/session.type";

import useGlobalVariables from "hooks/useGlobalVariables";
import MobileContainer from "./mobileContainer";
import WebContainer from "./webContainer";
import BetSlipComboBoost from "components/comboBoost/betSlipComboBoost";

/** Stake section component for betslip*/
const Stake = ({
	mode,
	bets,
	clearBets,
	setStake,
	updateBetStake,
	stake,
	session,
	useBonus,
	groupedRepeatedBets,
	focusedStakeId,
	setFocusedStakeId,
	showKeyboard,
	setShowKeyboard,
	setShowMobileBetSlipsModal,
	saveSettings,
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const globalVariables = useGlobalVariables();
	const { isMobile } = globalVariables;
	const currentBonus = useBonus && (session.bonuses?.[0] ?? {});
	const isFreeAmountBonus = useBonus && currentBonus.bonusType === BONUS_TYPE.FREEAMOUNT;
	const isFreeBetBonus = useBonus && currentBonus.bonusType === BONUS_TYPE.FREEBET;
	const isQuickBetEnabled = getCurrentSettings(session).isQuickBet;

	const currency = session.currency ?? {};

	const isQuickBetStakeDisabled = isQuickBetEnabled ? session.isDemo : false;

	const getFocusedStakeBet = () => {
		if (focusedStakeId === null) {
			return { stake };
		}

		const focusedBet = bets.find(bet => bet.key === focusedStakeId);

		return focusedBet ? focusedBet : null;
	};

	const focusedStakeBet = getFocusedStakeBet();

	const hasCombineError = groupedRepeatedBets !== null && Object.keys(groupedRepeatedBets).length > 0;

	const onKeyboardKeyClick = (value, pressedKey) => {
		if (pressedKey === VIRTUAL_KEYBOARD_KEYS.OK) {
			if (!isQuickBetEnabled || session.isDemo) {
				return;
			}

			if (!hasError()) {
				saveSettings({ quickBetAmount: value });
			}
		}

		if (focusedStakeId === null) {
			setStake(value)
		}

		updateBetStake(value, focusedStakeBet?.key);
	};

	const toggleKeyboardVisibility = (visible) => {
		if (!visible && focusedStakeId !== null) {
			setFocusedStakeId(null);
		}

		setShowKeyboard(visible);
	};

	/** Function which filters on not expired bets
	 * @function
	 * @returns {array} - active bets
	 * @memberOf Stake
	 */
	const getActiveBets = () => bets.filter((bet) => !bet.isExpired);

	/** Function which calculates the possible win
	 * @function
	 * @returns {number}
	 * @memberOf Stake
	 */
	const calculatePossibleWin = () => {
		let possibleWin = 0;
		if (mode === BETSLIP_MODES.SINGLE) {
			possibleWin = getActiveBets().reduce((total, bet) => total + Number(bet.stake) * bet.factor - (useBonus && currentBonus.winType === BONUS_WIN_TYPE.PURE ? bet.stake : 0), 0);
		} else if (mode === BETSLIP_MODES.MULTI) {
			possibleWin = getActiveBets().reduce((total, bet) => total * bet.factor, 1) * stake - (useBonus ? stake : 0);
		}
		return toFixed(possibleWin, currency.decimalCount ?? 2);
	};

	const renderTotal = () => {
		const total = calculateTotalOdds(getActiveBets(), session)
		if (window.isNaN(total)) {
			return 0;
		}

		return `x${numberWithSpaces(total)}`;
	}

	/** Function get bet min limit depend on bet type
	 * @function
	 * @returns {number}
	 * @memberOf Stake
	 */
	const getMinLimit = () => (mode === BETSLIP_MODES.SINGLE ? (currency.singleMin ?? 0) : (currency.multiMin ?? 0));

	/** Function get bet min limit depend on bet type
	 * @function
	 * @returns {number}
	 * @memberOf Stake
	 */
	const getMaxLimit = () => (mode === BETSLIP_MODES.SINGLE ? (currency.singleMax ?? Infinity) : (currency.multiMax ?? Infinity));

	/** Function get bet min limit depend on bet type
	 * @function
	 * @returns {number}
	 * @memberOf Stake
	 */
	const getMaxPossibleWin = () => (mode === BETSLIP_MODES.SINGLE ? (currency.singlePossibleWinMax ?? Infinity) : (currency.multiPossibleWinMax ?? Infinity));

	const checkIsThereFreeAmountBonusError = () => {
		let totalStack = 0;
		if (isQuickBetEnabled) {
			totalStack = stake;
		} else if (mode === BETSLIP_MODES.SINGLE) {
			totalStack = bets.reduce((acc, b) => {
				const stake = Number(b.stake);
				if (Number.isNaN(stake)) {
					return acc;
				}
				return acc + stake;
			}, 0);
		} else if (mode === BETSLIP_MODES.MULTI) {
			totalStack = stake;
		}

		if (currentBonus.amount < totalStack) { return true }
		const difference = currentBonus.amount - totalStack
		if (difference === 0) { return false }
		return difference < getMinLimit()
	}

	/** Function which checks if the stake is valid
	 * @function
	 * @returns {boolean}
	 * @memberOf Stake
	 */
	const hasError = () => {
		const isValueInRange = ((Number(stake) < getMinLimit() || Number(stake) > getMaxLimit()) &&
			((stake !== "" && mode === BETSLIP_MODES.MULTI) || isQuickBetEnabled)) ||
			(!isQuickBetEnabled && Number(calculatePossibleWin()) > getMaxPossibleWin());

		if (isValueInRange) { return isValueInRange; }

		if (isFreeAmountBonus) {
			const retVal = checkIsThereFreeAmountBonusError();
			if (retVal) {
				return retVal;
			}
		}

		if (mode === BETSLIP_MODES.MULTI) {
			return isFactorsResultOverLimited(bets)
		}

		return false
	}

	const Container = useMemo(() => isMobile ? MobileContainer : WebContainer, [isMobile]);

	return (
		<Fragment>
			<Container>
				<BetSlipComboBoost />
				{(!isMobile || focusedStakeId === null) && (
					<div style={{ marginTop: "auto" }} className="vs--betslip-actions vs--flex vs--flex-row vs--align-center vs--justify-between vs--pl-12 vs--pr-12 vs--pt-8 vs--pb-8">
						{isQuickBetEnabled ? (
							<span className="vs--flex vs--title-white vs--font-normal vs--font-regular">
								{t('bet.seeAllYourBets')}&nbsp;
								<span
									className="vs--title-brand vs--cursor-pointer vs--ml-4"
									onClick={() => {
										setShowMobileBetSlipsModal(false);
										navigateToPage(navigate, Paths.HISTORY);
									}}
								>
									{t('bet.betsHistory')}
								</span>
							</span>
						) : (
							<Fragment>
								{
									(
										session.isSplitStakeEnabled &&
										mode === BETSLIP_MODES.SINGLE &&
										!isFreeBetBonus
									)
										? (
											<BetStakeMode />
										)
										: null
								}
								<div className="vs--betslip-actions-delete" onClick={() => {
									clearBets();
									setStake("");
								}}>
									<span className="vs--title-white vs--font-normal vs--font-regular">
										{t("bet.deleteAll")}
									</span>
								</div>
							</Fragment>
						)}
					</div>
				)}
				<div className='vs--flex vs--flex-col vs--justify-end'>
					<div className="vs--betslip-stake vs--pb-16 vs--pt-8 vs--pl-12 vs--pr-12">
						<StakeInput
							focusedStakeId={focusedStakeId}
							setFocusedStakeId={setFocusedStakeId}
							showKeyboard={showKeyboard}
							checkIsThereFreeAmountBonusError={checkIsThereFreeAmountBonusError}
							hasError={hasError}
						/>
						{mode === BETSLIP_MODES.MULTI && (
							<div className="vs--betslip-stake-count vs--mt-8 vs--pt-6 vs--pb-6">
								<div className="vs--flex vs--justify-between vs--align-center">
									<span className="vs--title-gray-80 vs--font-small vs--font-medium">
										{t("bet.totalOdds")}
									</span>
									<span className="vs--title-brand vs--font-medium vs--font-small">
										{renderTotal()}
									</span>
								</div>
							</div>
						)}
						{
							!isMobile && !useBonus && !isQuickBetStakeDisabled
								? <FavoriteBets className="vs--mt-8" focusedStakeId={focusedStakeId} />
								: null
						}
						<div className="vs--betslip-stake-divider" />
						<div className="vs--betslip-stake-demo vs--mt-8 vs--flex vs--flex-col">
							{
								isMobile && !useBonus && !isQuickBetStakeDisabled
									? <FavoriteBets className="vs--mt-8 vs--mb-4" focusedStakeId={focusedStakeId} />
									: null
							}
							{
								isMobile
									? (
										<Keyboard
											{...{ [BETSLIP_KEEP_FOCUSED_STAKE_DATA_ATTRS.KEYBOARD]: true }}
											className="vs--mt-4 vs--mb-8"
											focusedInputId={focusedStakeId}
											value={`${focusedStakeBet?.stake ?? stake}`}
											onChange={onKeyboardKeyClick}
											visible={showKeyboard}
											toggleVisibility={toggleKeyboardVisibility}
											isInteger={(currency.decimalCount ?? 0) === 0}
										/>
									)
									: null
							}
							{!hasCombineError && <BetButtons focusedStakeId={focusedStakeId} hasError={hasError} />}
						</div>
					</div>
				</div>
			</Container>
			{hasCombineError && (
				<div className="vs--betslip-combined-event-error vs--pt-18 vs--pb-18 vs--pl-12 vs--pr-12 vs--font-small vs--font-regular">
					{t("bet.notCombinable")}
				</div>
			)}
		</Fragment>
	);
};

/** Stake propTypes
 * PropTypes
 */
Stake.propTypes = {
	/** Redux state property, current betslip mode */
	mode: PropTypes.oneOf(Object.values(BETSLIP_MODES)),
	/** Redux state property, current bets in betslip section */
	bets: PropTypes.arrayOf(betType),
	/** Redux action to clear betslip bets */
	clearBets: PropTypes.func,
	/** Redux action to set common stake in betslip */
	setStake: PropTypes.func,
	/** Redux action to update single bet stake */
	updateBetStake: PropTypes.func,
	/** Redux state property, common stake of betslip */
	stake: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/** Redux state property, current session */
	session: sessionType,
	/** Redux state property, bonus usage property */
	useBonus: PropTypes.bool,
	/** React prop, bets on the same events */
	groupedRepeatedBets: PropTypes.object,
	/** React property, focused Betslip/Bet stake  */
	focusedStakeId: PropTypes.string,
	/** React property, set last focused stake */
	setFocusedStakeId: PropTypes.func,
	/** Redux state property, show keyboard for clicked Bet/Betslip stake */
	showKeyboard: PropTypes.bool,
	/** Redux action to show keyboard for clicked Bet/Betslip stake */
	setShowKeyboard: PropTypes.func,
	/** Redux action, sho/hide betslip modale for mobile */
	setShowMobileBetSlipsModal: PropTypes.func,
	/** Redux action to switch player to the real mode */
	saveSettings: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		mode: state.betslip.mode,
		showKeyboard: state.betslip.showKeyboard,
		bets: state.betslip.bets,
		stake: state.betslip.stake,
		session: state.auth.session,
		useBonus: state.bonus.useBonus
	};
};

const mapDispatchToProps = (dispatch) => ({
	clearBets: () => {
		dispatch(clearBets());
	},
	setStake: (stake) => {
		dispatch(setStake(stake));
	},
	updateBetStake: (stake, key) => {
		dispatch(updateBetStake(stake, key));
	},
	setShowKeyboard: (show) => {
		dispatch(setShowKeyboard(show));
	},
	setShowMobileBetSlipsModal: (value) => {
		dispatch(setShowMobileBetSlipsModal(value));
	},
	saveSettings: (data) => {
		dispatch(saveSettings(data));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Stake);
