import { GAME_TYPE } from "constants/game.constants";

export const flagsByCountry = {
	[GAME_TYPE.FOOTBALL_SINGLE_MATCH]: {
		alg: "alg.svg",
		dz: "alg.svg",
		arg: "arg.svg",
		ar: "arg.svg",
		arm: "arm.svg",
		am: "arm.svg",
		bel: "bel.svg",
		be: "bel.svg",
		bra: "bra.svg",
		br: "bra.svg",
		col: "col.svg",
		co: "col.svg",
		eng: "eng.svg",
		"gb-eng": "eng.svg",
		esp: "esp.svg",
		es: "esp.svg",
		fra: "fra.svg",
		fr: "fra.svg",
		ger: "ger.svg",
		de: "ger.svg",
		gha: "gha.svg",
		gh: "gha.svg",
		gre: "gre.svg",
		gr: "gre.svg",
		irn: "irn.svg",
		ir: "irn.svg",
		ita: "ita.svg",
		it: "ita.svg",
		nga: "nga.svg",
		ng: "nga.svg",
		por: "por.svg",
		pt: "por.svg",
		rsa: "rsa.svg",
		za: "rsa.svg",
		sen: "sen.svg",
		sn: "sen.svg",
		tur: "tur.svg",
		tr: "tur.svg",
		uru: "uru.svg",
		uy: "uru.svg"
	},
	[GAME_TYPE.ENGLISH_LEAGUE]: {
		ars: "ars.svg",
		ast: "ast.svg",
		bou: "bou.svg",
		bha: "bha.svg",
		bre: "bre.svg",
		bur: "bur.svg",
		che: "che.svg",
		cry: "cry.svg",
		eve: "eve.svg",
		ful: "ful.svg",
		ips: "ips.svg",
		ldu: "ldu.svg",
		lei: "lei.svg",
		liv: "liv.svg",
		mnc: "mnc.svg",
		mnu: "mnu.svg",
		new: "new.svg",
		nor: "nor.svg",
		ntf: "ntf.svg",
		sou: "sou.svg",
		tot: "tot.svg",
		wat: "wat.svg",
		whu: "whu.svg",
		wol: "wol.svg"
	},
	[GAME_TYPE.AFRICAN_CUP]: {
		cmr: "cmr.svg",
		cm: "cmr.svg",
		sen: "sen.svg",
		sn: "sen.svg",
		alg: "alg.svg",
		dz: "alg.svg",
		mli: "mli.svg",
		tun: "tun.svg",
		gui: "gui.svg",
		egy: "egy.svg",
		gha: "gha.svg",
		gh: "gha.svg",
		zim: "zim.svg",
		civ: "civ.svg",
		mar: "mar.svg",
		nga: "nga.svg",
		ng: "nga.svg",
		sdn: "sdn.svg",
		eth: "eth.svg",
		mtn: "mtn.svg",
		gam: "gam.svg"
	},
	[GAME_TYPE.WORLD_CUP]: {
		arg: "arg.svg",
		ar: "arg.svg",
		bel: "bel.svg",
		be: "bel.svg",
		bra: "bra.svg",
		br: "bra.svg",
		cmr: "cmr.svg",
		cm: "cmr.svg",
		eng: "eng.svg",
		"gb-eng": "eng.svg",
		esp: "esp.svg",
		es: "esp.svg",
		fra: "fra.svg",
		fr: "fra.svg",
		ger: "ger.svg",
		de: "ger.svg",
		irn: "irn.svg",
		ir: "irn.svg",
		ita: "ita.svg",
		it: "ita.svg",
		nga: "nga.svg",
		ng: "nga.svg",
		por: "por.svg",
		pt: "por.svg",
		rus: "rus.svg",
		ru: "rus.svg",
		sen: "sen.svg",
		sn: "sen.svg",
		tur: "tur.svg",
		tr: "tur.svg",
		uru: "uru.svg",
		uy: "uru.svg"
	},
	[GAME_TYPE.EUROPEAN_CUP]: {
		aut: "aut.svg",
		at: "aut.svg",
		bel: "bel.svg",
		be: "bel.svg",
		cro: "cro.svg",
		hr: "cro.svg",
		den: "den.svg",
		dk: "den.svg",
		eng: "eng.svg",
		"gb-eng": "eng.svg",
		esp: "esp.svg",
		es: "esp.svg",
		fra: "fra.svg",
		fr: "fra.svg",
		ger: "ger.svg",
		de: "ger.svg",
		ita: "ita.svg",
		it: "ita.svg",
		ned: "ned.svg",
		nl: "ned.svg",
		por: "por.svg",
		pt: "por.svg",
		rus: "rus.svg",
		ru: "rus.svg",
		sui: "sui.svg",
		ch: "sui.svg",
		swe: "swe.svg",
		se: "swe.svg",
		tur: "tur.svg",
		tr: "tur.svg",
		wal: "wal.svg",
		"gb-wls": "wal.svg"
	},
	[GAME_TYPE.CHAMPIONS_LEAGUE]: {
		ajx: "ajx.svg",
		atm: "atm.svg",
		bar: "bar.svg",
		bay: "bay.svg",
		bnf: "bnf.svg",
		bvb: "bvb.svg",
		che: "che.svg",
		int: "int.svg",
		juv: "juv.svg",
		liv: "liv.svg",
		mnc: "mnc.svg",
		mnu: "mnu.svg",
		por: "cl_por.svg",
		psg: "psg.svg",
		rma: "rma.svg",
		tot: "tot.svg"
	},
	[GAME_TYPE.COPA_LIBERTADORES]: {
		aso: "aso.svg",
		boc: "boc.svg",
		cam: "cam.svg",
		ccp: "ccp.svg",
		cru: "cru.svg",
		eme: "eme.svg",
		fla: "fla.svg",
		gre: "copa_gre.svg",
		ldu: "copa_ldu.svg",
		lib: "lib.svg",
		nac: "nac.svg",
		oli: "oli.svg",
		pal: "pal.svg",
		rac: "rac.svg",
		riv: "riv.svg",
		sfc: "sfc.svg"
	},
	[GAME_TYPE.PENALTY_SHOOTOUT]: {
		bra: "bra.svg", br: "bra.svg",
		bel: "bel.svg", be: "bel.svg",
		fra: "fra.svg", fr: "fra.svg",
		eng: "eng.svg", "gb-eng": "eng.svg",
		ita: "ita.svg", it: "ita.svg",
		esp: "esp.svg", es: "esp.svg",
		por: "por.svg", pt: "por.svg",
		ger: "ger.svg", de: "ger.svg",
		cro: "cro.svg", hr: "cro.svg",
		ned: "ned.svg", nl: "ned.svg",
		uru: "uru.svg", uy: "uru.svg",
		arg: "arg.svg", ar: "arg.svg",
		col: "col.svg", co: "col.svg",
		mar: "mar.svg", ma: "mar.svg",
		nga: "nga.svg", ng: "nga.svg",
		sen: "sen.svg", sn: "sen.svg",
		tun: "tun.svg", tn: "tun.svg",
		mex: "mex.svg", mx: "mex.svg",
		tur: "tur.svg", tr: "tur.svg",
		irn: "irn.svg", ir: "irn.svg",
		den: "den.svg", dk: "den.svg",
		cmr: "cmr.svg", cm: "cmr.svg"
	},
	[GAME_TYPE.TURKISH_LEAGUE]: {
		ads: "ads.svg",
		aly: "aly.svg",
		ank: "ank.svg",
		ant: "ant.svg",
		bfk: "bfk.svg",
		bjk: "bjk.svg",
		crs: "crs.svg",
		fb: "fb.svg",
		fkg: "fkg.svg",
		gfk: "gfk.svg",
		gs: "gs.svg",
		hty: "hty.svg",
		ist: "ist.svg",
		kay: "kay.svg",
		kny: "kny.svg",
		ksp: "ksp.svg",
		pen: "pen.svg",
		sam: "sam.svg",
		svs: "svs.svg",
		ts: "ts.svg"
	}
}

export const flagCodeMapper = (gameType, flagCode) => {
	if (!flagsByCountry[gameType]) {
		return flagCode;
	}
	if (!flagCode || typeof flagCode !== "string") {
		return flagCode;
	}
	const lowerCaseFlagCode = flagCode.toLowerCase();
	if (!Object.prototype.hasOwnProperty.call(flagsByCountry[gameType], lowerCaseFlagCode)) {
		return flagCode;
	}
	return flagsByCountry[gameType][lowerCaseFlagCode];
};
