import {
	SET_KENO_SELECTED_BALLS,
	ADD_KENO_SELECTED_BALLS,
	REMOVE_KENO_SELECTED_BALLS,
	SET_KENO_ACTIVE_BALLS,
	SET_KENO_LAST_ADDED_BALLS,
	SET_KENO_HOVERED_BALLS,
	SET_KENO_LAST_BETS_BEFORE,
	SET_KENO_LAST_BETS_AFTER,
	SET_KENO_CURRENT_BETS_BEFORE,
	SET_KENO_CURRENT_BETS_AFTER,
	SET_KENO_CURRENT_BETS,
	UPDATE_KENO_CURRENT_BETS,
	SET_KENO_LAST_BETS
} from "../../actionTypes";

import { GAME_EVENT_TYPE } from "constants/game.constants";
import { KENO_WINNER_BALLS_COUNT } from "../../../constants/game.constants";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case SET_KENO_SELECTED_BALLS:
			return {
				...state,
				selectedBalls: payload.balls
			};
		case ADD_KENO_SELECTED_BALLS:
			return {
				...state,
				selectedBalls: state.selectedBalls.concat(Array.isArray(payload.balls) ? payload.balls : [payload.balls])
			};
		case REMOVE_KENO_SELECTED_BALLS:
			return {
				...state,
				selectedBalls: state.selectedBalls.filter((ball) => (Array.isArray(payload.balls) ? !payload.balls.includes(ball) : payload.balls !== ball))
			};
		case SET_KENO_ACTIVE_BALLS:
			return {
				...state,
				activeBalls: !payload.add ? payload.balls : state.activeBalls.length < KENO_WINNER_BALLS_COUNT ? state.activeBalls.concat([{ number: payload.balls[state.activeBalls.length], animating: true }]) : state.activeBalls
			};
		case SET_KENO_LAST_ADDED_BALLS:
			return {
				...state,
				lastAddedBalls: payload.balls
			};
		case SET_KENO_HOVERED_BALLS:
			return {
				...state,
				hoveredBalls: payload.balls
			};
		case SET_KENO_LAST_BETS_BEFORE:
			return {
				...state,
				isLastBetsLoading: true
			};
		case SET_KENO_LAST_BETS_AFTER:
			return {
				...state,
				isLastBetsLoading: false
			};
		case SET_KENO_CURRENT_BETS_BEFORE:
			return {
				...state,
				isCurrentBetsLoading: true
			};
		case SET_KENO_CURRENT_BETS_AFTER:
			return {
				...state,
				isCurrentBetsLoading: false
			};
		case SET_KENO_CURRENT_BETS:
			return {
				...state,
				currentBets: payload.bets
			};
		case UPDATE_KENO_CURRENT_BETS:
			return {
				...state,
				currentBets: state.currentBets.filter((currentBet) => payload.canceledBet.bets.findIndex((bet) => bet.id === currentBet.id) === -1)
			};
		case SET_KENO_LAST_BETS:
			return {
				...state,
				lastBets: payload.bets
			};
		default:
			return state;
	}
};
