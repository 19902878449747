import PropTypes from "prop-types";

import { BETSLIP_MODES, BET_STATE } from "constants/betslip.constants";
import { GAME_TYPE } from "constants/game.constants";
import { PERIOD } from "constants/market.constants";

import footballDataType from "./footballData.type";
import racingDataType from "./racingData.type";

export default PropTypes.shape({
	id: PropTypes.number,
	type: PropTypes.oneOf(Object.values(BETSLIP_MODES)),
	state: PropTypes.oneOf(Object.values(BET_STATE)),
	totalAmount: PropTypes.number,
	totalFactor: PropTypes.number,
	possibleWin: PropTypes.number,
	winning: PropTypes.number,
	betTime: PropTypes.string,
	payoutTime: PropTypes.string,
	betCount: PropTypes.number,
	eventIds: PropTypes.arrayOf(PropTypes.number),
	cancelReason: PropTypes.string,
	bets: PropTypes.arrayOf(
		PropTypes.shape({
			state: PropTypes.oneOf(Object.values(BET_STATE)),
			factor: PropTypes.number,
			amount: PropTypes.number,
			argument: PropTypes.number,
			group: PropTypes.string,
			outcome: PropTypes.string,
			gameData: PropTypes.oneOfType([footballDataType, racingDataType]),
			gameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
			startTime: PropTypes.string,
			period: PropTypes.oneOf(Object.values(PERIOD)),
			eventId: PropTypes.number,
			winning: PropTypes.number,
			possibleWin: PropTypes.number,
			allowCancel: PropTypes.bool,
			id: PropTypes.number,
			cancelReason: PropTypes.string
		})
	)
});
