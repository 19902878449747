import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Switch from "components/ui/switch";

import { BETSLIP_STAKE_MODES } from "constants/betslip.constants";
import { BONUS_TYPE } from "constants/bonus.constants";
import sessionType from "types/session.type";

import { setStakeMode } from "store/actions/betslip/betslip.actions";

/** Stake per bet/split switcher component*/
const BetStakeMode = ({ setStakeMode, stakeMode, useBonus, session }) => {
	const { t } = useTranslation();

	const currentBonus = session.bonuses?.[0] ?? {};
	const isFreeAmountBonus = useBonus && currentBonus.bonusType === BONUS_TYPE.FREEAMOUNT;

	// true if user can't change stake mode
	const readOnly = useBonus && !isFreeAmountBonus;

	/** Function which fires on switcher change
	 * @function
	 * @param {boolean} e - switcher value
	 * @memberOf BetStakeMode
	 */
	const onSwitchChange = (e) => {
		setStakeMode(e ? BETSLIP_STAKE_MODES.SPLIT : BETSLIP_STAKE_MODES.PER_BET);
	};

	return (
		<div
			className={
				"vs--stake-mode vs--flex vs--align-center vs--justify-center" +
				(readOnly ? " vs--stake-mode-disabled" : "")
			}
		>
			<span className="vs--title-white vs--font-regular vs--font-normal vs--pr-8">
				{t("bet.splitStake")}
			</span>
			<Switch
				onChange={onSwitchChange}
				checked={stakeMode === BETSLIP_STAKE_MODES.SPLIT}
			/>
		</div>
	);
};

/** BetStakeMode propTypes
 * PropTypes
 */
BetStakeMode.propTypes = {
	/** Redux state property, current stake split mode(per bet/split) */
	stakeMode: PropTypes.oneOf(Object.values(BETSLIP_STAKE_MODES)),
	/** Redux action to set current stake split mode */
	setStakeMode: PropTypes.func,
	/** Redux state property, bonus usage property */
	useBonus: PropTypes.bool,
	/** Redux state property, current session */
	session: sessionType
};

const mapStateToProps = (state) => {
	return {
		stakeMode: state.betslip.stakeMode,
		useBonus: state.bonus.useBonus,
		session: state.auth.session
	};
};

const mapDispatchToProps = (dispatch) => ({
	setStakeMode: (mode) => {
		dispatch(setStakeMode(mode));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(BetStakeMode);
