import TopBarGamesProvider from "providers/TopBarGamesProvider";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentGame } from "store/actions/game/game.actions";

const TopBarMenuHoc = (Component) => {
	const getCurrentGameType = (state) => state.game.currentGameType;
	const WrappedByTopBarMenuHocComponent = () => {
		const dispatch = useDispatch();
		const selectedGame = useSelector(getCurrentGameType);
		const setSelectedGame = useCallback((gt) => dispatch(setCurrentGame(gt)), [dispatch]);
		return (
			<TopBarGamesProvider showCountdown={true} selectedGame={selectedGame} setSelectedGame={setSelectedGame}>
				<Component />
			</TopBarGamesProvider>
		);
	};

	WrappedByTopBarMenuHocComponent.displayName = "WrappedByTopBarMenuHocComponent_" + Component.displayName;

	return WrappedByTopBarMenuHocComponent;
};

export default TopBarMenuHoc;
