import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import BetSlip from "./partials/betslip";
import PendingBets from "./partials/pendingBets";
import RightBarTabs from "./partials/tabs";

import AnimationMarker from "./partials/animationMarker";

import SessionStorageUtils from "utils/sessionStorage";
import { getCurrentSettings } from "utils/settings";

const getIsQuickBetEnabled = (state) => getCurrentSettings(state.auth.session).isQuickBet;
const getBetslipSuccess = state => state.betslip.success;

/* RightBar Component */
const RightBar = ({ rightbarTab }) => {
	const isQuickBetEnabled = useSelector(getIsQuickBetEnabled);
	const success = useSelector(getBetslipSuccess);

	return (
		<div className="vs--rightBar vs--ml-8">
			<RightBarTabs />
			{rightbarTab === 1 ? <BetSlip /> : <PendingBets />}
			{success && !isQuickBetEnabled && !SessionStorageUtils.get("repeatBet") && (
				<AnimationMarker />
			)}
		</div>
	);
};

/** RightBar propTypes
 * PropTypes
 */
RightBar.propTypes = {
	/** Redux state property, selected tab(betslip/pendings) */
	rightbarTab: PropTypes.oneOf([1, 2])
};

const mapStateToProps = (state) => {
	return {
		rightbarTab: state.betslip.rightbarTab
	};
};

export default connect(mapStateToProps, null)(RightBar);
