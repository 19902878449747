import axios from "axios";
import i18n from "i18next";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { BONUS_HISTORY_BEFORE, BONUS_HISTORY_AFTER, SET_BONUS_HISTORY, ADD_BONUS_HISTORY, SET_BONUS_HISTORY_FILTERS, SET_USE_BONUS } from "../../actionTypes";

import { binaryToFlags } from "utils/binaryCalculations";
import { getCurrentSettings } from "utils/settings";

import { saveSettings } from "store/actions/auth/auth.actions";
import { setStake, setStakeMode } from "../betslip/betslip.actions";
import { BETSLIP_STAKE_MODES } from "constants/betslip.constants";
import { BONUS_STATES, BONUS_TYPE } from "constants/bonus.constants";
import { GAME_TYPE_MAPPER } from "constants/game.constants";
import vsToast from "utils/toast";

const setBonusHistoryBefore = () => ({
	type: BONUS_HISTORY_BEFORE
});

const setBonusHistoryAfter = () => ({
	type: BONUS_HISTORY_AFTER
});

const setBonusHistory = (data) => ({
	type: SET_BONUS_HISTORY,
	payload: { data }
});

export const setBonusHistoryFilters = (filters) => ({
	type: SET_BONUS_HISTORY_FILTERS,
	payload: { filters }
});

const addBonusHistory = (data) => ({
	type: ADD_BONUS_HISTORY,
	payload: { data }
});

const setUseBonusAction = (data) => ({
	type: SET_USE_BONUS,
	payload: data
});

export const setUseBonus = (payload) => (dispatch, getState) => {
	dispatch(setUseBonusAction(payload));

	if (payload) {
		const state = getState();
		const bets = state?.betslip?.bets ?? [];
		const bonuses = state?.auth?.session?.bonuses ?? [];
		const availableGamesTypes = (state?.auth?.session?.games ?? []).map((game) => game.type);
		const settings = getCurrentSettings(state.auth.session);
		const singleMin = state.auth.session?.currency?.singleMin ?? 1;

		const difference = bonuses[0].amount - singleMin;
		const totalStack = difference < singleMin ? bonuses[0].amount : singleMin;

		let bonusType = 0,
			bonusGameType = 0,
			bonusAmount = 0,
			bonusRoundCount = 0;

		if (bonuses.length > 0) {
			bonusType = bonuses[0].bonusType;
			bonusGameType = bonuses[0].gameType;
			bonusAmount = bonusType === BONUS_TYPE.FREEAMOUNT ? (settings.isQuickBet ? totalStack : "") : bonuses[0].amount;
			bonusRoundCount = bonuses[0].roundCount;
		}

		const bonusGamesTypes = binaryToFlags(availableGamesTypes, bonusGameType);

		if (bets.some((bet) => !bonusGamesTypes.includes(bet.gameType))) {
			const notIncludedGamesText = availableGamesTypes.reduce((acc, gameType) => {
				if (!bonusGamesTypes.includes(gameType)) {
					acc += `${acc.length > 0 ? ", " : ""}${i18n.t(`common.${GAME_TYPE_MAPPER[gameType]}`)}`;
				}

				return acc;
			}, "");

			vsToast.error(i18n.t("errors.message.bonusInvalidGames").replace("%%notIncludedGames%%", notIncludedGamesText));
			return;
		}

		if (bonusType === BONUS_TYPE.FREEBET && bets.length > bonusRoundCount) {
			vsToast.error(i18n.t("errors.message.InappropriateQuantity"));
			dispatch(setUseBonusAction(false));
			return;
		}

		if(settings.isQuickBet && bonusAmount !== Number(settings.quickBetAmount)) {
			dispatch(saveSettings({ quickBetAmount: bonusAmount }));
		}

		dispatch(setStake(bonusAmount));
		dispatch(setStakeMode(BETSLIP_STAKE_MODES.PER_BET));
	}
};

export const getBonusHistory = (actionType) => {
	return (dispatch, getState) => {
		if (actionType !== "add") {
			dispatch(setBonusHistoryBefore());
		}

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_BONUS_HISTORY}`,
			method: Methods.GET,
			params: {
				...getState().bonus.filters,
				status: BONUS_STATES.ALL === getState().bonus.filters.status ? "" : getState().bonus.filters.status
			}
		})
			.then(({ data: { value: data } }) => {
				if (actionType === "add") {
					dispatch(addBonusHistory(data));
				} else {
					dispatch(setBonusHistory(data));
					dispatch(setBonusHistoryAfter());
				}
			})
			.catch(() => {
				if (actionType !== "add") {
					dispatch(setBonusHistoryAfter());
				}
			});
	};
};
