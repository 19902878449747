import { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useGlobalVariables from "hooks/useGlobalVariables";
import useEvent from "hooks/useEvent";
import Flag from "components/ui/flag";
import { mergeClassNames, isRTL } from "utils/common";
import { TableColumnNode } from "helpers/table.halper";
import { RESULTS, GAME_TYPE } from "constants/game.constants";

const getLanguageId = (state) => state.auth.session?.languageId ?? null;

const useStandingsColumns = ({ isFromResults, gameType }) => {
	const { t } = useTranslation();
	const [columnsData, setColumnsData] = useState([]);
	const { isMobile } = useGlobalVariables();
	const languageId = useSelector(getLanguageId);
	const isRtl = isRTL(languageId);

	const getLetterAndClassNameFromMatch = useEvent((match) => {
		switch (match.matchResult) {
			case RESULTS.WIN:
				return { letter: t("statistics.win").charAt(0), className: "vs--team-status vs--flex vs--justify-center vs--align-center vs--bg-green vs--font-bold vs--font-normal" };
			case RESULTS.DRAW:
				return { letter: t("statistics.draw").charAt(0), className: "vs--team-status vs--flex vs--justify-center vs--align-center vs--bg-yellow vs--font-bold vs--font-normal" };
			case RESULTS.LOSE:
				return { letter: t("statistics.lose").charAt(0), className: "vs--team-status vs--flex vs--justify-center vs--align-center vs--bg-red vs--font-bold vs--font-normal" };
			default:
				break;
		}
		return { letter: " - ", className: "" };
	});

	useLayoutEffect(() => {
		const showDividerForTeams = !isFromResults || (isFromResults && isMobile);
		const showDividerForAll = isFromResults && !isMobile;
		const columns = [
			new TableColumnNode(
				null,
				(
					isFromResults
						? (
							<span className="vs--title-white vs--font-medium vs--font-normal vs--pl-20">
								{t(`markets.${gameType}.positionShort`)}
							</span>
						)
						: null
				),
				(...args) => {
					const { tableTextClassName, rowIndex } = args.at(-1);
					return <span className={mergeClassNames(tableTextClassName, "vs--statistics-chart-standings-quick-inner-item-pos-num vs--pl-12 vs--pr-4", isFromResults && "vs--pl-20")}>{`${rowIndex + 1}.`}</span>;
				},
				{ width: 6 }
			),
			new TableColumnNode(
				"countryCode",
				(
					isFromResults
						? (
							<span className="vs--title-white vs--font-medium vs--font-normal vs--ml-16">
								{t(`markets.${gameType}.team`)}
							</span>
						)
						: (
							<span className="vs--standings-pos vs--title-white vs--font-medium vs--font-normal">
								{t(`markets.${gameType}.positionShort`)}
							</span>
						)
				),
				(countryCode, ...args) => {
					const { tableTextClassName } = args.at(-1);
					return (
						<div
							className={
								mergeClassNames(
									tableTextClassName,
									"vs--flex vs--justify-start vs--align-center",
									isFromResults && "vs--ml-16"
								)
							}
						>
							<Flag code={countryCode} gameType={gameType} />
							<span
								className={
									mergeClassNames(
										"vs--title-white vs--font-regular vs--font-normal vs--ml-8",
										showDividerForTeams && "vs--flex vs--flex-equal vs--justify-between vs--align-center"
									)
								}
							>
								{countryCode}
								{showDividerForTeams ? <div className="vs--table-standings-cell-with-divider"></div> : null}
							</span>
						</div>
					);
				},
				{ width: isFromResults ? "33%" : 74, alignment: isRtl ? "right" : "left" }
			),

			...[
				{ name: "played", title: <span className="vs--title-white vs--font-medium vs--font-normal">P</span> },
				{ name: "win", title: <span className="vs--title-white vs--font-medium vs--font-normal">W</span> },
				{ name: "draw", title: <span className="vs--title-white vs--font-medium vs--font-normal">D</span> },
				{ name: "lose", title: <span className="vs--title-white vs--font-medium vs--font-normal">L</span> }
			].map((colObj) => {
				return new TableColumnNode(
					colObj.name,
					colObj.title,
					(value, ...args) => {
						const { tableTextClassName } = args.at(-1);
						return (
							<span className={mergeClassNames(tableTextClassName, showDividerForAll && "vs--flex vs--flex-equal vs--justify-between vs--align-center")}>
								{value}
								{showDividerForAll ? <div className="vs--table-standings-cell-with-divider"></div> : null}
							</span>
						);
					},
					{ width: isFromResults ? 54 : null, colProps: { style: { minWidth: 16 } } }
				);
			})
		];

		if (!isMobile) {
			columns.push(
				...[
					{ name: "totalAgainstGoals", title: <span className="vs--title-white vs--font-medium vs--font-normal">GA</span> },
					{ name: "totalGoals", title: <span className="vs--title-white vs--font-medium vs--font-normal">GF</span> }
				].map((colObj) => {
					return new TableColumnNode(
						colObj.name,
						colObj.title,
						(value, ...args) => {
							const { tableTextClassName } = args.at(-1);
							return (
								<span className={mergeClassNames(tableTextClassName, showDividerForAll && "vs--flex vs--flex-equal vs--justify-between vs--align-center")}>
									{value}
									{showDividerForAll ? <div className="vs--table-standings-cell-with-divider"></div> : null}
								</span>
							);
						},
						{ width: isFromResults ? 54 : null }
					);
				})
			);
		}

		columns.push(
			// GD
			new TableColumnNode(
				null,
				<span className="vs--title-white vs--font-medium vs--font-normal">GD</span>,
				(...args) => {
					const { totalGoals, totalAgainstGoals } = args.at(1);
					const { tableTextClassName } = args.at(-1);
					return (
						<span className={mergeClassNames(tableTextClassName, showDividerForAll && "vs--flex vs--flex-equal vs--justify-between vs--align-center")}>
							{totalGoals - totalAgainstGoals}
							{showDividerForAll ? <div className="vs--table-standings-cell-with-divider"></div> : null}
						</span>
					);
				},
				{ width: isFromResults ? 54 : null }
			),

			// PTS
			new TableColumnNode(
				"points",
				<span className="vs--title-white vs--font-medium vs--font-normal">PTS</span>,
				(points, ...args) => {
					const { tableTextClassName } = args.at(-1);
					return <span className={tableTextClassName}>{points}</span>;
				},
				{ width: isFromResults ? 54 : null }
			)
		);

		if (isFromResults) {
			columns.push(
				new TableColumnNode(
					null,
					(
						<span className="vs--flex vs--justify-end vs--align-center vs--pr-14">
							<span className="vs--title-white vs--font-medium vs--font-normal vs--last-games vs--mr-54">{t("common.last5Games")}</span>
						</span>
					),
					(...args) => {
						const record = args.at(1);
						const { tableTextClassName } = args.at(-1);
						return (
							<span className={mergeClassNames(tableTextClassName, "vs--flex vs--justify-end vs--align-center vs--pr-14")}>
								{(record?.lastMatches ?? []).map((lm, i) => {
									const { letter, className } = getLetterAndClassNameFromMatch(lm);
									return (
										<span key={i} className={mergeClassNames(className, "vs--mr-16 vs--team-status")}>
											{letter}
										</span>
									);
								})}
							</span>
						);
					},
					{ width: "33%" }
				)
			);
		}

		setColumnsData(columns);
	}, [isFromResults, isMobile, isRtl, getLetterAndClassNameFromMatch]);

	return columnsData;
};

export default useStandingsColumns;
