import axios from "axios";
import { initTranslations } from "translations/config";
import store from "store/configureStore";
import { setMaintenanceMode } from "store/actions/auth/auth.actions";
import { refreshToken, logout, refreshStreamAccess } from "utils/auth";
import { showError } from "utils/message";
import { setCookie, getCookie } from "utils/common";
import SessionStorageUtils from "utils/sessionStorage";
import { RESPONSE_STATE } from "constants/response.constants";
import ApiUrls from "constants/api.constants";
axios.defaults.withCredentials = true;
const COOKIE_NAME = "_ga_anl_cf3q";

const checkPathAndUpdateCookies = (data, responseInfo) => {
	if (getCookie(COOKIE_NAME)) {
		return;
	}
	if (data?.status !== RESPONSE_STATE.SUCCESS) {
		return;
	}
	const responseURL = responseInfo?.request?.responseURL ?? "";
	if (!responseURL) {
		return;
	}
	const url = new URL(responseURL);
	if (![ApiUrls.AUTHENTICATE].some((path) => url.pathname.includes(path))) {
		return;
	}
	const token = data?.token;
	if (!token) {
		return;
	}
	setCookie(COOKIE_NAME, token, 365);
};

// const DOSRedirect = () => {

// 	const redirectUrl = new URL(import.meta.env.SYSTEM_API_URL);
// 	redirectUrl.pathname = Paths.CHALLENGE_PROCESSING;
// 	redirectUrl.search = "url=" + window.location.href;

// 	return window.location.replace(redirectUrl);
// }

axios.interceptors.request.use(
	(config) => {
		if (config?.headers?.grant_type !== "refresh_token") {
			const token = store.getState()?.auth?.player?.token;

			if (token) {
				config.headers["Authorization"] = "Bearer " + token;
			}

			//Set default content type
			if (!config.headers["Content-Type"] || config.headers["Content-Type"] === "text/plain;charset=utf-8") {
				config.headers["Content-Type"] = "application/json;charset=utf-8";
			}
		} else {
			delete config.headers["grant_type"];
		}

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	({ status, data, ...other }) => {
		checkPathAndUpdateCookies(data, other);

		if (data?.status !== RESPONSE_STATE.SUCCESS) {
			showError({
				message: data?.message ?? "",
				status: data?.status ?? "",
				resource: data?.resource ?? ""
			});
		}
		return { status, data };
	},
	(error) => {
		// if (!error.response) {
		// 	return DOSRedirect();
		// }
		const status = error?.response?.status;
		const errorData = error?.response?.data ?? {};
		if (status >= 400) {
			if (errorData.status === RESPONSE_STATE.UNAUTHORIZED) {
				if (status === 401) {
					const refresh_token = store.getState()?.auth?.player?.refreshToken ?? null;

					if (refresh_token) {
						const config = error.response.config;
						if (config.headers["stream"]) {
							return refreshStreamAccess(refresh_token, config);
						}
						return refreshToken(refresh_token, config);
					} else {
						logout();
					}
				} else if (status === 400) {
					logout();
				}
			} else if (errorData.status === RESPONSE_STATE.MAINTENANCE_MODE) {
				setTimeout(() => {
					SessionStorageUtils.remove("player");
					if (store.getState().auth.translationsLoaded) {
						store.dispatch(setMaintenanceMode());
					} else {
						initTranslations(null, "en", () => {
							store.dispatch(setMaintenanceMode());
						});
					}
				}, 0);
			} else {
				setTimeout(() => {
					const message = errorData.message ?? "";
					const status = errorData.status ?? "";
					const resource = errorData.resource ?? "";
					if (store.getState().auth.translationsLoaded) {
						showError({ message, status, resource });
						return;
					}

					initTranslations(null, "en", () => {
						showError({ message, status, resource });
					});
				}, 0);
			}
		}
		return Promise.reject(error);
	}
);
